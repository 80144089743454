import colors from './html-color-list';
import nearestColor from './nearest-color';
const getNearestColor = nearestColor.from(colors);

export default function getColorName(r, g, b) {
  if (r > 255) r = 255;
  if (g > 255) g = 255;
  if (b > 255) b = 255;

  try {
    const { name } = getNearestColor(rgbToHex(r, g, b));
    return name;
  } catch (err) {
    return null;
  }
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
