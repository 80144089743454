import { makeAutoObservable } from 'mobx';
import ScreenshotsStore from './Screenshots';
import AppScreenshotsStore from './AppScreenshots';

class UI {

  signupPopupVisible = false;
  profileVisible = false;
  searchVisible = false;
  searchTab = 'patterns';
  isFirefox = navigator?.userAgent?.toLowerCase()?.indexOf('firefox') > -1;
  welcomeProVisible = false;
  onboardingVisible = false;
  searchType = 'sites';
  showSurvey = false;
  selectedScreens = [];
  // showPaywall = false;
  // showPaywall = !!localStorage.getItem('signup-offer') ?? false;

  constructor() {
    makeAutoObservable(this);
  }

  showSignupPopup() {
    this.signupPopupVisible = true;
  }

  hideSignupPopup() {
    this.signupPopupVisible = false;
  }

  showProfile() {
    this.profileVisible = true;
  }

  hideProfile() {
    this.profileVisible = false;
  }

  showSearch() {
    this.searchVisible = true;
  }

  hideSearch() {
    this.searchVisible = false;
  }

  setSearchTab(tab = 'patterns') {
    this.searchTab = tab;
  }

  showOnboarding() {
    this.onboardingVisible = true;
  }

  hideOnboarding() {
    this.onboardingVisible = false;
  }

  setSearchType(type = 'sites') {
    this.searchType = type;
  }

  get searchUrl() {
    switch(this.searchType) {
      case 'sites':
        return '/search';
      case 'apps':
        return '/apps/search';
      default:
        return '/search';
    }
  }

  setShowSurvey(state = false) {
    this.showSurvey = state;
  }

  selectScreen(id) {
    const index = this.selectedScreens.findIndex(i => i === id);
    if (index === -1) {
      this.selectedScreens.push(id);
    }
    this._onSelectChange();
  }

  unSelectScreen(id) {
    const index = this.selectedScreens.findIndex(i => i === id);
    if (index > -1) {
      this.selectedScreens.splice(index, 1);
    }
    this._onSelectChange();
  }

  clearSelectScreen() {
    this.selectedScreens.forEach(id => {
      const screen = ScreenshotsStore.show(id) ?? AppScreenshotsStore.show(id);
      screen?.unSelect();
    });

    this.selectedScreens = [];
    this._onSelectChange();
  }

  _onSelectChange() {
    if (this.selectedScreens.length) {
      document.body.classList.add('has_selection');
    } else {
      document.body.classList.remove('has_selection');
    }
    
  }

  // setShowPaywall(state = false) {
  //   this.showPaywall = state;

  //   if (state) {
  //     localStorage.setItem('signup-offer', 1);
  //   } else {
  //     localStorage.removeItem('signup-offer');
  //   }
  // }

  getExperimentValue(key) {
    let value = localStorage.getItem(key);

    if (!value) {
      value = Math.floor(Math.random() * 2 + 1);
      localStorage.setItem(key, value);
    }

    return value;
  }

}

const UIStore = new UI();
export default UIStore;
