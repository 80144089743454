import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Title from 'components/Title/Title';
import * as Icons from 'icons';
import AlertStore from 'stores/Alert';
import styles from './Alerts.module.scss';

const Alerts = observer(() => {

  function onAnimationEnd(event, alert) {
    if (alert.hidden) {
      AlertStore.delete(alert.id);
    }
  }

  return (
    <div className={styles.container}>
      {AlertStore.list.map(alert => {
        if (alert.status === 'bookmark') {
          return (
            <Link 
              to="/bookmarks"
              className={classnames(styles.item, styles.blur, styles.bookmark, {
                [styles.hidden]: alert.hidden
              })}
              onAnimationEnd={event => onAnimationEnd(event, alert)}
              key={alert.id}
            >
              <Icons.Bookmark className={styles.iconBookmark} />
              {alert.message}
              <Icons.Greater2 className={styles.iconGreater} />
            </Link>
          )
        }
        return (
          <div 
            className={classnames(styles.item, {
              [styles.success]: alert.status === 'success',
              [styles.blur]: alert.status === 'blur',
              [styles.hidden]: alert.hidden
            })}
            onAnimationEnd={event => onAnimationEnd(event, alert)}
            key={alert.id}
          >
            {alert.status === 'error' &&
              <Title priority="3" noMargin>
                Error
              </Title>
            }
            {alert.message}
          </div>
        );
      })}
    </div>
  );

});

export default Alerts;
