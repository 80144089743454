import AuthRequest from './auth_request';

const origin = process.env.REACT_APP_API_ORIGIN;

function login(id_token) {
  return AuthRequest.post(`${origin}/auth/login`, {id_token});
}

function otpEmail(email) {
  return AuthRequest.post(`${origin}/otp`, {authentication: {email}});
}

function otpCode(email, code) {
  return AuthRequest.get(`${origin}/otp?code=${code}&email=${email}`);
}

function categories() {
  return AuthRequest.get(`${origin}/v1/categories/available`);
}

function elements() {
  return AuthRequest.get(`${origin}/v1/page_elements/available`);
}

function patterns() {
  return AuthRequest.get(`${origin}/v1/design_patterns/available`);
}

function sites(params = '') {
  if (params) {
    params = `?${params}`;
  }

  return AuthRequest.get(`${origin}/v1/sites${params}`);
}

function sitesAvailable(page = 1, category = null) {
  let params = `?page=${page}`;
  if (category) {
    params += `&category=${category}`;
  }
  return AuthRequest.get(`${origin}/v1/sites/available${params}`);
}

function screenshots(siteId) {
  return AuthRequest.get(`${origin}/v1/sites/${siteId}/screenshots`);
}

function search(params = null) {
  if (params === 'root') {
    params = null;
  }

  if (params) {
    params = `?${params}`;
  }

  return AuthRequest.get(`${origin}/v1/search${params ?? ''}`);
}

function searchApps(params = null) {
  if (params === 'root') {
    params = null;
  }

  if (params) {
    params = `?${params}`;
  }

  return AuthRequest.get(`${origin}/v1/search/apps${params ?? ''}`);
}

function site(id) {
  return AuthRequest.get(`${origin}/v1/sites/${id}`);
}

function sitesRandom() {
  return AuthRequest.get(`${origin}/v1/sites/random`);
}

function sitesSearch(query) {
  return AuthRequest.get(`${origin}/v1/sites/search?query=${query}`);
}

function app(id) {
  return AuthRequest.get(`${origin}/v1/apps/${id}`);
}

function appScreenshots(id) {
  return AuthRequest.get(`${origin}/v1/apps/${id}/screenshots`);
}

function users() {
  return AuthRequest.get(`${origin}/v1/users`);
}

function bookmarks() {
  return AuthRequest.get(`${origin}/v1/bookmarks`);
}

function bookmarksScreenshots() {
  return AuthRequest.get(`${origin}/v1/bookmarks/screenshots`);
}

function bookmarksCreate(id) {
  return AuthRequest.post(`${origin}/v1/bookmarks`, { screenshot_id: id });
}

function bookmarksDelete(id) {
  return AuthRequest.delete(`${origin}/v1/bookmarks/${id}`);
}

function screenshotsSimilar(id, page) {
  return AuthRequest.get(`${origin}/v1/screenshots/${id}/similar${page ? `?page=${page}` : ''}`);
}

function appScreenshotsSimilar(id, page) {
  return AuthRequest.get(`${origin}/v1/app_screenshots/${id}/similar${page ? `?page=${page}` : ''}`);
}

function trackings(id) {
  const data = {
    tracking: {
      id,
      type: 'screenshot'
    }
  };
  return AuthRequest.post(`${origin}/v1/trackings`, data);
}

function subscriptionPlans(key = 'default') {
  // return AuthRequest.get(`${origin}/v1/subscription_plans?key=${key}`);
  return AuthRequest.get(`https://api.refero.design/v1/subscription_plans?key=${key}`);
}

function subscriptions() {
  return AuthRequest.get(`${origin}/v1/subscriptions`);
}

function subscriptionCancel(id) {
  return AuthRequest.delete(`${origin}/v1/subscriptions/${id}`);
}

function addAbandonedCart() {
  return AuthRequest.post(`${origin}/v1/users/add_abandoned_cart`);
}

function appsAvailable(page = 1) {
  return AuthRequest.get(`${origin}/v1/apps/available?page=${page}`);
}

function bookmarksFolders() {
  return AuthRequest.get(`${origin}/v1/bookmarks/folders/`);
}

function bookmarksFoldersCreate(name) {
  return AuthRequest.post(`${origin}/v1/bookmarks/folders`, {folder: {name}});
}

function bookmarksFoldersUpdate(id, name) {
  return AuthRequest.put(`${origin}/v1/bookmarks/folders/${id}`, {folder: {name}});
}

function bookmarksFoldersDelete(id) {
  return AuthRequest.delete(`${origin}/v1/bookmarks/folders/${id}`);
}

function bookmarksFolder(id) {
  return AuthRequest.get(`${origin}/v1/bookmarks/folders/${id}`);
}

function bookmarkAdd(folderId, screenId) {
  return AuthRequest.post(`${origin}/v1/bookmarks/folders/${folderId}/add`, { screenshot_id: screenId });
}

function bookmarkRemove(folderId, screenId) {
  return AuthRequest.post(`${origin}/v1/bookmarks/folders/${folderId}/remove`, { screenshot_id: screenId });
}

function teamCreate(name, logo_url, domains) {
  return AuthRequest.post(`${origin}/v1/teams`, { name, logo_url, domains });
}

function teamUpdate(id, name, logo_url, domains) {
  return AuthRequest.put(`${origin}/v1/teams/${id}`, { name, logo_url, domains });
}

function teamDeleteMember(teamId, userid) {
  return AuthRequest.delete(`${origin}/v1/teams/${teamId}/members/${userid}`);
}

function teamList() {
  return AuthRequest.get(`${origin}/v1/teams`);
}

function teamPresignedUrl() {
  return AuthRequest.post(`${origin}/v1/teams/presigned_teamlogo_url`);
}

function teamJoin(t) {
  return AuthRequest.post(`${origin}/v1/teams/join`, { t });
}

function customerPortal() {
  return AuthRequest.get(`${origin}/v1/users/customer_portal`);
}



export default {
  login,
  otpEmail,
  otpCode,
  categories,
  elements,
  patterns,
  search,
  searchApps,
  sites,
  site,
  sitesAvailable,
  sitesSearch,
  sitesRandom,
  screenshots,
  users,
  bookmarks,
  bookmarksCreate,
  bookmarksDelete,
  bookmarksScreenshots,
  screenshotsSimilar,
  appScreenshotsSimilar,
  trackings,
  app,
  appScreenshots,
  subscriptionPlans,
  subscriptions,
  subscriptionCancel,
  addAbandonedCart,
  appsAvailable,
  bookmarksFolders,
  bookmarksFoldersCreate,
  bookmarksFoldersUpdate,
  bookmarksFoldersDelete,
  bookmarksFolder,
  bookmarkAdd, 
  bookmarkRemove,
  teamCreate,
  teamUpdate,
  teamList,
  teamPresignedUrl,
  teamDeleteMember,
  customerPortal,
  teamJoin
}
