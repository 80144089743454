import { Suspense } from 'react';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';
import styles from './Terms.module.scss';

export default function Terms({ uid }) {
  const [ document ] = usePrismicDocumentByUID('terms', uid);

  return (
    <Layout>
      <div className={styles.container}>
        <Suspense fallback={<div>Loading</div>}>
          <Title>
            {document?.data?.title?.[0]?.text}
          </Title>
        </Suspense>
        
        <PrismicRichText field={document?.data?.text} />
      </div>
    </Layout>
  );
}
