import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import Button from 'components/Button/Button';
import FolderPreview from 'components/FolderPreview/FolderPreview';
import BookmarksStore from 'stores/Bookmarks';
import styles from './BookmarksPopup.module.scss';
import Placeholder from 'components/Placeholder/Placeholder';

const BookmarksPopup = observer(() => {

  const form = useRef(null);
  const [ name, setName ] = useState('');
  const [ action, setAction ] = useState('edit');
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    if (BookmarksStore.screenToAdd) {
      document.body.classList.add(styles.bodyFix);
      fetch();
    } else {
      document.body.classList.remove(styles.bodyFix);
    }
  }, [ BookmarksStore.screenToAdd ]);

  useEffect(() => {
    if (BookmarksStore.screenToAdd) {
      disableScope('screen');
    } else {
      enableScope('screen');
    }
  }, [ BookmarksStore.screenToAdd ]);

  useHotkeys('esc', close, {
    scopes: 'bookmarks',
    enableOnFormTags: true
  });

  function fetch() {
    if (!BookmarksStore.folders?.length) {
      BookmarksStore.fetchFolders();
    }
  }

  async function submit(event) {
    event.preventDefault();
    await BookmarksStore.createFolder(name);
    setName('');
    setAction('edit');
  }

  function close() {
    setAction('edit');
    BookmarksStore.setScreenToAdd(null);
  }

  if (!BookmarksStore.screenToAdd) {
    return null;
  }

  function renderCreateBody() {
    return (
      <>
        <form 
          className={styles.form}
          ref={form} 
          onSubmit={submit} 
          disabled={BookmarksStore.creatingFolder}
        >
          <div className={styles.title}>
            Create Board
          </div>

          <div className={styles.row}>
            <input 
              value={name}
              onChange={event => setName(event.target.value)}
              autoFocus
              className={styles.field}
              type="text" 
              required
              maxLength="255"
              placeholder="Board Name"
            />
          </div>

          <div className={styles.buttons}>
            <Button 
              square 
              light 
              compact 
              onClick={event => setAction('edit')}
            >
              Cancel
            </Button>
            <Button 
              square 
              compact 
              element="button"
              className={styles.submit}
            >
              Create
            </Button>
          </div>
        </form>
      </>
    )
  }

  function renderBody() {
    return (
      <>
        <div className={styles.title}>
          Add Screen to Bookmarks
        </div>

        {/* <div className={styles.label}>
          Latest
        </div> */}
        
        <div className={styles.folders}>
          {!BookmarksStore.folders?.length && BookmarksStore.fetching ?
            <Placeholder name="folderPreview" />
          : null }


          {BookmarksStore.folders?.map((id, index) => {
            const folder = BookmarksStore.getFolder(id);
            return <FolderPreview folder={folder} key={id} withoutAnimation={index > 2} />
          })}
        </div>

        <div className={styles.buttons}>
          <Button 
            square 
            light 
            compact 
            onClick={event => setAction('create')}
          >
            Create New Board
          </Button>
          <Button 
            square 
            compact 
            element="button"
            className={styles.submit}
            onClick={close}
          >
            Close
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.bg} onClick={close} />
      <div className={styles.popup}>
        {action === 'edit' ? renderBody() : null}
        {action === 'create' ? renderCreateBody() : null}
      </div>
    </div>
  )
});

export default BookmarksPopup;
