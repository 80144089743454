
import classnames from 'classnames';
import styles from './Layout.module.scss';

function Layout({children, home = false, withTopPadding}) {
  return (
    <div 
    className={classnames(styles.container, {
      [styles.home]: home,
      [styles.withTopPadding]: withTopPadding,
    })}
    >
      <div 
        className={classnames(styles.body)}
      >
        {children}
      </div>
    </div>
  );
}

function WithPadding({children}) {
  return (
    <div className={styles.withPadding}>
      {children}
    </div>
  )
}

function HomeWithPadding({children}) {
  return (
    <div className={styles.homeWithPadding}>
      {children}
    </div>
  ) 
}

Layout.WithPadding = WithPadding;
Layout.HomeWithPadding = HomeWithPadding;

export default Layout;