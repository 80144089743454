export default function Lock3(props) {
  return (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 12V7a3 3 0 1 1 6 0v5" stroke="currentColor" strokeWidth={1.6} />
    <rect
      x={5.8}
      y={11.8}
      width={12.4}
      height={8.4}
      rx={1.2}
      stroke="currentColor"
      strokeWidth={1.6}
    />
  </svg>
  )
}

