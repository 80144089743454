import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import * as Icons from 'icons';
import BookmarksStore from 'stores/Bookmarks';
import styles from './FolderPreview.module.scss';
import { useState } from 'react';

const FolderPreview = observer(({ folder, withoutAnimation }) => {

  const [ updating, setUpdating ] = useState(false);

  async function onClick() {
    setUpdating(true);
    if (isAdded) {
      await folder.removeScreen(BookmarksStore.screenToAdd);
    } else {
      await folder.addScreen(BookmarksStore.screenToAdd);
    }
    setUpdating(false);
  }

  const isAdded = folder?.isAdded(BookmarksStore.screenToAdd);

  return (
    <div 
      className={classNames(styles.folder, {
        [styles.updating]: updating,
        [styles.withoutAnimation]: withoutAnimation
      })} 
      onClick={onClick}
    >
      <Icons.Bookmarks2 
        className={classNames(styles.icon, isAdded ? styles.filled : null)} 
      />
      <div className={styles.name}>
        {folder.name}
      </div>
      
    </div>
  );
});

export default FolderPreview;
