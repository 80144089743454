import card1 from 'images/cards/pattern-11.jpeg';
import card12 from 'images/cards/pattern-12.jpeg';
import card13 from 'images/cards/pattern-13.jpeg';
import card21 from 'images/cards/pattern-21.jpeg';
import card22 from 'images/cards/pattern-22.jpeg';
import card23 from 'images/cards/pattern-23.jpeg';
import card31 from 'images/cards/pattern-31.jpeg';
import card32 from 'images/cards/pattern-32.jpeg';
import card33 from 'images/cards/pattern-33.jpeg';
import card41 from 'images/cards/pattern-41.jpeg';
import card42 from 'images/cards/pattern-42.jpeg';
import card43 from 'images/cards/pattern-43.jpeg';
import card51 from 'images/cards/pattern-51.jpeg';
import card52 from 'images/cards/pattern-52.jpeg';
import card53 from 'images/cards/pattern-53.jpeg';
import card61 from 'images/cards/pattern-61.jpeg';
import card62 from 'images/cards/pattern-62.jpeg';
import card63 from 'images/cards/pattern-63.jpeg';

const APP_PATTERNS_LIST = [
  {
    name: 'Empty State',
    id: 116,
    images: [card1, card12, card13],
    enlarged: false,
    apps: true
  },
  {
    name: 'Product Features',
    id: 209,
    images: [card21, card22, card23],
    enlarged: false,
    apps: true
  },
  {
    name: 'Audio Player',
    id: 162,
    images: [card31, card32, card33],
    enlarged: false,
    apps: true
  },
  {
    name: 'Dark Mode',
    id: 120,
    images: [card41, card42, card43],
    enlarged: false,
    apps: true
  },
  {
    name: 'Stats',
    id: 163,
    images: [card51, card52, card53],
    enlarged: false,
    apps: true
  },
  {
    name: 'Media Editing',
    id: 185,
    images: [card61, card62, card63],
    enlarged: false,
    apps: true
  },
  {
    name: 'Article & Text',
    id: 160,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Wish List & Bookmark',
    id: 183,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Suggestion & Similar Items',
    id: 222,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Testimonials',
    id: 153,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Feedback & Survey',
    id: 119,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Trial & Freemium',
    id: 212,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Tutorial & Education',
    id: 218,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Searching',
    id: 192,
    enlarged: false,
    compact: true,
    apps: true
  }
];

export default APP_PATTERNS_LIST;