import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './Cards.module.scss';
import mixpanel from 'api/mixpanel';

function Cards({ children, mobileColumn }) {

  const [ ghostChildren, setGhostChildren ] = useState(null);
  const [ cachedChildren, setCachedChildren ] = useState(null);
  const [ hiding, setHiding ] = useState(false);
  const [ hidden, setHidden ] = useState(false);
  const [ isReady, setReady ] = useState(false);
 
  useEffect(() => {
    setHiding(true);
    setHidden(true);
    // setTimeout(() => {
      setCachedChildren(children);
      setHidden(false);
    // }, 100);
    if (ghostChildren === null) {
      clone();
    }
  }, [ children ]);

  function onAnimationEnd() {
    clone();
    setHiding(false);
  }

  function onReady() {
    setReady(true);
  }

  function clone() {
    const elements = children?.slice(0, 6);
    setGhostChildren(elements);
  }

  return (
    <div className={classnames(styles.container, {
      [styles.mobileColumn]: mobileColumn
    })}>

      <div 
        className={classnames(styles.grid, styles.ghostChildren, {
          [styles.mobileColumn]: mobileColumn,
          [styles.hiding]: hiding,
          [styles.ready]: isReady,
        })}
        onAnimationEnd={onAnimationEnd}
      >
        {ghostChildren}
      </div>

      <div 
        className={classnames(styles.grid, styles.content, {
          [styles.hidden]: hidden
        })}
        onAnimationEnd={onReady}
      >
        {cachedChildren ?? children}
      </div>
    </div>
  )
} 

function Item({ to, title, subTitle, children, hideCollage = false }) {
  return (
    <Link 
      to={to} 
      className={styles.item} 
      onClick={event => mixpanel.track('HomeCardClick')}
    >
      {!hideCollage ?
        <div className={styles.collageWrapper}>
          {children}
        </div>
      : null}
      <div className={styles.body}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.subTitle}>
          {subTitle}
        </div>
      </div> 
    </Link>
  )
}

function Screens({ images }) {
  return (
    <div className={classnames(styles.screens)}>
      {images?.map((image, index) =>
        <ScreenImage 
          key={image}
          src={image}
        />
      )}
    </div>
  )
}

function ScreenImage({ src }) {
  const [ loaded, setLoaded ] = useState(false);

  return (
    <div className={styles.screen}>
      <img 
        className={classnames(styles.screenImage, 
          styles.hidden, {
            [styles.visible]: loaded
          }
        )}
        onLoad={event => setLoaded(true)}
        loading="lazy"
        src={src} 
        alt="" 
        key={src}
      />
    </div>
  );
}

function Screen({ image, logos }) {
  return (
    <div className={classnames(styles.screens)}>
      <img 
        className={styles.singleScreen}
        src={image} 
        alt="" 
      />
      {logos.length ? 
        <div className={styles.logos}>
          {logos.map(logo =>
            <img 
              className={styles.logo}
              loading="lazy"
              src={logo} 
              alt="" 
              key={logo} 
            />  
          )}
        </div>
      : null}
    </div>
  )
}

function AppScreens({ images }) {
  return (
    <div className={classnames(styles.appScreens)}>
      {images?.map((image, index) =>
        <AppScreen 
          src={image} 
          key={image}
        />
      )}
    </div>
  )
}

function AppScreen({ src }) {
  const [ loaded, setLoaded ] = useState(false);

  return (
    <img 
      className={classnames(styles.screen, {
        [styles.hidden]: !loaded
      })}
      src={src}
      onLoad={event => setLoaded(true)}
      alt="" 
    />
  )
}

function Background({ type, id }) {
  return (
    <div className={styles.bg}>
      <div className={classnames(styles.bgImage, styles[`${type}-${id}`])} />
    </div>
  )
}

Cards.Item = Item;
Cards.Screen = Screen;
Cards.Screens = Screens;
Cards.AppScreens = AppScreens;
Cards.Background = Background;

export default Cards;