export default function Lock2(props) {
  return (
    <svg
      width={10}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3a1 1 0 0 1 2 0v2H4V3ZM2 5V3a3 3 0 0 1 6 0v2a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2Z"
        fill="currentColor"
      />
    </svg>
  )
}