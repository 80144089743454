import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import DownloadStore from 'stores/Download';
import styles from './Downloads.module.scss';

const Downloads = observer(() => {

  // if (!DownloadStore.list?.length) {
  //   return null;
  // }

  return (
    <div 
      className={classnames(styles.container, {
        [styles.visible]: !!DownloadStore.list?.length,
      })}
    >
      <div className={styles.label}>
        Preparing Screen&hellip;
      </div>
      {DownloadStore.list?.map(item => 
        <div 
          className={styles.item} 
          key={item.id}
        >
          <div className={styles.progress}>
            <div 
              className={styles.value} 
              style={{'width': `${item.progress * 100}%`}}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Downloads;
