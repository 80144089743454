import { makeAutoObservable, runInAction } from 'mobx';
import { search } from 'fast-fuzzy';
import CATEGORIES from 'json/categories.json';
import DESIGN_PATTERNS from 'json/design-patterns.json';
import PAGE_ELEMENTS from 'json/page-elements';
import PAGE_TYPES from 'json/page-types.json';
import FONTS from 'json/fonts.json';
import UIStore from './UI';

const SITES_URL = '/json/sites.json';
const APPS_URL = '/json/apps.json';

DESIGN_PATTERNS.sort((a, b) => a.kind.localeCompare(b.kind));
PAGE_ELEMENTS.sort((a, b) => a.kind.localeCompare(b.kind));
PAGE_TYPES.sort((a, b) => a.kind.localeCompare(b.kind));

const TABS = {
  'sites': ['page_types', 'design_patterns', 'page_elements', 'categories', 'fonts'],
  'apps': ['page_types', 'design_patterns', 'page_elements', 'categories']
};

const ALLOWED_PARAMS = [
  'design_patterns[id][]=',
  'page_types[id][]=',
  'page_elements[id][]=',
  'categories[id][]=',
  'fonts[id][]=',
  'site_id[id][]=',
  'app_id[id][]'
];

class Search {

  numberOfColumns = 3;
  visible = false;
  items = [];
  query = '';
  tab = TABS['sites'][0];
  selected = null;
  filtered = null;
  selectedIndex = 0;
  suggestions = null;
  order = 'default';
  grouped = false;

  patternKinds = this.getKinds(DESIGN_PATTERNS);
  elementKinds = this.getKinds(PAGE_ELEMENTS);  
  pageTypeKinds = this.getKinds(PAGE_TYPES);  

  patternCells = null;
  elementCells = null;
  pageTypeCells = null;
  categorieCell = null;
  fontCells = null;

  dataCache = {};
  _fetchJsonPromise = {};
  _lastSearch = null;

  constructor() {
    makeAutoObservable(this);
  }

  show() {
    this.visible = true;  
    this.generateAllCells();
  }

  hide() {
    this.visible = false;
  }

  setQuery(newQuery) {
    this.query = newQuery;
    this.reset();

    if (this.query?.length > 2) {
      this.filter(this.query);
      this.search(this.query);
    }
  }

  reset() {
    this.selected = null;
    this.selectedIndex = 0;
    this.filtered = [];
  }

  pop() {
    return this.items.pop();
  }

  async addParams(params) {
    // let needPopulateData = false;

    const items = params?.replace('?', '').split('&')?.filter(item => !!item).map(item => {
      return [
        item.split('[id]')?.[0] ?? null,
        parseInt(item.replace(/[^0-9]/g,''))
      ]
    });

    const query = items?.find(item => item[0]?.includes('query='))?.[0]?.split('=')?.[1];
    
    if (query) {
      this.setQuery(decodeURI(query));
    } else {
      this.setQuery('');
    }

    const order = items?.find(item => item[0]?.includes('order='))?.[0]?.split('=')?.[1];
    
    if (order) {
      this.order = order;
    } else {
      this.order = 'default';
    }

    const grouped = items?.find(item => item[0]?.includes('grouped='))?.[0]?.split('=')?.[1];

    if (grouped) {
      this.grouped = true;
    } else {
      this.grouped = false;
    }

    const result = [];
    let index = 0;
    while(items[index]) {
      const item = items[index];
      const type = item[0];
      const id = item[1];

      if (type === 'site_id' || type === 'app_id') {
        const url = type === 'site_id' ? SITES_URL : APPS_URL;
        const sites = await this.fetchJson(url);
        this._clearFetchPromiseCache(url)
        const site = sites?.find(_site => id == _site.id);
        if (site) {
          result.push({
            type, 
            value: site
          });
        }
      }

      result.push({
        type,
        value: this.getList(type)?.find(el => el.id === id)
      });

      index++;
    }

    runInAction(() => {
      this.items = result?.filter(item => !!item.type && !!item.value);
    })
  } 

  async populateItems() {
    const result = [];
    let index = 0;

    while(this.items[index]) {
      const item = this.items[index];
      if ((item.type === 'site_id' || item.type === 'app_id')) {
        const url = item.type === 'site_id' ? SITES_URL : APPS_URL;
        const sites = await this.fetchJson(url);
        this._clearFetchPromiseCache(url)

        // console.log('sites', url, sites?.find(({id}) => id == item.value));
        const site = sites?.find(({id}) => id == item.value);
        if (site) {
          result.push({
            type: item.type,
            value: sites?.find(({id}) => id == item.value)
          });
        }
      }
      result.push(item);
      
      index++;
    }

    this.items = result;
  }

  getKinds(list) {
    const kinds = list.map(({kind}) => kind);
    return [...new Set(kinds)];
  }

  patternFilter(kind, searchType = UIStore.searchType) {
    const ids = this.items?.filter(item => item.type === 'design_patterns')?.map(item => item.value.id);

    return DESIGN_PATTERNS.filter(pattern => {
      return (searchType === 'sites' ? pattern.desktop : pattern.mobile) && pattern.kind === kind
    })?.map(item => {      
      return {
        ...item,
        active: ids?.includes(item.id)
      }
    });
  }

  elementFilter(kind, searchType = UIStore.searchType) {
    const ids = this.items?.filter(item => item.type === 'page_elements')?.map(item => item.value.id);

    return PAGE_ELEMENTS.filter(element => {
      return (searchType === 'sites' ? element.desktop : element.mobile) && element.kind === kind
    })?.map(item => {      
      return {
        ...item,
        active: ids?.includes(item.id)
      }
    });
  }

  pageTypeFilter(kind, searchType = UIStore.searchType) {
    const ids = this.items?.filter(item => item.type === 'page_types')?.map(item => item.value.id);

    return PAGE_TYPES.filter(type => {
      return (searchType === 'sites' ? type.desktop : type.mobile) && type.kind === kind;
    })?.map(item => {      
      return {
        ...item,
        active: ids?.includes(item.id)
      }
    });
  }

  normalize(list) {
    const kinds = list.filter(this.filterKinds.bind(this))?.map(({ kind }) => {
      return {
        kind,
        items: []
      }
    });
    kinds.forEach(kindItem => {
      kindItem.items = list.filter(item => item.kind === kindItem.kind);
    });
    return kinds;
  }

  filterKinds({ kind }, index, arr) {
    return arr.findIndex(item => item.kind === kind) === index;
  }

  setTab(tab) {
    this.tab = tab;
    this.selected = null;
  }

  prev(skip = 1) {
    if (this.filtered?.length) {
      skip = 1;
      const list = this.filtered?.length ? this.filtered : this.getList(this.tab);
      // let index = list?.findIndex(item => item.id === this.selected || item.value?.id === this.selected);
      let index = list?.findIndex(item => item.suggestionId === this.selected || item.value?.id === this.selected);
      index -= skip;
      // this.selected = list?.[index]?.['id'] ?? list?.[list.length - 1]?.['id'] ?? list?.[index]?.['value']?.['id'] ?? list?.[list.length - 1]?.['value']?.['id'];
      this.selected = list?.[index]?.['suggestionId'] ?? list?.[0]?.['suggestionId'];
      return this.selected;
    }

    const list = this.getCellList();

    if (this.selected === null) {
      this.selected = list[0];
      return this.selected;
    } else {
      const index = Math.max(list.findIndex(id => id === this.selected), 0);
      const column = (index) % this.numberOfColumns;
      this.selected = this._getPrevCell(list, index, skip, column);
    }

    return this.selected;
  }

  next(skip = 1) {
    if (this.filtered?.length) {
      skip = 1;
      const list = this.filtered?.length ? this.filtered : this.getList(this.tab);
      let index = list?.findIndex(item => item.suggestionId === this.selected || item.value?.id === this.selected);
      index += skip;
      // this.selected = list?.[index]?.['id'] ?? list?.[0]?.['id'] ?? list?.[index]?.['value']?.['id'] ?? list?.[0]?.['value']?.['id'];
      this.selected = list?.[index]?.['suggestionId'] ?? list?.[0]?.['suggestionId'];
      return this.selected;
    }

    const list = this.getCellList();

    if (this.selected === null) {
      this.selected = list[0];
      return this.selected;
    } else {
      const index = Math.max(list.findIndex(id => id === this.selected), 0);
      const column = (index) % this.numberOfColumns;
      this.selected = this._getNextCell(list, index, skip, column);
    }
  
    return this.selected;
  }

  _getNextCell(cells, index, skip, column) {
    let result = null;

    while(!result) {
      index += skip;
      result = cells[index];
      if (result === undefined) {
        result = cells[skip === 1 ? 0 : column];
      }
    }

    return result;
  }

  _getPrevCell(cells, index, skip, column) {
    let result = null;

    while(!result) {
      index -= skip;
      if (index < 0) {
        index = cells.length - 1 - (skip === 1 ? 0 :(this.numberOfColumns - 1 - column));
      }
      result = cells[index];
    }

    return result;
  }

  prevTab() {
    const tabList = TABS[UIStore.searchType];
    let index = tabList?.findIndex(tab => tab === this.tab);
    index--;
    const tab = tabList[index] ?? tabList[tabList.length - 1];
    this.setTab(tab);
  }

  nextTab() {
    const tabList = TABS[UIStore.searchType];
    let index = tabList?.findIndex(tab => tab === this.tab);
    index++;
    const tab = tabList[index] ?? tabList[0];
    this.setTab(tab);
  }

  getList(key = this.tab) {
    switch (key) {
      case 'page_types':
        return PAGE_TYPES.filter(item => (UIStore.searchType === 'sites' ? item.desktop : item.mobile));
      case 'design_patterns':
        return DESIGN_PATTERNS.filter(item => (UIStore.searchType === 'sites' ? item.desktop : item.mobile));
      case 'page_elements':
        return PAGE_ELEMENTS.filter(item => (UIStore.searchType === 'sites' ? item.desktop : item.mobile));
      case 'categories':
        return CATEGORIES;
      case 'fonts':
        return FONTS;
      default:
        return null;
    }
  }

  getCellList(key = this.tab) {
    switch (key) {
      case 'page_types':
        return this.pageTypeCells;
      case 'design_patterns':
        return this.patternCells;
      case 'page_elements':
        return this.elementCells;
      case 'categories':
        return this.categorieCell;
      case 'fonts':
        return this.fontCells;
      default:
        return null;
    }
  }

  isSelected(type, id) {
    return !!this.items?.filter(item => item.type === type)?.find(item => item.value.id === id);
  }

  filter(query) {
    const pageTypes = this.filterGroup(PAGE_TYPES, query, 'page_types');
    if (pageTypes.list?.length) {
      this.filtered = [...this.filtered, ...pageTypes.list];
    }

    const patterns = this.filterGroup(DESIGN_PATTERNS, query, 'design_patterns');
    if (patterns.list?.length) {
      this.filtered = [...this.filtered, ...patterns.list];
    }

    const elements = this.filterGroup(PAGE_ELEMENTS, query, 'page_elements');
    if (elements.list?.length) {
      this.filtered = [...this.filtered, ...elements.list];
    }

    const categories = this.filterGroup(CATEGORIES, query, 'categories');
    if (categories.list?.length) {
      this.filtered = [...this.filtered, ...categories.list];
    }

    const fonts = this.filterGroup(FONTS, query, 'fonts');
    if (fonts.list?.length) {
      this.filtered = [...this.filtered, ...fonts.list];
    }

    this.filtered.sort((a, b) => {
      return b.score - a.score;
    });

    this.filtered.unshift({
      type: 'query',
      suggestionId: `query_${query}`,
      value: {
        id: query,
        name: this.query,
      }
    });

    // this.selected = this.filtered?.[0]?.['value']?.['id'];
    this.selected = this.filtered?.[0]?.['suggestionId'];
  }

  filterGroup(group, query, type) {
    const items = group?.filter(({ desktop, mobile }) => {
      return (UIStore.searchType === 'sites' ? desktop : mobile) || type === 'categories' || type === 'fonts';
    });

    const threshold = Math.max(1 - Math.min((query.length - 2) / 10, .85), .6);
    const result = search(query, items, {
      returnMatchData: true,
      threshold,
      keySelector: (item) => {
        return [ item.name, ...(item.aliases ?? []) ].filter(item => !!item)
      }
    })
    // .slice(0, 5)
    .map(data => {
      const level = data.original === data.item?.name || data.original === data.item?.app_name ? 1 : 2;
      const addScore = data.match.index === 0 ? .2 : 0;
      return { 
        type, 
        level,
        value: data.item,
        score: data.score + addScore + (level === 1 ? .2 : 0),
        suggestionId: `${type}_${data.item.id}`
      }
    })
    .sort((a, b) => {
      if (a.level < b.level ){
        return -1;
      }
      if ( a.level > b.level ){
        return 1;
      }
      return 0;
    })
    .slice(0, 5)

    return {
      list: result,
      maxScore: Math.max(...result.map(item => item.score))
    };
  }

  getFilteredItem() {
    // return this.filtered?.find(item => item.value?.id === this.selected);
    return this.filtered?.find(item => item.suggestionId === this.selected);
  }

  normalizeSearchParams(params = []) {
    // params = params?.filter(item => !item.includes('query=') && !item.includes('order='));
    // params = params?.filter(item => !PARAMS_TO_CLEAN.some(param => item.startsWith(param)) );
    params = params?.filter(item => ALLOWED_PARAMS.some(param => item.startsWith(param)) );

    if (this.query) {
      params.push(`query=${this.query}`);
    }

    if (this.order && this.order !== 'default') {
      params.push(`order=${this.order}`);
    }

    if (this.grouped) {
      params.push(`grouped=true`);
    }
    
    return params;
  }

  async findSuggestions() {
    this.suggestions = null;

    if (SearchStore.items?.length > 1 || this.query?.length > 0) {
      this.suggestions = null;
      return null;
    }

    try {
      const item = SearchStore.items?.length ? SearchStore.items?.[0] : null;
      // const item = SearchStore.items?.length > 0 ? SearchStore.items?.[0] : null;

      if (item?.type === 'site_id') {
        return null;
      }
      const path = this._jsonPath(UIStore.searchType, item?.type, item?.value?.id);
      const result = await this.fetchJson(path);
      this._clearFetchPromiseCache(path);
      runInAction(() => {
        if (this.query?.length === 0 && SearchStore.items?.length <= 1) {
          let list = this._shuffle(result)?.slice(0, 5);
          this.suggestions = list;
        }
      });
    } catch (err) {
      console.log('findSuggestions err', err);
    }
  }

  _jsonPath(searchType = UIStore.searchType, type, id) {
    if (!type || !id) {
      return `/json/${UIStore.searchType === 'sites' ? 'desktop' : 'ios'}/root.json`;
    }
    return `/json/${UIStore.searchType === 'sites' ? 'desktop' : 'ios'}/${type}/${id}.json`;
  }

  _shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  getItem(key, id) {
    switch (key) {
      case 'design_patterns':
        return DESIGN_PATTERNS.find(item => item.id === id);
      case 'page_elements':
        return PAGE_ELEMENTS.find(item => item.id === id);
      case 'categories':
        return CATEGORIES.find(item => item.id === id);
      case 'fonts':
        return FONTS.find(item => item.id === id);
      default:
        return null;
    }
  }

  getTabIndex(name) {
    return TABS[UIStore.searchType]?.findIndex(tab => name) ?? null;
  }

  setSelected(id) {
    this.selected = id;
  }

  generateCells(list, kinds) {
    const result = [];
    kinds.forEach(kind => {
      const group = list.filter(item => item.kind === kind && (UIStore.searchType === 'sites' ? item.desktop : item.mobile))?.map(item => item.id);
      let toAdd = this.numberOfColumns - group.length % this.numberOfColumns;
      while(toAdd) {
        group.push(null);
        toAdd--;
      }
      result.push(group);
    });
    return result.flat();
  }

  generateAllCells() {
    setTimeout(() => {
      this.patternCells = this.generateCells(DESIGN_PATTERNS, this.patternKinds);
      this.elementCells = this.generateCells(PAGE_ELEMENTS, this.elementKinds);
      this.pageTypeCells = this.generateCells(PAGE_TYPES, this.pageTypeKinds);
      this.categorieCell = CATEGORIES.map(category => category.id);
      this.fontCells = FONTS.map(font => font.id);
    });
  }

  async search(query) {
    try {
      const now = new Date().getTime();
      this._lastSearch = now;
      const url = UIStore.searchType === 'sites' ? SITES_URL : APPS_URL;
      const sites = await this.fetchJson(url);
      this._clearFetchPromiseCache(url);
      const results = search(query, sites, {
        returnMatchData: true,
        keySelector: (site) => {
          return [ site.domain, site.name, site.description ].filter(item => !!item)
        }
      })
      .filter(data => data.score > .75)
      .map(data => {
        return {
          ...data.item,
          score: data.score
        }
      });

      if (!results?.length || now !== this._lastSearch) {
        return;
      }

      runInAction(() =>  {
        results.forEach(site => {
          const index = this.filtered?.findIndex(item => item?.value?.id === site.id);
          if (index === -1) {
            const type = UIStore.searchType === 'sites' ? 'site_id' : 'app_id';
            this.filtered.push({
              score: site.score,
              type: type,
              suggestionId: `${type}_${site.id}`,
              value: {
                id: site.id,
                name: site.name ?? site.domain,
                data: site
              }
            });
          }
        });

        this.filtered.sort((a, b) => {
          return b.score - a.score;
        });
      });
    } catch (err) {
      console.log('json fetch err', err);
    }
  }

  async fetchJson(url) {
    if (this.dataCache[url]) {
      return this.dataCache[url];
    }

    if (this._fetchJsonPromise[url]) {
      return this._fetchJsonPromise[url];
    }

    this._fetchJsonPromise[url] = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url);
        const json = response.json();
        this.dataCache[url] = json;
        resolve(json);
        // this._fetchJsonPromise[url] = null;
      } catch (err) {
        // reject(err);
        resolve([]);
      } finally {
        this._fetchJsonPromise[url] = null;
      }
    });

    return this._fetchJsonPromise[url];
  }

  _clearFetchPromiseCache(url) {
    this._fetchJsonPromise[url] = null;
  }

  setOrder(val) {
    this.order = val;
  }

  params(appSiteSwitch = false, excludeGrouped = false) {    
    let result = this.items
      ?.filter(item => {
        if (item.type === 'categories') {
          return true;
        }

        if (item.type === 'fonts') {
          return true;
        }

        if (appSiteSwitch && (item.type  === 'app_id' || item.type === 'site_id')) {
          return (item.type === 'app_id' && item.value?.site?.id) || (item.type === 'site_id' && item.value?.app?.id);
        }

        if (UIStore.searchType === 'sites') {
          return item.value.desktop || item.type === 'site_id';
        } 
        
        // if (UIStore.searchType === 'apps') {
          return item.value.mobile || item.type === 'app_id';
        // }
      })
      ?.map(item => {
        if (appSiteSwitch) {
          if (item.value.site?.id) {
            return `site_id[id][]=${item.value.site.id}`;
          }
          if (item.value.app?.id) {
            return `app_id[id][]=${item.value.app.id}`;
          }
        }
        return `${item.type}[id][]=${item.value.id}`;
      });

    if (this.query) {
      result.push(`query=${encodeURIComponent(this.query)}`);
    }

    if (this.order && this.order != 'default') {
      result.push(`order=${this.order}`);
    }

    if (!excludeGrouped && this.grouped) {
      result.push('grouped=true');
    }

    return result?.join('&');
  }

  searchUrl(appSiteSwitch = false, excludeGrouped = false) {
    return `${UIStore.searchUrl}?${this.params(appSiteSwitch, excludeGrouped)}`;
  }
   
}

const SearchStore = new Search();
export default SearchStore;
