import { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
// import Placeholder from 'components/Placeholder/Placeholder';
import mixpanel from 'api/mixpanel';
import ScreenshotsStore from 'stores/Screenshots';
import styles from './SitePreview3.module.scss';

function SitePreview3({ 
  site,
  screenshots,
  compact,
  plain,
  mixpanelEvent = 'SitePreviewClick'
}) {

  const [ logoLoaded, setLogoLoaded ] = useState(false);
  const [ photo1Loaded, setPhoto1Loaded ] = useState(false);
  const [ photo2Loaded, setPhoto2Loaded ] = useState(false);  

  return (
    <Link
      className={classnames(styles.container, {
        [styles.compact]: compact
      })}
      onClick={event => mixpanel.track(mixpanelEvent, { id: site?.id })}
      to={`/${site?.id}-${site?.domain}`}
    >
      <div 
        className={styles.bg} 
        style={{ 
          backgroundColor: `#${site?.backgroundColor ? site?.backgroundColor : 'AEB8D6'}`,
        }}
      />

      <div className={styles.head}>     
        <div className={classnames(styles.faviconWrap, {
          [styles.faviconLoaded]: !!site?.id && logoLoaded
        })}>
          <img 
            className={classnames(styles.favicon)}
            // src={site?.faviconUrl ?? `https://www.google.com/s2/favicons?domain=${site?.domain}&sz=256`} 
            src={site?.faviconUrl} 
            alt="" 
            onLoad={event => setLogoLoaded(true)}
            loading="lazy"
          />
        </div>
      </div>
      
      <div>
        <div className={classnames(styles.title, {
          [styles.empty]: !site
        })}>
          {site?.name ?? site?.domain}
        </div>

        <div className={classnames(styles.desc, {
          [styles.empty]: !site
        })}>
          {site?.description}
        </div>
      </div>
      
      <div className={styles.images}>
        {!site ?
          <>
            <div 
              className={classnames(styles.imageContainer, styles.imageContainerMain)}
              style={{ color: `#AEB8D666` }}
            />
            <div 
              className={classnames(styles.imageContainer)}
              style={{ color: `#AEB8D666` }}
            />
          </>
        :null}

        {!compact && (screenshots?.slice(0,2))?.map((item, index) => {
          const screenshot = plain ? item : ScreenshotsStore.show(item);

          return (
            <div 
              className={classnames(styles.imageContainer, {
                [styles.imageContainerMain]: index === 0
              })}
              style={{ color: `#${site?.backgroundColor ? site.backgroundColor : 'AEB8D6'}66` }}
              key={screenshot?.thumbnail ?? index}
            >
              <img 
                className={classnames(styles.image, styles[`image-${index}`], {
                  [styles.loaded]: index === 0 ? photo1Loaded : photo2Loaded
                })} 
                src={screenshot?.thumbnail} 
                onLoad={event => index === 0 ? setPhoto1Loaded(true) : setPhoto2Loaded(true)}
                loading="lazy"
                alt="" 
              />
            </div>
          )
        })}    

      </div>    

    </Link>
  );
}

function areEqual(prev, next) {
  return prev.site?.id === next.site?.id;
}

export default memo(SitePreview3, areEqual);