import classnames from 'classnames';
import styles from './Tags.module.scss';

function Tags({ title, children, centered, disableMobileScroll, className, noPadding }) {
  return (
    <figure className={classnames(styles.container, className, {
      [styles.centered]: centered,
      [styles.disableMobileScroll]: disableMobileScroll,
      [styles.noPadding]: noPadding,
    })}>
      {title ? 
        <figcaption className={styles.title}>
          {title}
        </figcaption>
      : null}

      <ul className={styles.items}>
        {children}
      </ul>
    </figure>
  );
}

function Item({children, rounded, withBorder, postfix, onClick, active}) {
  return (
    <li 
      onClick={onClick}
      className={classnames(styles.item, {
        [styles.rounded]: rounded,
        [styles.withBorder]: withBorder,
        [styles.clickable]: onClick,
        [styles.active]: active,
      })}
    >
      {children} 
      {!!postfix && 
        <span className={styles.postfix}>
          {postfix}
        </span>
      }
    </li>
  );
}

Tags.Item = Item;

export default Tags;