import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import AppPreview from 'components/AppPreview/AppPreview';
import AppsStore from 'stores/Apps';
import AppScreenshotsStore from 'stores/AppScreenshots';
import AppFeedStore from 'stores/AppFeed';
import styles from './AppFeed.module.scss';

const AppFeed = observer(() => {

  useEffect(() => {
    nextPage();

    function onScroll(event) {
      if (document.body.scrollHeight - window.pageYOffset < 3000 && !AppFeedStore.feedLoaded) {
        nextPage();
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  async function nextPage() {
    const page = await AppFeedStore.fetch();
    mixpanel.track('AppFeed', { page });
  }

  return (
    <>
      <div className={styles.container}>
        {AppFeedStore.list?.map((id, index) => {
          const app = AppsStore.store[id];
          return (
            <AppPreview
              // screenshots={AppScreenshotsStore.list(id)}
              screenshots={app?.featuredScreenshots?.map(s => s.id) ?? AppScreenshotsStore.list(id)}
              app={app} 
              key={index}
              source="index"
              // plain
            />
          )
        })}
      </div>
    </>
  );
});

export default AppFeed;
