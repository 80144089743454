import { makeAutoObservable, runInAction } from 'mobx';
import Site from './Site';
import ReferoApi from 'api/refero_api';
import AlertStore from './Alert';
import ScreenshotsStore from './Screenshots';
// import UserStore from './User';

class Sites {

  store = {};
  lists = {};
  fetching = false;
  searchResults = [];
  searchKey = undefined;
  fetchError = false;

  constructor() {
    makeAutoObservable(this);
  }

  async list(filter = 'index', params) {
    try {
      if (this.lists[filter]) {
        return;
      }
      this.setFetching(true);
      const sites = await ReferoApi.sites(params);
      this.setFetching(false);

      runInAction(() => {
        this.lists[filter] = [];
        sites?.forEach(site => {
          // this.store[site.id] = new Site(site);
          this.add(site);
          this.lists[filter].push(site.id);
        });
      });
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  async show(id) {
    try {
      if (this.store[id] && this.store[id]?.hasAllFields()) {
        return;
      }
      const site = await ReferoApi.site(id);
      this.add(site);
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async search(params, ignoreCache = false, withPagination = false) {
    try {
      if (params === this.searchKey && !ignoreCache) {
        return;
      }

      if (this.fetching) {
        return;
      }

      this.setFetchError(false);
      this.setFetching(true);
      this.searchKey = params ?? 'root';

      if (withPagination) {
        const { next = null, pages, per_page, count, current } = ScreenshotsStore.pagination(params);
        const screenLeft = count - (current * per_page);
        const screenNumber = Math.min(screenLeft, per_page) ?? 24;
        
        if (!next || pages === 0) {
          this.setFetching(false);
          return;
        }
        params += `&page=${next}`;

        if (screenNumber > 0) {
          ScreenshotsStore.addPlaceholders(this.searchKey, screenNumber);
        }
      } else {
        ScreenshotsStore.addPlaceholders(this.searchKey);
      }

      const { records, pagination } = await ReferoApi.search(params);
      ScreenshotsStore.add(records, this.searchKey, null, pagination);

      runInAction(() => {
        records?.forEach(({site}) => {
          this.add(site);
        });
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
      this.setFetchError(true);
      ScreenshotsStore.clearPlaceholders(this.searchKey);
    } 
  }

  trySearchNext(params) {
    if (this.fetching) {
      return;
    }
    const { next = null } = ScreenshotsStore.pagination(params);

    if (!next) {
      return;
    }

    this.search(params, true, true);
  }

  async randomList() {
    try {
      this.lists['random'] = [];
      const { sites } = await ReferoApi.sitesRandom();
      runInAction(() => {
        this.lists['random'] = [];
        sites?.forEach(site => {
          // this.store[site.id] = new Site(site);
          this.add(site);
          this.lists['random'].push(site.id);
        });
      });
    } catch (err) {}
  }

  add(site) {
    const old = this.store[site.id];

    if (!old) {
      this.store[site.id] = new Site(site);
    } else {
      // this.store[site.id].update(new Site({
      this.store[site.id] = new Site({
        ...old, 
        ...site,
        featured_screenshots: old.featuredScreenshots ?? site.featured_screenshots
      })
    }
  }

  find(id) {
    return this.store[id] ?? null;
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  populate(id) {
    this.find(id)?.populate();
  }

  setFetchError(state = false) {
    this.fetchError = state;
  }
}

const SitesStore = new Sites();
export default SitesStore;
