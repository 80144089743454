import { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import useEscape from 'hooks/useEscape';
import Button from 'components/Button/Button';
import BookmarksStore from 'stores/Bookmarks';
import styles from './CreateFolderPopup.module.scss';

const CreateFolderPopup = observer(({ id, value, label = 'Create Board', subLabel, deleteAction, submitLabel = 'Create Board'}) => {

  const form = useRef(null);
  const navigate = useNavigate();
  const [ name, setName ] = useState(value ?? '');

  useEffect(() => {
    document.body.classList.add(styles.bodyFix);

    return () => {
      document.body.classList.remove(styles.bodyFix);
    }
  }, []);

  useEscape(() => {
    BookmarksStore.hideAllFolderPopups();
  });
 
  async function submit(event) {
    event.preventDefault();

    if (deleteAction) {
      await BookmarksStore.deleteFolder(id);
      BookmarksStore.hideAllFolderPopups();
      return navigate('/bookmarks');
    } 

    if (!id) {
      await BookmarksStore.createFolder(name);
    } else {
      await BookmarksStore.updateFolder(id, name);
    }
    BookmarksStore.hideAllFolderPopups();
  }

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <div className={styles.popup}>
        <form 
          className={styles.form}
          ref={form} 
          onSubmit={submit} 
          disabled={BookmarksStore.creatingFolder}
        >
          <div className={styles.title}>
            {label}
          </div>

          {subLabel ?
            <div className={styles.subLabel}>
              {subLabel}
            </div>
          : null}

          {!deleteAction ?
            <div className={styles.row}>
              <input 
                value={name}
                onChange={event => setName(event.target.value)}
                autoFocus
                className={styles.field}
                type="text" 
                required
                maxLength="255"
                placeholder="Board Name"
              />
            </div>
          : null}

          <div className={styles.buttons}>
            <Button 
              square 
              light 
              compact 
              onClick={event => BookmarksStore.hideAllFolderPopups(false)}
            >
              Cancel
            </Button>
            <Button 
              square 
              compact 
              element="button"
              className={styles.submit}
            >
              {submitLabel}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
});

export default CreateFolderPopup;
