import { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import SearchStore from 'stores/Search';
// import getABValue from 'helpers/get-ab-value';
import styles from './SearchResultSite.module.scss';
import mixpanel from 'api/mixpanel';

export default function SearchResultSite({ site, params }) {

  const [ logoLoaded, setLogoLoaded ] = useState(false);
  // const groupLinkType = useMemo(() => getABValue('_group_link_type', ['site', 'search']), []);

  function onClick() {
    mixpanel.track('SiteGroupClick');
  }

  function getUrl() {
    if (!site) {
      return '/';
    }

    let params = '';

    if (site?.id) {
      params += `?site_id[id][]=${site.id}`;
    }

    if (SearchStore.params(false, true)) {
      params += `&${SearchStore.params(false, true)}`
    }

    return `/search${params}`;

    // if (groupLinkType === 'search' || SearchStore.items?.length > 1) {
      // return `/search?site_id[id][]=${site.id}&${SearchStore.params(false, true)}`;
    // }

    // let params = SearchStore.items?.map(item => {
    //   if (item.type === 'page_types') {
    //     return `page-type=${item.value?.id}`;
    //   }

    //   if (item.type === 'design_patterns') {
    //     return `pattern=${item.value?.id}`;
    //   }

    //   if (item.type === 'page_elements') {
    //     return `element=${item.value?.id}`;
    //   }

    //   return null;
    // }).filter(item => !!item).join('&');

    

    // return `/${site?.id}-${site?.domain}?${params}`;
  }

  return (
    <Link 
      className={styles.container}
      to={getUrl()}
      onClick={onClick}
    >
      <div 
        className={styles.bg} 
        style={{ 
          backgroundColor: `#${site?.backgroundColor ? site.backgroundColor : 'AEB8D6'}`,
        }}
      />
      <div 
        className={styles.screenshots}
        style={{ 
          color: `#${site?.backgroundColor ? site.backgroundColor : 'AEB8D6'}18`,
        }}
      >
        {site?.featuredScreenshots?.map((screenshot, index) =>
          <Screenshot 
            screenshot={screenshot} 
            size={site?.featuredScreenshots.length ?? 0}
            index={index}
            key={screenshot?.id} 
          />
        )}

        {site ? 
          <img 
            className={classnames(styles.favicon, {
              [styles.visible]: logoLoaded
            })} 
            src={site?.faviconUrl ?? `https://www.google.com/s2/favicons?domain=${site?.domain}&sz=256`} 
            onLoad={() => setLogoLoaded(true)}
            alt="" 
          />
        : null}
      </div>
      <div className={styles.body}>
        <h3 className={styles.name}>
          {site?.name ?? site?.domain}
        </h3>
        <div className={styles.numberOfScreens}>
          {site?.screenshotsCount} Screens
        </div>
      </div>
    </Link>
  );
}

function Screenshot({ screenshot, size, index }) {

  const [ loaded, setLoaded ] = useState(false);

  return (
    <img 
      className={classnames(styles.screenshot, styles[`screenshot-${size}-${index}`], {
        [styles.visible]: loaded
      })}
      src={screenshot.thumbnail_url} 
      onLoad={() => setLoaded(true)}
      loading="lazy"
      alt="" 
    />  
  )
}