// import isIndianUser from 'helpers/is-indian-user';
import centsToDollars from 'helpers/cents-to-dollars';
// import usdToInr from 'helpers/usd-to-inr';
// import * as Icons from 'icons';
import styles from './PricePerMonth.module.scss';

export default function PricePerMonth({ price, plan, withoutPrefix }) {

  function pricePerMonth() {
    let result = 0;
    if (plan?.interval === 'year') {
      result = plan?.price / plan?.interval_count / 12;
    } else {
      result = plan?.price / plan?.interval_count ?? 0;
    }

    return result;
  }

  function normalize(value) {
    // if (isIndianUser()) {
    //   value = usdToInr(value) * .25;
    //   value = roundUp(value);
    // }

    return value;
  }

  // function roundUp(value) {
  //   return Math.ceil(value / 100) * 100;
  // }

  if (!price) {
    return 0;
  }

  function getSymbol() {
    // if (isIndianUser()) {
    //   return '₹';
    // }

    return '$';
  }

  function renderPrefix() {
    return null;
    // if (!isIndianUser() || withoutPrefix) {
    //   return null;
    // }

    // return (
    //   <>
    //     <span className={styles.oldPrice}>
    //       ₹{centsToDollars(roundUp(usdToInr(pricePerMonth())))}
    //     </span>
    //     <Icons.ArrowRight2 className={styles.arrow} />
    //   </>
    // )
  }

  return (
    <span className={styles.container}>
      {renderPrefix()}{getSymbol()}{centsToDollars(normalize(pricePerMonth()))}
    </span>
  );
}
