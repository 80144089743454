import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as Icons from 'icons';
import ButtonPro from 'components/ButtonPro/ButtonPro';
import UIStore from 'stores/UI';
import UserStore from 'stores/User';
import styles from './Profile.module.scss';
import Button from 'components/Button/Button';
import { useHotkeys } from 'react-hotkeys-hook';

const Profile = observer(() => {

  const [ theme, setTheme ] = useState(localStorage.getItem('theme') ?? 'system');
  const [ visible, setVisible ] = useState(false);
  const [ hiding, setHiding ] = useState(false);

  useEffect(() => {
    const metaTags = document.querySelectorAll('meta[name="theme-color"]');

    if (theme === 'dark') {
      document.body.setAttribute('id', 'dark-theme');
      localStorage.setItem('theme', 'dark');
      metaTags?.forEach(tag => tag.setAttribute('content', '#13151A'));
    }

    if (theme === 'light') {
      document.body.setAttribute('id', 'light-theme');
      localStorage.setItem('theme', 'light');
      metaTags?.forEach(tag => tag.setAttribute('content', '#ffffff'));
    }

    if (theme === 'system') {
      document.body.setAttribute('id', '');
      localStorage.removeItem('theme');
      metaTags?.forEach(tag => {
        if (tag.media?.includes('dark')) {
          tag?.setAttribute('content', '#13151A');
        } else {
          tag?.setAttribute('content', '#ffffff')
        }
      });
    }
  }, [ theme ]); 

  useEffect(() => {
    if (UIStore.profileVisible) {
      setVisible(true);
      setHiding(false);
    } else {
      setVisible(false);
    }
  }, [ UIStore.profileVisible ]);

  // useEscape(close);

  useHotkeys('esc', close);

  function onClickLogin() {
    UIStore.hideProfile();
    UIStore.showSignupPopup();
  }

  function close() {
    setHiding(true);
  }

  function onAnimationEnd() {
    if (hiding) {
      UIStore.hideProfile();
    } 
  }

  function renderBadge() {
    const team = UserStore.teams?.[0];

    if (team && team.subscription_id) {
      return (
        <img className={styles.teamPic} src={team.logo_url} alt="" />
      )
    }

    if (UserStore.hasSubscription()) {
      return (
        <div className={styles.proIcon}>
          <Icons.Pro />
        </div>
      )
    }

    return null;
  }

  if (!visible) {
    return null;
  }

  // if (!UIStore.profileVisible) {
  //   return null;
  // }

  return (
    <div className={classnames(styles.container, {
        [styles.hiding]: hiding 
      })}
      // onAnimationEnd={onAnimationEnd}
    >
      <div 
        className={styles.background} 
        onClick={close} 
      />

      <div 
        className={classnames(styles.popup, {
          // [styles.hiding]: hiding 
        })}
        onAnimationEnd={onAnimationEnd}
      >

        {UserStore.isSignedIn() ?
          <div className={styles.nameContainer}>
            <div className={styles.nameWrap}>
              {UserStore.teams?.[0]?.['name'] ?
                <div className={styles.email}>
                  {UserStore.teams?.[0]?.['name']}
                </div>
              : null}

              {UserStore.name ?
                <div className={styles.name}>
                  {UserStore.name}
                </div>
              : null}

              {UserStore.email ?
                <div className={styles.email}>
                  {UserStore.email}
                </div>
              : null}
            </div>

            {/* {UserStore.onTrial && !UserStore.hasSubscription() ?
              <div className={styles.badge}>
                <div className={styles.badgeBg} />
                <div className={styles.badgeLabel}>
                  Trial
                </div>
              </div>
            : null} */}

            {renderBadge()}
          </div>
        : null}

        {UserStore.isSignedIn() && !UserStore.hasSubscription() ?
          <div className={styles.trial}>
            {/* {UserStore.isTrialExpired() ?
              <div className={styles.trialEnds}>
                Your trial period has expired. Get Pro for unlimited access.
              </div>     
            : 
              <div className={styles.trialEnds}>
                Your trial period will end in {UserStore.tillTrialEnds()}. Get Pro for unlimited access.
              </div>
            } */}

            <ButtonPro 
              block 
              onClick={event => UIStore.hideProfile()} 
            />
          </div>
        : null}

        <div className={styles.title}>
          Appearance
        </div>

        <div className={styles.themes}>
          <div className={classnames(styles.theme)}>
            <div onClick={event => setTheme('light')} className={classnames(styles.themeIcon, styles.light, {
              [styles.active]: theme === 'light'
            })} />
            Light
          </div>
          <div className={classnames(styles.theme)}>
            <div onClick={event => setTheme('dark')} className={classnames(styles.themeIcon, styles.dark, {
              [styles.active]: theme === 'dark'
            })} />
            Dark
          </div>
          <div className={classnames(styles.theme)}>
            <div onClick={event => setTheme('system')} className={classnames(styles.themeIcon, styles.system, {
              [styles.active]: theme === 'system'
            })} />
            System
          </div>
        </div>

        {UserStore.isSignedIn() && UserStore.teams?.length ?
          <Link 
            className={classnames(styles.link)}
            to="/teams"
            onClick={event => UIStore.hideProfile()}
          >
            <Icons.Team className={styles.icon} />
            Team Management
          </Link>
        : null}

        {UserStore.isSignedIn() ?
          <Link 
            className={classnames(styles.link)}
            to="/bookmarks"
            onClick={event => UIStore.hideProfile()}
          >
            <Icons.Bookmarks2 className={styles.icon} />
            Bookmarks
          </Link>
        : null}

        {UserStore.isSignedIn() ?
          <Link 
            className={classnames(styles.link)}
            to="/billing"
            onClick={event => UIStore.hideProfile()}
          >
            <Icons.Card className={styles.icon} />
            Billing & Subscription 
          </Link>
        : null}
        
        <Link 
          className={classnames(styles.link)}
          to="/pricing"
          onClick={event => UIStore.hideProfile()}
        >
          <Icons.Stars className={styles.icon} />
          Pricing
        </Link>

        <Link 
          className={classnames(styles.link)}
          to="/how-it-works"
          onClick={event => UIStore.hideProfile()}
        >
          <Icons.Player className={styles.icon} />
          How it Works
        </Link>

        <Link 
          className={classnames(styles.link)}
          to="/affiliate"
          onClick={event => UIStore.hideProfile()}
        >
          <Icons.Affiliate className={styles.icon} />
          Affiliate
        </Link>
        
        <div className={styles.links}>
          <a 
            href="https://forms.gle/NbTgktyTDcr4SWwf7" 
            target="_blank" 
            rel="noreferrer"
          >
            Give Feedback
          </a>

          <a 
            href="https://twitter.com/referodesign" 
            target="_blank" 
            rel="noreferrer"
          >
            Follow us on X
          </a>
        </div>
        
        {UserStore.isSignedIn() ?
          <div onClick={event => UserStore.logout()} className={styles.logout}>
            Log out
          </div>
        : 
          <Button block compact square onClick={onClickLogin}>
            <Button.Icon>
              <Icons.Login />
            </Button.Icon>
            Log In or Sign Up
          </Button>
        }

      </div>
    </div>
  )
});

export default Profile;
