import classnames from 'classnames';
import * as Icons from 'icons';
import referoApi from 'api/refero_api';
import styles from './TeamPic.module.scss';
import { useState } from 'react';

export default function TeamPic({ className, url, onReady }) {

  const [ uploading, setUploading ] = useState(false);

  async function onChange(event) {
    try {
      setUploading(true);
      const file = event.target.files[0];
      const { url } = await referoApi.teamPresignedUrl();
      await fetch(url, { method: 'PUT', body: file });
      onReady(url?.split('?')?.[0]);
    } catch (err) {
      console.log('err', err);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div className={classnames(styles.container, className, {
      [styles.uploading]: uploading
    })}>
      <div 
        className={styles.preview}
        style={{ backgroundImage: `url(${url})` }}
      >
        <div className={styles.spinner} />
        <div className={styles.icon}>
          <Icons.Upload />
        </div>
        <input 
          onChange={onChange}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          className={styles.field} 
        />
      </div>
    </div>
  )
}
