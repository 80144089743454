export default function LogoRound(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={84}
      height={84}
      viewBox="0 0 84 84"
      fill="none"
      {...props}
    >
      <circle cx={42} cy={42} r={38} stroke="currentColor" strokeWidth={8} />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M31.238 62H23l7.62-42h17.23C55.126 20 60 25.933 60 32c0 5.6-2.952 9.133-5.835 11.067-1.716 1.133-3.638 2-5.766 2.6L55.607 62H46.34l-6.11-15.333h-6.178L31.238 62Zm6.246-34.333L35.424 39h9.543c3.57 0 6.727-2.733 6.727-6 0-2.667-2.128-5.333-5.217-5.333h-8.993Z"
        clipRule="evenodd"
      />
    </svg>
  )
}