import { useMemo } from 'react';

function randomId() {
  return (Math.random() + 1).toString(36).substring(7);
}

export default function PlanPro(props) {
  const a = useMemo(randomId, []);
  const b = useMemo(randomId, []);

  return (
    <svg
      width={46}
      height={18}
      fill="none"
      viewBox="0 0 46 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#C3C9EC"
        d="M.69 17 3.576.62h6.11c2.782 0 4.654 2.418 4.654 5.018 0 3.276-2.834 5.902-6.032 5.902H4.772L3.81 17H.69Zm4.602-8.45h3.224c1.326 0 2.704-1.222 2.704-2.6 0-1.17-.884-2.34-2.054-2.34h-2.99l-.884 4.94ZM14.858 17 17.744.62h6.526c2.756 0 4.602 2.314 4.602 4.68 0 2.184-1.118 3.562-2.21 4.316-.65.442-1.378.78-2.184 1.014l2.73 6.37h-3.51l-2.314-5.98h-2.34L17.978 17h-3.12Zm4.706-8.97h3.614c1.352 0 2.548-1.066 2.548-2.34 0-1.04-.806-2.08-1.976-2.08h-3.406l-.78 4.42Zm17.409 9.1c-4.082 0-7.176-3.276-7.176-7.462 0-5.2 4.368-9.178 8.788-9.178 4.082 0 7.176 3.276 7.176 7.462 0 5.2-4.368 9.178-8.788 9.178Zm.312-2.99c2.626 0 5.356-2.47 5.356-6.006 0-2.73-1.846-4.654-4.342-4.654-2.652 0-5.382 2.47-5.382 6.006 0 2.73 1.898 4.654 4.368 4.654Z"
      />
      <path
        fill={`url(#${a})`}
        d="M.69 17 3.576.62h6.11c2.782 0 4.654 2.418 4.654 5.018 0 3.276-2.834 5.902-6.032 5.902H4.772L3.81 17H.69Zm4.602-8.45h3.224c1.326 0 2.704-1.222 2.704-2.6 0-1.17-.884-2.34-2.054-2.34h-2.99l-.884 4.94ZM14.858 17 17.744.62h6.526c2.756 0 4.602 2.314 4.602 4.68 0 2.184-1.118 3.562-2.21 4.316-.65.442-1.378.78-2.184 1.014l2.73 6.37h-3.51l-2.314-5.98h-2.34L17.978 17h-3.12Zm4.706-8.97h3.614c1.352 0 2.548-1.066 2.548-2.34 0-1.04-.806-2.08-1.976-2.08h-3.406l-.78 4.42Zm17.409 9.1c-4.082 0-7.176-3.276-7.176-7.462 0-5.2 4.368-9.178 8.788-9.178 4.082 0 7.176 3.276 7.176 7.462 0 5.2-4.368 9.178-8.788 9.178Zm.312-2.99c2.626 0 5.356-2.47 5.356-6.006 0-2.73-1.846-4.654-4.342-4.654-2.652 0-5.382 2.47-5.382 6.006 0 2.73 1.898 4.654 4.368 4.654Z"
      />
      <path
        fill={`url(#${b})`}
        d="M.69 17 3.576.62h6.11c2.782 0 4.654 2.418 4.654 5.018 0 3.276-2.834 5.902-6.032 5.902H4.772L3.81 17H.69Zm4.602-8.45h3.224c1.326 0 2.704-1.222 2.704-2.6 0-1.17-.884-2.34-2.054-2.34h-2.99l-.884 4.94ZM14.858 17 17.744.62h6.526c2.756 0 4.602 2.314 4.602 4.68 0 2.184-1.118 3.562-2.21 4.316-.65.442-1.378.78-2.184 1.014l2.73 6.37h-3.51l-2.314-5.98h-2.34L17.978 17h-3.12Zm4.706-8.97h3.614c1.352 0 2.548-1.066 2.548-2.34 0-1.04-.806-2.08-1.976-2.08h-3.406l-.78 4.42Zm17.409 9.1c-4.082 0-7.176-3.276-7.176-7.462 0-5.2 4.368-9.178 8.788-9.178 4.082 0 7.176 3.276 7.176 7.462 0 5.2-4.368 9.178-8.788 9.178Zm.312-2.99c2.626 0 5.356-2.47 5.356-6.006 0-2.73-1.846-4.654-4.342-4.654-2.652 0-5.382 2.47-5.382 6.006 0 2.73 1.898 4.654 4.368 4.654Z"
      />
      <defs>
        <radialGradient
          id={a}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(145.905 14.907 7.58) scale(39.246 32.7265)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4D96" />
          <stop offset={0.679} stopColor="#FF468F" />
          <stop offset={1} stopColor="#FF4DA2" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id={b}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(145.305 20.938 7.638) scale(23.7171 49.101)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9700" />
          <stop offset={0.479} stopColor="#FF9700" stopOpacity={0.7} />
          <stop offset={1} stopColor="#FF9500" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}
