export default function Stars(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={1.6}
        d="M12.812 6.812a.187.187 0 0 1 .073-.098.211.211 0 0 1 .115-.032c.046 0 .087.013.115.032a.187.187 0 0 1 .073.098l1.333 3.604a1.8 1.8 0 0 0 1.063 1.063l3.604 1.333c.056.021.083.049.098.073a.21.21 0 0 1 .032.115.21.21 0 0 1-.032.115.186.186 0 0 1-.098.073l-3.604 1.333a1.8 1.8 0 0 0-1.063 1.063l-1.333 3.604a.186.186 0 0 1-.073.098.21.21 0 0 1-.115.032.21.21 0 0 1-.115-.032.186.186 0 0 1-.073-.098l-1.333-3.604a1.8 1.8 0 0 0-1.063-1.063l-3.604-1.333a.187.187 0 0 1-.098-.073.211.211 0 0 1-.032-.115c0-.046.013-.087.032-.115a.187.187 0 0 1 .098-.073l3.604-1.333a1.8 1.8 0 0 0 1.063-1.063l1.333-3.604Z"
      />
      <path
        fill="currentColor"
        d="M6.125 4.014a.4.4 0 0 1 .75 0l.506 1.368a.4.4 0 0 0 .237.237l1.368.506a.4.4 0 0 1 0 .75l-1.368.506a.4.4 0 0 0-.237.237l-.506 1.368a.4.4 0 0 1-.75 0l-.506-1.368a.4.4 0 0 0-.237-.237l-1.368-.506a.4.4 0 0 1 0-.75l1.368-.506a.4.4 0 0 0 .237-.237l.506-1.368Z"
      />
    </svg>
  );
}
