import { makeAutoObservable } from 'mobx';
import ReferoApi from 'api/refero_api';
import Screenshot from './Screenshot';
import AlertStore from './Alert';
import SitesStore from './Sites';
import Site from './Site';

class Screenshots {

  _lists = {};
  _storage = {};
  _pagination = {};
  fetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(siteId) {
    try {
      if (this.fetching) {
        return;
      }
      this.setFetching(true);
      this.addPlaceholders(siteId, 24);
      const { records } = await ReferoApi.screenshots(siteId);
      this.add(records, siteId, siteId);
      SitesStore.populate(siteId);
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  async similar(id, ignoreCache = false) {
    try {
      if (!ignoreCache && this._pagination[this.getSimilarKey(id)]) {
        return;
      } 
      this.setFetching(true);
      const { records, pagination } = await ReferoApi.screenshotsSimilar(id);
      this.add(records, this.getSimilarKey(id), null, pagination);
      records?.forEach(screenshot => {
        SitesStore.add(screenshot.site);
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  async similarNextPage(id) {
    try {
      const next = this.getNextSimilarPage(id);
      if (!next) {
        return;
      }
      this.setFetching(true);
      const { records, pagination } = await ReferoApi.screenshotsSimilar(id, next);
      this.add(records, this.getSimilarKey(id), null, pagination);
      records?.forEach(screenshot => {
        SitesStore.add(screenshot.site);
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  add(list, key, siteId, pagination) {
    this._lists[key] ??= [];

    if (pagination) {
      this._pagination[key] = pagination;
    }

    const _list = this._lists[key];
    const isGrouped = key?.toString()?.includes('grouped=true');

    let hasPlaceholders = true;
    while(hasPlaceholders) {
      // const item = this._lists[key]?.at(-1);
      const item = _list?.[_list?.length - 1];
      if (item?.startsWith?.('placeholder_')) {
        _list.pop();
      } else {
        hasPlaceholders = false;
      }
    }

    list?.forEach(json => {
      let item;

      if (isGrouped) {
        item = new Site({ 
          ...json.site,
          featured_screenshots: json.screenshots,
          screenshots_count: json.total_count
        });
      } else {
        // console.log('add', json.id, siteId);
        item = new Screenshot(json, siteId ?? json?.site?.id);
      }

      const id = isGrouped ? `site_${item.id}` : item.id;

      if (!this._storage[id]) {
        this._storage[id] = item;
      } else {
        this._storage[id].update(item);
      }
      
      const index = _list.indexOf(id);
      if (index === -1) {
        _list.push(id);
      }
    });
    
  }

  addPlaceholders(key, numberOfPlaceholders = 24) {
    this._lists[key] ??= [];
    const list = this.placeholderList(numberOfPlaceholders);
    this._lists[key].push(...list);
  }

  clearPlaceholders(key) {
    let hasPlaceholders = true;
    while(hasPlaceholders) {
      const item = this._lists[key]?.[this._lists[key]?.length - 1];
      if (item?.startsWith?.('placeholder_')) {
        this._lists[key].pop();
      } else {
        hasPlaceholders = false;
      }
    }
  }

  list(key, params) {
    if (params?.['page-type']) {
      return this.pageTypesFilter(key, parseInt(params['page-type']));
    }

    if (params?.pattern) {
      return this.patternFilter(key, parseInt(params.pattern));
    }

    if (params?.element) {
      return this.elementsFilter(key, parseInt(params.element));
    }

    // return this._lists[key]?.length ? this._lists[key] : this.placeholderList(24);
    return this._lists[key] ?? this.placeholderList(12);
  }

  placeholderList(numberOfPlaceholders) {
    return Array.from(Array(numberOfPlaceholders).keys()).map(i => `placeholder_${i}`);
  }

  bookmarks(source) {
    return this.list('bookmarks')?.filter(id => {
      const screenshot = this.show(id);
      // console.log('screenshot', screenshot.isApp);

      if (source === 'sites') {
        return !screenshot.appId;
      } else {
        return screenshot.appId;
      }
    });
  }

  pagination(key) {
    return this._pagination[key] ?? {};
  }

  show(id) {
    return this._storage[id] ?? null;
  }

  pageTypesFilter(key, pageTypeId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.pageTypes?.findIndex(pageType => pageType.id === pageTypeId) > -1;
    });
  }

  patternFilter(key, patternId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.patterns?.findIndex(pattern => pattern.id === patternId) > -1;
    });
  } 

  elementsFilter(key, elementId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.elements?.findIndex(element => element.id === elementId) > -1;
    });
  } 

  findIndex(id, key, params) {
    return ScreenshotsStore.list(key, params)?.findIndex(screenId => screenId === id) ?? 0;
  }

  nextId(id, key, params) {
    let index = this.findIndex(id, key, params);
    index++;
    const list = this.list(key, params);
    // return list?.[index] ?? list?.[0] ?? null;
    return list?.[index] ?? null;
  }

  prevId(id, key, params) {
    let index = this.findIndex(id, key, params);
    index--;
    const list = this.list(key, params);
    // return list?.[index] ?? list?.[list.length - 1] ?? null;
    return list?.[index] ?? null;
  }

  isLast(id, key, params) {
    const index = this.findIndex(id, key, params);
    const list = this.list(key, params);
    return index === list?.length - 1;
  }

  deleteScreenshot(key, id) {
    const index = this._lists?.[key]?.findIndex(item => item === id);
    if (index > -1) {
      this._lists?.[key]?.splice(index, 1);
    }
  }

  addScreenshot(key, id) {
    this._lists[key] ??= [];
    this._lists[key].unshift(id);
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  getSimilarKey(id) {
    return `screenshots-similar-${id}`;
  }

  hasMoreSimilar(id) {
    return !!this.getNextSimilarPage(id);
  }

  getNextSimilarPage(id) {
    const { next } = this._pagination[this.getSimilarKey(id)] ?? {};
    return next;
  }
   
}

const ScreenshotsStore = new Screenshots();
export default ScreenshotsStore;
