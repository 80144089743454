export default function Player(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width={18}
        height={12}
        x={3}
        y={6}
        stroke="currentColor"
        strokeWidth={1.6}
        rx={3}
      />
      <path
        fill="currentColor"
        d="m10.71 9.175 4.036 2.484a.4.4 0 0 1 0 .681l-4.036 2.485a.4.4 0 0 1-.61-.341V9.516a.4.4 0 0 1 .61-.341Z"
      />
    </svg>
  )
}
