import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function PaymentLinkRedirect() {
  const { url } = useParams();
  useEffect(() => {
    if (url) {
      const _url = window.atob(url);
      window.location.replace(_url);
    }
  }, []);
  return null;
}
