export default function Card(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width={18}
        height={13}
        x={3}
        y={5.5}
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.6}
        rx={2}
      />
      <path stroke="currentColor" strokeWidth={1.6} d="M3 9.636h18" />
    </svg>
  )
};
