import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import SitePreview3 from 'components/SitePreview3/SitePreview3';
import SitesStore from 'stores/Sites';
import ScreenshotsStore from 'stores/Screenshots';
import FeedStore from 'stores/Feed';
import styles from './SiteRandom.module.scss';

const SiteRandom = observer(({ id }) => {

  useEffect(() => {
    nextPage();
    mixpanel.track('SimilarSitesRender');
  }, [ id ]);

  function nextPage() {
    FeedStore.random();
  }

  function isEnlarged(index) {
    return [0, 1, 2, 3].includes(index % 20);
  }

  function getSites() {
    return FeedStore.listRandom?.slice(0, 10);
  }

  return (
    <>
      <h2 className={styles.title}>
        Explore More Websites
      </h2>

      <div className={styles.container}>

        {getSites()?.map((id, index) => {
          return (
            <SitePreview3
              screenshots={ScreenshotsStore.list(id)}
              site={SitesStore.store[id]} 
              key={id}
              compact={!isEnlarged(index)}
              mixpanelEvent="SimilarSiteClick"
            />
          )
        })}

      </div>
    </>
  );
});

export default SiteRandom;
