import { makeAutoObservable, runInAction } from 'mobx';
import ReferoApi from 'api/refero_api';
import Screenshot from './Screenshot';
import AlertStore from './Alert';
import AppsStore from './Apps';

class AppScreenshots {

  _lists = {};
  _storage = {};
  _pagination = {};
  fetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(id) {
    try {
      this.fetching = true;
      const { records } = await ReferoApi.appScreenshots(id);
      // UserStore.setLeftQueries(left_queries);
      this.add(records, id, id);
      AppsStore.populate(id);
      runInAction(() => {
        this.fetching = false;
      });
    } catch (err) {
      AlertStore.add(err.message);
      runInAction(() => {
        this.fetching = false;
      });
    }
  }

  async similar(id, ignoreCache = false) {
    try {
      if (!ignoreCache && this._pagination[this.getSimilarKey(id)]) {
        return;
      } 
      this.fetching = true;
      const { records, pagination } = await ReferoApi.appScreenshotsSimilar(id);
      this.add(records, this.getSimilarKey(id), null, pagination);
      records?.forEach(screenshot => {
        AppsStore.add(screenshot.app);
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  async similarNextPage(id) {
    try {
      const next = this.getNextSimilarPage(id);
      if (!next) {
        return;
      }
      this.setFetching(true);
      const { records, pagination } = await ReferoApi.appScreenshotsSimilar(id, next);
      this.add(records, this.getSimilarKey(id), null, pagination);
      records?.forEach(screenshot => {
        AppsStore.add(screenshot.app);
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  add(list, key, appId, pagination) {
    this._lists[key] ??= [];
    if (pagination) {
      this._pagination[key] = pagination;
    }

    let hasPlaceholders = true;
    while(hasPlaceholders) {
      // const item = this._lists[key]?.at(-1);
      const item = this._lists[key]?.[this._lists[key]?.length - 1]
      if (item?.startsWith?.('placeholder_')) {
        this._lists[key].pop();
      } else {
        hasPlaceholders = false;
      }
    }

    list?.forEach(item => {
      const screenshot = new Screenshot(item, null, true, appId);
      const isSelected = this._storage[screenshot.id]?.selected ?? false;
      if (isSelected) {
        screenshot.select();
      }
      this._storage[screenshot.id] = screenshot;
      const index = this._lists[key].indexOf(screenshot.id);
      if (index === -1) {
        this._lists[key].push(screenshot.id);
      }
    });
  }

  addPlaceholders(key, numberOfPlaceholders = 24) {
    this._lists[key] ??= [];
    const list = Array.from(Array(numberOfPlaceholders).keys()).map(i => `placeholder_${i}`);
    this._lists[key].push(...list);
  }

  list(key, params) {
    if (params?.['page-type']) {
      return this.pageTypesFilter(key, parseInt(params['page-type']));
    }

    if (params?.pattern) {
      return this.patternFilter(key, parseInt(params.pattern));
    }

    if (params?.element) {
      return this.elementsFilter(key, parseInt(params.element));
    }

    return this._lists[key] ?? null;
  }

  pagination(key) {
    return this._pagination[key] ?? {};
  }

  show(id) {
    return this._storage[id] ?? null;
  }

  pageTypesFilter(key, pageTypeId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.pageTypes?.findIndex(pageType => pageType.id === pageTypeId) > -1;
    });
  }

  patternFilter(key, patternId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.patterns?.findIndex(pattern => pattern.id === patternId) > -1;
    });
  } 

  elementsFilter(key, elementId) {
    return this.list(key)?.filter(id => {
      const screenshot = this.show(id);
      return screenshot?.elements?.findIndex(element => element.id === elementId) > -1;
    });
  } 

  findIndex(id, key, params) {
    return this.list(key, params)?.findIndex(screenId => screenId === id) ?? 0;
  }

  nextId(id, key, params) {
    let index = this.findIndex(id, key, params);
    index++;
    const list = this.list(key, params);
    // return list?.[index] ?? list?.[0] ?? null;
    return list?.[index] ?? null;
  }

  prevId(id, key, params) {
    let index = this.findIndex(id, key, params);
    index--;
    const list = this.list(key, params);
    // return list?.[index] ?? list?.[list.length - 1] ?? null;
    return list?.[index] ?? null;
  }

  isLast(id, key, params) {
    const index = this.findIndex(id, key, params);
    const list = this.list(key, params);
    return index === list?.length - 1;
  }

  deleteScreenshot(key, id) {
    const index = this._lists?.[key]?.findIndex(item => item === id);
    if (index > -1) {
      this._lists?.[key]?.splice(index, 1);
    }
  }

  addScreenshot(key, id) {
    this._lists[key] ??= [];
    this._lists[key].unshift(id);
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  getSimilarKey(id) {
    return `screenshots-similar-${id}`;
  }

  hasMoreSimilar(id) {
    return !!this.getNextSimilarPage(id);
  }

  getNextSimilarPage(id) {
    const { next } = this._pagination[this.getSimilarKey(id)] ?? {};
    return next;
  }
   
}

const AppScreenshotsStore = new AppScreenshots();
export default AppScreenshotsStore;
