import Layout from 'components/Layout/Layout';
import styles from './Feedback.module.scss';

export default function Feedback() {
  return (
    <Layout withTopPadding>
      <h1 className={styles.title}>
        Feedback
      </h1>
    </Layout>
  )
}
