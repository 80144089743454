import { useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import lemonsqueezyUrl from 'helpers/lemonsqueezy-url';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import mixpanel from 'api/mixpanel';
import centsToDollars from 'helpers/cents-to-dollars';
import UserStore from 'stores/User';
import { Link } from 'react-router-dom';
import PricePerPeriod from 'components/PricePerPeriod/PricePerPeriod';
import PricePerMonth from 'components/PricePerMonth/PricePerMonth';
import Placeholder from 'components/Placeholder/Placeholder';
import styles from './PriceCard3.module.scss';

const PriceCard3 = observer(({ plan, desc, gradient, children, free, team, icon, saveAmount }) => {

  const [ redirecting, setRedirecting ] = useState(false);

  function onClickSubscribe() {
    setRedirecting(true);
    UserStore.addToAbondonedCartGroup();
    mixpanel.track('SubscribeButtonClick', { source: 'price-card' });
  }

  function renderPrice() {
    if (plan?.interval === 'day' && plan?.interval_count === 7) {
      return '$' + centsToDollars(plan?.price);
    }

    return <PricePerMonth price={plan?.price} plan={plan} />
  }

  function renderChargePeriod() {
    if (plan?.interval === 'day' && plan?.interval_count === 7) {
      return 'week';
    }
    return 'month';
  }

  function getPeriodName() {
    if (plan?.interval === 'year') {
      return 'annually';
    }

    if (plan?.interval === 'month' && plan?.interval_count === 3) {
      // return 'quarterly';
      return 'every 3 months';
    }

    if (plan?.interval === 'day' && plan?.interval_count === 7) {
      return 'weekly';
    }

    return 'monthly';
  }

  function renderPeriodPrice() {
    if (free) {
      return 'Free to use';
    }

    if (!plan) {
      return (
        <Placeholder name="newPricePeriod" />
      )
    }

    if (team) {
      return (
        <>
          Per seat
          <div className={styles.bullet} />
          <PricePerPeriod price={plan?.price} /> billed {getPeriodName()}
        </>
      )
    }

    return (
      <>
        <PricePerPeriod price={plan?.price} /> billed {getPeriodName()}
      </>
    )
  }

  function renderSubscribeButton() {
    if (!UserStore.isSignedIn()) {
      return (
        <div className={styles.button} onClick={event => UIStore.showSignupPopup()}>
          Get Started
        </div>
      )
    }

    if (free) {
      return (
        <div className={classnames(styles.button, styles.disabled)}>
          {UserStore.hasSubscription() ? 'Free Plan' : 'Current'}
        </div>
      )
    }

    if (team) {
      if (UserStore.teams?.length) {
        return (
          <Link to="/teams" className={styles.button}>
            Manage Team
          </Link>
        )
      }

      return (
        <Link to="/teams/create" className={styles.button}>
          Create Team
        </Link>
      )
    }

    if (!plan) {
      return (
        <div className={classnames(styles.button, styles.disabled)}>
          Loading Pricing Plan
        </div>
      )
    }

    return (
      <>
        <a 
          // className={classnames(styles.button, 'lemonsqueezy-button')} 
          className={classnames(styles.button, {
            [styles.redirecting]: redirecting
          })} 
          onClick={onClickSubscribe}
          href={lemonsqueezyUrl(plan?.url, UserStore.id, UserStore.email)}
        >
          {redirecting ? 'Redirecting to Payment Form' : UserStore.hasSubscription() ? 'Your Current Plan' : 'Upgrade Now' }
          
        </a>
      </>
    )
  }


  return (
    <div className={classnames(styles.container, {
      [styles.free]: free,
      [styles.gradient]: gradient,
    })}>
      <div className={styles.body}>

        <div className={styles.iconWrapper}>
          {icon}
        </div>
        
        <div 
          className={styles.desc}
          dangerouslySetInnerHTML={{
            __html: desc
          }} 
        />

        {saveAmount ?
          <div className={styles.save}>
            Save {saveAmount}
            <Icons.Stars2 className={styles.stars} />
            Best Value!
          </div>
        : <div className={styles.saveBlast} />}

        <div className={styles.priceWrap}>
          {plan || free ?
            <div className={styles.price}>
              <span>
                {renderPrice()}
              </span>/{renderChargePeriod()}
            </div>
          : 
            <Placeholder name="newPrice" />
          }

          <div className={styles.priceNote}>
            {/* Billed $96 yearly */}
            {renderPeriodPrice()}
          </div>
        </div>

        {renderSubscribeButton()}
      </div>
      <div className={styles.footer}>
        {children}
      </div>
    </div>
  );
});

export default PriceCard3;

export function PriceCard3Item({ title, desc, icon }) {
  return (
    <div className={styles.feature}>
      <div className={styles.featureIcon}>
        {icon}
      </div>
      <div className={styles.featureText}>
        <strong>{title}:</strong> {desc}
      </div>
    </div>
  );
}

export function PriceCard3LibraryIcon() {
  return (
    <div className={styles.iconLibrary}>
      <div className={styles.layer1} />
      <div className={styles.layer2} />
      <div className={styles.layer3} />
    </div>
  )
}