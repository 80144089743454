import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import BookmarksStore from 'stores/Bookmarks';
import CreateFolderPopup from 'components/CreateFolderPopup/CreateFolderPopup';
import * as Icons from 'icons';
import mixpanel from 'api/mixpanel';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import SearchResult from 'components/SearchResult/SearchResult';
import ScreenshotsStore from 'stores/Screenshots';
import AppScreenshotsStore from 'stores/AppScreenshots';
import SitesStore from 'stores/Sites';
import AppsStore from 'stores/Apps';
import DownloadStore from 'stores/Download';
import styles from './Folder.module.scss';


const TABS = ['web', 'ios'];
const NAMES = {
  'web': 'Web',
  'ios': 'iOS'
};

const Folder = observer(() => {

  const navigate = useNavigate();
  const { id, type } = useParams();
  const [ tab, setTab ] = useState(type ?? TABS[0]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    BookmarksStore.showFolder(id);
  }

  function getIcon(key) {
    switch (key) {
      case 'web': 
        return <Icons.Planet width="24" prefix="folder" />
      case 'ios':
        return <Icons.AppStore width="24" prefix="folder" />
      default:
        return null;
    }
  }

  function countScreens(key) {
    return getSource(key)?.length ?? 0;
  }

  function getSource(key) {
    if (key === 'web') {
      return ScreenshotsStore.list(getKey());
    }

    return AppScreenshotsStore.list(getKey())
  }

  function getKey() {
    return `bookmarks_${id}`;
  }

  function downloadAll() {
    mixpanel.track('DonwloadZipFolder', { tab });

    if (tab === 'web') {
      const list = getSource(tab)?.map((id, index) => {
        const screen = ScreenshotsStore.show(id);
        return {
          urls: screen.singleScreen ? [screen.url[0]] : screen.url,
          name: `${screen.id}.jpg`
        }
      });
      DownloadStore.createZip(list, folder?.name); 
    } else {
      const list = getSource(tab)?.map((id, index) => {
        const screen = AppScreenshotsStore.show(id);
        return screen.url;
      });
      DownloadStore.createAppZip(list, folder?.name); 
    }
  }

  function renderDesktopScreenshot(id) {
    const screenshot = ScreenshotsStore.show(id);
    const site = SitesStore.find(screenshot?.siteId);
    const url = `/${site?.id}-${site?.domain}/${screenshot?.id}?source=${getKey()}`;

    return (
      <div 
        className={styles.imageContainer}
        key={id} 
      >
        <SearchResult 
          url={url}
          site={site} 
          screenshot={screenshot}
          selected={screenshot?.selected}
        />
      </div>
    )
  }

  function renderAppScreenshot(id) {
    const screenshot = AppScreenshotsStore.show(id);
    const app = AppsStore.find(screenshot?.appId);
    const url = `/apps/${app?.id}/${screenshot.id}?source=${getKey()}`;

    return (
      <div 
        className={styles.imageContainerApp}
        key={id} 
      >
         <SearchResult
          url={url}
          app={app}
          isApp
          screenshot={screenshot}
          selected={screenshot?.selected}
        />
      </div>
    )
  }

  function onClickTab(item) {
    setTab(item);
    return navigate(`/bookmarks/${id}/${item}`, {
      state: { preventScrollTop: true }
    });
  }

  const folder = BookmarksStore.getFolder(parseInt(id));

  return (
    <Layout>
      <div className={styles.container}>
        
        <div className={styles.header}>
          <Link to="/bookmarks" className={styles.back}>
            <Icons.ArrowLeft2 />
          </Link>

          <div className={styles.title}>
            {folder?.name}
          </div>

          <div className={styles.buttonsWrap}>

            <Dropdown actions={[
              {
                name: 'Rename Board',
                onClick: () => BookmarksStore.setFolderPopupVisible(true),
                icon: <Icons.Pencil />
              },
              {
                name: 'Delete',
                color: '#EE6060',
                onClick: () => BookmarksStore.setDeletePopupVisible(true),
                icon: <Icons.Trash />
              }
            ]}>
              <div className={styles.back}>
                <div className={styles.dots}></div>
              </div>
            </Dropdown>
            
            {/* {tab === 'ios' ? */}
              <Button 
                onClick={downloadAll}
                className={styles.download}
                mobileHidden
              >
                <Button.Icon>
                  <Icons.Download3 /> 
                </Button.Icon>
                <Button.MobileHidden>
                  Download All
                </Button.MobileHidden>
              </Button>
            {/* : null} */}
          </div>
        </div>

        <div className={styles.filters}>

          <TabsSlider 
            className={styles.tabs} 
            compact
            noBg
            activeIndex={TABS?.findIndex(item => tab === item)}
          >
            {TABS.map(item => 
              <TabsSlider.Item 
                onClick={event => onClickTab(item)}
                active={item === tab}
                key={item}
              >
                <TabsSlider.Icon>
                  {getIcon(item)}
                </TabsSlider.Icon>
                {NAMES[item]} <TabsSlider.Postfix>{countScreens(item)}</TabsSlider.Postfix>
              </TabsSlider.Item>
            )}
          </TabsSlider>
            

          {/* <div>sort</div> */}
        
        </div>
        
        <div className={styles.images}>
          {getSource(tab)?.map(id => {
            if (tab === TABS[0]) {
              return renderDesktopScreenshot(id);
            } else {
              return renderAppScreenshot(id);
            }
          })}
        </div>

      </div>

      {BookmarksStore.createFolderPopupVisible ?
        <CreateFolderPopup 
          id={id} 
          value={folder?.name} 
          label="Rename Board" 
          submitLabel="Save"
        />
        : null
      }

      {BookmarksStore.deletePopupVisible ?
        <CreateFolderPopup 
          id={id} 
          deleteAction 
          label="Are you sure you want to delete this board?" 
          subLabel="This action cannot be undone"
          submitLabel="Delete Board"
        />
        : null
      }
    </Layout>
  );
});

export default Folder;
