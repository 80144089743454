import { makeAutoObservable, runInAction } from 'mobx';
import mergeImages from 'helpers/merge-images';
import AlertStore from './Alert';

export default class DownloadItem {

  screenshots = [];
  domain = null;
  id = null;
  progress = 0;
  onComplete

  constructor(screenshots, domain, id, onComplete) {
    this.screenshots = screenshots?.map(url => url.replace('images.refero.design', 'refer-production.s3.us-east-2.amazonaws.com'))
    this.domain = domain;
    this.id = id;
    this.onComplete = onComplete;
    // this.worker = new window.Worker(new URL('workers/canvas-render.js', import.meta.url));
    // console.log('WORKER', this.worker);

    makeAutoObservable(this);
  }

  async start() {
    if (!this.screenshots?.length) {
      return;
    }

    // this.worker.postMessage('TEST message');

    try {
      const result = await mergeImages(this.screenshots, progress => 
        runInAction(() => this.progress = progress)
      );

      const a = document.createElement('a');
      a.href = result;
      a.download = `refero.design ${this.domain} ${this.id}.jpg`; 
      a.click();
      a.remove();

      this.onComplete();
    } catch (err) {
      AlertStore.add(err.message);
    }
    
  }

  async getBlob() {
    if (!this.screenshots?.length) {
      return;
    }

    try {
      const result = await mergeImages(this.screenshots, progress => 
        runInAction(() => this.progress = progress)
      , true);
      this.onComplete();

      return result;
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  setProgress(value) {
    this.progress += value;
  }

}

