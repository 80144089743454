import { makeAutoObservable, runInAction } from 'mobx';
import referoApi from 'api/refero_api';
import AlertStore from './Alert';

// const web = [10, 13, 14];
// const ios = [9, 11, 12];

class Pricing {

  items = {};
  fetching = false;
  tabs = [
    {
      name: 'Monthly',
      interval: 'month',
      interval_count: 1
    },
    {
      name: 'Quarterly',
      interval: 'month',
      interval_count: 3
    },
    {
      name: 'Annual',
      interval: 'year',
      interval_count: 1
    }
  ];
  tabsWeekly = [
    {
      name: 'Weekly',
      interval: 'day',
      interval_count: 7
    },
    {
      name: 'Monthly',
      interval: 'month',
      interval_count: 1
    },
    {
      name: 'Annual',
      interval: 'year',
      interval_count: 1
    }
  ];
  activeTab = 2;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(priceKey) {
    try {
      if (this.fetching) {
        return;
      }

      this.setFetching(true);
      const { subscription_plans } = await referoApi.subscriptionPlans(priceKey);

      runInAction(() => {
        this.items[priceKey] = subscription_plans.sort((a, b) => a.price - b.price);
      });

      this.setFetching(false);
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  list(priceKey) {
    if (!priceKey) {
      return null;
    }

    return this.items[priceKey] ?? null;
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  setTab(index) {
    this.activeTab = index;
  }

  getActiveTab(tabs = this.tabs) {
    return tabs[this.activeTab] ?? null;
  }

  calcAnualPrice(plan) {
    if (!plan) {
      return 0;
    }

    if (plan.interval === 'month') {
      return 12 / plan.interval_count * plan.price
    }

    return plan.price;
  }

}

const PricingStore = new Pricing();
export default PricingStore;
