import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const searches = {
  'compare': 'page_types[id][]=19&site_id[id][]=72&site_id[id][]=272&site_id[id][]=165&site_id[id][]=69&order=popular',
  'hero': 'page_elements[id][]=98&categories[id][]=32&order=popular',
  'audio': 'design_patterns[id][]=162&site_id[id][]=137&site_id[id][]=197&site_id[id][]=77&order=popular',
  '404': 'page_types[id][]=41&page_elements[id][]=95&order=popular',
  'signup': 'page_types[id][]=19&design_patterns[id][]=117',
  'progress': 'page_types[id][]=24&page_elements[id][]=77'
};

export default function Redirect({ param = 'design_patterns', search = false }) {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (search) {
      return navigate(`/search?${searches[id]}`);
    }
    navigate(`/search?${param}[id][]=${id}`);
  }, []);
  return null;
}
