import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import pro from 'images/pro@x2.png';
import mixpanel from 'api/mixpanel';
import styles from './WelcomePro.module.scss';

export default function WelcomePro() {

  useEffect(() => {
    mixpanel.track('SubscriptionSuccessRedirect');
  }, []);

  return (
    <div className={styles.container}>
      <Link to="/" replace className={styles.bg} />
      <div className={styles.body}>

        <div className={styles.head}>
          <img className={styles.icon} src={pro} alt="Pro" />
        </div>

        <div className={styles.title}>
          Welcome to the Pro!
        </div>
        <div>
          We're glad you're here. Let's explore design together with your unlimited access.
        </div>

        <div className={styles.buttons}>
          <Link to="/" replace className={styles.button}>
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
}
