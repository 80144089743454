import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import * as Icons from 'icons';
import SearchStore from 'stores/Search';
import styles from './Suggestions.module.scss';

import pageTypes from 'json/page-types.json';
import patterns from 'json/design-patterns.json';
import elements from 'json/page-elements.json';
import UIStore from 'stores/UI';

const Suggestions = observer(({ urlPrefix }) => {

  function getName(id, type) {
    const result = getSource(type)?.find(item => item.id === id);
    return UIStore.searchType === 'sites' ? result?.name : (result?.app_name !== '' ? result?.app_name : result?.name);
  }

  function getSource(type) {
    switch(type) {
      case 'page_types':
        return pageTypes;
      case 'design_patterns':
        return patterns;
      case 'page_elements':
        return elements;
      default:
        return null;
    }
  }

  function onClick(type, id) {
    mixpanel.track('SuggestionClick', { type, id });
    localStorage.setItem('suggestion-tip-hidden', '1');
  }

  // function isTipVisible() {
  //   return !localStorage.getItem('suggestion-tip-hidden');
  // }

  function buildParams(item) {
    let params = SearchStore.params();
    if (params) {
      params += '&';
    }
    params += `${item.type}[id][]=${item.id}`;
    return params;
  }

  if (!SearchStore.suggestions?.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {SearchStore.suggestions?.map(item => 
        <Link 
          to={`?${buildParams(item)}`} 
          className={styles.item} 
          onClick={event => onClick(item.type, item.id)}
          key={item.id}
        >
          <Icons.Plus className={styles.icon} width="10" height="10" />
          {getName(parseInt(item.id), item.type)}
        </Link>
      )}

      {/* {isTipVisible() ?
        <div className={styles.tip}>
          Combine tags for best results
        </div>
      : null} */}
    </div>
  );
});

export default Suggestions;
