import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import styles from './Logo.module.scss';

const Logo = observer(() => {
  return (
    <Link 
      to={UIStore.searchType === 'sites' ? '/' : '/apps'} 
      className={styles.container}
      title="Refero"
    >
      <Icons.LogoRound className={styles.icon} />
    </Link>
  );
});

export default Logo;
