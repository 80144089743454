import mixpanel from 'api/mixpanel';
import Button from 'components/Button/Button';
import * as Icons from 'icons';
import UserStore from 'stores/User';
import styles from './ScreenControls.module.scss';

export default function ScreenControls({ 
  copy, 
  download, 
  save, 
  edit,
  pageUrl
}) {

  function pageUrlClick() {
    mixpanel.track('PageUrlClick', { pageUrl });
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>

        <Button light block className={styles.button} onClick={save}>
          <Button.Icon>
            <Icons.Bookmarks2 /> 
          </Button.Icon>
          Save to..
        </Button>

        {window.ClipboardItem ?
          <Button light round className={styles.button} onClick={copy}>
            <Button.Icon>
              <Icons.Copy /> 
            </Button.Icon>
          </Button>
        :null}

        {pageUrl ?
          <a href={pageUrl} target="_blank" rel="noreferrer" className={styles.button} onClick={pageUrlClick}>
            <Button light round>
              <Button.Icon>
                <Icons.Globe2 width="18" /> 
              </Button.Icon>
            </Button>

            <div className={styles.tooltip}>
              Visit original page
            </div>
          </a>
        :null}

      </div>
      <div className={styles.row}>
        
        <Button block className={styles.button} onClick={download}>
          <Button.Icon>
            <Icons.Download2 /> 
          </Button.Icon>
          Download
        </Button>

        {UserStore.isAdmin() ?
          <Button light onClick={edit}>
            Edit
          </Button>
        :null}

      </div>
    </div>
  );
}