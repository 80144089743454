export default function Close2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.6}
        d="M17.072 7 7 17.071M7 7l10.071 10.071"
      />
    </svg>
  )
};