import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import mixpanel from 'api/mixpanel';
// import Title from 'components/Title/Title';
import Button from 'components/Button/Button';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import UserStore from 'stores/User';
import styles from './Hero3.module.scss';
import screenshots from 'json/screenshots-count.json';
import appScreenshots from 'json/app-screenshots-count.json';

const Hero3 = observer(({ title, subTitle, apps }) => {

  function signup() {
    UIStore.showSignupPopup();
    mixpanel.track('HeroButtonClick', {type: 'signup'});
  }

  function explore() {
    mixpanel.track('HeroButtonClick', {type: 'explore'});
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.left}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{__html: title}} />
        </div>
        <div className={styles.right}>

          <h2 className={styles.text} dangerouslySetInnerHTML={{__html: subTitle}} />

          <div className={styles.buttons}>
            {apps ?
              <Link to="/apps/search" onClick={explore} className={styles.cta}>
                <Button className={styles.button} blockTablet>
                  <Button.Icon>
                    <Icons.Pages />
                  </Button.Icon>
                  Explore iOS Screens
                  <Button.Postfix>
                    {(appScreenshots?.all)?.toLocaleString()}
                  </Button.Postfix>
                </Button>
              </Link>
            :
              <Link to="/search" onClick={explore} className={styles.cta}>
                <Button className={styles.button} blockTablet>
                  <Button.Icon>
                    <Icons.Pages />
                  </Button.Icon>
                  Explore Web Pages
                  <Button.Postfix>
                    {(screenshots?.all)?.toLocaleString()}
                  </Button.Postfix>
                </Button>
              </Link>
            }

            {!UserStore.isSignedIn() && 
              <Button 
                light 
                blockTablet 
                className={styles.button} 
                onClick={signup}
              >
                <Button.Icon>
                  <Icons.Login />
                </Button.Icon>
                Log In or Sign Up
              </Button>
            }
          </div>

        </div>
      </div>
    </div>
  );
});

export default Hero3;
