// import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
// import referoApi from 'api/refero_api';
import Layout from 'components/Layout/Layout';
// import PlanPreview from 'components/PlanPreview/PlanPreview';
// import TabsSlider from 'components/TabsSlider/TabsSlider';
import Testimonials from 'components/Testimonials/Testimonials';
import * as Icons from 'icons';
// import AlertStore from 'stores/Alert';
import screenshots from 'json/screenshots-count.json';
import appScreenshots from 'json/app-screenshots-count.json';
import shuffle from 'helpers/shuffle';
import styles from './Features2.module.scss';

import DESIGN_PATTERNS from 'json/design-patterns.json';
import PAGE_ELEMENTS from 'json/page-elements';
import PAGE_TYPES from 'json/page-types.json';
import SiteFeatures from 'components/SiteFeatures/SiteFeatures';
import CompareTable from 'components/CompareTable/CompareTable';
import { Link } from 'react-router-dom';
import sites from 'images/sites@x2.png';

// const ITEMS = [
//   {interval: 'month', intervalCount: 1},
//   {interval: 'month', intervalCount: 3},
//   {interval: 'year', intervalCount: 1},
// ];

const tags = [
  ...DESIGN_PATTERNS.map(tag => {
    return {...tag, type: 'design_patterns'}
  }), 
  ...PAGE_ELEMENTS.map(tag => {
    return {...tag, type: 'page_elements'}
  }), 
  ...PAGE_TYPES.map(tag => {
    return {...tag, type: 'page_types'}
  })
].sort(shuffle);

const Features2 = observer(({ excludeTable = false, excludeLogoTitle = false }) => {

  function renderIcon(type) {
    switch (type) {
      case 'page_types': 
        return <Icons.PageTypesColor className={styles.tagIcon} width="28" />
      case 'design_patterns':
        return <Icons.PatternsColor className={styles.tagIcon} width="28" />
      case 'page_elements':
        return <Icons.ElementsColor className={styles.tagIcon} width="28" />
      default:
        return null;
    }
  }

  function buildTagLink(tag) {
    return `/search?${tag.type}[id][]=${tag.id}`;
  }

  return (
    <>
      <Layout.HomeWithPadding>
        <div className={styles.container}>

          {!excludeLogoTitle ?
            <div className={styles.title}>
              {/* Trusted by leading design teams */}
              Trusted by amazing people at companies like
            </div>
          :null}

          <div className={styles.logos}>
            <div className={classnames(styles.logo, styles.intuit)} />
            <div className={classnames(styles.logo, styles.twilio)} />
            
            <div className={classnames(styles.logo, styles.deloitte)} />
            <div className={classnames(styles.logo, styles.disney)} />
            {/* <div className={classnames(styles.logo, styles.checkr)} /> */}
            <div className={classnames(styles.logo, styles.monday)} />
            <div className={classnames(styles.logo, styles.plaid)} />

            {/* <div className={classnames(styles.logo, styles.pwc)} /> */}
            {/* <div className={classnames(styles.logo, styles.salesforce)} /> */}
            {/* <div className={classnames(styles.logo, styles.bytedance)} /> */}
          </div>

          <div className={styles.phBadges}>
            <a 
              className={styles.phBadge}
              href="https://www.producthunt.com/products/refero/reviews?utm_source=badge-product_rating&utm_medium=badge&utm_souce=badge-refero" 
              target="_blank"
              rel="noreferrer"
            >
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/product_rating.svg?product_id=525051&theme=light" 
                alt="Refero - Explore&#0032;real&#0045;world&#0032;designs&#0032;from&#0032;the&#0032;best&#0032;products | Product Hunt" 
  
                width="242" 
                height="108" 
              />
            </a>

            {/* <a 
              className={styles.phBadge}
              href="https://www.producthunt.com/posts/refero-2-0?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-refero&#0045;2&#0045;0" 
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=409657&theme=light&period=daily" alt="Refero&#0032;2&#0046;0 - Explore&#0032;real&#0045;world&#0032;designs&#0032;from&#0032;the&#0032;best&#0032;products | Product Hunt" width="250" height="54" />
            </a>
            <a 
              className={styles.phBadge}
              href="https://www.producthunt.com/posts/refero-2-0?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-refero&#0045;2&#0045;0" 
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=409657&theme=light&period=weekly&topic_id=267" alt="Refero&#0032;2&#0046;0 - Explore&#0032;real&#0045;world&#0032;designs&#0032;from&#0032;the&#0032;best&#0032;products | Product Hunt" width="250" height="54" />
            </a> */}
          </div>

          <h2 className={classnames(styles.titleH2, styles.lessMargin)}>
            Discover proven designs from industry leaders
          </h2>

          <div className={styles.title}>
            Get inspired by designs that have been tested and proven effective in real-life products.
          </div>

          <img src={sites} className={styles.sites} alt="Refero sites" loading="lazy" />

          <div className={classnames(styles.title, styles.extraMargin)}>
            400+ best-in-class web and iOS products 
          </div> 

          <SiteFeatures />



          <h2 className={styles.titleH2}>
            {(Math.floor((screenshots?.all + appScreenshots?.all)/1000)*1000)?.toLocaleString()}+ screens library to overcome any design hurdle
          </h2>
        </div>

      </Layout.HomeWithPadding>

      <div className={styles.tags}>
        <div className={styles.tagsRowWrap}>
          <div className={styles.tagsRow}>
            <div className={classnames(styles.tagsRowBody, styles.animationRightToLeft)}>
              {tags.slice(0, 40).map(tag =>
                <Link 
                  to={buildTagLink(tag)}
                  className={styles.tag} 
                  key={`${tag.type}-${tag.id}`}
                >  
                  {renderIcon(tag.type)}
                  {tag.name}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={styles.tagsRowWrap}>
          <div className={styles.tagsRow}>
            <div className={classnames(styles.tagsRowBody, styles.animationLeftToRight)}>
              {tags.slice(41, 80).map(tag =>
                <Link 
                  to={buildTagLink(tag)}
                  className={styles.tag} 
                  key={`${tag.type}-${tag.id}`}
                >  
                  {renderIcon(tag.type)}
                  {tag.name}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={styles.tagsRowWrap}>
          <div className={styles.tagsRow}>
            <div className={classnames(styles.tagsRowBody, styles.animationRightToLeft2)}>
              {tags.slice(81, 120).map(tag =>
                <Link 
                  to={buildTagLink(tag)}
                  className={styles.tag} 
                  key={`${tag.type}-${tag.id}`}
                >  
                  {renderIcon(tag.type)}
                  {tag.name}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <h2 className={styles.titleH2}>
        What people say
      </h2>

      <Testimonials />
      
      {!excludeTable ?
        <Layout.HomeWithPadding>
          <CompareTable />
        </Layout.HomeWithPadding>
      : null}

    </>
  );
});

export default Features2;