export default function Close(props) {
  return (
    <svg
      width={10}
      height={16}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v14.14a.4.4 0 0 1-.658.306l-3.698-3.114a1 1 0 0 0-1.288 0L.658 15.446A.4.4 0 0 1 0 15.14V1Z"
        fill="currentColor"
      />
    </svg>
  )
};