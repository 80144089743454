export default function Affiliate(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <circle
        cx={7}
        cy={7}
        r={3}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
      />
      <circle
        cx={17}
        cy={17}
        r={3}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.6}
        d="M14 7h2a1 1 0 0 1 1 1v2M10 17H8a1 1 0 0 1-1-1v-2"
      />
    </svg>
  )
}
