import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import mixpanel from 'api/mixpanel';
import Layout from 'components/Layout/Layout';
import PriceItem from 'components/PriceItem/PriceItem';
// import Features from 'components/Features/Features';
import Features2 from 'components/Features2/Features2';
import Faq from 'components/Faq/Faq';
import styles from './Price.module.scss';
import FAQ from 'json/pricing-faq.json';

export default function Price({ title = 'Pricing', subTitle, onePrice = false }) {

  useEffect(() => {
    mixpanel.track('PricePageVisit');
  }, []);

  return (
    <div className={styles.container}>

      <Helmet>
        <title>Refero — Pricing</title>
        <link rel="canonical" href={`https://refero.design/pricing`} />
        <meta property="og:url" content={`https://refero.design/pricing`} />
        <meta property="og:title" content={`Refero — Pricing`} />
        <meta name="twitter:title" content={`Refero — Pricing`} />
        <meta property="og:site_name" content={`Refero — Pricing`} />
      </Helmet>

      <Layout>
        <h1 className={styles.title}>
          {title}
        </h1>

        {subTitle ?
          <div className={styles.subTitle}>
            {subTitle}
          </div>
        : null}
      </Layout>

      <PriceItem fetchPlans />
      <Features2 stickyTableHead />
      <PriceItem />

      <Layout>
        <h2 className={styles.faqTitle}>
          FAQ
        </h2>
        <Faq>
          {FAQ?.map((item, index) => 
            <Faq.Item {...item} key={index} />
          )}

          <script type='application/ld+json'>{`
            {
              "@context":"https://schema.org",
              "@type":"FAQPage",
              "mainEntity": ${JSON.stringify(FAQ?.map(({ question, answer }) => {
                return {
                  "@type":"Question",
                  "name":question,
                  "acceptedAnswer":{
                    "@type":"Answer",
                    "text":answer
                  }
                }
              }))}
            }
          `}</script>
        </Faq>
      </Layout>

    </div>
  );
}
