import UserStore from 'stores/User';

function getToken() {
  // return '';
  return localStorage?.getItem('refero_token');
}

const UNAUTH_CODES = [401, 403];

function getRequest(url) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(!!token && { 'Authorization': `Bearer ${token}` })
        })
      });

      // if (response.status === 401) {
      if (UNAUTH_CODES.includes(response.status)) {
        UserStore.logout();
        return reject(new Error('failed_authorisation_request'));
      }

      if (response.status === 404) {
        return reject(new Error('Something went wrong. Try again later'));
      }

      const json = await response.json();

      if (json.error) {
        reject(new Error(json.error || json.message));
      } else {
        resolve(json);
      }
    } catch (err) {
      reject(new Error(err.message || 'Internal error. Please try again later.'));
    }
  });
};

function putRequest(url, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(!!token && { 'Authorization': `Bearer ${token}` })
        }),
        body: JSON.stringify(data)
      });

      if (response.status === 401) {
        return reject(new Error('failed_authorisation_request'))
      }

      if (response.status === 404) {
        return reject(new Error('Something went wrong. Try again later'));
      }


      let json = await response.json();

      if (json.error || response.status === 400) {
        reject(new Error(json.error || json.message));
      } else {
        resolve(json);
      }
    } catch (err) {
      reject(new Error(err.message || 'Internal error. Please try again later.'));
    }
  });
}

function postRequest(url, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(!!token && { 'Authorization': `Bearer ${token}` })
        }),
        body: JSON.stringify(data)
      });

      if (response.status === 401) {
        return reject(new Error('failed_authorisation_request'));
      }

      if (response.status === 404) {
        return reject(new Error('Something went wrong. Try again later'));
      }

      let json = await response.json();

      if (json.error || response.status === 400) {
        reject(new Error(json.error || json.message));
      } else {
        resolve(json);
      }
    } catch (err) {
      reject(new Error(err.message || 'Internal error. Please try again later.'));
    }
  });
};

function deleteRequest(url, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(!!token && { 'Authorization': `Bearer ${token}` })
        }),
        body: JSON.stringify(data)
      });

      if (response.status === 401) {
        return reject(new Error('failed_authorisation_request'))
      }

      if (response.status === 404) {
        return reject(new Error('Something went wrong. Try again later'));
      }

      let json = await response.json();

      if (json.error) {
        reject(new Error(json.error || json.message));
      } else {
        resolve(json);
      }
    } catch (err) {
      reject(new Error(err.message || 'Internal error. Please try again later.'));
    }
  });
};


export default {
  get: getRequest,
  put: putRequest,
  post: postRequest,
  delete: deleteRequest
}