import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import mixpanel from 'api/mixpanel';
import referoApi from 'api/refero_api';
import getColorName from 'helpers/get-color-name';
import * as Icons from 'icons';
import SiteHead from 'components/SiteHead/SiteHead';
import Title from 'components/Title/Title';
import Tags from 'components/Tags/Tags';
import ScreenWithLogin from 'components/ScreenWithLogin/ScreenWithLogin';
import SimilarScreenshots from 'components/SimilarScreenshots/SimilarScreenshots';
import ScreenControls from 'components/ScreenControls/ScreenControls';
import SitesStore from 'stores/Sites';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';
import AlertStore from 'stores/Alert';
import DownloadStore from 'stores/Download';
import BookmarksStore from 'stores/Bookmarks';
import ScreenshotsStore from 'stores/Screenshots';
import getParamsString from 'helpers/get-params-string';
import isObjectEmpty from 'helpers/is-object-empty';
import styles from './Screen.module.scss';


const Screen = observer(({ isPopup }) => {

  const { siteId, domain, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); 
  const prevButton = useRef(null);
  const nextButton = useRef(null);
  const closeButton = useRef(null);
  const container = useRef(null);
  const recommendationsAnchor = useRef(null);
  const [ firstImageReady, setFirstImageReady ] = useState(false);
  const [ params, setParams ] = useState(Object.fromEntries(new URLSearchParams(location.search)));

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '50px'
  });

  useEffect(() => {
    if (!isPopup && !params?.source?.startsWith('bookmarks_') && Object.keys(params).length !== 0) {
      return window.location.replace(`/${siteId}-${domain}/${id}`);
    }

    const handleClick = (event) => {
      if (event.keyCode === 37) {
        return prevButton.current?.click();
      } 
  
      if (event.keyCode === 39) {
        nextButton.current?.click();
      }
    };
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('keydown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (siteId) {
      SitesStore.show(siteId);
    } 
    fetchScreenshots();
  }, [ siteId ]);

  useEffect(() => {
    if (UserStore.loginDate) {
      fetchScreenshots();
    }
  }, [ UserStore.loginDate ]);

  useEffect(() => {
    setParams(Object.fromEntries(new URLSearchParams(location.search)));
  }, [ location.search ]);

  useHotkeys('esc', () => closeButton.current?.click(), { scopes: 'screen' });

  useEffect(() => {
    if (firstImageReady) {
      setFirstImageReady(false);
    }

    mixpanel.track('ScreenView', { siteId, id, domain, isPopup });
    setTimeout(() => track(id), 500);
  }, [ id ]);

  useEffect(() => {
    if (firstImageReady) {
      preloadNextImage();
    }
  }, [ firstImageReady ]);

  async function track(id) {
    try {
      await referoApi.trackings(id);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchScreenshots() {
    const source = getSource();

    if (source) {
      fetchAdditionalData(source);
    } else {
      ScreenshotsStore.fetch(siteId);
    }
  }

  function preloadNextImage() {
    const nextId = ScreenshotsStore.nextId(parseInt(id), getScreenshotKey(), params);
    const screen = ScreenshotsStore.show(nextId);

    screen?.url?.slice(0, 2)?.forEach(url => {
      const img = new Image();
      img.src = url;
    });
  }

  function close(event) {
    event?.stopPropagation();
    event?.preventDefault();

    if (UIStore.isFirefox) {
      container.current.classList.add(styles.noScroll);
    }
    
    navigate(-1);
    mixpanel.track('ScreenViewClose');
  }

  function buildUrl(screenshotId) {
    const _screen = ScreenshotsStore.show(screenshotId);
    const _site = SitesStore.find(_screen?.siteId);
    return `/${_site?.id}-${_site?.domain}/${screenshotId}${location.search}`;
  }

  function prev() {
    const prevId = ScreenshotsStore.prevId(parseInt(id), getScreenshotKey(), params);
    if (prevId) {
      makeNavigation(prevId);
      mixpanel.track('ScreenViewPrev');
    }
  }

  function next() {
    const screensAfter = ScreenshotsStore.list(getScreenshotKey(), params)?.length - ScreenshotsStore.findIndex(parseInt(id), getScreenshotKey(), params);
    if (screensAfter <= 5 && !ScreenshotsStore.fetching) {
      SitesStore.trySearchNext(getParamsString());
    }
    const nextId = ScreenshotsStore.nextId(parseInt(id), getScreenshotKey(), params);
    if (nextId) {
      makeNavigation(nextId);
      mixpanel.track('ScreenViewNext');
    }
  }

  function makeNavigation(screenshotId) {
    if (!screenshotId) {
      return;
    }
    navigate(buildUrl(screenshotId), {
      replace: true,
      ...(isPopup && {state: { prevPath: location.pathname }})
    });
  }

  function download() {
    mixpanel.track('Download');

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    if (!UserStore.hasSubscription()) {
      let count = parseInt(localStorage.getItem('dc') ?? 0);
      if (count >= 99) {
        return AlertStore.add('Something went wrong. Try again later');
      }
      localStorage.setItem('dc', count + 1);
    }

    DownloadStore.add(screenshot?.url, domain, id);
  }

  function copy() {
    mixpanel.track('Copy', { type: 'desktop' });

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    DownloadStore.copy(screenshot?.url, id);
  }

  async function fetchAdditionalData(source) {
    if (source === 'search') {
      await SitesStore.search(getScreenshotKey());
    }

    if (source?.startsWith('bookmarks_')) {
      const folderId = parseInt(source.replace('bookmarks_', ''));
      await BookmarksStore.showFolder(folderId);
    }
  }

  function isScreenAvailable() {
    const result = list?.includes(parseInt(id));
    if (result === undefined) {
      return false;
    }
    return result;
  }

  function isBookmark() {
    return params?.source?.startsWith('bookmarks_');
  }

  function renderContent() {
    if (!list?.length) {
      return null;
    }

    if (!UserStore.isReady) {
      return null;
    }

    if (!UserStore.isSignedIn() && !isBookmark() && (!isScreenAvailable() || 
        (ScreenshotsStore.isLast(parseInt(id), getScreenshotKey(), params) && list?.length > 3))
    ) {
      return <ScreenWithLogin />
    }

    if (UserStore.isSignedIn() && !isBookmark() && !UserStore.hasSubscription() && UserStore.isTrialExpired() && (!isScreenAvailable() ||
      (ScreenshotsStore.isLast(parseInt(id), getScreenshotKey(), params) && list?.length > 3))
    ) {
      return <ScreenWithLogin paywall />
    }

    return (
      <div className={classnames(styles.imagesWrap, {
        [styles.singleScreenCentered]: screenshot?.singleScreen || screenshot?.url?.length === 1
      })}>
        {screenshot?.url?.map((url, index) =>
          index === 0 ?
            <img 
              className={styles.screenshot} 
              src={url}
              alt={buildAlt()} 
              async
              key={url}
              onLoad={event => setFirstImageReady(true)}
            />
          : 
            (!screenshot?.singleScreen && navigator?.userAgent !== "ReactSnap" && firstImageReady && 
              <img 
                className={styles.screenshot} 
                src={url}
                alt="" 
                async
                loading="lazy"
                key={url}
              />
            )
        )}
      </div>
    )
  }

  function getScreenshotKey() {
    const source = getSource();

    if (source?.startsWith('bookmarks_')) {
      return source;
    }

    switch (source) {
      case 'search':
        return getParamsString();
      case 'bookmarks':
        return 'bookmarks';
      default:
        return site?.id;
    }
  }

  function getScreenshotsCount() {
    if (isObjectEmpty(params)) {
      return site?.screenshotsCount ?? list?.length;
    }

    if (params?.source === 'search') {
      const pagination = ScreenshotsStore.pagination(getParamsString());
      return pagination?.count?.toLocaleString();
    }

    return list?.length;
  }

  function getSource() {
    return (new URLSearchParams(location.search)).get('source');

    // if (location.search?.includes('source=search')) {
    //   return 'search';
    // }

    // if (location.search?.includes('source=bookmarks')) {
    //   return 'bookmarks';
    // }

    // return null;
  }

  function onClickBookmark() {
    mixpanel.track('BookmarkAdd');

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    BookmarksStore.setScreenToAdd(parseInt(id))
  }

  function edit() {
    window.open(`https://admin.refero.design/sites/${site.id}/${id}`);
  }

  function buildPageTitle() {
    return `${site?.name ?? domain} ${screenshot?.pageTypes?.map(el => el?.name)?.join(' and ') } UI — Refero`;
  }

  function buildPageDescription() {
    return `${site?.name ?? domain} website screenshot. Graphic and web design reference. UX UI design inspiration.`;
  }

  function buildAlt() {
    return `${site?.name ?? domain} web screenshot, ${screenshot?.pageTypes?.map(el => el?.name)?.map(n => `${n} UI`).join(' and ')} ${[...(screenshot?.patterns?.map(pt => pt?.name) ?? []) ]?.map(n => `${n} UX`).join(', ')}`
  }

  function renderFonts() {
    const fonts = screenshot?.fonts
      ?.filter((font, index, self) => 
        index === self.findIndex(f => 
          f.display_name === font.display_name 
        )
      )
      ?.filter(font => !!font.display_name);

    if (!fonts?.length) {
      return;
    }

    return (
      <>
        <Title priority="3" lineHeightReset flex>
          <Icons.Fonts className={styles.icon} /> Fonts
        </Title>

        <Tags noPadding>
        {fonts?.map(item =>
          <Link 
            to={`/search?fonts[id][]=${item.id}`}
            key={item.id}
          >
            <Tags.Item withBorder>
              {item.display_name}
            </Tags.Item>
          </Link>
        )}
        </Tags>
      </>
    )
  }

  function renderColors() {
    return (
      <>
        {!!screenshot?.colors?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.Colors className={styles.icon} /> Colors
          </Title>
        }

        <Tags noPadding>
          {screenshot?.colors?.map((item, index) =>
            <Tags.Item withBorder key={`${index}-${item[0]}-${item[1]}-${item[2]}`}>
              <div className={styles.colorIcon} style={{'backgroundColor': `rgb(${item[0]}, ${item[1]}, ${item[2]})`}} />
              {getColorName(...item)}
            </Tags.Item>
          )}
        </Tags>
      </>
    )
  }

  function renderElements() {
    return (
      <>
        {!!screenshot?.elements?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.ElementsColor className={styles.icon} /> UI Elements
          </Title>
        }

        <Tags noPadding>
          {screenshot?.elements?.map(item =>
            <Link 
              to={`/search?page_elements[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }

  function renderPatterns() {
    return (
      <>
        {!!screenshot?.patterns?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.PatternsColor className={styles.icon} /> UX Patterns
          </Title>
        }

        <Tags noPadding>
          {screenshot?.patterns?.map(item =>
            <Link 
              to={`/search?design_patterns[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }

  function renderPageTypes() {
    return (
      <>
        {!!screenshot?.pageTypes?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.PageTypesColor className={styles.icon} /> Page Types
          </Title>
        }

        <Tags noPadding>
          {screenshot?.pageTypes?.map(item =>
            <Link 
              to={`/search?page_types[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }
 
  const screenshot = ScreenshotsStore.show(parseInt(id));
  const site = SitesStore.find(siteId ?? screenshot?.siteId);
  const list = ScreenshotsStore.list(getScreenshotKey(), params);

  return (
    <div 
      className={classnames(styles.container, {
        [styles.withBorder]: !isPopup,
      })}
      ref={container}
    >

      <Helmet>
        <title>{buildPageTitle()}</title>
        <link rel="canonical" href={`https://refero.design/${siteId}-${domain}/${id}`} />
        <meta property="og:url" content={`https://refero.design/${siteId}-${domain}/${id}`} />
        <meta property="og:title" content={buildPageTitle()} />
        <meta name="twitter:title" content={buildPageTitle()} />
        <meta property="og:site_name" content={buildPageTitle()} />
        <meta name="description" content={buildPageDescription()} />
        <meta name="keywords" content={[...(screenshot?.elements?.map(el => el?.name) ?? []), ...(screenshot?.patterns?.map(pt => pt.name) ?? [])].join(', ')} />
      </Helmet>
      
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org",
          "@type": "ImageObject",
          "contentUrl": "${screenshot?.url?.[0]}",
          "keywords": "${['UX UI design', 'design reference', 'web design', ...(screenshot?.elements?.map(el => el?.name) ?? []), ...(screenshot?.patterns?.map(pt => pt.name) ?? [])].join(', ')}",
          "creator": {
            "@type": "Organization",
            "name": "${site?.name}",
            "logo": "${site?.faviconUrl}"
          }
        }
      `}</script>

      <div className={styles.bodyWrap}>
        <div className={styles.body}>
          <div className={styles.content}>
            {renderContent()}
            <div className={styles.overlay} />

            {!!screenshot &&
              <div className={styles.controls2Wrap}>
                <div className={styles.controls2}>

                  {/* {params?.source !== 'search' ? */}
                    <div className={classnames(styles.button, styles.buttonWide)}>
                      <div className={styles.controlBackground} />
                      {ScreenshotsStore.findIndex(parseInt(id), getScreenshotKey(), params) + 1}
                      /
                      {getScreenshotsCount()}
                    </div>
                  {/* : <div />} */}

                  {/* <div className={styles.more} onClick={scrollToRecommendations}>
                    Explore Similar Screens
                  </div> */}

                  <div className={styles.imageControlsArrows}>
                    <div className={styles.button} onClick={prev} ref={prevButton}>
                      <div className={styles.controlBackground} />
                      <Icons.ArrowLeft />
                    </div>
                    <div className={styles.button} onClick={next} ref={nextButton}>
                      <div className={styles.controlBackground} />
                      <Icons.ArrowRight />
                    </div>
                  </div>

                </div>
              </div>
            }

          </div>

          <div 
            className={classnames(styles.sidebar, {
              [styles.sidebarPopup]: isPopup,
            })}
          >
            <div className={styles.tagWrapper}>
              <div className={styles.header}>
                <SiteHead 
                  tag="h1"
                  noMargin 
                  card site={site ?? {domain}} 
                  source="screenshotSidebar" 
                />
                {isPopup ?
                  <div className={styles.closeWrapper}>
                    <div className={styles.close} onClick={close} ref={closeButton}>
                      <Icons.Close />
                    </div>
                  </div>
                : null}
              </div>

              <div className={styles.sidebarBody}>
                {renderPageTypes()}
                {renderPatterns()}
                {renderElements()}
                {renderFonts()}
                {renderColors()}
              </div>
            </div>

            <div className={styles.footer}>
              <ScreenControls 
                copy={copy}
                download={download}
                save={onClickBookmark}
                edit={edit}
                pageUrl={screenshot?.pageUrl}
              />

                {/* {!!screenshot?.createdAt && 
                  <time 
                    dateTime={format(new Date(screenshot.createdAt), 'yyyy-MM-dd')}
                    className={styles.date}
                  >
                    Uploaded: {format(new Date(screenshot.createdAt), 'MMMM yyyy')}
                  </time>
                } */}

                <div className={styles.controls}>

                  {/* {UserStore.isAdmin() &&
                    <>
                      <a href={`https://admin.refero.design/sites/${siteId}/${id}`} target="_blank" rel="noreferrer">
                        <Button block compact className={classnames(styles.buttonLeft)}>
                          <Icons.Edit />
                        </Button>
                      </a>
                    </>
                  } */}

                  {/* <Button onClick={copy} block compact>
                    <Button.Icon>
                      <Icons.Copy /> 
                    </Button.Icon>
                    Copy
                  </Button>

                  <Button 
                    onClick={onClickBookmark} 
                    light block compact 
                    className={classnames(styles.buttonRight)}
                  >
                    <Button.Icon>
                      <Icons.Bookmark2 />
                    </Button.Icon> 
                    Bookmark
                  </Button> */}
              
                  {/* {BookmarksStore?.isBookmarked(id) ?
                    <Button onClick={deleteBookmark} light block compact confirm className={classnames(styles.buttonRight)}>
                      <Button.Icon>
                        <Icons.Check />
                      </Button.Icon> 
                      Added
                    </Button>
                  :
                    <Button onClick={addBookmark} light block compact className={classnames(styles.buttonRight)}>
                      <Button.Icon>
                        <Icons.Bookmark2 />
                      </Button.Icon> 
                      Bookmark
                    </Button>
                  } */}
          
                  {/* <Button onClick={download} round light compact className={styles.buttonRight}>
                    <Button.Icon>
                      <Icons.Download2 /> 
                    </Button.Icon>
                  </Button>
               */}
                </div>
              </div>
            
          </div>
        </div>
      </div>
        
      <div className={styles.metaBody}>
        <div 
          className={classnames(styles.recommendationsAnchor, {
            [styles.recommendationsAnchorPopup]: isPopup
          })} 
          ref={recommendationsAnchor} 
        />
        <Title priority="2" tag="h3">
          Explore Similar Screens
        </Title>
      </div>
      
      <div className={styles.similar} ref={ref}>
        {inView ?
          <SimilarScreenshots id={id} />
        : null}
      </div>
      
    </div>
  );
});

export default Screen;
