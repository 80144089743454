import { useRef, useState } from 'react';
import * as Icons from 'icons';
import GmailConnect from 'components/GmailConnect/GmailConnect';
import EmailSignup from 'components/EmailSignup/EmailSignup';
import styles from './Signup.module.scss';

export default function Signup({ children }) {

  // eslint-disable-next-line no-unused-vars
  const [ email, setEmail ] = useState('');
  const emailForm = useRef(null);

  function emailChangeCallback(emailAddress) {
    setEmail(emailAddress);
  }

  return (
    <div className={styles.container}>

      <h3 className={styles.title}>
        {children}
      </h3>
      
      <div className={styles.buttons}>
        <GmailConnect>
          Continue with Google
        </GmailConnect>

        <div className={styles.hr}>
          <div className={styles.hrLabel}>or</div>
        </div>

        <EmailSignup 
          ref={emailForm}
          emailChangeCallback={emailChangeCallback}
        />

        <div className={styles.agreements}>
          By loging in you are agree with our <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>&nbsp;and&nbsp;<a href="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>
        </div>
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          Full access
        </li>

        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          No card required
        </li>

        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          3 days free trial
        </li>
      </ul>

    </div>
  )
}