import { makeAutoObservable, runInAction } from 'mobx';

import { DownloadStream } from 'dl-stream';
import { downloadZip } from 'client-zip';

import mergeImages from 'helpers/merge-images';
import DownloadItem from './DownloadItem';
import CopyItem from './CopyItem';
import AlertStore from './Alert';

class Download {

  list = [];

  constructor() {
    makeAutoObservable(this);
  }

  add(screenshots, domain, id) {
    if (this.list?.findIndex(i => i.id === id) > -1) {
      return;
    }

    const item = new DownloadItem(screenshots, domain, id, () => {
      const items = this.list?.filter(i => i.progress === 1);
      items?.forEach((downloadItem) => {
        const index = this.list?.findIndex(i => i.id === downloadItem.id);
        if (index > -1) {
          setTimeout(() => {
            runInAction(() => {
              this.list.splice(index, 1);
            });
          }, 1000);
        }
      });
    });

    item?.start();
    this.list.push(item);
  }

  async createZip(input, name = 'archive') { 
    try {
      let index = 0;
      const blobs = [];
      const size = input.reduce((x, y) => x + y.urls.length, 0);
      const item = new DownloadItem([], name, new Date().getTime());
      this.list.push(item);

      while(input[index]) {
        const urls = input[index]['urls'];
        const name = input[index]['name'];
        const _blob = await mergeImages(urls, () => {
          item.setProgress(1 / size);
        }, true);
        blobs.push({
          name,
          lastModified: new Date(),
          input: _blob
        });
        index++;
      }

      const blob = await downloadZip(blobs).blob();

      // console.log('repsonse', repsonse[0]);

      this._createLink(blob, name);

      setTimeout(() => {
        runInAction(() => {
          const index = this.list.findIndex(i => i.id === item.id);
          if (index > -1) {
            this.list.splice(index, 1);
          }
        });
      }, 1000);

      
    } catch (err) {
      AlertStore.add(err.message);
    }    
  }

  async createAppZip(input, name = 'archive') {  
    try {
      // const blob = await downloadZip(new DownloadStream(input)).blob();
      const item = new DownloadItem([], name, new Date().getTime());
      this.list.push(item);
      const blob = await downloadZip(blobAndCountBytes(new DownloadStream(input))).blob();

      async function *blobAndCountBytes(downloadStream) {
        for await (const response of downloadStream) {
          item.setProgress(1 / input.length);
          yield response;
        }
      }
      
      this._createLink(blob, name);

      setTimeout(() => {
        runInAction(() => {
          const index = this.list.findIndex(i => i.id === item.id);
          if (index > -1) {
            this.list.splice(index, 1);
          }
        });
      }, 1000);

    } catch (err) {
      AlertStore.add(err.message);
    }    
    
  }

  _createLink(blob, name) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${name}.zip`;
    link.click();
    link.remove();
  }

  copy(screenshots, id) {
    id = `copy_${id}`;
    
    if (this.list?.findIndex(i => i.id === id) > -1) {
      return;
    }

    const item = new CopyItem(screenshots, id, () => {
      const items = this.list?.filter(i => i.progress === 1);

      items?.forEach((copyItem) => {
        const index = this.list?.findIndex(i => i.id === copyItem.id);
        if (index > -1) {
          runInAction(() => {
            this.list.splice(index, 1);
          });
        }
      });

    });
    item?.start();
    this.list.push(item);
  }

}

const DownloadStore = new Download();
export default DownloadStore;
