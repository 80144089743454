import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { format, isBefore } from 'date-fns';
import * as Icons from 'icons';
import AlertStore from 'stores/Alert';
import centsToDollars from 'helpers/cents-to-dollars';
import referoApi from 'api/refero_api';
import Layout from 'components/Layout/Layout';
import ButtonPro from 'components/ButtonPro/ButtonPro';
import styles from './Billing.module.scss';
import Dialog from 'components/Dialog/Dialog';

const Billing = observer(() => {

  const [ subscriptions, setSubscriptions ] = useState(null);
  const [ invoices, setInvoices ] = useState(null);
  const [ dialogData, setDialogData ] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  async function fetchSubscriptions() {
    try {
      const list = await referoApi.subscriptions();
      const invoiceList = list?.map(item => item.invoices)?.flat();
      setSubscriptions(list);
      setInvoices(invoiceList);
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async function cancel(subscription) {
    try {
      await referoApi.subscriptionCancel(subscription.id);
      AlertStore.add('Subscription has been cancelled', 'success');
      const index = subscriptions?.findIndex(({ id }) => id === subscription.id);
      if (index > -1) {
        const updated = [...subscriptions];
        updated[index] = {
          ...updated[index],
          status: 'cancelled'
        }
        setSubscriptions(updated);
      }
      setDialogData(null);
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  function hasActiveSubscription() {
    let result = false;

    subscriptions?.forEach(subscription => {
      if (subscription.status === 'active') {
        result = true;
      }

      if (subscription.status === 'on_trial') {
        result = true;
      }

      if (subscription.status === 'cancelled' && isBefore(new Date(), new Date(subscription.ends_at))) {
        result = true;
      }

      if (subscription.status === 'expired' && isBefore(new Date(), new Date(subscription.renews_at)) ) {
        result = true;
      }
    });

    return result;
  }

  function getPeriodName(plan) {
    if (plan?.interval === 'year') {
      return 'Annually';
    }

    if (plan?.interval === 'month' && plan?.interval_count === 3) {
      return 'Quarterly';
    }

    return 'Monthly';
  }

  function endsAtRender(subscription) {
    if (subscription.status !== 'cancelled' && subscription.status !== 'expired') {
      return null;
    }

    const endsAt = new Date(subscription?.ends_at);
    const isEnded = isBefore(endsAt, new Date());

    return `. End${isEnded ? 'ed' : 's'} at ${format(endsAt, 'd MMM yyyy')}`
  }

  function getStatus(subscription) {
    if (subscription.status === 'active') {
      return `Renews ${format(new Date(subscription.renews_at), 'd MMM yyyy')}`;
    }

    if (subscription.status === 'on_trial') {
      return `Trial ends ${format(new Date(subscription.renews_at), 'MMMM d, yyyy')}`;
    }

    return subscription.status;
  }

  async function onManageClick() {
    let url = 'https://referodesign.lemonsqueezy.com/billing';

    try { 
      const { customer_portal_url } = await referoApi.customerPortal();
      // window.open(customer_portal_url, '_blank').focus();
      if (customer_portal_url) {
        url = customer_portal_url;
      }
    } catch (err) {
      AlertStore.add(err.message);
    }

    window.location.href = url;    
  }

  return (
    <Layout>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Billing & Subscription
        </h1>

        <h2 className={styles.subTitle}>
          Subscriptions
        </h2>

        {subscriptions !== null && !hasActiveSubscription() ?
          <div className={styles.plan}>
            <div className={styles.planBody}>
              <div className={styles.planTitle}>
                Free Plan
              </div>
              <div className={styles.planNote}>
                Upgrade your plan to get unlimited access.  
              </div>
            </div>
            <div className={styles.planControls}>
              <ButtonPro block />
            </div>
          </div>
        :null}

        <div className={styles.subscriptions}>
          {subscriptions?.sort((a, b) => b.id - a.id).map(subscription => 
            <div className={styles.plan} key={subscription.id}>
              <div className={styles.planBody}>
                <div className={styles.planTitle}>
                  Refero <Icons.Pro className={styles.proIcon} />
                </div>
                <div className={styles.planNote}>
                  ${centsToDollars(subscription?.subscription_plan?.price, 2)} {getPeriodName(subscription?.subscription_plan)} 
                  <div className={styles.status}>
                    {getStatus(subscription)}
                    {endsAtRender(subscription)}
                  </div>
                </div>
              </div>
              <div className={styles.planControls}>
                <div className={styles.button} onClick={onManageClick}>
                  Manage Subscription
                </div>

                {/* {!['cancelled', 'expired'].includes(subscription.status) ?
                  <div className={styles.button} onClick={event => setDialogData(subscription)}>
                    Cancel Subscription
                  </div>
                : null} */}
              </div>
            </div>
          )}
        </div>

        <h2 className={styles.subTitle}>
          Transaction History
        </h2>

        {invoices?.length === 0 ?
          <div className={styles.empty}>
            No Transactions Yet
          </div>
        :null}

        <ul className={styles.list}>
          {invoices?.map(invoice => 
            <li className={styles.item} key={invoice.id}>
              <div className={styles.details}>
                <div className={styles.date}>
                  {format(new Date(invoice.created_at), 'd MMM yyyy')}
                </div>
                <div className={styles.amount}>
                  ${centsToDollars(invoice.total, 2)}
                </div>
              </div>
              <a href={invoice.invoice_url} className={styles.receipt} target="_blank" rel="noreferrer">
                Download Receipt
              </a>
            </li>
          )}
        </ul>
        
      </div>

      <Dialog 
        subscription={dialogData} 
        close={event => setDialogData(null)}
        submit={event => cancel(dialogData)}
      />
    </Layout>
  );
});

export default Billing;
