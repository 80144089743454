export default function Search4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm.072 5.695a7 7 0 1 1 2.012-2.23l2.414 2.414A1.5 1.5 0 0 1 13.377 15l-2.306-2.305Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

