import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import * as Icons from 'icons';
import mixpanel from 'api/mixpanel';
import shuffle from 'helpers/shuffle';
import styles from './NothingFound.module.scss';

import DESIGN_PATTERNS from 'json/design-patterns.json';
import PAGE_ELEMENTS from 'json/page-elements';
import PAGE_TYPES from 'json/page-types.json';
import UIStore from 'stores/UI';


export default function NothingFound() {
  const location = useLocation();
  const [ tags ] = useState(getTags());

  useEffect(() => {
    const query = location.search?.replace('?', '')?.split('&')?.map(param => param.split('='));
    const params = {};
    query?.forEach(item => {
      const key = item[0]?.replace('[id][]', '');
      if (key) {
        if (key === 'query') {
          params[key] = item[1];
        } else {
          params[key] ??= [];
          params[key]?.push(item[1]);
        }
      }
    });
    mixpanel.track('NothingFound', {...params});
  }, []);

  function renderIcon(type) {
    switch (type) {
      case 'page_types': 
        return <Icons.PageTypesColor className={styles.tagIcon} width="28" />
      case 'design_patterns':
        return <Icons.PatternsColor className={styles.tagIcon} width="28" />
      case 'page_elements':
        return <Icons.ElementsColor className={styles.tagIcon} width="28" />
      default:
        return null;
    }
  }

  function buildLink(tag) {
    if (UIStore.searchType === 'apps') {
      return `/apps/search?${tag.type}[id][]=${tag.id}`;
    }
    return `/search?${tag.type}[id][]=${tag.id}`;
  }

  function getTags() {
    return [
      ...DESIGN_PATTERNS
        .filter(tag => tag.desktop && tag.mobile)
        .map(tag => {
          return {...tag, type: 'design_patterns'}
        }), 
      ...PAGE_ELEMENTS
        .filter(tag => tag.desktop && tag.mobile)
        .map(tag => {
          return {...tag, type: 'page_elements'}
        }), 
      ...PAGE_TYPES
        .filter(tag => tag.desktop && tag.mobile)
        .map(tag => {
          return {...tag, type: 'page_types'}
        })
    ].sort(shuffle);
  }

  function getTagName(tag) {
    if (UIStore.searchType === 'apps') {
      return !!tag.app_name ? tag.app_name : tag.name;
    }
    return tag.name;
  }

  return (
    <div className={styles.container}>
      
      <div className={styles.chip}>
        <div className={styles.illustration} />
        Nothing Found
      </div>

      <div className={styles.wrap}>
        <div className={styles.title}>
          Try to search this instead:
        </div>

        <div className={styles.tagsWrap}>        
          <div className={styles.tags}>
            <div className={styles.tagsRowWrap}>
              <div className={styles.tagsRow}>
                <div className={classnames(styles.tagsRowBody)}>
                  {tags?.slice(0, 40).map(tag =>
                    <Link to={buildLink(tag)} className={styles.tag} key={`${tag.type}-${tag.id}`}>  
                      {renderIcon(tag.type)}
                      {getTagName(tag)}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.tagsRowWrap}>
              <div className={styles.tagsRow}>
                <div className={classnames(styles.tagsRowBody)}>
                  {tags?.slice(41, 80).map(tag =>
                    <Link to={buildLink(tag)} className={styles.tag} key={`${tag.type}-${tag.id}`}>  
                      {renderIcon(tag.type)}
                      {getTagName(tag)}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.tagsRowWrap}>
              <div className={styles.tagsRow}>
                <div className={classnames(styles.tagsRowBody)}>
                  {tags?.slice(81, 120).map(tag =>
                    <Link to={buildLink(tag)} className={styles.tag} key={`${tag.type}-${tag.id}`}>  
                      {renderIcon(tag.type)}
                      {getTagName(tag)}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}