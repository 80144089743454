import { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import mixpanel from 'api/mixpanel';
import classnames from 'classnames';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import Tags from 'components/Tags/Tags';
import ScreenLink from 'components/ScreenLink/ScreenLink';
import Paywall3 from 'components/Paywall3/Paywall3';
import SiteRandom from 'components/SiteRandom/SiteRandom';
import UniversalHeader from 'components/UniversalHeader/UniversalHeader';
import * as Icons from 'icons';
import SitesStore from 'stores/Sites';
import ScreenshotsStore from 'stores/Screenshots'
import styles from './Site.module.scss';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';

const TABS = [ 'page-type', 'patterns', 'elements' ];

const Site = observer(() => {
  const { siteId, domain } = useParams();
  const [ pageTypesFilter, setPageTypesFilter ] = useState(null);
  const [ patternFilter, setPatternFilter ] = useState(null);
  const [ elementFilter, setElementFilter] = useState(null);
  const [ tab, setTab ] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  // const isSignedIn = UserStore.isSignedIn();

  // useEffect(() => {
  //   function onScroll(event) {
  //     if (document.body.scrollHeight - window.scrollY < 3000) {
  //       console.log('next page');
  //     }
  //   }

  //   window.addEventListener('scroll', onScroll);
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, []);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '50px'
  });

  useEffect(() => {
    const params = (new URL(document.location)).searchParams;

    if (params.get('page-type')) {
      const id = parseInt(params.get('page-type'));
      setPageTypesFilter(id);
      setTab('page-type');
      mixpanel.track('SiteFilter', { key: 'page-type' });
      return;
    }

    if (params.get('pattern')) {
      const id = parseInt(params.get('pattern'));
      setPatternFilter(id);
      setTab('patterns');
      mixpanel.track('SiteFilter', { key: 'patterns' });
      return;
    }

    if (params.get('element')) {
      const id = parseInt(params.get('element'));
      setElementFilter(id);
      setTab('elements');
      mixpanel.track('SiteFilter', { key: 'elements' });
      return;
    }

    setPageTypesFilter(null);
    setElementFilter(null);
    setPatternFilter(null);
    
    if (!tab) {
      setTab('page-type');
    }
  }, [ location.search ]);

  useEffect(() => {
    fetch();
    mixpanel.track('Site', { id: siteId });
  }, [ siteId ])

  useEffect(() => {
    if (UserStore.loginDate) {
      ScreenshotsStore.fetch(parseInt(siteId));
    }
  }, [UserStore.loginDate]);

  async function fetch() {
    ScreenshotsStore.fetch(parseInt(siteId));
    SitesStore.show(parseInt(siteId));
  }

  function getScreenshots() {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    return ScreenshotsStore.list(parseInt(siteId), params);
  }

  function buildParams() {
    if (pageTypesFilter) {
      return `page-type=${pageTypesFilter}`;
    }

    if (patternFilter) {
      return `pattern=${patternFilter}`;
    }

    if (elementFilter) {
      return `element=${elementFilter}`;
    }

    return null;
  }

  function onShowAllTagsClick(event) {
    event.preventDefault();

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    navigate('/upgrade');
  }

  function showAllTagsRender(type) {
    if (!site) {
      return null;
    }

    if (UserStore.isSignedIn() && (UserStore.hasSubscription() || UserStore.onTrial)) {
      return null;
    }

    return (
      <Tags.Item onClick={onShowAllTagsClick}>
        <Icons.Lock2 className={styles.loginIcon} />
        {`Show All ${type}`} 
      </Tags.Item>
    )
  }

  function getTabIcon(type) {
    switch(type) {
      case TABS[0]:
        return <Icons.PageTypesColor />
      case TABS[1]:
        return <Icons.PatternsColor />
      case TABS[2]:
        return <Icons.ElementsColor />;
      default:
        return null;
    }
  }

  function getTabLabel(type) {
    switch(type) {
      case TABS[0]:
        return 'Page Types'
      case TABS[1]:
        return 'UX Patterns'
      case TABS[2]:
        return 'UI Elements'
      default:
        return null;
    }
  }

  function buildPageTitle() {
    return `${site?.name ?? domain} website screenshots and UX UI design references — Refero`;
  }

  function buildPageDescription() {
    return `Discover a wealth of UI and UX design inspiration with collection of screenshots from the ${site?.name ?? domain} website. Explore webpage designs, perfect for graphic and web design references. Find your next UX/UI design inspiration today!`
  }

  const site = SitesStore.find(parseInt(siteId));
  const noSubscription = !UserStore.isSignedIn() || (!UserStore.onTrial && !UserStore.hasSubscription());

  return (
    <>
      <Helmet>
        <title>{buildPageTitle()}</title>
        <link rel="canonical" href={`https://refero.design/${siteId}-${domain}`} />
        <meta property="og:url" content={`https://refero.design/${siteId}-${domain}`} />
        <meta property="og:title" content={buildPageTitle()} />
        <meta name="twitter:title" content={buildPageTitle()} />
        <meta property="og:site_name" content={buildPageTitle()} />
        <meta name="description" content={buildPageDescription()} />
      </Helmet>

      <div className={styles.header}>
        <UniversalHeader data={site} />
      </div>

      <div className={styles.tabsWrap}>
        <TabsSlider 
          compact
          noBg
          activeIndex={TABS?.findIndex(item => tab === item)}
          className={styles.tabs}
        >
          {TABS?.map(item =>
            <TabsSlider.Item 
              to=""
              onClick={event => setTab(item)}
              active={tab === item}
              state={{ preventScrollTop: true }}
              key={item}
            >
              <TabsSlider.Icon>
                {getTabIcon(item)}
              </TabsSlider.Icon>
              {getTabLabel(item)}
            </TabsSlider.Item>
          )}
        </TabsSlider>
      </div>
        
      <div className={styles.tags}>
        {tab === 'page-type' ?
          <Tags>
            <Link 
              to="" 
              state={{ preventScrollTop: true }}
            >
              <Tags.Item
                active={pageTypesFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {site?.pageTypes?.map(pageType => 
              <Link 
                to={`?page-type=${pageType.id}`} 
                state={{ preventScrollTop: true }}
                key={pageType.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && pageType.count}
                  active={pageTypesFilter === pageType.id}
                >
                  {pageType.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('Page Types')}
          </Tags>
        : null}

        {tab === 'patterns' ?
          <Tags>
            <Link 
              to="" 
              state={{ preventScrollTop: true }}
            >
              <Tags.Item
                active={patternFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {site?.patterns?.map(pattern => 
              <Link 
                to={`?pattern=${pattern.id}`} 
                state={{ preventScrollTop: true }}
                key={pattern.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && pattern.count}
                  active={patternFilter === pattern.id}
                >
                  {pattern.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('UX Patterns')}
          </Tags>
        : null}

        {tab === 'elements' ?
          <Tags>
            <Link 
              to=""
              state={{ preventScrollTop: true }}
            >
              <Tags.Item
                active={elementFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {site?.elements?.map(element => 
              <Link 
                to={`?element=${element.id}`} 
                state={{ preventScrollTop: true }}
                key={element.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && element.count}
                  active={elementFilter === element.id}
                >
                  {element.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('UI Elements')}
          </Tags>
        : null}
      </div>
    
      <div className={styles.body}>
        <div className={classnames(styles.images, {
          [styles.noSubscription]: noSubscription
        })}>
          {getScreenshots()?.map(id => {
            const screen = ScreenshotsStore.show(id);
            return (
              <div 
                className={styles.imageContainer} 
                key={id}
              >
                <ScreenLink 
                  data={screen} 
                  site={site}  
                  params={buildParams()}
                  selected={screen?.selected} 
                  // disableUrl={!isSignedIn}
                  // showSignUpOnClick={!isSignedIn}
                />
              </div>  
            )
          })}
          
        </div>
        
        {UserStore.isSignedIn() && (UserStore.onTrial || UserStore.hasSubscription()) ?
          <div ref={ref}>
            {inView ?
              <SiteRandom id={siteId} />
            : null}
          </div>
        : null}
      </div>
      
      <Paywall3 screenshotsCount={site?.screenshotsCount - 12}>
        Log In or Sign Up to&nbsp;See&nbsp;All<br /> 
        {site?.domain && 
          <Paywall3.Icon>
            <img 
              src={site?.faviconUrl ?? `https://www.google.com/s2/favicons?domain=${site?.domain}&sz=256`} 
              async
              loading="lazy"
              alt={site?.name ? site.name : site?.domain}
            />
          </Paywall3.Icon>
        }
        <span style={{ color: `#${site?.backgroundColor ? site.backgroundColor : 'AEB8D6'}` }}>{site?.name ?? domain}</span> screens
      </Paywall3>
    </>
  );
});

export default Site;
