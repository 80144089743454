import classnames from 'classnames';
import styles from './PatternsColor.module.scss';

export default function PatternsColor({ className, width, height }) {
  return (
    <div 
      className={classnames(styles.container, className)} 
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  )
}


