import { useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import Logo from 'components/Logo/Logo';
import SearchField from 'components/SearchField/SearchField';
import Profile from 'components/Profile/Profile';
import SearchSuggestions2 from 'components/SearchSuggestions2/SearchSuggestions2';
import WebIosSwitcher from 'components/WebIosSwitcher/WebIosSwitcher';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import UserStore from 'stores/User';
import SearchStore from 'stores/Search';
import styles from './Header.module.scss';

const Header = observer(({ hidden = false }) => {

  const [ userPicLoaded, setUserPicLoaded ] = useState(false);

  function showSearchPanel(event) {
    SearchStore.show();
  }

  function renderUserBadge() {
    const team = UserStore.teams?.[0];

    if (team && team.subscription_id) {
      return (
        <img className={styles.teamPic} src={team.logo_url} alt="" />
      )
    }

    if (UserStore.hasSubscription()) {
      return (
        <Icons.Pro className={styles.pro} height="16" />
      )
    }

    if (UserStore.onTrial && !UserStore.hasSubscription()) {
      return (
        <div className={styles.badgeWrapper}>
          <div className={styles.badge}>
            <div className={styles.badgeBg} />
            <div className={styles.badgeLabel}>
              Trial
            </div>
          </div>
        </div>
      )
    }
  }

  function renderControls() {
    if (UserStore.fetching) {
      return <div className={styles.userPicWrap} />
    }

    return (
      UserStore.isSignedIn() ?
        <>
          <div 
            className={styles.userPicWrap}
            onClick={event => UIStore.showProfile()}
          >
            <img
              className={classnames(styles.userPic, {
                [styles.userPicReady]: userPicLoaded,
              })}
              src={UserStore.picture} 
              onLoad={event => setUserPicLoaded(true)}
              alt="" 
            />
            {renderUserBadge()}
          </div>
        </>
      :
        <div 
          onClick={event => UIStore.showProfile()} 
          className={classnames(styles.burger, {
            [styles.burgerFocused]: UIStore.profileVisible
          })}
        >
          <div className={styles.burgerLine} />
          <div className={styles.burgerLine} />
        </div>
    )
  }

  return (
    <div
      className={classnames({
        [styles.hidden]: hidden
      })}
    >
      <div className={styles.blast} />
      <div 
        id="header"
        className={classnames(styles.container, {
          // [styles.hidden]: hidden
        })}
      >
        <div className={styles.header}>
          <div className={styles.body}>
            <div className={styles.logoWrapper}>
              <Logo />
            </div>

            <SearchSuggestions2 />
            <div className={classnames(styles.searchWrap, {
              [styles.visibleMobile]: SearchStore.visible
            })}>
              <SearchField />
              <WebIosSwitcher />
            </div>

            <div className={styles.controls}>
              <div
                onClick={showSearchPanel}
                className={classnames(styles.button, styles.searchMobile)}
              >
                <Icons.Search2 />
              </div>

              {renderControls()}
              <Profile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
