import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import mixpanel from 'api/mixpanel';
import referoApi from 'api/refero_api';
import * as Icons from 'icons';
import SiteHead from 'components/SiteHead/SiteHead';
import Title from 'components/Title/Title';
import Tags from 'components/Tags/Tags';
import ScreenWithLogin from 'components/ScreenWithLogin/ScreenWithLogin';
import AppSimilarScreenshots from 'components/AppSimilarScreenshots/AppSimilarScreenshots';
import AppsStore from 'stores/Apps';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';
import AlertStore from 'stores/Alert';
import DownloadStore from 'stores/Download';
import BookmarksStore from 'stores/Bookmarks';
import AppScreenshotsStore from 'stores/AppScreenshots';
import getParamsString from 'helpers/get-params-string';
import isObjectEmpty from 'helpers/is-object-empty';
import styles from './AppScreen.module.scss';
import ScreenControls from 'components/ScreenControls/ScreenControls';

const AppScreen = observer(({ isPopup }) => {

  const { appId, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); 
  const prevButton = useRef(null);
  const nextButton = useRef(null);
  const closeButton = useRef(null);
  const container = useRef(null);
  const recommendationsAnchor = useRef(null);
  const [ firstImageReady, setFirstImageReady ] = useState(false);
  const [ params, setParams ] = useState(Object.fromEntries(new URLSearchParams(location.search)));

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '50px'
  });

  useEffect(() => {
    if (!isPopup && !params?.source?.startsWith('bookmarks_') && Object.keys(params).length !== 0) {
      return window.location.replace(`/apps/${appId}/${id}`);
    }

    const handleClick = (event) => {
      if (event.keyCode === 37) {
        return prevButton.current?.click();
      } 
  
      if (event.keyCode === 39) {
        nextButton.current?.click();
      }
    };
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('keydown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (appId) {
      AppsStore.show(appId);
    } 
    fetchScreenshots();
  }, [ appId ]);

  useEffect(() => {
    if (UserStore.loginDate) {
      fetchScreenshots();
    }
  }, [UserStore.loginDate]);

  useEffect(() => {
    setParams(Object.fromEntries(new URLSearchParams(location.search)));
  }, [location.search]);

  useHotkeys('esc', () => closeButton.current?.click(), { scopes: 'screen' });

  useEffect(() => {
    if (firstImageReady) {
      setFirstImageReady(false);
    }

    mixpanel.track('ScreenView', { appId, id, isPopup });
    setTimeout(() => track(id), 500);
  }, [id]);

  useEffect(() => {
    if (firstImageReady) {
      preloadNextImage();
    }
  }, [firstImageReady]);

  async function track(id) {
    try {
      await referoApi.trackings(id);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchScreenshots() {
    const source = getSource();

    if (source) {
      fetchAdditionalData(source);
    } else {
      AppScreenshotsStore.fetch(appId);
    }
  }

  function preloadNextImage() {
    const nextId = AppScreenshotsStore.nextId(parseInt(id), getScreenshotKey(), params);
    const screen = AppScreenshotsStore.show(nextId);
    const img = new Image();
    img.src = screen?.url;
    img.onload = () => {
      img.remove();
    }
  }

  function close(event) {
    event?.stopPropagation();
    event?.preventDefault();

    if (UIStore.isFirefox) {
      container.current.classList.add(styles.noScroll);
    }
    
    navigate(-1);
    mixpanel.track('ScreenViewClose');
  }

  function buildUrl(screenshotId) {
    const _screen = AppScreenshotsStore.show(screenshotId);
    const _app = AppsStore.find(_screen.appId);
    return `/apps/${_app.id}/${screenshotId}${location.search}`;
  }

  function prev() {
    const prevId = AppScreenshotsStore.prevId(parseInt(id), getScreenshotKey(), params);
    if (prevId) {
      makeNavigation(prevId);
      mixpanel.track('ScreenViewPrev');
    }
  }

  function next() {
    const screensAfter = AppScreenshotsStore.list(getScreenshotKey(), params)?.length - AppScreenshotsStore.findIndex(parseInt(id), getScreenshotKey(), params);
    if (screensAfter <= 5 && !AppScreenshotsStore.fetching) {
      AppsStore.trySearchNext(getParamsString());
    }
    const nextId = AppScreenshotsStore.nextId(parseInt(id), getScreenshotKey(), params);
    if (nextId) {
      makeNavigation(nextId);
      mixpanel.track('ScreenViewNext');
    }
  }

  function makeNavigation(screenshotId) {
    if (!screenshotId) {
      return;
    }
    navigate(buildUrl(screenshotId), {
      replace: true,
      ...(isPopup && {state: { prevPath: location.pathname }})
    });
  }

  function download() {
    mixpanel.track('Download');

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    if (!UserStore.hasSubscription()) {
      let count = parseInt(localStorage.getItem('dc') ?? 0);
      if (count >= 99) {
        return AlertStore.add('Something went wrong. Try again later');
      }
      localStorage.setItem('dc', count + 1);
    }

    DownloadStore.add([screenshot?.url], app.name, id); 
  }

  function copy() {
    mixpanel.track('Copy', { type: 'ios' });

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    DownloadStore.copy([screenshot?.url], id);
  }

  // function addBookmark() {
  //   mixpanel.track('BookmarkAdd');

  //   if (!UserStore.isSignedIn()) {
  //     return UIStore.showSignupPopup();
  //   }

  //   BookmarksStore.create(id);
  // }

  // function deleteBookmark() {
  //   mixpanel.track('BookmarkDelete');
  //   BookmarksStore.delete(id);
  // }

  // function scrollToRecommendations() {
  //   recommendationsAnchor.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // }

  async function fetchAdditionalData(source) {
    if (source === 'search') {
      await AppsStore.search(getScreenshotKey());
    }

    // if (source === 'bookmarks') {
    if (source?.startsWith('bookmarks_')) {
      // await BookmarksStore.fetchScreenshots();
      const folderId = parseInt(source.replace('bookmarks_', ''));
      await BookmarksStore.showFolder(folderId);
    }
  }

  function isScreenAvailable() {
    const result = list?.includes(parseInt(id));
    if (result === undefined) {
      return false;
    }
    return result;
  }

  function isBookmark() {
    return params?.source?.startsWith('bookmarks_');
  }

  function renderContent() {
    if (!list?.length) {
      return null;
    }

    if (!UserStore.isReady) {
      return null;
    }

    if (!UserStore.isSignedIn() && !isBookmark() && (!isScreenAvailable() || 
      (AppScreenshotsStore.isLast(parseInt(id), getScreenshotKey(), params) && list?.length > 3))
    ) {
      return <ScreenWithLogin />
    }

    // if (UserStore.isSignedIn() && !UserStore.hasSubscription() && !isScreenAvailable()) {
    //   return <ScreenWithLogin paywall />
    // }

    if (UserStore.isSignedIn() && !isBookmark() && !UserStore.hasSubscription() && (!isScreenAvailable() ||
      (AppScreenshotsStore.isLast(parseInt(id), getScreenshotKey(), params) && list?.length > 3))
    ) {
      return <ScreenWithLogin paywall />
    }

    return (
      <div className={classnames(styles.imagesWrap, {
        [styles.imagesWrapWithHeader]: !isPopup
      })}>
        <img 
          className={styles.screenshot} 
          src={screenshot?.url}
          alt={buildAlt()} 
          async
          onLoad={event => setFirstImageReady(true)}
          key={screenshot?.url}
        />
      </div>
    )
  }

  function getScreenshotKey() {
    const source = getSource();

    if (source?.startsWith('bookmarks_')) {
      return source;
    }

    switch (source) {
      case 'search':
        return getParamsString();
      case 'bookmarks':
        return 'bookmarks';
      default:
        return app?.id;
    }
  }

  function getScreenshotsCount() {
    if (isObjectEmpty(params)) {
      return app?.screenshotsCount ?? list?.length;
    }

    if (params?.source === 'search') {
      const pagination = AppScreenshotsStore.pagination(getParamsString());
      return pagination?.count?.toLocaleString();
    }

    return list?.length;
  }

  function getSource() {
    return (new URLSearchParams(location.search)).get('source');

    // if (location.search?.includes('source=search')) {
    //   return 'search';
    // }

    // if (location.search?.includes('source=bookmarks')) {
    //   return 'bookmarks';
    // }

    // return null;
  }

  // function pinterestShare() {
  //   const pageUrl = `https://refero.design/apps/${appId}/${id}`;
  //   const imageUrl = screenshot?.url;
  //   let desc = [`${app.name} iOS app`, 'Explore real-world designs from the best products on Refero', ...(screenshot?.pageTypes?.map(pt => pt.name) ?? []), ...(screenshot?.patterns?.map(pt => pt.name) ?? []), ...(screenshot?.elements?.map(el => el.name) ?? [])].join(', ');
  //   desc += ` UX design, UI design, UX/UI, iOS app design, Website, Interface design, Product design, design inspiration`;
  //   const url = `http://pinterest.com/pin/create/button/?url=${pageUrl}&media=${imageUrl}&description=${escape(desc)}`;
  //   window.open(url, '_blank');
  // }

  function onClickBookmark() {
    mixpanel.track('BookmarkAdd');

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    BookmarksStore.setScreenToAdd(parseInt(id))
  }

  function edit() {
    window.open(`https://admin.refero.design/apps/${appId}/${id}`);
  }

  function buildPageTitle() {
    return `${app?.name ?? ''} ${screenshot?.pageTypes?.map(el => el?.name)?.join(' and ') } iOS UI — Refero`;
  }

  function buildPageDescription() {
    return `${app?.name ?? ''} iOS app screenshot. Mobile and app design reference. UX UI design inspiration.`;
  }

  function buildAlt() {
    return `${app?.name ?? ''} iOS screenshot, ${screenshot?.pageTypes?.map(el => el?.name)?.map(n => `${n} UI`).join(' and ')} ${[...(screenshot?.patterns?.map(pt => pt?.name) ?? []) ]?.map(n => `${n} UX`).join(', ')}`
  }

  function renderElements() {
    return (
      <>
        {!!screenshot?.elements?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.ElementsColor className={styles.icon} /> UI Elements
          </Title>
        }

        <Tags noPadding>
          {screenshot?.elements?.map(item =>
            <Link 
              to={`/apps/search?page_elements[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }

  function renderPatterns() {
    return (
      <>
        {!!screenshot?.patterns?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.PatternsColor className={styles.icon} /> UX Patterns
          </Title>
        }

        <Tags noPadding>
          {screenshot?.patterns?.map(item =>
            <Link 
              to={`/apps/search?design_patterns[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }

  function renderPageTypes() {
    return (
      <>
        {!!screenshot?.pageTypes?.length && 
          <Title priority="3" lineHeightReset flex>
            <Icons.PageTypesColor className={styles.icon} /> Screen Types
          </Title>
        }

        <Tags noPadding>
          {screenshot?.pageTypes?.map(item =>
            <Link 
              to={`/apps/search?page_types[id][]=${item.id}`}
              key={item.id}
            >
              <Tags.Item withBorder>
                {item.name}
              </Tags.Item>
            </Link>
          )}
        </Tags>
      </>
    )
  }
 
  const screenshot = AppScreenshotsStore.show(parseInt(id));
  const app = AppsStore.find(appId ?? screenshot?.appId);
  const list = AppScreenshotsStore.list(getScreenshotKey(), params);

  return (
    <div 
      className={classnames(styles.container, {
        [styles.withBorder]: !isPopup,
      })}
      ref={container}
    >

      <Helmet>
        <title>{buildPageTitle()}</title>
        <link rel="canonical" href={`https://refero.design/apps/${appId}/${id}`} />
        <meta property="og:url" content={`https://refero.design/apps/${appId}/${id}`} />
        <meta property="og:title" content={buildPageTitle()} />
        <meta name="twitter:title" content={buildPageTitle()} />
        <meta property="og:site_name" content={buildPageTitle()} />
        <meta name="description" content={buildPageDescription()} />
        <meta name="keywords" content={[...(screenshot?.elements?.map(el => el?.name) ?? []), ...(screenshot?.patterns?.map(pt => pt?.name) ?? [])].join(', ')} />
      </Helmet>

      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org",
          "@type": "ImageObject",
          "contentUrl": "${screenshot?.url}",
          "keywords": "${['UX UI design', 'design reference', 'ios app design', ...(screenshot?.elements?.map(el => el?.name) ?? []), ...(screenshot?.patterns?.map(pt => pt?.name) ?? [])].join(', ')}",
          "creator": {
            "@type": "Organization",
            "name": "${app?.name}",
            "logo": "${app?.faviconUrl}"
          }
        }
      `}</script>

      <div className={styles.bodyWrap}>
        <div className={styles.body}>
          <div className={styles.content}>
            {renderContent()}
            <div className={styles.overlay} />

            {!!screenshot &&
              <div className={styles.controls2Wrap}>
                <div className={styles.controls2}>

                  <div className={styles.fixedButtonWrapper}>
                    {/* {params?.source !== 'search' ? */}
                      <div className={classnames(styles.button, styles.buttonWide)}>
                        <div className={styles.controlBackground} />
                        {AppScreenshotsStore.findIndex(parseInt(id), getScreenshotKey(), params) + 1}
                        /
                        {getScreenshotsCount()}
                      </div>
                    {/* : <div />} */}
                  </div>

                  {/* <div className={styles.more} onClick={scrollToRecommendations}>
                    Explore Similar Screens
                  </div> */}

                  <div className={styles.imageControlsArrows}>
                    <div className={styles.button} onClick={prev} ref={prevButton}>
                      <div className={styles.controlBackground} />
                      <Icons.ArrowLeft />
                    </div>
                    <div className={styles.button} onClick={next} ref={nextButton}>
                      <div className={styles.controlBackground} />
                      <Icons.ArrowRight />
                    </div>
                  </div>

                </div>
              </div>
            }

          </div>

          <div 
            className={classnames(styles.sidebar, {
              [styles.sidebarPopup]: isPopup,
            })}
          >
            <div className={styles.tagWrapper}>
              <div className={styles.header}>
                <SiteHead 
                  tag="h1"
                  noMargin 
                  card 
                  app={app} 
                  site={app} 
                  source="appScreenshotSidebar" 
                />
                {isPopup ?
                  <div className={styles.closeWrapper}>
                    <div className={styles.close} onClick={close} ref={closeButton}>
                      <Icons.Close />
                    </div>
                  </div>
                : null}
              </div>

              <div className={styles.sidebarBody}>
                {renderPageTypes()}
                {renderPatterns()}
                {renderElements()}
                {/* {renderFonts()} */}
                {/* {renderColors()} */}
              </div>
            </div>

            <div className={styles.footer}>
                {/* {!!screenshot?.createdAt && 
                  <time 
                    dateTime={format(new Date(screenshot.createdAt), 'yyyy-MM-dd')}
                    className={styles.date}
                  >
                    Uploaded: {format(new Date(screenshot.createdAt), 'MMMM yyyy')}
                  </time>
                } */}

              <ScreenControls 
                copy={copy}
                download={download}
                save={onClickBookmark}
                edit={edit}
              />

                <div className={styles.controls}>

                  {/* {UserStore.isAdmin() &&
                    <>
                      <a href={`https://admin.refero.design/apps/${appId}/${id}`} target="_blank" rel="noreferrer">
                        <Button block compact className={classnames(styles.buttonLeft)}>
                          <Icons.Edit />
                        </Button>
                      </a>
                    </>
                  } */}

                  {/* <Button onClick={copy} block compact>
                    <Button.Icon>
                      <Icons.Copy /> 
                    </Button.Icon>
                    Copy
                  </Button>

                  <Button 
                    onClick={onClickBookmark} 
                    light block compact 
                    className={classnames(styles.buttonRight)}
                  >
                    <Button.Icon>
                      <Icons.Bookmark2 />
                    </Button.Icon> 
                    Bookmark
                  </Button> */}
              
                  {/* {BookmarksStore?.isBookmarked(id) ?
                    <Button onClick={deleteBookmark} light block compact confirm className={classnames(styles.buttonRight)}>
                      <Button.Icon>
                        <Icons.Check />
                      </Button.Icon> 
                      Added
                    </Button>
                  :
                    <Button onClick={addBookmark} light block compact className={classnames(styles.buttonRight)}>
                      <Button.Icon>
                        <Icons.Bookmark2 />
                      </Button.Icon> 
                      Bookmark
                    </Button>
                  } */}
          
                  {/* <Button onClick={download} round light compact className={styles.buttonRight}>
                    <Button.Icon>
                      <Icons.Download2 /> 
                    </Button.Icon>
                  </Button> */}
              
                </div>
              </div>
            
          </div>
        </div>
      </div>
        
      <div className={styles.metaBody}>
        <div 
          className={classnames(styles.recommendationsAnchor, {
            [styles.recommendationsAnchorPopup]: isPopup
          })} 
          ref={recommendationsAnchor} 
        />
        <Title priority="2" tag="h3">
          Explore Similar Screens
        </Title>
      </div>
      
      <div className={styles.similar} ref={ref}>
        {inView ?
          <AppSimilarScreenshots id={id} />
        : null}
      </div>
      
    </div>
  );
});

export default AppScreen;
