export default function ArrowNE(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path stroke="currentColor" strokeWidth={1.4} d="M4.5 1H15m0 0v10.5M15 1 1 15" />
    </svg>
  )
}
