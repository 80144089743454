import classnames from 'classnames';
import * as Icons from 'icons';
import styles from './GmailConnect.module.scss';

const WIDTH = 600;
const HEIGHT = 650;

const CLIENT_ID = process.env.REACT_APP_GMAIL_CLIENT_ID;
const REDIRECT_URL = process.env.REACT_APP_GMAIL_REDIRECT_URL;
const AUTH_SCOPE = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

export default function GmailConnect({children, invert, compact, className}) {

  function getUrl() {
    return `https://accounts.google.com/o/oauth2/v2/auth?scope=${AUTH_SCOPE}&access_type=offline&redirect_uri=${REDIRECT_URL}&client_id=${CLIENT_ID}&response_type=id_token&nonce=2ehd_3jd`;
  }

  function onClick(event) {
    event.preventDefault();
    const left = (window.screen.width - WIDTH) / 2;
    const top = (window.screen.height - HEIGHT) / 2;
    window.open(getUrl(), 'auth', `width=${WIDTH},height=${HEIGHT},scrollbars=no,status=no,location=no,toolbar=no,menubar=no,left=${left},top=${top}`);
  }

  return (
    <a 
      href={getUrl()} 
      onClick={onClick}
      className={classnames(styles.container, className, {
        [styles.invert]: invert,
        [styles.compact]: compact
      })}
    >
      <div className={styles.iconWrap}>
        <Icons.Google width="26" className={styles.icon} />
      </div>
      <div className={styles.label}>
        {children} 
      </div>  
    </a>
  );
}
