import { makeAutoObservable } from 'mobx';
import UIStore from './UI';
const weekAgo = new Date(new Date().getTime() - 21 * 24 * 60 * 60 * 1000);

class Screenshot {

  id = null;
  pageTypes = [];
  patterns = [];
  elements = [];
  fonts = [];
  thumbnail = null;
  createdAt = null;
  url = [];
  colors = [];
  siteId = null;
  appId = null;
  singleScreen = false;
  isApp = false;
  isBookmarked = false;
  selected = false;
  pageUrl = null;

  constructor({
    id,
    created_at,
    page_types,
    design_patterns,
    fonts,
    page_elements,
    thumbnail_url,
    url,
    colors,
    site,
    app,
    single_screen,
    page_url
  }, siteId, isApp = false, appId) {
    this.id = id;
    this.pageTypes = page_types;
    this.patterns = design_patterns;
    this.elements = page_elements;
    this.fonts = fonts ?? [];
    this.createdAt = new Date(created_at).getTime();
    this.thumbnail = thumbnail_url;
    this.url = url;
    this.colors = colors;
    this.siteId = site?.id ?? siteId ?? null;
    this.singleScreen = single_screen;
    this.isApp = isApp;
    this.appId = app?.id ?? appId ?? null;
    this.pageUrl ??= page_url;

    this.thumbnail = thumbnail_url?.replace('refer-production.s3.us-east-2.amazonaws.com', 'images.refero.design');
    if (isApp) {
      this.url = url?.replace('refer-production.s3.us-east-2.amazonaws.com', 'images.refero.design');
    } else {
      this.url = url?.map(i => i?.replace('refer-production.s3.us-east-2.amazonaws.com', 'images.refero.design'));
    }

    makeAutoObservable(this);
  }

  update(screenshot) {
    this.pageTypes ??= screenshot.pageTypes;
    this.patterns ??= screenshot.patterns;
    this.elements ??= screenshot.elements;
    this.fonts ??= screenshot.fonts;
    this.createdAt ??= screenshot.createdAt;
    this.thumbnail ??= screenshot.thumbnail;
    this.url ??= screenshot.url;
    this.colors ??= screenshot.colors;
    this.siteId = screenshot.siteId ?? this.siteId;
    this.singleScreen ??= screenshot.singleScreen;
    this.appId ??= screenshot.appId;
    this.pageUrl ??= screenshot.pageUrl;
  }
  
  isNew() {
    return this.createdAt > weekAgo;
  }

  setBookmarked(state) {
    this.isBookmarked = state;
  }

  select() {
    this.selected = true;
  }

  unSelect() {
    this.selected = false;
  }

  toggleSelect() {
    this.selected = !this.selected;
    if (this.selected) {
      UIStore.selectScreen(this.id);
    } else {
      UIStore.unSelectScreen(this.id);
    }
  }

}

export default Screenshot;
