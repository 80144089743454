import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import styles from './TeamForm.module.scss';
import TeamPic from 'components/TeamPic/TeamPic';
import TeamsStore from 'stores/Teams';

export default function TeamForm() {

  const [ name, setName ] = useState('');
  const [ domain, setDomain ] = useState('');
  const [ logoUrl, setLogoUrl ] = useState('https://images.refero.design/teampics/default.png');
  const navigate = useNavigate();

  async function onSubmit(event) {
    event.preventDefault();
    const team = await TeamsStore.create(name, logoUrl, [ domain ]);
    if (team?.id) {
      navigate(`/teams/invite/${team?.id}`); 
    }
  }

  return (
    <form className={styles.container} onSubmit={onSubmit}>

      <TeamPic 
        className={styles.teamPic} 
        url={logoUrl}
        onReady={setLogoUrl}
      />

      <div className={styles.row}>
        <input 
          onChange={event => setName(event.target.value)}
          className={styles.field} 
          type="text" 
          placeholder="Team Name" 
          autoFocus 
          required
        />
      </div>
      <div className={styles.row}>
        <input 
          onChange={event => setDomain(event.target.value)}
          className={styles.field} 
          type="text" 
          placeholder="Website Url" 
          required
        />
      </div>

      <div className={styles.buttons}>
        <Button 
          block 
          square 
          className={styles.button} 
          element="button"
        >
          Next
        </Button>
      </div>
    </form>
  )
}