import { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import * as Icons from 'icons';
import SearchStore from 'stores/Search';
import Patterns from './Patterns';
import Elements from './Elements';
import Categories from './Categories';
import PageTypes from './PageTypes';
import Fonts from './Fonts';
import styles from './SearchSuggestions2.module.scss';
import UIStore from 'stores/UI';
import renderTagName from 'helpers/render-tag-name';

const SearchSuggestions2 = observer(() => {
  const navigate = useNavigate();
  const scroll = useRef(null);

  useEffect(() => {
    scroll.current?.scrollTo(0, 0);
  }, [ SearchStore.tab ]);

  function onSelect(type, id, data) {
    if (type === 'query') {
      return onSelectKeyword(id);
    }
    
    const params = getParams();

    if (!SearchStore.isSelected(type, id)) {
      params.push(`${type}[id][]=${id}`);
      SearchStore.setQuery('');
      navigate(`${UIStore.searchUrl}?${ SearchStore.normalizeSearchParams(params)?.join('&') }`);
    }
    SearchStore.setQuery('');
    hide();
  }

  function onSelectKeyword(query) {
    const params = getParams()?.filter(item => !item.includes('query='));
    navigate(`${UIStore.searchUrl}?${SearchStore.normalizeSearchParams(params)?.join('&')}`);
    hide();
  }

  function getParams() {
    let parts = window.location.search?.replace('?', '')?.split('&');
    if (!SearchStore.query) {
      parts = parts?.filter(item => !item.includes('query='));
    }
    return parts?.filter(item => !!item);
  }

  function hide() {
    SearchStore.hide();
  }

  function onHelpClick() {
    hide();
  }

  function renderTypeName(type) {
    switch (type) {
      case 'page_types':
        if (UIStore.searchType === 'apps') {
          return 'Screen Type';
        }
        return 'Page Type';
      case 'design_patterns':
        return 'UX Pattern';
      case 'page_elements':
        return 'UI Element';
      case 'categories':
        return 'Category';
      case 'query':
        return 'Keyword';
      case 'fonts':
        return 'Font';
      case 'site':
      case 'site_id':
        return 'Website';
      case 'app':
      case 'app_id':
        return 'iOS App';
      default:
        return '';
    }
  }

  function renderIcon(type, data) {
    switch (type) {
      case 'page_types':
        return <div className={styles.item2Icon}><Icons.PageTypesColor /></div>
      case 'design_patterns':
        return <div className={styles.item2Icon}><Icons.PatternsColor /></div>
      case 'page_elements':
        return <div className={styles.item2Icon}><Icons.ElementsColor /></div>
      case 'categories':
        return <div className={styles.item2Icon}><Icons.CategoryColor /></div>
      case 'query':
        return <div className={styles.item2Icon}><Icons.Keyword /></div>
      case 'fonts':
        return <div className={styles.item2Icon}><Icons.Fonts /></div>
      case 'site_id':
      case 'app_id':
        const src = data?.value?.data?.icon_url ? `https://images.refero.design/${data?.value?.data?.icon_url}` : `https://www.google.com/s2/favicons?domain=${data?.value?.data?.domain}&sz=256`
        return <img className={styles.item2Logo} src={src} alt="" />
      default:
        return null;
    }
  }

  function renderTags() {
    return (
      <div className={styles.tags}>
        
        <div className={styles.tabsWrapper}>
          <ul className={styles.tabs}>

            <li onClick={event => SearchStore.setTab('page_types')} className={classNames(styles.tab, {
              [styles.active]: SearchStore.tab === 'page_types'
            })}>
              <div className={styles.tabLabel}>
                <Icons.PageTypesColor className={styles.icon} />
                {UIStore.searchType === 'sites' ? 'Page Types' : 'Screen Types'}
              </div>
              <Icons.ShevronRight className={styles.shevron} />
            </li>

            <li onClick={event => SearchStore.setTab('design_patterns')} className={classNames(styles.tab, {
              [styles.active]: SearchStore.tab === 'design_patterns'
            })}>
              <div className={styles.tabLabel}>
                <Icons.PatternsColor className={styles.icon} />
                UX Patterns
              </div>
              <Icons.ShevronRight className={styles.shevron} />
            </li>
            <li onClick={event => SearchStore.setTab('page_elements')} className={classNames(styles.tab, {
              [styles.active]: SearchStore.tab === 'page_elements'
            })}>
              <div className={styles.tabLabel}>
                <Icons.ElementsColor className={styles.icon} />
                UI Elements
              </div>
              <Icons.ShevronRight className={styles.shevron} />
            </li>
            <li onClick={event => SearchStore.setTab('categories')} className={classNames(styles.tab, {
              [styles.active]: SearchStore.tab === 'categories'
            })}>
              <div className={styles.tabLabel}>
                <Icons.CategoryColor className={styles.icon} />
                Categories
              </div>
              <Icons.ShevronRight className={styles.shevron} />
            </li>
            {UIStore.searchType === 'sites' ?
              <li onClick={event => SearchStore.setTab('fonts')} className={classNames(styles.tab, {
                [styles.active]: SearchStore.tab === 'fonts'
              })}>
                <div className={styles.tabLabel}>
                  <Icons.Fonts className={styles.icon}  />
                  Fonts
                </div>
                <Icons.ShevronRight className={styles.shevron} />
              </li>
            : null}
            <li className={classNames(styles.tab, styles.disabled)}>
              <div className={styles.tabLabel}>
                <Icons.Colors className={styles.icon}  />
                Colors
              </div>
              <span className={styles.soon}><span>Soon</span></span>
            </li>
            
          </ul>

          <Link to="/how-it-works" className={styles.help} onClick={onHelpClick}>
            <div className={styles.helpIcon}>
              <Icons.Screen />
            </div>
            
            <div>
              Learn How to&nbsp;Search Better and&nbsp;Faster
            </div>
          </Link>
        </div>

        <div 
          ref={scroll}
          className={styles.scroll}
        >
          {SearchStore.tab === 'page_types' ? <PageTypes onSelect={onSelect} /> : null}
          {SearchStore.tab === 'design_patterns' ? <Patterns onSelect={onSelect} /> : null}
          {SearchStore.tab === 'page_elements' ? <Elements onSelect={onSelect} /> : null}
          {SearchStore.tab === 'categories' ? <Categories onSelect={onSelect} /> : null}
          {SearchStore.tab === 'fonts' ? <Fonts onSelect={onSelect} /> : null}
        </div>

      </div>
    )
  }

  function renderSearchResults() {
    return (
      <div className={styles.results}>
        <div className={styles.scroll}>

          {SearchStore.filtered?.map(item =>
            <div 
              className={classNames(styles.item2, {
                [styles.item2Selected]: item.suggestionId === SearchStore.selected 
              })} 
              onClick={event => onSelect(item.type, item.value.id, item.value.data)}
              key={`${item.type}-${item.value.id}`}
              id={`suggestion-${item.suggestionId}`}
            >
              {renderIcon(item.type, item)} 
              <div className={styles.item2Body}>
                <div className={styles.item2Type}>
                  {renderTypeName(item.type)}
                </div>
                {/* {item.value.name} */}
                {renderTagName(item.value.name, item.value.app_name)}
              </div>

              <div className={styles.submit}>
                <Icons.Enter />
                Enter
              </div> 
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.container, {
      [styles.visible]: SearchStore.visible
    })}>
      <div className={styles.bg} onClick={hide} />
      <div className={styles.body}>
        <div className={styles.box}>
          {SearchStore.filtered?.length ?
            renderSearchResults()
          :
            renderTags()
          }

          <div className={styles.footer}>
            {SearchStore.query?.length < 3 ?
              <div className={styles.footerItem}>
                <div className={styles.buttonPreview}>Tab</div>
                <div className={styles.buttonPreview}>Tab + Shift</div>
                <div className={styles.footerItemLabel}>
                  Switch Tabs
                </div>
              </div>
            : null}
            <div className={styles.footerItem}>
              {SearchStore.query?.length < 3 ? 
                <div className={classNames(styles.buttonPreview, styles.buttonPreviewArr)}>←</div>
              : null}
              <div className={classNames(styles.buttonPreview, styles.buttonPreviewArr)}>↑</div>
              {SearchStore.query?.length < 3 ? 
                <div className={classNames(styles.buttonPreview, styles.buttonPreviewArr)}>→</div>
              : null}
              <div className={classNames(styles.buttonPreview, styles.buttonPreviewArr)}>↓</div>
              <div className={styles.footerItemLabel}>
                Navigate
              </div>
            </div>
            <div className={styles.footerItem}>
              <div className={styles.buttonPreview}>Enter</div>
              <div className={styles.footerItemLabel}>
                Select
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
});

export default SearchSuggestions2;
