import { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
// import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
// import Layout from 'components/Layout/Layout';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import Hero3 from 'components/Hero3/Hero3';
// import Signup from 'components/Signup/Signup';
import * as Icons from 'icons';
import Cards from 'components/Cards/Cards';
import styles from './Home2.module.scss';

import screenshots from 'json/screenshots-count.json';
import appScreenshots from 'json/app-screenshots-count.json';
// import UserStore from 'stores/User';
import UIStore from 'stores/UI';
import SiteFeed from 'components/SiteFeed/SiteFeed';

import PAGE_TYPES from 'json/page-types';
import PAGE_TYPES_COUNTS from 'json/page-types-count';
import PAGE_TYPE_SCREENS from 'json/page-types-screens';

import PATTERNS from 'json/design-patterns';
import PATTERNS_COUNTS from 'json/patterns-count.json';
import PATTERNS_SCREENS from 'json/patterns-screens.json';

import ELEMENTS from 'json/page-elements';
import ELEMENTS_COUNTS from 'json/elements-count';
import ELEMENTS_SCREENS from 'json/elements-screens.json';

import CATEGORIES from 'json/categories.json';
import FONTS from 'json/fonts-featured.json';
import Features2 from 'components/Features2/Features2';
import SignupForm from 'components/SingupForm/SignupForm';
import Layout from 'components/Layout/Layout';
import UserStore from 'stores/User';
import getABValue from 'helpers/get-ab-value';
import lang from 'json/lang.json'; 

const CATEGORY_IDS = [42, 27, 58, 32, 45, 57, 44, 56, 30, 65];
const PAGE_TYPES_SORT = ['Marketing', 'Onboarding', 'Commerce', 'Content', 'Business & Legal Pages', 'Customer Support', 'Utility'];
const PATTERNS_SORT = ['marketing', 'onboarding', 'page state', 'commerce & finance', 'content', 'social', 'legal & other', 'utility'];
const ELEMENTS_SORT = ['layout', 'overlay', 'bars', 'graphics', 'input controls', 'helper elements'];

const PAGE_TYPES_CHILDREN_SORT = {
  'Marketing': [44, 45, 46, 6],
  'Onboarding': [18, 19, 23, 20, 24, 22, 21],
  'Commerce': [13, 5, 47, 10, 9, 12, 11],
  'Content': [25, 28, 29, 31, 26, 30],
  'Business & Legal Pages': [41, 33, 35, 34, 8, 39, 40, 36, 37, 43, 38, 42],
  'Customer Support': [14],
  'Utility': [15, 16]
};

const TRENDING_SORT = {
  page_types: [28, 44, 18, 47, 15, 41, 45, 5, 46],
  design_patterns: [153, 111, 145, 163, 192, 193, 213, 137, 120],
  page_elements: [81, 54, 51, 61, 87, 72, 58, 71, 95]
};

const TABS = [
  {
    id: 'page_types',
    title: 'Page Types'
  },
  {
    id: 'design_patterns',
    title: 'UX Patterns'
  },
  {
    id: 'page_elements',
    title: 'UI Elements'
  },
  {
    id: 'sites',
    title: 'Websites'
  },
  {
    id: 'fonts',
    title: 'Fonts'
  },
];

const Home2 = observer(() => {

  const [ homeSection, setHomeSection ] = useState(localStorage.getItem('home_section') ?? TABS[0]['id']);
  const [ kind, setKind ] = useState(localStorage.getItem('kind') ?? 'trending');
  const cardsSorting = useMemo(() => getABValue('ab_cards_sorting', ['popular', 'default']), []);

  useEffect(() => {
    UIStore.setSearchType('sites');
  }, []);

  useEffect(() => {
    if (homeSection) {
      localStorage.setItem('home_section', homeSection);
    }
  }, [ homeSection ]);

  useEffect(() => {
    if (kind) {
      localStorage.setItem('kind', kind);
    }
  }, [ kind ]);

  function updateHomeSection(id) {
    setHomeSection(id);
    setKind(id === 'fonts' ? FONTS[0]['name'] : 'trending');
  }

  function randomKey() {
    return (Math.random() + 1).toString(36).substring(7);
  }
 
  function renderIcon(tab) {
    switch(tab) {
      case 'page_types':
        return <Icons.PageTypesColor />
      case 'design_patterns':
        return <Icons.PatternsColor />
      case 'page_elements':
        return <Icons.ElementsColor />
      case 'sites':
        return <Icons.Planet width="24" />
      case 'fonts':
        return <Icons.Fonts />
      default:
        return null;
    }
  }

  function getSource(type, isScreens = false) {
    switch(type) {
      case 'page_types':
        return isScreens ? PAGE_TYPE_SCREENS : PAGE_TYPES;
      case 'design_patterns':
        return isScreens ? PATTERNS_SCREENS : PATTERNS;
      case 'page_elements':
        return isScreens? ELEMENTS_SCREENS : ELEMENTS;
      case 'sites':
        return [];
      case 'fonts':
        return [];
      default:
        return null;
    }
  }

  function getKinds() {
    if (homeSection == 'sites') {
      return CATEGORY_IDS.map(id => CATEGORIES.find(c => c.id === id).name);
      // return CATEGORIES.filter(item => item.desktop)?.map(category => category.name)?.slice(0, 7);
    }

    if (homeSection === 'fonts') {
      return FONTS.map(group => group.name);
    }

    if (homeSection === 'page_types') {
      return PAGE_TYPES_SORT;
    }

    if (homeSection === 'design_patterns') {
      return PATTERNS_SORT;
    }

    if (homeSection === 'page_elements') {
      return ELEMENTS_SORT;
    }
    
    return [...new Set(getSource(homeSection)?.filter(item => item.desktop)?.map(item => item.kind))];
  }

  function getTags() {
    let results;
    if (kind === 'trending') {
      const filterArray = TRENDING_SORT[homeSection];
      results = getSource(homeSection)?.filter(item => item.desktop)?.filter(item => filterArray.indexOf(item.id) > -1);
      results.sort((a, b) => {  
        return filterArray.indexOf(a.id) - filterArray.indexOf(b.id);
      });

      return results;
    }

    results = getSource(homeSection)?.filter(item => item.desktop && item.kind === kind);

    if (homeSection === 'page_types') {
      results.sort((a, b) => {  
        return PAGE_TYPES_CHILDREN_SORT[kind].indexOf(a.id) - PAGE_TYPES_CHILDREN_SORT[kind].indexOf(b.id);
      });
    }

    return results;
  }

  function getFonts() {
    const index = FONTS.findIndex(item => item.name === kind);
    return FONTS[index]['fonts'];
  }

  function getUrl(tag) {
    return `/search?${homeSection}[id][]=${tag.id}${cardsSorting === 'popular' ? '&order=popular' : ''}`;
  }

  function getFontsUrl(fontId) {
    return `/search?fonts[id][]=${fontId}${cardsSorting === 'popular' ? '&order=popular' : ''}`;
  }

  function getScreens(id) {
    return getSource(homeSection, true)?.[id]?.map(item => `https://images.refero.design/screenshots/${item.thumbnail_url}`) ?? [];
  }

  function getCount(id) {
    switch(homeSection) {
      case 'page_types':
        return PAGE_TYPES_COUNTS[id]?.toLocaleString('en-US') ?? 0;
      case 'design_patterns':
        return PATTERNS_COUNTS[id]?.toLocaleString('en-US') ?? 0;
      case 'page_elements':
        return ELEMENTS_COUNTS[id]?.toLocaleString('en-US') ?? 0;
      default:
        return null;
    }
  }

  function convertTitleToId() {
    if (kind === 'trending') {
      return null;
    }
    return CATEGORIES.find(item => item.name === kind)?.id ?? null;
  }

  function renderContent() {
    if (homeSection === 'sites') {
      return <SiteFeed 
        category={convertTitleToId()} 
        disableInfiniteScroll={!UserStore.isSignedIn()}
      />
    }

    return (
      <Cards mobileColumn>
        {homeSection === 'fonts' ?
          getFonts().map(font => {
            return (
              <Cards.Item 
                to={getFontsUrl(font.id)}
                title={font.name}
                subTitle={`${font.count?.toLocaleString('en-US')} Pages`}
                // key={font.id}
                key={randomKey()}
              > 
                <Cards.Screen 
                  image={`/font-preview/43-${font.id}-light.png`} 
                  logos={font.logos}
                />
              </Cards.Item>
            );
          })
        :
          getTags().map(tag => {
            return (
              <Cards.Item 
                to={getUrl(tag)}
                title={tag.name}
                subTitle={`${getCount(tag.id)} Pages`}
                // key={tag.id}
                key={randomKey()}
              > 
                <Cards.Screens images={getScreens(tag.id)} />
              </Cards.Item>
            );
          })
        }
      </Cards>
    )
  }

  return (
    <>
      <Helmet>
        <title>{lang.meta.title}</title>
        <link rel="canonical" href="https://refero.design" />
        <meta property="og:url" content="https://refero.design" />
        <meta property="og:title" content={lang.meta.title} />
        <meta name="twitter:title" content={lang.meta.title} />
        <meta property="og:site_name" content={lang.meta.title} />
      </Helmet>

      <Hero3 
        title="Explore real-world designs from the <span>best products</span>"
        subTitle={`We make design research easier and faster than ever before. Explore over ${(Math.floor(screenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;web&nbsp;pages and more than ${(Math.floor(appScreenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;iOS&nbsp;screens`}
      /> 
      
      <div className={styles.tabs}>
        <TabsSlider
          withHover
          noBgMobile
          fixedWidthMobile
          activeIndex={TABS.findIndex(({ id }) => id === homeSection)}
        >
          {TABS.map(tab =>
             <TabsSlider.Item 
              active={tab.id === homeSection} 
              onClick={event => updateHomeSection(tab.id)}
              key={tab.id}
            >
              <TabsSlider.Icon>
                {renderIcon(tab.id)}
              </TabsSlider.Icon>
              {tab.title}
            </TabsSlider.Item>
          )}
        </TabsSlider>
      </div>

      <div className={styles.chips}>
        {homeSection !== 'fonts' ?
          <div 
            className={classnames(styles.chip, {
              [styles.active]: kind === 'trending'
            })}
            onClick={event => setKind('trending')}
          >
            {homeSection === 'sites' ?
              <Icons.Stars width="20" className={styles.chipIcon} />
            : 
              <Icons.ArrowNE2 className={styles.chipIcon} />
            }
            {homeSection === 'sites' ? 'New' : 'Trending'}
          </div>
        : null}

        {getKinds()?.map(title =>
          <div 
            className={classnames(styles.chip, {
              [styles.active]: kind === title
            })}
            onClick={event => setKind(title)}
            key={title}
          >
            {title}
          </div>
        )}
      </div>
      
      {renderContent()}
      
      {!UserStore.isSignedIn() ?
        <>
          <Layout.WithPadding>
            <SignupForm 
              noClooseButton 
              className={styles.signup}
            />
          </Layout.WithPadding>

          <Features2 
            excludeTable 
            excludeLogoTitle
          />

          <Layout.WithPadding>
            <SignupForm 
              noClooseButton 
              className={styles.signup}
            />
          </Layout.WithPadding>
        </>
      : null}
      
    </>
  );
});

export default Home2;
