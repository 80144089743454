import * as Icons from 'icons';
import mixpanel from 'api/mixpanel';
import GmailConnect from 'components/GmailConnect/GmailConnect';
import Title from 'components/Title/Title';
import styles from './ScreenWithLogin.module.scss';
import screenshots from 'json/screenshots-count.json';
import appScreenshots from 'json/app-screenshots-count.json';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function ScreenWithLogin({ paywall = false }) {

  useEffect(() => {
    mixpanel.track('ScreenWithLogin', { paywall });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Icons.Logo />

        <div className={styles.body}>
          <div className={styles.warning}>
            <Icons.Lock className={styles.lockIcon} /> All pages are available only with&nbsp;Refero&nbsp;Pro
          </div>

          <Title paywall className={styles.title}>
            {paywall ? 'Your trial period has expired' : 'Log In to continue'}
          </Title>

          <ul className={styles.features}>
            <li className={styles.feature}>Enjoy full functionality</li>
            <li className={styles.feature}>{(Math.floor((screenshots?.all + appScreenshots?.all)/1000)*1000)?.toLocaleString()}+ searchable interfaces</li>
            <li className={styles.feature}>Up to 1000 new uploads weekly</li>
          </ul>

          {paywall? 
            <Link to="/upgrade">
              <Button>
                Get Pro to Continue
              </Button>
            </Link>
          :
            <GmailConnect  
              compact 
              className={styles.button}
            >
              Continue with Google
            </GmailConnect>
          }
        </div>
      </div>

    </div>
  );
}
