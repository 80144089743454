import { makeAutoObservable, runInAction } from 'mobx';
import mergeImages from 'helpers/merge-images';
import AlertStore from './Alert';

export default class CopyItem {

  screenshots = [];
  progress = 0;
  id = null;
  onComplete;

  constructor(screenshots, id, onComplete) {
    // this.screenshots = screenshots?.map(url => url.replace('images.refero.design', 'refer-production.s3.us-east-2.amazonaws.com'));
    this.screenshots = screenshots;
    this.id = id;
    this.onComplete = onComplete;
    makeAutoObservable(this);
  }

  async start() {
    if (!this.screenshots?.length) {
      return;
    }

    try {
      await navigator.clipboard.write([ 
        new window.ClipboardItem({ 'image/png': mergeImages(this.screenshots, async (progress) => {
          try {
            runInAction(() => this.progress = progress);
          } catch (err) {
            console.log('err2', err);
            AlertStore.add(err.message);
            this.onComplete();
          }
        }, true) })
      ]);

      AlertStore.add('Screen Copied!', 'success');
      this.onComplete();
    } catch (err) {
      AlertStore.add(err.message);
    }
    
  }

}

