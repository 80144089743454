import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import * as Icons from 'icons';
import mixpanel from 'api/mixpanel';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';
import DownloadStore from 'stores/Download';
import BookmarksStore from 'stores/Bookmarks';
import AlertStore from 'stores/Alert';
import styles from './ThumbControls.module.scss';

const ThumbControls = observer(({ screenshot, app, site, className }) => {

  function download() {
    mixpanel.track('Download', { source: 'search' });

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    if (!UserStore.hasSubscription()) {
      let count = parseInt(localStorage.getItem('dc') ?? 0);
      if (count >= 99) {
        return AlertStore.add('Something went wrong. Try again later');
      }
      localStorage.setItem('dc', count + 1);
    }

    DownloadStore.add(app ? [screenshot?.url] : screenshot?.url, site?.domain ?? app?.name, screenshot?.id);
  }

  // async function bookmarkCreate() {
  //   mixpanel.track('BookmarkAdd', { source: 'search' });

  //   if (!UserStore.isSignedIn()) {
  //     return UIStore.showSignupPopup();
  //   }

  //   await BookmarksStore.create(screenshot.id);
  //   screenshot?.setBookmarked(true);
  // }

  // async function bookmarkDelete() {
  //   await BookmarksStore.delete(screenshot.id);
  //   screenshot?.setBookmarked(false);
  // }

  function onBookmarkClick() {
    mixpanel.track('BookmarkAdd', { source: 'search' });
    
    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    BookmarksStore.setScreenToAdd(screenshot.id);
  }
  
  return (
    <div className={classnames(styles.controls, className)}>

        <div 
          className={styles.button} 
          onClick={onBookmarkClick}
          title="Add to Bookmarks"
        >
          <Icons.Bookmarks2 />
        </div>

      {/* {screenshot?.isBookmarked ?
        <div 
          className={styles.button} 
          onClick={bookmarkDelete}
          title="Remove from Bookmarks"
        >
          <Icons.Trash 
            width="14"  
            className={styles.remove} 
          />
        </div>
      :
        <div 
          className={styles.button} 
          onClick={bookmarkCreate}
          title="Add to Bookmarks"
        >
          <Icons.Bookmarks2 />
        </div>
      } */}

      <div 
        className={styles.button} 
        onClick={download}
        title="Download Screen"
      >
        <Icons.Download3 />
      </div>
    </div>
  );
});

export default ThumbControls;
