export default function getABValue(key, values = ['1', '2']) {
  let value = localStorage.getItem(key);

  if (!value) {
    value = values[Math.floor(Math.random() * values.length)];
    localStorage.setItem(key, value);
  }

  return value;
}
