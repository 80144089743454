export default function Lock(props) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 7V3H3v4h2Zm2-4v4h2V3H7ZM6 2a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM5 3a1 1 0 0 1 1-1V0a3 3 0 0 0-3 3h2Z"
        fill="url(#lockAGradient)"
      />
      <rect x={1} y={5} width={10} height={7} rx={2} fill="url(#lockBGradient)" />
      <defs>
        <linearGradient
          id="lockAGradient"
          x1={-22}
          y1={2}
          x2={159.5}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB9F94" />
          <stop offset={0.229} stopColor="#FC7098" />
          <stop offset={0.474} stopColor="#C85BEA" />
          <stop offset={0.74} stopColor="#8E79FF" />
          <stop offset={1} stopColor="#60B6FF" />
        </linearGradient>
        <linearGradient
          id="lockBGradient"
          x1={0}
          y1={10.5}
          x2={299}
          y2={9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB9F94" />
          <stop offset={0.229} stopColor="#FC7098" />
          <stop offset={0.474} stopColor="#C85BEA" />
          <stop offset={0.74} stopColor="#8E79FF" />
          <stop offset={1} stopColor="#60B6FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}