import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';
import BookmarksStore from 'stores/Bookmarks';
import Folder from 'components/Folder/Folder';
import Placeholder from 'components/Placeholder/Placeholder';
import CreateFolderPopup from 'components/CreateFolderPopup/CreateFolderPopup';
import * as Icons from 'icons';
import styles from './Bookmarks.module.scss';
import Button from 'components/Button/Button';
import ScreenshotsStore from 'stores/Screenshots';

const Bookmarks = observer(() => {

  useEffect(() => {
    BookmarksStore.fetchFolders();
  }, []);

  function buildFolderUrl(folder) {
    const hasDesktop = !!ScreenshotsStore.list(`bookmarks_${folder.id}`)?.length;
    return `/bookmarks/${folder?.id}/${!hasDesktop ? 'ios' : 'web'}`;
  }

  return (
    <Layout>
      <div className={styles.container}>

        <div className={styles.header}>
          <div className={styles.title}>
            Bookmarks
          </div>

          <Button
            onClick={event => BookmarksStore.setFolderPopupVisible(true)}
            mobileHidden
          > 
            <Button.Icon>
              <Icons.Plus width="14" />
            </Button.Icon>
            <Button.MobileHidden>
              Create New Board
            </Button.MobileHidden>
          </Button>
        </div>

        <div className={styles.folders}>
          {!BookmarksStore.folders?.length && BookmarksStore.fetching ?
            Array.from(Array(6)).map((_, index) => 
              <div className={styles.folder} key={index}>
                <Placeholder name="folder" />
              </div>
            )
          : null}

          {BookmarksStore.folders?.map(id => {
            const folder = BookmarksStore.getFolder(id);
            return (
              <div className={styles.folder} key={folder?.id}>
                <Folder 
                  to={buildFolderUrl(folder)}
                  name={folder?.name}
                  count={folder?.bookmarksCount ?? 0}
                  screenshots={folder?.previewScreenshots}
                />
              </div>
            )
          })}
        </div>

        {!BookmarksStore?.folders && !BookmarksStore.fetching ? 
          <div className={styles.empty}>
            <Title priority="extra" tag="h1" lineHeightReset>
              Looks like you don’t have any bookmarks yet
            </Title>
          </div>
        : null}
        
      </div>

      {BookmarksStore.createFolderPopupVisible ?
        <CreateFolderPopup />
        : null
      }
      
    </Layout>
  );
});

export default Bookmarks;
