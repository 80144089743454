import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import SearchStore from 'stores/Search';
import styles from './SearchSuggestions2.module.scss';

import FONTS from 'json/fonts.json';

const Categories = observer(({ onSelect }) => {

  return (
    <>
      <div className={styles.group}>
        <div className={styles.items}>
          {FONTS?.map(font =>
            <div 
              className={classNames(styles.item, {
                [styles.selected]: font.id === SearchStore.selected
              })} 
              key={font.id}
              onClick={event => onSelect('fonts', font.id)}
              id={`fonts-${font.id}`}
            >
              {font.name}
              <div className={styles.count}>
                {font.screenshots_count ?? 0}
              </div>
            </div>
          )}
        </div>
      </div>
    </>   
  )
});

export default Categories;
