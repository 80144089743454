import classnames from 'classnames';
import styles from './Wizard.module.scss';

function Wizard({ children, title, steps, activeStep }) {

  return (
    <div className={styles.container}>
      <div className={styles.body}>

        <ol className={styles.steps}>
          {steps.map((step, index) =>
            <li 
              className={classnames(styles.step, {
                [styles.active]: index <= activeStep
              })}
              key={step}
            >
              {step}
            </li>
          )} 
        </ol>

        <div className={styles.content}>
          <h1 className={styles.title}>
            {title}
          </h1>
          {children}
        </div>

        <div className={styles.blast} />
      </div>
    </div>
  );
}

export default Wizard;
