import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Folder.module.scss';
import ScreenshotsStore from 'stores/Screenshots';
import AppScreenshotsStore from 'stores/AppScreenshots';

export default function Folder({ to, count, name, className, screenshots }) {

  return (
    <Link 
      to={to}
      className={classnames(styles.container, className)}
    >

      <div className={styles.images}>
        {screenshots?.slice(0, 3)?.map((id, index) => {
          const screen = ScreenshotsStore.show(id) ?? AppScreenshotsStore.show(id);

          return (
            <div 
              className={classnames(styles.previewWrapper)}
              key={id}
            >
              <img 
                className={styles.preview} 
                src={screen?.thumbnail} 
                alt=""
              />  
            </div>
          )
        })}
      </div>
      
      <div className={styles.name}>
        {name}
      </div>

      <div className={styles.count}>
        {count} Screen{count !== 1 ? 's' : ''}
      </div>
    </Link>
  )
}
