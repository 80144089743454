import card1 from 'images/cards/app-element-11.jpeg';
import card12 from 'images/cards/app-element-12.jpeg';
import card13 from 'images/cards/app-element-13.jpeg';

import card21 from 'images/cards/app-element-21.jpeg';
import card22 from 'images/cards/app-element-22.jpeg';
import card23 from 'images/cards/app-element-23.jpeg';

import card31 from 'images/cards/app-element-31.jpeg';
import card32 from 'images/cards/app-element-32.jpeg';
import card33 from 'images/cards/app-element-33.jpeg';

import card41 from 'images/cards/app-element-41.jpeg';
import card42 from 'images/cards/app-element-42.jpeg';
import card43 from 'images/cards/app-element-43.jpeg';

import card51 from 'images/cards/app-element-51.jpeg';
import card52 from 'images/cards/app-element-52.jpeg';
import card53 from 'images/cards/app-element-53.jpeg';

import card61 from 'images/cards/app-element-61.jpeg';
import card62 from 'images/cards/app-element-62.jpeg';
import card63 from 'images/cards/app-element-63.jpeg';

const ELEMENTS_LIST = [
  {
    name: 'Cards & Tiles',
    id: 54,
    images: [card1, card12, card13]
  },
  {
    name: 'Navigation Bar',
    id: 87,
    images: [card21, card22, card23]
  },
  {
    name: 'Tabs',
    id: 61,
    images: [card31, card32, card33]
  },
  {
    name: 'Button',
    id: 58,
    images: [card41, card42, card43]
  },
  {
    name: 'Illustration',
    id: 95,
    images: [card51, card52, card53]
  },
  {
    name: 'Price',
    id: 125,
    images: [card61, card62, card63]
  },
  {
    name: 'Bottom Sheet',
    id: 122
  },
  {
    name: 'Dialog & Modal Window',
    id: 81
  },
  {
    name: 'Step Indicator',
    id: 75
  },
  {
    name: 'Notifications',
    id: 83
  },
  {
    name: 'Table',
    id: 51
  },
  {
    name: 'Footer',
    id: 92
  },
  {
    name: 'Progress Bar',
    id: 77
  },
  {
    name: 'Tabbar',
    id: 126
  }
];

export default ELEMENTS_LIST;
