import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import SearchStore from 'stores/Search';
import styles from './WebIosSwitcher.module.scss';

const WebIosSwitcher = observer(() => {

  const navigate = useNavigate();

  function setTab(type) {
    UIStore.setSearchType(type);
    SearchStore.generateAllCells();
    
    if (SearchStore.visible) {
      if (SearchStore.tab === 'fonts') {
        SearchStore.setTab('page_types');
      }
      SearchStore.setQuery('');
    }

    if (window.location.pathname.startsWith('/search')) {
      return navigate(SearchStore.searchUrl(true, true));
    }

    if (window.location.pathname.startsWith('/apps/search')) {
      return navigate(SearchStore.searchUrl(true, true));
    }

    if (window.location.pathname === '/' && type === 'apps') {
      return navigate('/apps');
    }

    if (window.location.pathname === '/apps' && type === 'sites') {
      return navigate('/');
    }

    return navigate(`${type === 'apps' ? '/apps/search' : '/search'}`);
  }

  return (
    <div 
      className={classnames(styles.container, {
        // [styles.visibleMobile]: SearchStore.visible
      })}
    >
      <div className={classnames(styles.bg, {
        [styles.position1]: UIStore.searchType === 'sites',
        [styles.position2]: UIStore.searchType === 'apps'
      })} />

      <div
        className={classnames(styles.tab)}
        onClick={event => setTab('sites')}
      >
        <Icons.Planet className={styles.icon} width="24" height="24" prefix="WebIosSwitcher" />
        Websites
      </div>

      <div
        className={classnames(styles.tab)}
        onClick={event => setTab('apps')}
      >
        <Icons.AppStore className={styles.icon} width="24" height="24" prefix="WebIosSwitcher" />
        iOS Apps
      </div>
    </div>
  );
});

export default WebIosSwitcher;
