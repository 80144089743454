export default function Pages(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={4.8}
        y={4.8}
        width={5.4}
        height={5.4}
        rx={1.2}
        stroke="currentColor"
        strokeWidth={1.6}
      />
      <rect
        x={4.8}
        y={13.8}
        width={5.4}
        height={5.4}
        rx={1.2}
        stroke="currentColor"
        strokeWidth={1.6}
      />
      <rect
        x={13.8}
        y={4.8}
        width={5.4}
        height={5.4}
        rx={1.2}
        stroke="currentColor"
        strokeWidth={1.6}
      />
      <rect
        x={13.8}
        y={13.8}
        width={5.4}
        height={5.4}
        rx={1.2}
        stroke="currentColor"
        strokeWidth={1.6}
      />
    </svg>
  )
}