import { makeAutoObservable } from 'mobx';
import ScreenshotsStore from './Screenshots';

class Site {

  id = null;
  name = null;
  domain = null;
  description = null;
  pages = null;
  categories = null;
  pageTypes = [];
  patterns = [];
  elements = [];
  thumbnail = null;
  faviconUrl = null;
  backgroundColor = null;
  screenshotsCount = null;
  app = null;
  featuredScreenshots = null;

  constructor({
    id, 
    domain, 
    screenshots = null, 
    categories = null, 
    thumbnail_url, 
    name, 
    description, 
    favicon_url, 
    background_color = null, 
    screenshots_count, 
    app,
    featured_screenshots
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.domain = domain;
    this.categories = categories;
    this.thumbnail = thumbnail_url;
    this.faviconUrl = favicon_url;
    this.backgroundColor = background_color;
    this.screenshotsCount ??= screenshots_count;
    this.app = app;
    
    if (featured_screenshots?.length) {
      this.featuredScreenshots = featured_screenshots;
      // ScreenshotsStore.add(featured_screenshots, `featured_${this.id}`, `featured_${this.id}`);
      ScreenshotsStore.add(featured_screenshots, `featured_${this.id}`, this.id);
    }

    if (screenshots?.length) {
      ScreenshotsStore.add(screenshots, this.id, this.id);
    }
    this.populate();
    makeAutoObservable(this);
  }

  hasAllFields() {
    return this.categories !== null && ScreenshotsStore.list(this.id) !== null;
  }

  populate() {
    this.pageTypes = [];
    this.patterns = [];
    this.elements = [];

    ScreenshotsStore.list(this.id)?.forEach(id => {
      const screenshot = ScreenshotsStore.show(id);

      screenshot?.pageTypes?.forEach(pageType => {
        const index = this.pageTypes.findIndex(p => p.id === pageType.id);

        if (index === -1) {
          pageType.count = 1;
          this.pageTypes.push(pageType);
        } else {
          this.pageTypes[index]['count'] = (this.pageTypes[index]?.['count'] ?? 1) + 1;
        }
      });

      screenshot?.patterns?.forEach(pattern => {
        const index = this.patterns.findIndex(p => p.id === pattern.id);
        
        if (index === -1) {
          pattern.count = 1;
          this.patterns.push(pattern);
        } else {
          this.patterns[index]['count'] = (this.patterns[index]?.['count'] ?? 1) + 1;
        }
      });

      screenshot?.elements?.forEach(element => {
        const index = this.elements.findIndex(e => e.id === element.id);

        if (index === -1) {
          element.count = 1;
          this.elements.push(element);
        } else {
          this.elements[index]['count'] = (this.elements[index]?.['count'] ?? 1) + 1;
        }
      })
    });
  }

  update(json) {
    this.screenshots = json.screenshots ?? this.screenshots;
    this.categories = json.categories ?? this.categories;
    this.thumbnailUrl = json.thumbnailUrl ?? this.thumbnailUrl;
    this.name = json.name ?? this.name;
    this.description = json.description ?? this.description;
    this.faviconUrl = json.faviconUrl ?? this.faviconUrl;
    this.backgroundColor = json.backgroundColor ?? this.backgroundColor;
    this.screenshotsCount = json.screenshotsCount ?? this.screenshotsCount;
    this.featuredScreenshots = json.featuredScreenshots ?? this.featuredScreenshots;

  }

}

export default Site;
