import { makeAutoObservable } from 'mobx';
import ReferoApi from 'api/refero_api';
import AlertStore from './Alert';
import Team from './Team';

class Teams {

  store = [];
  fetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  async create(name, logoUrl, domains) {
    try {
      const rawTeam = await ReferoApi.teamCreate(name, logoUrl, domains);
      const team = new Team(rawTeam);
      this.add(team);
      this.setFetching(false);
      return team;
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  async list() {
    try {
      const { records } = await ReferoApi.teamList();
      records.forEach(record => {
        const team = new Team(record);
        this.add(team);
      });
      this.setFetching(false);
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  find(id) {
    return this.store.find(team => team.id === id);
  }

  firstTeam() {
    if (this.store?.length === 0) {
      return null;
    }
    return this.store[0];
  }

  add(team) {
    const index = this.store.findIndex(t => t.id === team.id);
    if (index < 0) {
      // this.store.push(team);
      this.store = [...this.store, team];
    } else {
      this.store[index] = team;
    }
  }

  setFetching(state = false) {
    this.fetching = state;
  }
  
}

const TeamsStore = new Teams();
export default TeamsStore;
