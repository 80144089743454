const SRC = 'https://cdn.paddle.com/paddle/paddle.js';
let loaded = false;

function loadScript() {
  return new Promise((resolve, reject) => {
    try {
      if (loaded) {
        return resolve();
      }

      const script = document.createElement('script');
      script.src = SRC;
      script.async = true;
      script.onload = () => {
        loaded = true;
        resolve();
      }
      document.body.appendChild(script);
    } catch (err) {
      reject(err);
    }
  });
}

async function init() {
  window.Paddle.Environment.set('sandbox');
  window.Paddle.Setup({ 
    vendor: 1897,
    debug: true
  });
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  loadScript,
  init
}