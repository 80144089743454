export default function ArrowRight(props) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.92 6.62a1 1 0 0 0-.21-.33l-5-5a1.004 1.004 0 1 0-1.42 1.42L8.59 6H1a1 1 0 1 0 0 2h7.59l-3.3 3.29a.999.999 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5-5a1 1 0 0 0 .21-.33 1 1 0 0 0 0-.76Z"
        fill="currentColor"
      />
    </svg>
  )
}
