import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import * as Icons from 'icons';
import styles from './Affiliate.module.scss';

import step1 from 'images/affiliate/step1.png';
import step2 from 'images/affiliate/step2.png';
import step3 from 'images/affiliate/step3.png';
import step4 from 'images/affiliate/step4.png';
import step5 from 'images/affiliate/step5.png';

import ball1 from 'images/affiliate/ball1.png';
import ball2 from 'images/affiliate/ball2.png';
import ball3 from 'images/affiliate/ball3.png';
import ball4 from 'images/affiliate/ball4.png';
import ball5 from 'images/affiliate/ball5.png';
import ball6 from 'images/affiliate/ball6.png';

export default function Affiliate() {

  const [ stepsVisible, setStepsVisible ] = useState(false);

  function toggle() {
    const next = !stepsVisible;
    setStepsVisible(next);
  }

  function showGuide() {
    setStepsVisible(true);
    document.getElementById('guide')?.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  return (
    <>
      <Helmet>
        <title>Refero Affiliate</title>
        <link rel="canonical" href={`https://refero.design/affiliate`} />
        <meta property="og:url" content={`https://refero.design/affiliate`} />
        {/* <meta property="og:title" content={buildPageTitle()} />
        <meta name="twitter:title" content={buildPageTitle()} />
        <meta property="og:site_name" content={buildPageTitle()} />
        <meta name="description" content={buildPageDescription()} /> */}
      </Helmet>

      <Layout>
        <div className={styles.container}>
          
          <div className={styles.hero}>
            <div className={styles.topTitle}>
              Refero Affiliate Program
            </div>
            <h1 className={styles.title}>
              Earn a 35% Commission on Every Sale
            </h1>

            <div className={styles.heroTxt}>
              Introduce Refero to your audience and get rewarded for your efforts.
            </div>

            <div className={styles.buttons}>
              <a href="https://referodesign.lemonsqueezy.com/affiliates" target="_blank" rel="noreferrer">
                <Button square blockMobile className={styles.button}>
                  Apply Now
                </Button>
              </a>

              <Button square light blockMobile className={styles.button} onClick={showGuide}>
                Show Step Guide
              </Button>
            </div>
          </div>

          <div className={styles.collage} />

          <ol className={styles.steps}>
            <li className={styles.step}>
              <div className={styles.stepNum}>
                Step 1
              </div>
              <div>
                <h3>
                  Sign Up
                </h3>
                <div className={styles.stepDesc}>
                  Apply for our affiliate program and get your unique link.
                </div>
                <a href="https://referodesign.lemonsqueezy.com/affiliates" target="_blank" rel="noreferrer">
                  <Button square>
                    Apply Now
                  </Button>
                </a>
              </div>
            </li>

            <li className={styles.step}>
              <div className={styles.stepNum}>
                Step 2
              </div>
              <div>
                <h3>
                  Spread the Word
                </h3>
                <div className={styles.stepDesc}>
                  When someone clicks and makes a purchase within 60 days, you earn a commission.
                </div>
              </div>
            </li>

            <li className={styles.step}>
              <div className={styles.stepNum}>
                Step 3
              </div>
              <div>
                <h3>
                  Receive Your Earnings
                </h3>
                <div className={styles.stepDesc}>
                  Connect your bank or PayPal to receive your payouts easily. 
                </div>
              </div>
            </li>
          </ol>

          <div className={styles.narrow}>
            <h2 className={styles.h2}>
              The affiliate program is open to everyone, no matter the size of your audience, offering an inclusive chance to earn.
            </h2>

            Our affiliate program is designed to be mutually beneficial. You'll be promoting a product that adds value to the design community while enjoying substantial rewards for your efforts.
          </div>

          <div className={styles.features}>
            <div className={styles.feature}>
              <img src={ball1} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Generous Commissions
                </h3>
                Earn a 35% commission. Each annual subscription sale could earn you approximately $42.
              </div>
            </div>
            
            <div className={styles.feature}>
            <img src={ball2} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Insightful Dashboard
                </h3>
                Access Lemon Squeezy’s platform for real-time stats, sales, and earnings insights.
              </div>
            </div>

            <div className={styles.feature}>
              <img src={ball3} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Extended Cookie Duration
                </h3>
                Benefit from a 60-day cookie window, securing your commissions from any conversion within this timeframe.
              </div>
            </div>

            <div className={styles.feature}>
              <img src={ball4} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Available for Everyone
                </h3>
                Our affiliate program welcomes everyone, regardless of subscriber count. Let’s grow together.
              </div>
            </div>

            <div className={styles.feature}>
              <img src={ball5} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Free Refero Access
                </h3>
                We appreciate those who spread the word about us and offer free access to Refero PRO as a token of our gratitude. <a href="mailto:main@refero.design">Contact us</a> to get free access.
              </div>
            </div>

            <div className={styles.feature}>
              <img src={ball6} alt="" className={classnames(styles.featureIcon)} loading="lazy" />
              <div>
                <h3 className={styles.featureTitle}>
                  Custom Solutions
                </h3>
                Let’s create offers that truly resonate with your followers. Enhance your promotions with tailored promo codes and exclusive giveaways. <a href="mailto:main@refero.design">Contact us</a> and let’s discuss custom solutions.
              </div>
            </div>
          </div>


          <div className={classnames(styles.steps2)} id="guide">
            <div className={styles.stepsHead} onClick={toggle}>
              Step-by-step guide to join the affiliate program
              <Icons.ArrowTop className={classnames(styles.arr, {
                [styles.open]: !stepsVisible
              })} />
            </div>

            <div className={classnames(styles.stepsBody, {
              [styles.hidden]: !stepsVisible
            })}>

              <div className={styles.stepItem}>
                <h3 className={styles.stepTitle}>
                  Step 1. Create you account on Lemon Squeezy
                </h3>
                <div className={styles.stepDesc}>
                <a href="https://referodesign.lemonsqueezy.com/affiliates" target="_blank" rel="noreferrer">Follow the link</a> to our affiliate program and create a new account with Lemon&nbsp;Squeezy
                </div>

                <div className={styles.stepScreens}>
                  <img className={classnames(styles.stepScreen)} src={step1} loading="lazy" alt="" />
                </div>
              </div>

              <div className={styles.stepItem}>
                <h3 className={styles.stepTitle}>
                  Step 2. Complete your profile and wait for an approval email from Lemon Squeezy
                </h3>
                <div className={styles.stepDesc}>
                  Share about your social networks and activities. The approval may take up to 48 hours.
                </div>

                <div className={styles.stepScreens}>
                  <img className={classnames(styles.stepScreen)} src={step2} loading="lazy" alt="" />
                </div>
              </div>

              <div className={styles.stepItem}>
                <h3 className={styles.stepTitle}>
                  Step 3. Go to Refero affiliate programm
                </h3>
                <div className={styles.stepDesc}>
                  Than your account approved follow <a href="https://affiliates.lemonsqueezy.com/programs/referodesign" target="_blank" rel="noreferrer">this link</a> or find Refero in the list of all affiliate programs.
                </div>

                <div className={styles.stepScreens}>
                  <img className={classnames(styles.stepScreen)} src={step3} loading="lazy" alt="" />
                </div>
              </div>

              <div className={styles.stepItem}>
                <h3 className={styles.stepTitle}>
                  Step 4. Submit your reqest
                </h3>

                <div className={styles.stepScreens}>
                  <img className={classnames(styles.stepScreen)} src={step4} loading="lazy" alt="" />
                </div>
              </div>

              <div className={styles.stepItem}>
                <h3 className={styles.stepTitle}>
                  Step 5.
                </h3>
                <div className={styles.stepDesc}>
                  You’re all set! Copy your unique link and share it with your audience. Track your earnings on the dashboard and make sure to connect your bank or PayPal to receive your payouts easily.
                </div>

                <div className={styles.stepScreens}>
                  <img className={classnames(styles.stepScreen)} src={step5} loading="lazy" alt="" />
                </div>
              </div>

            </div>
          </div>


          <div className={styles.hero}>
            <h1 className={styles.title}>
              Earn a 35% Commission on Every Sale
            </h1>

            <div className={styles.heroTxt}>
              Your advocacy can introduce the world to Refero, simplifying design research while offering a substantial reward for your efforts.
            </div>

            <div className={styles.buttons}>
              <a href="https://referodesign.lemonsqueezy.com/affiliates" target="_blank" rel="noreferrer">
                <Button square blockMobile className={styles.button}>
                  Apply Now
                </Button>
              </a>

              <Button square light blockMobile className={styles.button} onClick={showGuide}>
                Show Step Guide
              </Button>
            </div>
          </div>

          <div className={styles.collage} />

        </div>
      </Layout>
    
    </>
  )
}