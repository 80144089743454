import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import mixpanel from 'api/mixpanel';
import Layout from 'components/Layout/Layout';
import SearchResult from 'components/SearchResult/SearchResult';
// import Placeholder from 'components/Placeholder/Placeholder';
import Paywall3 from 'components/Paywall3/Paywall3';
import NothingFound from 'components/NothingFound/NothingFound';
import Suggestions from 'components/Suggestions/Suggestions';
import AppsStore from 'stores/Apps';
import AppScreenshotsStore from 'stores/AppScreenshots';
import UserStore from 'stores/User';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import * as Icons from 'icons';
import getParamsString from 'helpers/get-params-string';
import styles from './SearchApps.module.scss';
import Sort from 'components/Sort/Sort';

const SearchApps = observer(() => {
  const location = useLocation();
  const isSignedIn = UserStore.isSignedIn();

  useEffect(() => {
    UIStore.setSearchType('apps');
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    SearchStore.findSuggestions();
  }, [ SearchStore.items ]);

  useEffect(() => {
    const query = location.search?.replace('?', '')?.split('&')?.map(param => param.split('='));
    const params = {};
    query?.forEach(item => {
      const key = item[0]?.replace('[id][]', '');
      if (key) {
        if (key === 'query') {
          params[key] = item[1];
        } else {
          params[key] ??= [];
          params[key]?.push(item[1]);
        }
      }
    });
    mixpanel.track('SearchApps', {...params});
  }, [location.search]);

  useEffect(() => {
    AppsStore.search(getParamsString());
  }, [location.search]);

  useEffect(() => {
    if (UserStore.loginDate) {
      AppsStore.search(getParamsString(), true);
    }
  }, [UserStore.loginDate]);

  function onScroll(event) {    
    if (!AppsStore.fetching && document.body.scrollHeight - (window?.scrollY + window?.innerHeight) < window.innerHeight * 2) {
      AppsStore.search(getParamsString(), true, true);
    }
  }

  function getSearchTitle() {
    let results = SearchStore.items?.map(item => item?.value?.name)?.join(' - ');
    results = results ? `| ${results}` : '';
    return results;
  }

  function getLabelColor(type, color) {
    switch(type) {
      case 'page_types':
      case 'design_patterns':
        return 'rgba(232, 141, 141, 1)';
      case 'page_elements':
        return 'rgba(231, 148, 121, 1)';
      case 'app_id':
        return `#${color}`;
      default:
        return 'rgba(36, 188, 188, 1)';
    }
  }

  function getLabelIcon(type, favicon) {
    switch(type) {
      case 'page_types':
        return <Icons.PageTypesColor width="48" height="48" />
      case 'design_patterns':
        return <Icons.PatternsColor width="48" height="48" />
      case 'page_elements':
        return <Icons.ElementsColor width="48" height="48" />
      case 'app_id':
        return (
          <img
            src={`https://images.refero.design/${favicon}`}
            alt=""
          />
        )
      default:
        return <Icons.CategoryColor width="48" height="48" />
    }
  }
  
  function getPaywallLabel() {
    if (SearchStore.items?.length === 1) {
      const { type, value } = SearchStore.items?.[0];
      const name = value.app_name ? value.app_name : value.name;
      const hasScreenInName = name?.toLowerCase()?.endsWith('screen');
      return (
        <>
          <Paywall3.Icon>
            {getLabelIcon(type, value?.icon_url)}  
          </Paywall3.Icon>
          <span style={{ color: getLabelColor(type, value.background_color) }}>
            {name}{hasScreenInName ? 's' : ''}
          </span> {hasScreenInName ? '' : 'Screens'}
        </> 
      )
    }

    return 'Search Results';
  }

  function renderContent() {
    // if (!screenshots?.length && AppsStore.fetching) {
    //   return (Array.from(Array(12)).map((_, index) => 
    //     <div className={styles.imageContainer} key={index}>
    //       <Placeholder.PreviewApp
    //         withSiteName
    //         search
    //         key={index} 
    //       />
    //     </div>
    //   ))
    // }

    return (
      screenshots?.map(id => {
        const screenshot = AppScreenshotsStore.show(id);
        const app = AppsStore.find(screenshot?.appId);
        const url = `/apps/${screenshot?.appId}/${screenshot?.id}${location.search ? location.search + '&' : '?'}source=search`;

        return (
          <div 
            className={styles.imageContainer}
            key={id} 
          >
            <SearchResult 
              url={url}
              app={app} 
              isApp
              screenshot={screenshot}
              selected={screenshot?.selected}
              showSignUpOnClick={!isSignedIn}
            />
          </div>
        )
      })
    )
  }

  const screenshots = AppScreenshotsStore.list(getParamsString());
  const pagination = AppScreenshotsStore.pagination(getParamsString());
  const isNothingFound = !screenshots?.length && !AppsStore.fetching && pagination?.count === 0;

  return (
    <>
      <Helmet>
        <title>Refero — Search | iOS | {getSearchTitle()}</title>
      </Helmet>

      <Layout>
        <div className={styles.body}>
          {/* <Suggestions urlPrefix={window.location.search} /> */}
    
          <div className={styles.pagination}>
            <div className={styles.suggestionsWrap}>
              <Suggestions urlPrefix={window.location.search} />
            </div>
            {/* <SearchPlatformSwitch /> */}

            {pagination?.count ?
              <span className={styles.paginationValue}>
                {(pagination.count)?.toLocaleString()} Screens Found
              </span>
            : <div />}

            {!isNothingFound ?
              <div className={styles.sortWrap}>
                <Sort />
              </div>
            : null}
          </div>

          <div className={classnames(styles.images, {
            [styles.noSubscription]: !UserStore.isSignedIn() || (!UserStore.onTrial && !UserStore.hasSubscription())
          })}>
            {renderContent()}
          </div>

          {isNothingFound ?
            <NothingFound />
          : null}
        </div>
      </Layout>

      {!!screenshots?.length && pagination.count > 12 && 
        <Paywall3 screenshotsCount={pagination?.count - 12} isApp>
          Log In or Sign Up to&nbsp;See&nbsp;All<br />
          {getPaywallLabel()}
        </Paywall3>
      }
    </>
  );
});

export default SearchApps;
