import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import classnames from 'classnames';
import UIStore from 'stores/UI';
import styles from './SignupPopup.module.scss';
import SignupForm from 'components/SingupForm/SignupForm';

const SignupPopup = observer(() => {

  const [ visible, setVisible ] = useState(false);
  const [ hiding, setHiding ] = useState(false);
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    return () => {
      document.body.classList.remove(styles.bodyFix);
    }
  }, []);

  useEffect(() => {
    if (UIStore.signupPopupVisible) {
      document.body.classList.add(styles.bodyFix);
      setVisible(true);
      setHiding(false);
    } else {
      document.body.classList.remove(styles.bodyFix);
      setVisible(false);
    }
  }, [ UIStore.signupPopupVisible ]);

  useEffect(() => {
    if (visible) {
      disableScope('screen');
    } else {
      enableScope('screen');
    }
  }, [ visible ]);

  useHotkeys('esc', close, {
    // enabled: visible,
    scopes: 'login'
  });

  // useEscape(() => {
  //   close();
  // });

  function close() {
    setHiding(true);
  }

  function onAnimationEnd() {
    if (hiding) {
      UIStore.hideSignupPopup();
      document.body.classList.remove(styles.bodyFix);
    } 
  }

  if (!visible) {
    return null;
  }

  return (
    <div 
      className={classnames(styles.container, {
        [styles.hiding]: hiding 
      })}
      onAnimationEnd={onAnimationEnd}
    >
      <div 
        className={styles.background} 
        // onClick={event => UIStore.hideSignupPopup()} 
        onClick={close} 
      />

      <div className={styles.popup}>
        <div className={styles.body}>
          <SignupForm 
            onClose={close}
          />
        </div>
      </div>

    </div>
  )
});

export default SignupPopup;
