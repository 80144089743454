export default function Stars2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.412 3.783c.1-.27.48-.27.58 0l.39 1.056a.309.309 0 0 0 .183.182l1.056.391c.269.1.269.48 0 .58l-1.056.39a.309.309 0 0 0-.183.183l-.39 1.056a.309.309 0 0 1-.58 0l-.39-1.056a.309.309 0 0 0-.183-.183l-1.056-.39a.309.309 0 0 1 0-.58l1.056-.39a.309.309 0 0 0 .182-.183l.391-1.056ZM4.939 6.206a.2.2 0 0 1 .373 0l.252.682a.2.2 0 0 0 .118.117l.681.253a.2.2 0 0 1 0 .373l-.681.252a.2.2 0 0 0-.118.118l-.252.681a.2.2 0 0 1-.373 0l-.252-.681a.2.2 0 0 0-.118-.118l-.681-.252a.2.2 0 0 1 0-.373l.68-.253a.2.2 0 0 0 .119-.117l.252-.682ZM9.91 8.916a.58.58 0 0 1 1.089 0l.735 1.986a.58.58 0 0 0 .343.343l1.986.734a.58.58 0 0 1 0 1.09l-1.986.734a.58.58 0 0 0-.343.343l-.735 1.986a.58.58 0 0 1-1.089 0l-.735-1.986a.58.58 0 0 0-.343-.343l-1.986-.735a.58.58 0 0 1 0-1.089l1.986-.734a.58.58 0 0 0 .343-.343l.735-1.986Z"
      />
    </svg>
  );
}
