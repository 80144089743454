import classnames from 'classnames';
import styles from './ElementsColor.module.scss';

export default function ElementsColor({ className, width, height }) {
  return (
    <div 
      className={classnames(styles.container, className)} 
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  )
}

