export default function ArrowLeft(props) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.08 7.38a1 1 0 0 0 .21.33l5 5a1.004 1.004 0 1 0 1.42-1.42L3.41 8H11a1 1 0 1 0 0-2H3.41l3.3-3.29a1 1 0 0 0 0-1.42 1 1 0 0 0-1.42 0l-5 5a1 1 0 0 0-.21.33 1 1 0 0 0 0 .76Z"
        fill="currentColor"
      />
    </svg>
  )
}
