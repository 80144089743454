import { useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'api/mixpanel';
import UIStore from 'stores/UI';
import styles from './ScreenLink.module.scss';

const ScreenLink = observer(({ 
  data, 
  site, 
  params, 
  replaceOnClick = false, 
  screenId, 
  url, 
  mixpanelEvent, 
  mixpanelParams = {},
  app, 
  appScreen = false, 
  disableUrl,
  showSignUpOnClick,
  redirectToPrice,
  className,
  selected
}) => {

// export default function ScreenLink({ 
//   data, 
//   site, 
//   params, 
//   replaceOnClick = false, 
//   screenId, 
//   url, 
//   mixpanelEvent, 
//   mixpanelParams = {},
//   app, 
//   appScreen = false, 
//   disableUrl,
//   showSignUpOnClick,
//   redirectToPrice,
//   className
// }) {
  const location = useLocation();  
  const navigate = useNavigate();
  const [ loaded, setLoaded ] = useState(false);

  function buildULR() {
    if (url) {
      return url;
    }

    if (!data) {
      return '';
    }

    let base;

    if (appScreen) {
      base = `apps/${app?.id}/${screenId ?? data?.id}`;
    } else {
      base = `${site?.id}-${site?.domain}/${screenId ?? data?.id}`;
    }

    return `/${base}${params ? `?${params}` : ''}`;
  }

  function onClick() {
    if (redirectToPrice) {
      mixpanel.track('SimilarScreenNavigateToPrice');
      return navigate('/upgrade');
    }

    if (showSignUpOnClick) {
      mixpanel.track('SimilarScreenShowSignupPopup');
      UIStore.showSignupPopup();
    }

    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent, {
        id: screenId ?? data?.id,
        siteId: site?.id,
        domain: site?.domain,
        ...mixpanelParams
      });
    }
  }

  function getPageTypeLabel() {
    if (!data?.pageTypes) {
      return '';
    }

    return data?.pageTypes?.map(el => el?.name)?.map(name => `${name} UI`)?.join(' and ');
  }

  function builtImageAlt() {
    if (appScreen) {
      return `${app?.name ?? ''} iOS screenshot. ${getPageTypeLabel()}`;
    }
    return `${site?.name ?? site?.domain} web screenshot. ${getPageTypeLabel()}`;
  }

  function buildLinkTitle() {
    if (appScreen) {
      return `${getPageTypeLabel()} iOS design reference`;
    }
    return `${getPageTypeLabel()} design reference`;
  }

  function renderImage() {
    // return (
    //   <div 
    //     className={classnames(styles.image, className, {
    //       [styles.appRatio]: appScreen,
    //       [styles.visible]: true
    //     })}
    //     style={{ 'backgroundImage': `url(${data?.thumbnail ?? site?.thumbnail})` }}
    
    //     // onLoad={event => setLoaded(true)}
    //     alt="" 
    //   />
    // );

    return (
      <img 
        className={classnames(styles.image, className, {
          [styles.appRatio]: appScreen,
          [styles.visible]: loaded
        })}
        loading="lazy"
        decoding="async"
        src={data?.thumbnail ?? site?.thumbnail} 
        onLoad={event => setLoaded(true)}
        alt={builtImageAlt()} 
      />
    )
  }

  function renderCheckbox() {
    if (!data) {
      return null;
    }

    return (
      <div 
        className={classnames(styles.checkbox, {
          [styles.selected]: selected
        })}
        onClick={onSelect}
        tabIndex="0"
      />
    );
  }

  function onSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    data.toggleSelect();
  }

  if (disableUrl) {
    return (
      <div
        className={classnames(styles.container, className, {
          [styles.appContainer]: appScreen
        })}
        onClick={onClick}
      >
        {renderImage()}
      </div>
    )
  }

  return (
    <Link 
      to={buildULR()} 
      replace={replaceOnClick}
      state={{ prevPath: location.pathname }}
      className={classnames(styles.container, className, {
        [styles.appContainer]: appScreen
      })}
      onClick={onClick}
      title={buildLinkTitle()}
    >
      {renderImage()}
      {renderCheckbox()}
    </Link>
  );
});

export default ScreenLink;
