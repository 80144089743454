import { makeAutoObservable, runInAction } from 'mobx';
import AppScreenshotsStore from './AppScreenshots';
import ScreenshotsStore from './Screenshots';
import AlertStore from './Alert';
import refero_api from 'api/refero_api';

class Folder {

  id = null;
  name = null;
  public = false;
  createdAt = null;
  updatedAt = null;
  bookmarksCount = 0;
  previewScreenshots = [];

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.public = json.public;
    this.createdAt = json.created_at;
    this.updatedAt = json.updated_at;
    this.bookmarksCount = json.bookmarks_count ?? this.bookmarksCount;
    this.previewScreenshots = json.screenshots?.map(({ id }) => id) ?? [];
    
    makeAutoObservable(this);
  }

  update(json) {
    this.name = json.name;
    this.public = json.public;
    this.updatedAt = json.updated_at;
    this.bookmarksCount = json.bookmarks_count ?? this.bookmarksCount;
  }

  isAdded(screenId) {
    const key = `bookmarks_${this.id}`;
    return AppScreenshotsStore.list(key)?.includes(screenId) || ScreenshotsStore.list(key)?.includes(screenId);
  }

  async addScreen(id) {
    try {
      await refero_api.bookmarkAdd(this.id, id);
      const key = `bookmarks_${this.id}`;
      const screenshot = ScreenshotsStore.show(id) || AppScreenshotsStore.show(id);

      if (screenshot.isApp) {
        AppScreenshotsStore.addScreenshot(key, id);
      } else {
        ScreenshotsStore.addScreenshot(key, id);
      }

      runInAction(() => {
        this.previewScreenshots?.unshift(id);
        this.bookmarksCount = this.bookmarksCount + 1;
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async removeScreen(id) {
    try {
      await refero_api.bookmarkRemove(this.id, id);
      const key = `bookmarks_${this.id}`;
      ScreenshotsStore.deleteScreenshot(key, id);
      AppScreenshotsStore.deleteScreenshot(key, id);
      
      const index = this.previewScreenshots.findIndex(item => item === id);

      if (index > -1) {
        runInAction(() => {
          this.previewScreenshots?.splice(index, 1);
          this.bookmarksCount = this.bookmarksCount - 1;
        });
      }
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

}

export default Folder;
