export default function Search2(props) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={6.5} cy={6.5} r={5.5} stroke="currentColor" strokeWidth={2} />
      <path d="m14 14-3-3" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    </svg>
  );
}
