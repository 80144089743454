// import { DownloadStream } from 'dl-stream';

export default async function mergeImages(images = [], progress, returnBlob = false) {
  try {
    const list = [];
    const size = images.length;

    let width = 0;
    let height = 0;
    let index = 0;

    // console.log('images', images);
    // const res = await blobAndCountBytes(new DownloadStream(images));
    // async function *blobAndCountBytes(downloadStream) {
    //   console.log('rrr');
    //   for await (const response of downloadStream) {
    //     console.log('response', response);
    //     const blob = await response.blob();
    //     console.log('blob', blob);
    //     const t = await response.body.getReader().read();
    //     console.log('response', t);
    //     yield response;
    //   }
    // }
    // console.log(res);
    // console.log(res.next());

    while(index < size) {
      const image = await loadImage(images[index]);
      list[index] = image;
      width = image.width;
      height += image.height;
      progress(index / size);
      index++;
    }
    
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    let renderIndex = 0;
    let offsetY = 0;

    while(list[renderIndex]) {
      const image = list[renderIndex];
      if (image) {
        context.drawImage(image, 0, offsetY, image.width, image.height);
      }
      offsetY += image.height;
      renderIndex++;
    }

    if (!returnBlob) {
      progress(1);
      return canvas.toDataURL('image/jpeg');
    } else { 
      progress(1);
      return new Promise(resolve => canvas.toBlob(resolve));
    }
  } catch (err) {
    console.log('catch err', err);

    throw err;
  }
}

function loadImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = '*'; 
    image.onload = function () {
      resolve(image);
    };
    image.onerror = reject;
    image.src = url;
  });
}
