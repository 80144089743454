import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import styles from './SearchSuggestions2.module.scss';

import PATTERNS from 'json/patterns-count.json';
import APP_PATTERNS from 'json/app-patterns-count.json';
import renderTagName from 'helpers/render-tag-name';

const Patterns = observer(({ onSelect }) => {

  function getCount(id) {
    return (UIStore.searchType === 'sites' ? PATTERNS : APP_PATTERNS)?.[id] ?? 0;
  }

  

  return (
    <>
      {SearchStore.patternKinds?.map(kind =>
        <div className={styles.group} key={kind}>
          <div className={styles.kind}>
            {kind}
          </div>
          <div className={styles.items}>
            {SearchStore.patternFilter(kind)?.map(item =>
              <div 
                className={classNames(styles.item, {
                  [styles.selected]: item.id === SearchStore.selected || item.active
                })} 
                key={item.id}
                onClick={event => onSelect('design_patterns', item.id)}
                id={`design_patterns-${item.id}`}
              >
                {renderTagName(item.name, item.app_name)}
                {/* {item.name}  */}
                <div className={styles.count}>
                  {getCount(item.id)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>   
  )
});

export default Patterns;
