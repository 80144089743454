import { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import PriceItem from 'components/PriceItem/PriceItem';
// import PriceItem2 from 'components/PriceItem2/PriceItem2';
// import Features from 'components/Features/Features';
import Features2 from 'components/Features2/Features2';
import Layout from 'components/Layout/Layout';
import Faq from 'components/Faq/Faq';
import Signup from 'components/Signup/Signup';
import FAQ from 'json/pricing-faq.json';
import * as Icons from 'icons';
import UserStore from 'stores/User';
import styles from './Paywall3.module.scss';
// import UIStore from 'stores/UI';
// import { Link } from 'react-router-dom';
// import Button from 'components/Button/Button';

// const OPACITY_RATIO = window.innerWidth > 1680 ? .6 : 1.2;

const Paywall3 = observer(({ children, screenshotsCount, isApp = false, noBg = false, noPages = false }) => {

  const body = useRef(null);
  const container = useRef(null);
  const backgroundRef = useRef(null);
  // const version = UIStore.getExperimentValue('_compact_paywall');

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '50px'
  });

  useEffect(() => {
    function handleScroll(event) {
      if (window.innerWidth < 980 || !backgroundRef.current) {
        return;
      }
      // const diff = Math.max(body.current?.getBoundingClientRect()?.top, 0) * 1.5;
      const diff = Math.max(body.current?.getBoundingClientRect()?.top, 0);
      backgroundRef.current.style.opacity = (1 - (diff * 1.5) / (window.innerHeight)) * 1.5;
      // let value = (1 - diff / (window.innerHeight)) * -350 + 350;
      // value = Math.min(Math.max(value, 0), 200);
      // container.current.style.transform = `translate3d(0, ${value}px, 0)`;
    }

    if (backgroundRef.current) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, []);

  if (UserStore.isSignedIn() && !UserStore.onTrial && !UserStore.hasSubscription()) {
    return (
      <>
        {!noBg ?
          <div className={styles.background} ref={backgroundRef} />
        : null}

        <div 
          className={classnames(styles.container, styles.static)}
          ref={container}
        >

          <div className={styles.body} ref={body}>
            {!noPages ?
              <div className={styles.screens}>
                {/* <Icons.Lock3 className={styles.lockIcon} /> */}
                {screenshotsCount ?
                  `${(screenshotsCount)?.toLocaleString()} search results are hidden`
                :
                  `Other ${isApp ? 'Screens' : 'Pages'} Hidden`
                }

                <div className={styles.logos}>
                  <div className={styles.logoWrap} style={{ backgroundColor: '#81a0fe99' }}>
                    <img src="https://refer-production.s3.us-east-2.amazonaws.com/favicon/intercom.com/ced82899-8b0a-493d-8a30-f0ab2cfc3bb2.jpg" alt="Intercom Logo" />
                  </div>

                  <div className={styles.logoWrap} style={{ backgroundColor: '#58C03599' }}>
                    <img src="https://refer-production.s3.us-east-2.amazonaws.com/favicon/duolingo.com/fba06da4-2816-478c-add6-624504fc245f.jpg" alt="Duolingo Logo" />
                  </div>

                  <div className={styles.logoWrap} style={{ backgroundColor: '#8383EC99' }}>
                    <img src="https://refer-production.s3.us-east-2.amazonaws.com/favicon/webflow.com/5449d164-4063-4299-b227-a022c0f7e386.jpg" alt="Webflow Logo" />
                  </div>

                  <div className={styles.logoWrap} style={{ backgroundColor: '#E88CD199' }}>
                    <img src="https://refer-production.s3.us-east-2.amazonaws.com/favicon/gumroad.com/0a3626c7-8e33-44ec-83f1-c68d98aa5036.jpg" alt="Gumroad Logo" />
                  </div>

                  <div className={styles.logoWrap} style={{ backgroundColor: '#58C03599' }}>
                    <img src="https://refer-production.s3.us-east-2.amazonaws.com/favicon/wise.com/662094cb-3f38-4dbb-8808-386473cfe9b8.jpg" alt="Wise Logo" />
                  </div>
                </div>

              </div>
            : null}

            <Layout>
              <h3 className={styles.title}>
                {/* Your Trial Has Expired */}
                You see only 3% of&nbsp;full library.<br />
                Get unlimited access to&nbsp;everything.
              </h3>

              <h2 className={styles.subTitle}>
                Reclaim your time and energy. Quit the endless search and focus on&nbsp;what you love&nbsp;&mdash; designing!
              </h2>
            </Layout>
            
          
                <div ref={ref}>
                  <PriceItem
                    fetchPlans
                    ready={inView} 
                    mixPanelEvent="PriceBoxPaywallRender" 
                    defaultPriceTab={isApp ? 'ios' : 'web'}
                  />
                </div>

                <Features2 />

                <Layout>
                  <h2 className={styles.faqTitle}>
                    FAQ
                  </h2>
                  <Faq>
                    {FAQ?.map((item, index) => 
                      <Faq.Item {...item} key={index} />
                    )}

                    <script type='application/ld+json'>{`
                      {
                        "@context":"https://schema.org",
                        "@type":"FAQPage",
                        "mainEntity": ${JSON.stringify(FAQ?.map(({ question, answer }) => {
                          return {
                            "@type":"Question",
                            "name":question,
                            "acceptedAnswer":{
                              "@type":"Answer",
                              "text":answer
                            }
                          }
                        }))}
                      }
                    `}</script>
                  </Faq>
                </Layout>
           
              {/* <Layout>
                <Link to="/upgrade">
                  <Button block className={styles.cta}> 
                    Upgrade to Pro
                  </Button>
                </Link>
              </Layout> */}
            
          </div>
        </div>
      </>
    );
  }

  if (!UserStore.isSignedIn()) {
    return (
      <>
        {!noBg ?
          <div className={styles.background} ref={backgroundRef} />
        : null}

        <div 
          className={classnames(styles.container, {
            [styles.static]: noBg
          })}
          ref={container}
        >

          <div className={styles.body} ref={body}>
            {!noPages ?
              <div className={styles.screens}>
                <Icons.Lock3 className={styles.lockIcon} />
                {screenshotsCount ?
                  `${(screenshotsCount)?.toLocaleString()} more ${isApp ? 'screens' : 'pages'} hidden`
                :
                  `Other ${isApp ? 'Screens' : 'Pages'} Are Hidden`
                }
              </div>
            : null}

            <Signup>
              {children}
            </Signup>

          </div>
        </div>
      </>
    )} else {
      return null;
    }
});

function Icon({ children }) {
  return (
    <div className={styles.titleIcon}>
      {children}
    </div>
  )
}

Paywall3.Icon = Icon;

export default Paywall3;