import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import styles from './Dropdown.module.scss';

export default function Dropdown({ children, actions }) {

  const [ visible, setVisible ] = useState(false);

  function onClick(cb) {
    setVisible(false);
    cb();
  }

  useHotkeys('esc', () => setVisible(false));

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={event => setVisible(true)}>
        {children}
      </div>

      {visible ?
      <>
        <div 
          className={styles.bg} 
          onClick={event => setVisible(false)}
        />

        <div className={styles.actions}>
          {actions?.map(action =>
            <div 
              className={styles.action} 
              onClick={event => onClick(action.onClick)}
              style={{ color: action.color }}
              key={action.name}
            >
              <div className={styles.icon}>
                {action.icon}
              </div>
              {action.name}
            </div>  
          )}
        </div>
        </>
      : null}
    </div>
  )
}