import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import mixpanel from 'api/mixpanel';
import Layout from 'components/Layout/Layout';
import SearchStore from 'stores/Search';
import styles from './Tags.module.scss';

import PAGE_TYPES from 'json/page-types-count.json';
import PATTERNS from 'json/patterns-count.json';
import ELEMENTS from 'json/elements-count.json';

import APP_PAGE_TYPES from 'json/app-page-types-count.json';
import APP_PATTERNS from 'json/app-patterns-count.json';
import APP_ELEMENTS from 'json/app-elements-count.json';

const Tags = observer(({ source, type = 'sites' }) => {

  function onClick({ id, kind, name, app_name }) {
    mixpanel.track('PatternPageItemClick', { id, kind, name, app_name, source });
  }

  function getKinds() {
    if (source === 'pagetypes') {
      return SearchStore.pageTypeKinds;
    }

    if (source === 'patterns') {
      return SearchStore.patternKinds;
    }

    return SearchStore.elementKinds;
  }

  function filter(kind) {
    if (source === 'pagetypes') {
      return SearchStore.pageTypeFilter(kind, type);
    }

    if (source === 'patterns') {
      return SearchStore.patternFilter(kind, type);
    }

    return SearchStore.elementFilter(kind, type);
  }

  function count(id) {
    if (source === 'pagetypes') {
      return (type === 'apps' ? APP_PAGE_TYPES?.[id] : PAGE_TYPES?.[id]) ?? 0;
    }

    if (source === 'patterns') {
      return (type === 'apps' ? APP_PATTERNS?.[id] : PATTERNS?.[id]) ?? 0;
    }

    return (type === 'apps' ? APP_ELEMENTS?.[id] : ELEMENTS?.[id]) ?? 0;
  }

  function url(id) {
    if (source === 'pagetypes') {
      return `${urlPrefix()}/search?page_types[id][]=${id}`;
    }

    if (source === 'patterns') {
      return `${urlPrefix()}/search?design_patterns[id][]=${id}`;
    }

    return `${urlPrefix()}/search?page_elements[id][]=${id}`;
  }

  function urlPrefix() {
    return type === 'apps' ? '/apps' : '';
  }

  return (
    <>
      {/* <Helmet>
        <title>Refero — Search {getSearchTitle()}</title>
      </Helmet> */}

      <Layout>
        <div className={styles.body}>

          {getKinds()?.map(kind =>
            <div className={styles.group} key={kind}>
              <div className={styles.kind}>
                {kind}
              </div>
              
              <div className={styles.items}>
                {filter(kind)?.map(item =>
                  <Link 
                    onClick={event => onClick(item)}
                    to={url(item.id)}
                    className={classNames(styles.item)} 
                    key={item.id}
                  >
                    <div className={styles.name}>
                      {item.name}
                    </div> 
                    <div className={styles.count}>
                      {count(item.id)} Pages
                    </div>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
});

export default Tags;
