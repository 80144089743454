export default function Pencil(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="m11.782 3.927 2.22-2.22a1 1 0 0 1 1.414 0l.853.853.854.854a1 1 0 0 1 0 1.414l-2.22 2.22m-3.121-3.12-9.11 9.109a3 3 0 0 0-.863 1.814L1.56 17.27l2.419-.25a3 3 0 0 0 1.814-.862l9.11-9.11m-3.121-3.12 3.12 3.12"
      />
    </svg>
  )
}