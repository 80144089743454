import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import UserStore from 'stores/User';
// import UIStore from 'stores/UI';
import getABValue from 'helpers/get-ab-value';

mixpanel.init('e51451e85b80fb581e0ee27c5d6f9955', {debug: process.env.NODE_ENV === 'development'}); 

const isDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

// A/B tests
// const compactPaywall = UIStore.getExperimentValue('_compact_paywall');
// const homeScreenTest = UIStore.getExperimentValue('_home_screen');
// const pricingTest = UIStore.getExperimentValue('_pricing');
// const pricingTest2 = getABValue('_annual_pricing', ['old', 'next']);
// const screenGrouping = getABValue('_screen_grouping', ['enabled', 'disabled']);
// const groupLinkType = getABValue('_group_link_type', ['site', 'search']);
// const groupControl = getABValue('_group_control', ['tabs', 'switch']);
// const testWeekly = getABValue('_weekly_pricing', ['default', 'weekly']);
// const homeScreenAB = getABValue('_home_screen_ab', ['compact', 'unfolded']);

const ABCardsSorting = getABValue('ab_cards_sorting', ['popular', 'default'])

function isTrackable() {
  if (window?.location?.host?.includes('localhost') || UserStore.isAdmin() || process?.env?.NODE_ENV === 'development') {
    return false;
  }

  return true;
}

function track(eventName, params = {}) {
  if (!isTrackable()) {
    return;
  }
  
  mixpanel.track(eventName, {
    ...params,
    user_id: UserStore.id ?? null,
    trial: UserStore.onTrial,
    has_subscription: UserStore.hasSubscription(),
    theme: isDarkTheme ? 'dark' : 'light',
    time_zone: timeZone,
    'ab-cards-sorting': ABCardsSorting
    // 'ab-compact-paywall': compactPaywall,
    // 'ab-home-screen': homeScreenTest,
    // 'ab-pricing': pricingTest,
    // 'ab-screen-grouping': screenGrouping,
    // 'ab-annual-pricing': pricingTest2,
    // 'ab-weekly-pricing': testWeekly,
    // 'ab-group-link-type': groupLinkType,
    // 'ab-group-control': groupControl,
    // 'ab-home-screen': homeScreenAB
  });
}

function identify(user) {
  if (!isTrackable()) {
    return;
  }
  
  mixpanel.identify(user.id);
  mixpanel.people.set_once({
    '$email': user.email,
    '$name': user.name,
    'ID': user.id
  })
}

export default {
  track,
  identify
}
