import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import SitePreview3 from 'components/SitePreview3/SitePreview3';
import SitesStore from 'stores/Sites';
import ScreenshotsStore from 'stores/Screenshots';
import FeedStore from 'stores/Feed';
import styles from './SiteFeed.module.scss';

const SiteFeed = observer(({ category, disableInfiniteScroll = false }) => {

  const categoryLocal = useRef(null);

  useEffect(() => {
    if (category === undefined) {
      nextPage();
    }

    function onScroll(event) {
      if (!disableInfiniteScroll && document.body.scrollHeight - window.scrollY < 3000 && !FeedStore.feedLoaded) {
        nextPage();
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    categoryLocal.current = category;
    FeedStore.clear();
    nextPage();
  }, [ category ]);

  async function nextPage() {
    const page = await FeedStore.fetch(categoryLocal.current);
    mixpanel.track('SiteFeed', { page });
  }

  return (
    <>
      <div className={styles.container}>
        {FeedStore.list?.map((id, index) => {
          const site = SitesStore.store[id];
          return (
            <SitePreview3
              screenshots={site?.featuredScreenshots?.map(s => s.id) ?? ScreenshotsStore.list(id)}
              site={site} 
              key={`${category}_site_${index}`}
              compact={false}
            />
          )
        })}
      </div>
    </>
  );
});

export default SiteFeed;
