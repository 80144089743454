import { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import mixpanel from 'api/mixpanel';
import Tags from 'components/Tags/Tags';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import Placeholder from 'components/Placeholder/Placeholder';
import ScreenLink from 'components/ScreenLink/ScreenLink';
import Paywall3 from 'components/Paywall3/Paywall3';
import UniversalHeader from 'components/UniversalHeader/UniversalHeader';
import * as Icons from 'icons';
import AppsStore from 'stores/Apps';
import AppScreenshotsStore from 'stores/AppScreenshots';
import styles from './App.module.scss';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';

const TABS = [ 'page-type', 'patterns', 'elements' ];

const App = observer(() => {
  const { id } = useParams();
  const [ pageTypesFilter, setPageTypesFilter ] = useState(null);
  const [ patternFilter, setPatternFilter ] = useState(null);
  const [ elementFilter, setElementFilter] = useState(null);
  const [ tab, setTab ] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  // const isSignedIn = UserStore.isSignedIn();

  useEffect(() => {
    const params = (new URL(document.location)).searchParams;

    if (params.get('page-type')) {
      const id = parseInt(params.get('page-type'));
      setPageTypesFilter(id);
      setTab('page-type');
      mixpanel.track('SiteFilter', { key: 'page-type' });
      return;
    }

    if (params.get('pattern')) {
      const id = parseInt(params.get('pattern'));
      setPatternFilter(id);
      setTab('patterns');
      mixpanel.track('SiteFilter', { key: 'patterns' });
      return;
    }

    if (params.get('element')) {
      const id = parseInt(params.get('element'));
      setElementFilter(id);
      setTab('elements');
      mixpanel.track('SiteFilter', { key: 'elements' });
      return;
    }

    setPageTypesFilter(null);
    setElementFilter(null);
    setPatternFilter(null);
    
    if (!tab) {
      setTab('page-type');
    }
  }, [ location.search ]);

  useEffect(() => {
    fetch();
    mixpanel.track('App', { id });
  }, [ id ])

  useEffect(() => {
    if (UserStore.loginDate) {
      AppScreenshotsStore.fetch(parseInt(id));
    }
  }, [UserStore.loginDate]);

  async function fetch() {
    await AppsStore.show(parseInt(id));
    await AppScreenshotsStore.fetch(parseInt(id));
  }

  function getScreenshots() {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    return AppScreenshotsStore.list(parseInt(id), params);
  }

  function buildParams() {
    if (pageTypesFilter) {
      return `page-type=${pageTypesFilter}`;
    }

    if (patternFilter) {
      return `pattern=${patternFilter}`;
    }

    if (elementFilter) {
      return `element=${elementFilter}`;
    }

    return null;
  }

  function onShowAllTagsClick(event) {
    event.preventDefault();

    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    navigate('/upgrade');
  }

  function showAllTagsRender(type) {
    if (UserStore.isSignedIn() && (UserStore.hasSubscription() || UserStore.onTrial)) {
      return null;
    }

    return (
      <Tags.Item onClick={onShowAllTagsClick}>
        <Icons.Lock2 className={styles.loginIcon} />
        {`Show All ${type}`} 
      </Tags.Item>
    )
  }

  function getTabIcon(type) {
    switch(type) {
      case TABS[0]:
        return <Icons.PageTypesColor />
      case TABS[1]:
        return <Icons.PatternsColor />
      case TABS[2]:
        return <Icons.ElementsColor />;
      default:
        return null;
    }
  }

  function getTabLabel(type) {
    switch(type) {
      case TABS[0]:
        return 'Screen Types'
      case TABS[1]:
        return 'UX Patterns'
      case TABS[2]:
        return 'UI Elements'
      default:
        return null;
    }
  }

  function buildPageTitle() {
    return `${app?.name ?? ''} app screenshots and UX UI iOS design references — Refero`;
  }

  function buildPageDescription() {
    return `Discover a wealth of UI and UX design inspiration with collection of screenshots from the ${app?.name ?? ''} app. Explore iOS screen designs, perfect for graphic and mobile design references. Find your next UX/UI design inspiration today!`
  }

  const app = AppsStore.find(parseInt(id));
  const screenshots = getScreenshots();
  const noSubscription = !UserStore.isSignedIn() || (!UserStore.onTrial && !UserStore.hasSubscription());

  return (
    <>
      <Helmet>
        <title>{buildPageTitle()}</title>
        <link rel="canonical" href={`https://refero.design/apps/${id}`} />
        <meta property="og:url" content={`https://refero.design/apps/${id}`} />
        <meta property="og:title" content={buildPageTitle()} />
        <meta name="twitter:title" content={buildPageTitle()} />
        <meta property="og:site_name" content={buildPageTitle()} />
        <meta name="description" content={buildPageDescription()} />
      </Helmet>

      <div className={styles.header}>
        <UniversalHeader 
          data={app}
        />
      </div>

      <div className={styles.tabsWrap}>
        <TabsSlider 
          compact
          noBg
          activeIndex={TABS?.findIndex(item => tab === item)}
          className={styles.tabs}
        >
          {TABS?.map(item =>
            <TabsSlider.Item 
              to=""
              onClick={event => setTab(item)}
              active={tab === item}
              state={{ preventScrollTop: true }}
              key={item}
            >
              <TabsSlider.Icon>
                {getTabIcon(item)}
              </TabsSlider.Icon>
              {getTabLabel(item)}
            </TabsSlider.Item>
          )}
        </TabsSlider>
      </div>
      
      <div className={styles.tags}>
        {tab === 'page-type' ?
          <Tags>
            <Link 
              to="" 
              state={{ preventScrollTop: true }}
            >
              <Tags.Item 
                active={pageTypesFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {app?.pageTypes?.map(pageType => 
              <Link 
                to={`?page-type=${pageType.id}`} 
                state={{ preventScrollTop: true }}
                key={pageType.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && pageType.count}
                  active={pageTypesFilter === pageType.id}
                >
                  {pageType.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('Screen Types')}
          </Tags>
        : null}

        {tab === 'patterns' ?
          <Tags>
            <Link 
              to="" 
              state={{ preventScrollTop: true }}
            >
              <Tags.Item 
                active={patternFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {app?.patterns?.map(pattern => 
              <Link 
                to={`?pattern=${pattern.id}`} 
                state={{ preventScrollTop: true }}
                key={pattern.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && pattern.count}
                  active={patternFilter === pattern.id}
                >
                  {pattern.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('UX Patterns')}
          </Tags>
        :null}

        {tab === 'elements' ?
          <Tags>
            <Link 
              to=""
              state={{ preventScrollTop: true }}
            >
              <Tags.Item
                active={elementFilter === null}
              >
                All
              </Tags.Item>
            </Link>
            {app?.elements?.map(element => 
              <Link 
                to={`?element=${element.id}`} 
                state={{ preventScrollTop: true }}
                key={element.id}
              >
                <Tags.Item 
                  postfix={!noSubscription && element.count}
                  active={elementFilter === element.id}
                >
                  {element.name}
                </Tags.Item>
              </Link>
            )}
            {showAllTagsRender('UI Elements')}
          </Tags>
        :null}
      </div>
    
      <div className={styles.body}>
        <div className={classnames(styles.images, {
          [styles.noSubscription]: noSubscription
        })}>
          {/* {AppScreenshotsStore.fetching && !screenshots?.length ?  */}
          {!screenshots?.length ? 
            Array.from(Array(12)).map((_, index) => 
              <Placeholder.PreviewApp key={index} />
            )
          : 
            screenshots?.map(id => {
              const screen = AppScreenshotsStore.show(id);
              return (
                <div 
                  className={styles.imageContainer} 
                  key={id}
                >
                  <ScreenLink 
                    data={screen} 
                    app={app}  
                    params={buildParams()}
                    appScreen
                    selected={screen.selected}
                    // disableUrl={!isSignedIn}
                    // showSignUpOnClick={!isSignedIn}
                  />
                </div>  
              )
            })
          }
        </div>
      </div>
      
      <Paywall3 
        screenshotsCount={app?.screenshotsCount - 12}
        isApp
      >
        Log In or Sign Up to&nbsp;See&nbsp;All<br /> 
        {app?.faviconUrl && 
          <Paywall3.Icon>
            <img 
              src={app?.faviconUrl} 
              async
              loading="lazy"
              alt={app.name ?? ''}
            />
          </Paywall3.Icon>
        }
        <span style={{ color: `#${app?.backgroundColor ? app.backgroundColor : 'AEB8D6'}` }}>{app?.name ?? ''}</span> screens
      </Paywall3>
    </>
  );
});

export default App;
