import { makeAutoObservable } from 'mobx';
import AppScreenshotsStore from './AppScreenshots';

class App {

  id = null;
  name = null;
  description = null;

  categories = null;
  pageTypes = [];
  patterns = [];
  elements = [];
  thumbnail = null;
  faviconUrl = null;
  backgroundColor = null;
  screenshotsCount = null;
  site = null;
  featuredScreenshots = null;

  constructor({id, screenshots = null, categories = null, thumbnail_url, name, description, icon_url, background_color = null, screenshots_count, site, featured_screenshots}) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.categories = categories;
    this.thumbnail = thumbnail_url;
    this.faviconUrl = icon_url;
    this.backgroundColor = background_color;
    this.screenshotsCount = screenshots_count;
    this.site = site;

    if (featured_screenshots?.length) {
      this.featuredScreenshots = featured_screenshots;
      AppScreenshotsStore.add(featured_screenshots, `featured_${this.id}`, `featured_${this.id}`);
    }

    if (screenshots?.length) {
      AppScreenshotsStore.add(screenshots, this.id, this.id);
    }
    this.populate();
    makeAutoObservable(this);
  }

  hasAllFields() {
    return this.categories !== null && AppScreenshotsStore.list(this.id) !== null;
  }

  populate() {
    this.pageTypes = [];
    this.patterns = [];
    this.elements = [];

    AppScreenshotsStore.list(this.id)?.forEach(id => {
      const screenshot = AppScreenshotsStore.show(id);

      screenshot?.pageTypes?.forEach(pageType => {
        const index = this.pageTypes.findIndex(p => p.id === pageType.id);

        if (index === -1) {
          pageType.count = 1;
          this.pageTypes.push(pageType);
        } else {
          this.pageTypes[index]['count'] = (this.pageTypes[index]?.['count'] ?? 1) + 1;
        }
      });

      screenshot?.patterns?.forEach(pattern => {
        const index = this.patterns.findIndex(p => p.id === pattern.id);
        
        if (index === -1) {
          pattern.count = 1;
          this.patterns.push(pattern);
        } else {
          this.patterns[index]['count'] = (this.patterns[index]?.['count'] ?? 1) + 1;
        }
      });

      screenshot?.elements?.forEach(element => {
        const index = this.elements.findIndex(e => e.id === element.id);

        if (index === -1) {
          element.count = 1;
          this.elements.push(element);
        } else {
          this.elements[index]['count'] = (this.elements[index]?.['count'] ?? 1) + 1;
        }
      })
    });
  }

}

export default App;
