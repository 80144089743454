import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import Placeholder from 'components/Placeholder/Placeholder';
import Layout from 'components/Layout/Layout';
import SearchResult from 'components/SearchResult/SearchResult';
import Paywall3 from 'components/Paywall3/Paywall3';
import AppScreenshotsStore from 'stores/AppScreenshots';
import AppsStore from 'stores/Apps';
import UserStore from 'stores/User';
import styles from './AppSimilarScreenshots.module.scss';

const AppSimilarScreenshots = observer(({ id, replaceOnClick }) => {

  useEffect(() => {
    AppScreenshotsStore.similar(id);
    mixpanel.track('SimilarScreenshots', {id, page: 1});
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    if (UserStore.loginDate) {
      AppScreenshotsStore.similar(id, true);
    }
  }, [UserStore.loginDate]);

  function onScroll(event) {
    if (!AppScreenshotsStore.fetching && document.body.scrollHeight - (window?.scrollY + window?.innerHeight) < window.innerHeight * 2) {
      const page = AppScreenshotsStore.getNextSimilarPage(id);
      if (page) {
        mixpanel.track('SimilarScreenshots', {id, page});
      }
      AppScreenshotsStore.similarNextPage(id);
    }
  }

  const screenshots = AppScreenshotsStore.list(`screenshots-similar-${id}`);
  const showSignUpOnClick = !UserStore.isSignedIn();
  const redirectToPrice = UserStore.isSignedIn() && UserStore.isTrialExpired();

  return (
    <>
      <Layout>
        <div className={styles.body}>
          <div className={styles.images}>

            {!screenshots?.length ? 
              Array.from(Array(15)).map((_, index) => 
              <div className={styles.imageContainer} key={index}>
                <Placeholder.PreviewApp 
                  withSiteName
                  search
                  key={index} 
                />
              </div>
              )
            : null}

            {screenshots?.map(id => {
              const screenshot = AppScreenshotsStore.show(id);
              const app = AppsStore.find(screenshot.appId);

              return (
                <div 
                  className={styles.imageContainer} 
                  key={id}
                >
                  <SearchResult
                    // site={app} 
                    app={app}
                    isApp
                    screenshot={screenshot}
                    selected={screenshot?.selected}
                    replaceOnClick={replaceOnClick}
                    mixpanelEvent="SimilarAppScreenshotsClick"
                    showSignUpOnClick={showSignUpOnClick}
                    redirectToPrice={redirectToPrice}
                  />
                </div>  
              );
            })}
          </div>

          {/* {UserStore.isSignedIn() && !AppScreenshotsStore.hasMoreSimilar(id) ? 
            <div className={styles.theEnd}>
              You have reached the end 🎉
            </div>
          :null} */}

          
        </div>
      </Layout>
      <Paywall3 isApp>
        Login or Sign Up to&nbsp;See&nbsp;All<br />
        Similar Screens
      </Paywall3>
    </>
  );
});

export default AppSimilarScreenshots;
