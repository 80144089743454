import classnames from 'classnames';
import styles from './Colors.module.scss';

export default function Colors({ className }) {
  return (
    <div 
      className={classnames(styles.container, className)} 
    />
  )
}
