import classnames from 'classnames';
import styles from './Button.module.scss';

function Button({children, onClick, light, className, block, compact, confirm, blockMobile, blockTablet, title, round, square, element, mobileHidden, popovertarget}) {
  const Element = element ? element : 'div';
  return (
    <Element 
      popovertarget={popovertarget}
      className={classnames(styles.container, className, {
        [styles.light]: light,
        [styles.block]: block,
        [styles.compact]: compact,
        [styles.confirm]: confirm,
        [styles.blockMobile]: blockMobile,
        [styles.blockTablet]: blockTablet,
        [styles.round]: round,
        [styles.square]: square,
        [styles.mobileHidden]: mobileHidden,
      })}
      onClick={onClick}
      title={title}
    >
      {children}
    </Element>
  );
}

function Icon({children}) {
  return (
    <div className={styles.icon}>
      {children}
    </div>
  )
}

function Postfix({children}) {
  return (
    <div className={styles.postfix}>
      {children}
    </div>
  )
}

function MobileHidden({children}) {
  return (
    <div className={styles.mobileHiddenBody}>
      {children}
    </div>
  )
}

Button.Icon = Icon;
Button.Postfix = Postfix;
Button.MobileHidden = MobileHidden;

export default Button;