export default function ArrowLeft2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={12}
      viewBox="0 0 18 12"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="M6.294 11 1 6m0 0 5.294-5M1 6h16"
      />
    </svg>
  )
}
