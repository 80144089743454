import { Helmet } from 'react-helmet-async';
import * as Icons from 'icons';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import logo from 'images/mediakit-logo@x2.jpg';
import visuals from 'images/mediakit-visuals@x2.jpg';
import styles from './MediaKit.module.scss';

export default function MediaKit() {
  return (
    <Layout.WithPadding>
      <Helmet>
        <title>Refero — Media & Press Kit</title>
        <link rel="canonical" href="https://refero.design/mediakit" />
        <meta property="og:url" content="https://refero.design/mediakit" />
        <meta property="og:title" content="Refero — Media & Press Kit" />
        <meta name="twitter:title" content="Refero — Media & Press Kit" />
        <meta property="og:site_name" content="Refero — Media & Press Kit" />
      </Helmet>

      <div className={styles.container}>

        <h1 className={styles.title}>
          Media & Press Kit
        </h1>

        <div className={styles.text}>
          If you need more information about Refero, please contact Mike at <a href="mailto:mike@refero.design">mike@refero.design</a>. We look forward to hearing from you!
        </div>

        <div className={styles.items}>
          <div className={styles.item}>
            <img className={styles.collage} src={logo} alt="Logos" />
            <div className={styles.itemTitle}>Logos</div>

            <a href="https://images.refero.design/static/Logos.zip" download>
              <Button className={styles.button} blockTablet>
                <Button.Icon>
                  <Icons.Download2 />
                </Button.Icon>
                Download
              </Button>
            </a>

          </div>
          <div className={styles.item}>
            <img className={styles.collage} src={visuals} alt="Visuals" />
            <div className={styles.itemTitle}>Product Visuals</div>

            <a href="https://images.refero.design/static/Product-Visuals.zip" download>
              <Button className={styles.button} blockTablet>
                <Button.Icon>
                  <Icons.Download2 />
                </Button.Icon>
                Download
              </Button>
            </a>
            
          </div>
        </div>

      </div>

    </Layout.WithPadding>
  )
}