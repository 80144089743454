import classnames from 'classnames';
import image1 from 'images/site-feature-1@x2.png';
import image2 from 'images/site-feature-2@x2.png';
import image3 from 'images/site-feature-3@x2.png';
import styles from './SiteFeatures.module.scss';

export default function SiteFeatures() {
  return (
    <div className={styles.container}>

      <div className={styles.item}>
        <div className={styles.title}>
          High quality Web &&nbsp;iOS references
        </div>
        <div className={styles.text}>
          Quit the endless search and focus on what you love&nbsp;&mdash; designing!
        </div>

        <div className={styles.image}>
          <img src={image1} alt="iOS references" loading="lazy" />
        </div>
      </div>

      <div className={classnames(styles.item, styles.sand)}>
        <div className={styles.title}>
          Powerful search functionality
        </div>
        <div className={styles.text}>
          Pinpoint the perfect design references in seconds.
        </div>

        <div className={styles.image}>
          <img src={image2} alt="Powerful search" loading="lazy" />  
        </div>
      </div>

      <div className={classnames(styles.item, styles.violet, styles.wide)}>
        <div className={styles.title}>
        Expertly curated,<br /> AI-enhanced
        </div>
        <div className={styles.text}>
          Expert curators and cutting-edge AI algorithms work together to deliver a consistently detailed and reliable design library.
        </div>
        <div className={styles.image}>
          <img src={image3} alt="AI-enhanced" loading="lazy" />  
        </div>
      </div>

    </div>
  )
}