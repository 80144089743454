export default function getParamsString() {
  const params = document?.location?.search
    ?.replace('?', '')
    ?.split('&')
    ?.map(item => item.split('='))
    ?.filter(item => !!item[0] && !!item[1])
    ?.filter(item => item[0] !== 'source');

  const hasSiteOrApp = params.findIndex(param => param[0] === 'site_id[id][]' || param[0] === 'app_id[id][]') > -1;
  if (hasSiteOrApp) {
    const hasOrderParam = params.findIndex(param => param[0] === 'order') > -1;
    if (!hasOrderParam) {
      params.push(['order', 'managed']);
    }
  }

  const result = params?.map(item => item.join('='))?.join('&');
  return result && result !== '' ? result : 'root';
}
