import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import TeamForm from 'components/TeamForm/TeamForm';
import Wizard from 'components/Wizard/Wizard';
import styles from './TeamCreate.module.scss';
import Button from 'components/Button/Button';
import AlertStore from 'stores/Alert';
import TeamsStore from 'stores/Teams';
import PricingStore from 'stores/Pricing';
import PriceCardTeam from 'components/PriceCardTeam/PriceCardTeam';

const STEPS = ['1. Create Team', '2. Invite Members', '3. Checkout'];

export function TeamCreate() {
  return (
    <Wizard 
      steps={STEPS}
      activeStep={0}
      title="Create Team"
      key="wizard"
    >
      <TeamForm />
    </Wizard>
  )
}

export const TeamInvite = observer(() => {

  const field = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    TeamsStore.list();
  }, []); 

  function onFocus() {
    field.current.select();
  }

  async function copy() {
    if (!team) {
      return;
    }
    await navigator.clipboard.writeText(`${window.location.origin}/teams/join/${team?.token ?? ''}`);
    AlertStore.add('Invite link copied!', 'success');
  }

  function next() {
    if (!team) {
      return;
    }

    navigate(`/teams/checkout/${team?.id}`)
  }

  const team = TeamsStore.find(parseInt(id));

  return (
    <Wizard 
      steps={STEPS}
      activeStep={1}
      title="Invite Members"
      key="wizard"
    >
      <div className={styles.text}>
        Copy and share this link with your colleagues to&nbsp;invite them to&nbsp;join the team. Our pricing is&nbsp;based on&nbsp;per-seat usage. There&rsquo;s no&nbsp;need to&nbsp;wait for them to&nbsp;join&nbsp;&mdash; simply share the link and proceed to&nbsp;the next step.
      </div>

      <div className={styles.controls}>
        <input 
          ref={field}
          value={`${window.location.origin}/teams/join/${team?.token ?? ''}`} 
          onFocus={onFocus}
          readOnly
          className={styles.field}
          disabled={!team}
        />

        <Button 
          square
          light
          block
          onClick={copy}
        >
          Copy Link 
        </Button>
      </div>

      <Button
        square
        block
        className={styles.button}
        onClick={next}
      >
        Next
      </Button>
    
    </Wizard>
  )
});



export const TeamCheckout = observer(() => {

  useEffect(() => {
    window.createLemonSqueezy?.();
    PricingStore.fetch('team');
  }, []);

  return (
    <Wizard 
      steps={STEPS}
      activeStep={2}
      title="Checkout"
      key="wizard"
    >
      <div className={styles.text}>
        Select the billing plan that fits your team&rsquo;s needs. You&rsquo;ll initially be&nbsp;charged for a&nbsp;single seat. As&nbsp;more team members join, we&rsquo;ll automatically adjust the billing. For queries, <a href="mailto:main@refero.design">contact&nbsp;us</a> anytime.
      </div>

      <div className={styles.cards}>
        {(PricingStore.list('team') ?? [null])?.map((plan, index) =>
          <PriceCardTeam 
            key={index}
            plan={plan}
          />
        )}
      </div>


    </Wizard>
  )
});