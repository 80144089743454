import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Layout/Layout';
import AlertStore from 'stores/Alert';
import referoApi from 'api/refero_api';
import UserStore from 'stores/User';
import Button from 'components/Button/Button';
import UIStore from 'stores/UI';
import * as Icons from 'icons';
import styles from './TeamJoin.module.scss';

export const TeamJoin = observer(() => {

  const { token } = useParams();
  // const [ joining, setJoining ] = useState(false);
  const joining = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (UserStore.isSignedIn() && token) {
      join();
    }
  }, [ UserStore.id ]);

  async function join() {
    try {
      if (joining.current === true) {
        return;
      }
      joining.current = true;
      const { message } = await referoApi.teamJoin(token);
      AlertStore.add(message, 'success');
      navigate('/');
      UserStore.auth();
    } catch (err) {
      joining.current = false;
      AlertStore.add(err.message);
      navigate('/');
    }
  }

  function signup() {
    UIStore.showSignupPopup();
  }

  return (
    <Layout>
      <div className={styles.container}>
        {!UserStore.isSignedIn() ?
          <div>
            <div className={styles.text}>
              Please Log In or Sign Up to join team
            </div>
            <Button 
              light 
              blockTablet 
              className={styles.button} 
              onClick={signup}
            >
              <Button.Icon>
                <Icons.Login />
              </Button.Icon>
              Log In or Sign Up
            </Button>
          </div>
        : 'Please wait while you are joining team'}
      </div>
    </Layout>
  )
});
