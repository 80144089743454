import { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
// import PriceCard2 from 'components/PriceCard2/PriceCard2';
import * as Icons from 'icons';
import priceKey from 'helpers/price-key';
// import UserStore from 'stores/User';
import styles from './PriceItem.module.scss';
import PricingStore from 'stores/Pricing';
import PriceCard3, { PriceCard3Item, PriceCard3LibraryIcon } from 'components/PriceCard3/PriceCard3';
import TabsSlider from 'components/TabsSlider/TabsSlider';
// import Offer from 'components/Offer/Offer';
// import ChristmasBanner from 'components/ChristmasBanner/ChristmasBanner';

const PriceItem = observer(({ mixPanelEvent = 'PriceBoxRender', fetchPlans = false}) => {

  const cards = useRef(null);
  const key = useMemo(priceKey, []);
  // const key = 'default';
  const teamKey = 'team';
  const tabs = useMemo(() => {
    if (key === 'default') {
      return PricingStore.tabs;
    }
    return PricingStore.tabsWeekly;
  }, []);

  useEffect(() => {
    if (fetchPlans) {
      fetch();
    }
    
    mixpanel.track(mixPanelEvent);
    setScrollPosition();
  }, []);

  function setScrollPosition() {
    if (cards.current) {
      cards.current.scrollLeft = cards.current.scrollWidth / 3;
    }
  }

  // useEffect(() => {
  //   window.createLemonSqueezy?.();
  // }, [ UserStore.id ]);

  async function fetch() {
    await PricingStore.fetch(key);
    await PricingStore.fetch(teamKey);
  }

  // function onTeamClick() {
  //   const element = document.getElementById('team-plan-faq');
  //   element.scrollIntoView({behavior: 'smooth', block: 'center'});
  //   element?.click();
  // }

  function getSave(plan, plans) {
    if (!plan || plan.interval !== 'year') {
      return 0;
    }
    
    const amounts = plans?.map(item => {
      return PricingStore.calcAnualPrice(item);
    });

    const annualPrice =  PricingStore.calcAnualPrice(plan);
    const maxPrice = Math.max(...amounts);

    // const percent = ((maxPrice - annualPrice) / maxPrice * 100).toFixed();
    // return `${percent}%`;

    const amount = ((maxPrice - annualPrice) / 100).toFixed();
    return `$${amount}`;
  }

  function getPlan(planKey) {
    const plans = PricingStore.list(planKey);
    const tab = PricingStore.getActiveTab(tabs);
    return plans?.find(plan => plan.interval === tab.interval && plan.interval_count === tab.interval_count);
  }

  function getTeamPlan() {
    const plans = PricingStore.list(teamKey);
    return plans?.[0];
  }


  return (
    <>
      <TabsSlider 
        wide
        activeIndex={PricingStore.activeTab}
        className={styles.tabs}
        // noBgMobile
      >
        {tabs.map((tab, index) => 
          <TabsSlider.Item 
            onClick={event => PricingStore.setTab(index)}
            active={index === PricingStore.activeTab}
            key={tab.name}
          >
            {tab.name}
          </TabsSlider.Item>
        )}
      </TabsSlider>  

      

      <div className={styles.container}>

        {/* <ChristmasBanner /> */}

        <div className={styles.cards} ref={cards}>
          <PriceCard3 
            free
            desc="Basic access with essential features. Perfect for beginners." 
            icon={<Icons.PlanFree />}
          >
            <PriceCard3Item 
              icon={<PriceCard3LibraryIcon />}
              title="Limited library" 
              desc="Access to only ~3%&nbsp;of&nbsp;the full collection." 
            />
            <PriceCard3Item 
              icon={<Icons.Search4 />}
              title="Basic search" 
              desc="Search by tag or company." 
            />
          </PriceCard3>

          <PriceCard3 
            gradient
            saveAmount={getSave(getPlan(key), PricingStore.list(key))}
            // saveAmount="$72"
            desc="Enjoy all the features and our library for Web & iOS." 
            icon={<Icons.PlanPro />}
            plan={getPlan(key)}
          >
            <PriceCard3Item 
              icon={<Icons.Planet width="24" height="24" />}
              title="Web designs" 
              desc="Access to the world’s largest curated collection." 
            />
            <PriceCard3Item 
              icon={<Icons.AppStore width="24" height="24" />}
              title="iOS designs" 
              desc="Explore the ever-growing library of apps." 
            />
            <PriceCard3Item 
              icon={<Icons.Search5 className={styles.iconSearch} />}
              title="Advanced search" 
              desc="Group search results to discover user flows." 
            />
          </PriceCard3>

          <PriceCard3 
            team
            desc="All Pro features with flexible per-seat billing." 
            icon={<Icons.PlanTeam />}
            plan={getTeamPlan()}
          >
            <PriceCard3Item 
              icon={<Icons.PlanPro width="24" height="24" />}
              title="Includes everything from Pro" 
              desc="Unlimited access to all features." 
            />
            <PriceCard3Item 
              icon={<Icons.Bookmark className={styles.iconBookmark} width="10" height="16" />}
              title="Collaborative bookmarks" 
              desc="Coming&nbsp;Soon." 
            />
          </PriceCard3>

          {/* {(PricingStore.list(key) ?? [null,null,null]).map((plan, index) =>
            <PriceCard2 
              key={index}
              plan={plan}
              noBg={plan?.interval !== 'year'}
              save={getSave(plan, PricingStore.list(key))}
            />
          )} */}
        </div>

        <div className={styles.student}>
          Are you a student? Get 50% off 
          <a 
            href="https://forms.gle/7b3aACXDeD9uEpFw6"
            target="_blank"
            rel="noreferrer"
            className={styles.apply}
          >
            Apply
          </a>
        </div>

        
        {/* <Offer /> */}

        {/* <div className={styles.teamPlan} onClick={onTeamClick}>
          <Icons.Team className={styles.teamIcon} />
          Team Subscription Coming Soon! Join Exclusive Beta Access
        </div> */}
        
      </div>
    </>
  );
});

export default PriceItem;
