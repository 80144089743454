export default function Keyword(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <rect width={8} height={2} x={12} y={5} fill="#9FA5BA" rx={0.5} />
      <rect width={8} height={2} x={12} y={9} fill="#9FA5BA" rx={0.5} />
      <rect width={16} height={2} x={4} y={13} fill="#9FA5BA" rx={0.5} />
      <rect width={16} height={2} x={4} y={17} fill="#9FA5BA" rx={0.5} />
      <g filter="url(#aKeyword)">
        <path
          fill="url(#bKeyword)"
          d="M4.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.3c.11 0 .2.09.2.2v3.3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V7.2c0-.11.09-.2.2-.2h1.3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-5Z"
        />
      </g>
      <defs>
        <linearGradient
          id="bKeyword"
          x1={5.233}
          x2={6.424}
          y1={5.38}
          y2={10.365}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDAE36" />
          <stop offset={1} stopColor="#FD7A74" />
        </linearGradient>
        <filter
          id="aKeyword"
          width={6}
          height={6.2}
          x={4}
          y={5}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.2} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend in2="shape" result="effect1_innerShadow_6671_237" />
        </filter>
      </defs>
    </svg>
  )
}