import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'api/mixpanel';
import UIStore from 'stores/UI';
import Button from 'components/Button/Button';
import * as Icons from 'icons';
import styles from './Selection.module.scss';
import ScreenshotsStore from 'stores/Screenshots';
import AppScreenshotsStore from 'stores/AppScreenshots';
import DownloadStore from 'stores/Download';
import UserStore from 'stores/User';

const Selection = observer(() => {

  const navigate = useNavigate();

  function clear() {
    UIStore.clearSelectScreen();
    mixpanel.track('ClearScreensSelection');
  }

  function download() {
    if (!UserStore.isSignedIn()) {
      return UIStore.showSignupPopup();
    }

    if (!UserStore.hasSubscription() && !UserStore.onTrial) {
      return navigate('/upgrade');
    }

    mixpanel.track('DownloadZippedScreens');

    const webList = UIStore.selectedScreens?.map(id => {
      const screen = ScreenshotsStore.show(id);
      if (!screen) {
        return null;
      }
      return {
        urls: screen.singleScreen ? [screen.url[0]] : screen.url,
        name: `${screen.id}.jpg`
      }
    }).filter(screen => !!screen);

    if (webList?.length) {
      DownloadStore.createZip(webList, 'refero.design - web screens'); 
    }

    const appList = UIStore.selectedScreens?.map(id => {
      const screen = AppScreenshotsStore.show(id);
      if (!screen) {
        return null;
      }
      return screen.url;
    }).filter(screen => !!screen);

    if (appList?.length) {
      DownloadStore.createAppZip(appList, 'refero.design - ios screens'); 
    }

    // if (UIStore.searchType === 'sites') {
    //   const list = UIStore.selectedScreens?.map(id => {
    //     const screen = ScreenshotsStore.show(id);
    //     return {
    //       urls: screen.singleScreen ? [screen.url[0]] : screen.url,
    //       name: `${screen.id}.jpg`
    //     }
    //   });
    //   DownloadStore.createZip(list, 'refero.design - screens'); 
    // } else {
    //   const list = UIStore.selectedScreens?.map(id => {
    //     const screen = AppScreenshotsStore.show(id);
    //     return screen.url;
    //   });
    //   DownloadStore.createAppZip(list, 'refero.design - screens'); 
    // }

    // clear();
    UIStore.clearSelectScreen();
  }

  const size = UIStore.selectedScreens?.length;

  if (!size) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.label}>
            {size} screen{size > 1 ? 's' : ''} selected
          </div>
          <Button light onClick={download}>
            <Button.Icon>
              <Icons.Download3 /> 
            </Button.Icon>
            Download
          </Button>
        </div>

        <div 
          className={styles.clear}
          onClick={clear}
        >
          <Icons.Close2 />
        </div>
      </div>
    </div>
  );
});

export default Selection;
