import { useState } from 'react';
import classnames from 'classnames';
import lemonsqueezyUrl from 'helpers/lemonsqueezy-url';
import Placeholder from 'components/Placeholder/Placeholder';
import PricePerPeriod from 'components/PricePerPeriod/PricePerPeriod';
import PricePerMonth from 'components/PricePerMonth/PricePerMonth';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';
import mixpanel from 'api/mixpanel';
import centsToDollars from 'helpers/cents-to-dollars';
import styles from './PriceCardTeam.module.scss';

export default function PriceCardTeam({ plan, type }) {

  const [ redirecting, setRedirecting ] = useState(false);

  // useEffect(() => {
  //   window.createLemonSqueezy?.();
  // }, [ plan ]);

  function getPeriodName() {
    if (plan?.interval === 'year') {
      return 'annually';
    }

    if (plan?.interval === 'month' && plan?.interval_count === 3) {
      return 'quarterly';
      // return 'every 3 months';
    }

    if (plan?.interval === 'day' && plan?.interval_count === 7) {
      return 'weekly';
    }

    return 'monthly';
  }

  function onClickSubscribe() {
    setRedirecting(true);
    UserStore.addToAbondonedCartGroup();
    mixpanel.track('SubscribeButtonClick', { source: 'price-card' });
  }

  function getPriceName() {
    if (!plan) {
      return null;
    }

    if (plan?.interval === 'year') {
      return 'Annual';
    }

    if (plan?.interval === 'month' && plan?.interval_count === 3) {
      return 'Quarterly';
    }

    if (plan?.interval === 'day' && plan?.interval_count === 7) {
      return 'Weekly';
    }

    return 'Monthly';
  }

  function renderSubscribeButton() {
    // if (UserStore.hasSubscription()) {
    //   return null;
    // }

    if (!UserStore.isSignedIn()) {
      return (
        <div className={styles.button} onClick={event => UIStore.showSignupPopup()}>
          Get Started
        </div>
      )
    }

    if (!plan) {
      return (
        <div className={classnames(styles.button, styles.disabled)}>
          Loading Pricing Plans
        </div>
      )
    }

    return (
      <>
        <a 
          // className={classnames(styles.button, 'lemonsqueezy-button')} 
          className={classnames(styles.button, {
            [styles.disabled]: redirecting
          })} 
          onClick={onClickSubscribe}
          href={lemonsqueezyUrl(plan?.url, UserStore.id, UserStore.email)}
        >
          {redirecting ? 'Redirecting' : 'Subscribe' }
        </a>
      </>
    )
  }

  return (
    <div className={classnames(styles.card)}>
      
      <div className={classnames(styles.content)}>
        {/* <div className={classnames(styles.contentBg)} /> */}


        <div className={styles.name}>
          {getPriceName()}
        </div>          


        <div className={styles.price}>
          {plan ?
            <span className={styles.tabNum}>
              {plan?.interval === 'day' && plan?.interval_count === 7 ?
                '$' + centsToDollars(plan?.price)
              :
                <PricePerMonth price={plan?.price} plan={plan} />
              }
            </span>
          : 
            <Placeholder name="priceTitle" />
          }
          <div className={styles.seat}>
            seat/month
          </div>
        </div>

        <div className={styles.period}>
          {plan ?
            <div><span className={styles.tabNum}><PricePerPeriod price={plan?.price} /></span> billed {getPeriodName()}</div>
          : 
            <Placeholder name="pricePeriod" />
          }
        </div>
        <div className={styles.buttonWrapper}>
          {renderSubscribeButton()}
        </div>
      </div>
    </div>
  );
}

