import { useState } from 'react';
import classnames from 'classnames';
import * as Icons from 'icons';
import styles from './Faq.module.scss';

function Faq({ children }) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

function Item({ question, answer, id }) {
  const [ expanded, setExpanded ] = useState(false);

  function toggle() {
    setExpanded(!expanded);
  }

  return (
    <div 
      className={styles.item} 
      onClick={toggle}
      id={id}
    >
      <div className={classnames(styles.button, {
          [styles.rotated]: expanded
        })}
       >
        <Icons.ArrowTop />
      </div>
      <div className={styles.question}>
        {question}
      </div>
      <div 
        className={classnames(styles.answer, {
          [styles.expanded]: expanded
        })}
        dangerouslySetInnerHTML={{
          __html: answer
        }}
      />
    </div>
  )
}

Faq.Item = Item;

export default Faq;