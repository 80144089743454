// import isIndianUser from 'helpers/is-indian-user';
import centsToDollars from 'helpers/cents-to-dollars';
// import usdToInr from 'helpers/usd-to-inr';
import styles from './PricePerPeriod.module.scss';

export default function PricePerPeriod({ price }) {

  function normalize(value) {
    // if (isIndianUser()) {
    //   value = usdToInr(value) * .25;
    //   value = Math.ceil(value / 100) * 100;
    // }

    return value;
  }

  if (!price) {
    return 0;
  }

  function getSymbol() {
    // if (isIndianUser()) {
    //   return '₹';
    // }

    return '$';
  }

  function getComment() {
    // if (isIndianUser()) {
    //   return `or $${centsToDollars(price * .25)}`
    // }

    return null;
  }

  return (
    <span className={styles.container}>
      {getSymbol()}{centsToDollars(normalize(price))} {getComment()}
    </span>
  );
}
