import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'css/index.scss';
import Root from 'components/Root/Root';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // ReactDOM.hydrateRoot(rootElement, <App />);
} else {
  ReactDOM.createRoot(rootElement).render(<App />);
}

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </React.StrictMode>
  )
}
