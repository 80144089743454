import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import mixpanel from 'api/mixpanel';
import Placeholder from 'components/Placeholder/Placeholder';
import Layout from 'components/Layout/Layout';
import SearchResult from 'components/SearchResult/SearchResult';
import Paywall3 from 'components/Paywall3/Paywall3';
import ScreenshotsStore from 'stores/Screenshots';
import SitesStore from 'stores/Sites';
import UserStore from 'stores/User';
import styles from './SimilarScreenshots.module.scss';

const SimilarScreenshots = observer(({ id, replaceOnClick }) => {

  useEffect(() => {
    ScreenshotsStore.similar(id);
    mixpanel.track('SimilarScreenshots', {id, page: 1});
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    if (UserStore.loginDate) {
      ScreenshotsStore.similar(id, true);
    }
  }, [UserStore.loginDate]);

  function onScroll(event) {
    if (!ScreenshotsStore.fetching && document.body.scrollHeight - (window?.scrollY + window?.innerHeight) < window.innerHeight * 2) {
      const page = ScreenshotsStore.getNextSimilarPage(id);
      if (page) {
        mixpanel.track('SimilarScreenshots', {id, page});
      }
      ScreenshotsStore.similarNextPage(id);
    }
  }

  const screenshots = ScreenshotsStore.list(`screenshots-similar-${id}`);
  const showSignUpOnClick = !UserStore.isSignedIn();
  const redirectToPrice = UserStore.isSignedIn() && UserStore.isTrialExpired();

  return (
    <>
      <Layout>
        <div className={styles.body}>
          <div className={styles.images}>

            {!screenshots?.length ? 
              Array.from(Array(12)).map((_, index) => 
              <div className={styles.imageContainer} key={index}>
                <Placeholder.Preview 
                  withSiteName
                  search
                  key={index} 
                />
              </div>
              )
            : null}

            {screenshots?.map(id => {
              const screenshot = ScreenshotsStore.show(id);
              const site = SitesStore.find(screenshot?.siteId);

              return (
                <div 
                  className={styles.imageContainer} 
                  key={id}
                >
                  <SearchResult 
                    // url={`/${site?.id}-${site?.domain}/${screenshot?.id}${location.search ? location.search + '&' : '?'}source=search`}
                    site={site} 
                    screenshot={screenshot}
                    selected={screenshot?.selected}
                    replaceOnClick={replaceOnClick}
                    mixpanelEvent="SimilarScreenshotsClick"
                    showSignUpOnClick={showSignUpOnClick}
                    redirectToPrice={redirectToPrice}
                  />
                </div>  
              );
            })}
          </div>

          {/* {UserStore.isSignedIn() && !ScreenshotsStore.hasMoreSimilar(id) ? 
            <div className={styles.theEnd}>
              You have reached the end 🎉
            </div>
          :null} */}

          
        </div>
      </Layout>
      <Paywall3>
        Log In or Sign Up to&nbsp;See&nbsp;All<br />
        Similar Screens
      </Paywall3> 
    </>
  );
});

export default SimilarScreenshots;
