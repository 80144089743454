import { observer } from 'mobx-react-lite';
import paddle from 'api/paddle';
import Layout from 'components/Layout/Layout';
import UserStore from 'stores/User';
import styles from './Checkout.module.scss';
import { useEffect } from 'react';

const Checkout = observer(() => {

  useEffect(() => {
    if (UserStore?.email) {
      init();
    }
  }, [UserStore.email]);

  async function init() {
    await paddle.loadScript();

    paddle.init();

    window.Paddle?.Checkout?.open({
			method: 'inline',
			product: 10912,       // Replace with your Product or Plan ID
			allowQuantity: false,
			disableLogout: true,
			frameTarget: styles.checkout, // The className of your checkout <div>
			frameInitialHeight: 416,
			frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',    // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      passthrough: {
        user_id: UserStore.id
      },
      email: UserStore.email
    });
  }

  return (
    <Layout withTopPadding>
      <div className={styles.container}>
        <div className={styles.checkout} />
      </div>
    </Layout>
  )
});

export default Checkout;
