import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import styles from './Footer.module.scss';
import Logo from 'components/Logo/Logo';
import UserStore from 'stores/User';

const Footer = observer(() => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>

        <div className={styles.logoWrap}>
          <Logo />
        </div>
       
        <ul className={classnames(styles.menu, styles.accent)}>
          {UserStore.isSignedIn() ?
            <>
              <li><Link to="/bookmarks">Bookmarks</Link></li>
              <li><Link to="/billing">Billing & Subscription</Link></li>
            </>
          : null}

          <li><Link to="/pricing">Pricing</Link></li>
          <li><Link to="/how-it-works">How It Works</Link></li>
          <li><a href="https://forms.gle/NbTgktyTDcr4SWwf7" target="_blank" rel="noreferrer">Give Feedback</a></li>
        </ul>

        <ul className={styles.menu}>
          <li><a href="https://twitter.com/referodesign" target="_blank" rel="noreferrer">Follow Us on X</a></li>
          {/* <li><Link to="/mediakit">Media Kit</Link></li> */}
          <li><Link to="/terms-of-use">Terms of Use</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>

        <div>
          Have a question? Contact us: <a href="mailto:main@refero.design">main@refero.design</a>
        </div>

      </div>
    </div>
  );
});

export default Footer;
