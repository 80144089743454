import Layout from 'components/Layout/Layout';
import styles from './TeamSuccess.module.scss';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';

export default function TeamSuccess() {
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.body}>

          <h3>
            Welcome to the Refero for Teams!
          </h3>

          <div>
            We're glad you're here. Let's explore design together with your unlimited access.
          </div>

          <Link to="/teams">
            <Button light square block>
              Manage My Team
            </Button>
          </Link>

          <Link to="/">
            <Button square block>
              Start Exploring
            </Button>
          </Link>

        </div>
      </div> 
    </Layout>
  );
}