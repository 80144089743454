// {
//   "id": 1,
//   "name": "Refero",
//   "url": null,
//   "logo_url": null,
//   "domains": [
//       "https://refero.design"
//   ],
//   "members": [
//       {
//           "id": 2594,
//           "email": "stepan@refero.design",
//           "name": "Stepan Smirnov",
//           "picture": "https://lh3.googleusercontent.com/a/AEdFTp6Z4JZ5vubi6zfKiUP16drmwUvD-ZAY5S07Pvhg=s96-c",
//           "role": "admin"
//       }
//   ],
//   "token": "DwtWpENPbTa1B8lT"
// }


import { makeAutoObservable, runInAction } from 'mobx';
import AlertStore from './Alert';
import refero_api from 'api/refero_api';

class Team {

  id = null;
  name = null;
  logoUrl = null;
  domains = [];
  members = [];
  token = null;
  hasSubscription = false;

  constructor({
    id,
    name,
    logo_url,
    domains,
    members,
    token,
    has_subscription
  }) {
    this.id = id;
    this.name = name;
    this.logoUrl = logo_url;
    this.domains = domains;
    this.members = members;
    this.token = token;
    this.hasSubscription = has_subscription
   
    makeAutoObservable(this);
  }

  async update(name, logo_url, domains) {
    try {
      const updated = await refero_api.teamUpdate(this.id, name, logo_url, domains);

      runInAction(() => {
        this.id = updated.id;
        this.name = updated.name;
        this.logoUrl = updated.logo_url;
        this.domains = updated.domains;
        this.members = updated.members;
        this.token = updated.token;
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async deleteUser(userId) {
    try {
      await refero_api.teamDeleteMember(this.id, userId);
      runInAction(() => {
        const members = this.members.filter(m => m.id !== userId);
        this.members = [...members];
        AlertStore.add('User succesfully removed from the team', 'success');
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  isAdmin(userId) {
    return this.members.find(member => member.id === userId)?.role === 'admin';
  }
}

export default Team;
