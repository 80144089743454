export default function lemonsqueezyUrl(planUrl, id, email) {
  let url = `${planUrl}?dark=1`;

  if (id) {
    url += `&checkout[custom][user_id]=${id}`;
  }

  if (email) {
    url += `&checkout[email]=${email}`;
  }

  return url;
}