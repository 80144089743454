import { makeAutoObservable, runInAction } from 'mobx';
import ReferoApi from 'api/refero_api';
import AlertStore from './Alert';
import SitesStore from './Sites';

class Feed {

  list = [];
  listRandom = [];
  fetching = false;
  pagination = null;
  feedLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(category = null) {
    try {
      if (this.fetching) {
        return;
      }

      this.setFetching(true);

      const page = this.getNextPage();

      if (page === null) {
        this.feedLoaded = true;
        return;
      }

      // if (page > 1 && category === !null) {
        this.list = [...this.list, ...this.generatePlaceholders() ];
      // }

      
      const { records, pagination } = (page === 1 && category === null) ? await this.firstPage() : await ReferoApi.sitesAvailable(page, category);
      // const { records, pagination } = await ReferoApi.sitesAvailable(page, category);

      runInAction(() => {
        this.pagination = pagination;

        let lastIndex = this.list?.length - 1;
        while(lastIndex >= 0) {
          if (this.list[lastIndex].startsWith?.('placeholder_')) {
            this.list.splice(lastIndex, 1);
          }
          lastIndex--;
        }
        records?.forEach(site => {
          const index = this.list.indexOf(site.id);
          if (index === -1) {
            this.list.push(site.id);
            SitesStore.add(site);
          }
        });
      });

      this.setFetching(false);
      return page;
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  async firstPage() {
    try {
      return (await fetch('/json/feed/web.json')).json();
    } catch (err) {
      return null;
    }
  }

  getNextPage() {
    if (this.pagination?.next === null) {
      return null;
    }

    return this.pagination?.next ?? 1;
  }

  async random() {
    try {
      if (this.fetching) {
        return;
      }

      this.setFetching(true);
      this.listRandom = this.generatePlaceholders(10);
      const { sites } = await ReferoApi.sitesRandom();

      runInAction(() => {
        this.listRandom = [];

        sites?.forEach(site => {
          this.listRandom.push(site.id);
          SitesStore.add(site);
        });
      });

      this.setFetching(false);
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  populate(id) {
    this.find(id)?.populate();
  }

  generatePlaceholders(fixedScreenNumber = null) {
    let screenNumber;
    if (!fixedScreenNumber) {
      const screenLeft = this.pagination?.count - (this.pagination?.current * this.pagination?.per_page);
      screenNumber = Math.min(screenLeft, this.pagination?.per_page) ?? 24;
      if (isNaN(screenNumber) || !screenNumber) {
        screenNumber = 24;
      }
    }
    return Array.from(Array(screenNumber ?? fixedScreenNumber)).map(() => `placeholder_${Math.random().toString(36)}`);
  }

  clear() {
    this.list = [];
    this.fetching = false;
    this.pagination = null;
    this.feedLoaded = false;
  }

}

const FeedStore = new Feed();
export default FeedStore;
