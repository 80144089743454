import { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';

import AppsStore from 'stores/Apps';
import AppScreenshotsStore from 'stores/AppScreenshots';
import styles from './Flow.module.scss';

const RATIO = -0.002;


const Flow = observer(() => {
  const { id } = useParams();
  const canvas = useRef(null);
  const positionX = useRef(0);
  const positionY = useRef(0);

  useEffect(() => {
    fetch();
  }, [ id ]);

  async function fetch() {
    AppsStore.show(parseInt(id));
    AppScreenshotsStore.fetch(parseInt(id));
  }

  function getScreenshots(params = {}) {
    return AppScreenshotsStore.list(parseInt(id), params);
  }

  const onWheel = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();

      // console.log('e.clientX', e.deltaX);
      

      positionX.current = positionX.current + (e.clientX - positionX.current) * e.deltaX * RATIO;
      positionY.current = positionY.current + (e.clientY - positionY.current) * e.deltaY * RATIO;

      window.requestAnimationFrame(() => {
        canvas.current.style.transform = `scale3d(1, 1, 1) translate3d(${positionX.current}px, ${positionY.current}px, 0)`;
      });

      return false;
    }, [ ]);

  const divRefCallback = useCallback((node) => {
    if (node == null) {
      return;
    }
    node.addEventListener('wheel', onWheel, { passive: false });
  }, [ onWheel ]);

  const app = AppsStore.find(parseInt(id));
  // const screenshots = getScreenshots();

  // console.log('RENDER');

  return (
    <div 
      className={styles.container}
    >
      <div className={styles.events} ref={divRefCallback} />
      <div 
        className={styles.canvas}
        ref={canvas}
        style={{
          // transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
          // transform: `translate3d(${pos.x}px, ${pos.y}px, 0)`
        }}
      >
      
        {app?.pageTypes?.map((pageType, index) => 
          <div 
            className={styles.group}
            key={pageType.id}

            style={{ top: 500 * index + 'px' }}
          >
            {pageType.name}

            <div className={styles.groupBody}>
              {getScreenshots({ 'page-type': pageType.id })?.map((id, index2) => {
                const screen = AppScreenshotsStore.show(id);
                
                return (
                  <Screen
                    // src={screen.url}
                    src={screen.thumbnail}
                    index={index2}
                    key={id}
                  /> 
                )
              })}
            </div>
          </div>
        )}

      </div>

    </div>
  );
});

export default Flow;

function Screen({ src, index }) {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '50px'
  });

  return (
    <div 
      className={styles.screenWrap}
      ref={ref}
      style={{ left: 270 * index + 'px' }}
    >
      <img
        src={src}
        className={classnames(styles.screen, {
          [styles.visible]: inView
        })}
        loading="lazy"
        alt=""
      /> 

      <div className={styles.arrow} />
    
    </div>
  )
}
