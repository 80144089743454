import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import UserStore from 'stores/User';
import styles from './EmailSignup.module.scss';
import AlertStore from 'stores/Alert';

const EmailSignup = observer(forwardRef(({ emailChangeCallback }, ref) => {

  const [ email, setEmail ] = useState('');
  const [ emailTemp, setEmailTemp ] = useState('');
  const [ code, setCode ] = useState(['', '', '', '']);
  const [ attempts, setAttempts ] = useState(0);

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);

  const inputs = [ input1, input2, input3, input4 ];

  useEffect(() => {
    if (attempts > 2) {
      setAttempts(0);
      setEmailTemp('');
      setEmail('');
    }
  }, [ attempts ]);

  useEffect(() => {
    if (code.every(item => !!item)) {
      submitCode();
    }
  }, [ code ]);

  useEffect(() => {
    emailChangeCallback(email);
  }, [ email ]);

  useImperativeHandle(ref, () => ({
    clearEmail() {
      setAttempts(0);
      setEmailTemp('');
      setEmail('');
    }
  }));

  async function submit(event) {
    try {
      event.preventDefault();
      await UserStore.otpEmail(emailTemp);
      setEmail(emailTemp);
      setEmailTemp('');
    } catch (err) {
      AlertStore.add(err.message)
    }
  }

  async function submitCode() {
    try {
      await UserStore.otpCode(email, code.join(''));
      setEmail('');
      setCode(['', '', '', '']);
      setTimeout(() => input1.current?.focus());
    } catch (err) {
      AlertStore.add(err.message ?? 'Invalid Code');
      setAttempts(attempts + 1);
      setCode(['', '', '', '']);
      setTimeout(() => input1.current?.focus());
    }
  }

  function onChange(value, index) {
    if (index < 0) {
      return;
    }
    if (value?.length > 1) {
      value = value[0];
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  }

  function onKeyUp(event, index) {
    if (event.code === 'Backspace') {
      const prevIndex = index - 1;
      onChange('', prevIndex);
      inputs?.[prevIndex]?.current?.focus();
      return inputs?.[prevIndex]?.current?.select();
    }

    if (event.code === 'ArrowLeft') {
      const prevIndex = index - 1;
      // onChange('', prevIndex);
      inputs?.[prevIndex]?.current?.focus();
      return inputs?.[prevIndex]?.current?.select();
    }

    if (event.code === 'ArrowRight') {
      inputs?.[index + 1]?.current?.focus();
      return inputs?.[index + 1]?.current?.select();
    }
    
    inputs?.[index + 1]?.current?.focus();
  }

  function onPaste(event) {
    event.preventDefault();
    const data = (event.clipboardData || window.clipboardData).getData('text');
    const newCode = [...code];
    newCode.forEach((_, index) => {
      const value = parseInt(data[index]);
      if (value || value === 0) {
        newCode[index] = value;
      }
    });
    setCode(newCode);
  }
  
  return (
    <>
      {!email ?
        <form className={styles.container} onSubmit={submit}>
          <div className={styles.row}>
            <input 
              type="email" 
              placeholder="Email" 
              className={styles.field} 
              value={emailTemp}
              onChange={({target: {value}}) => setEmailTemp(value)}
              disabled={UserStore.submitting}
              required
            />
          </div>
          <div className={styles.row}>
            <button 
              className={styles.button} 
              disabled={UserStore.submitting}
            >
              Continue with Email
            </button>
          </div>
        </form>
      :
        <div className={styles.codeWrapper}>
          {/* <div className={styles.title}>
            Check your inbox
          </div> */}
          <div className={styles.tagLine}>
            We've sent a verification code to {email}.<br />
            Please check your email, including the spam folder.
          </div>
          <div className={styles.code}>
            <input type="number" value={code[0]} maxLength="1" className={styles.codeInput} ref={input1} onChange={e => onChange(e.target.value, 0)} onKeyUp={e => onKeyUp(e, 0)} onPaste={onPaste} disabled={UserStore.submitting} autoFocus />
            <input type="number" value={code[1]} maxLength="1" className={styles.codeInput} ref={input2} onChange={e => onChange(e.target.value, 1)} onKeyUp={e => onKeyUp(e, 1)} onPaste={onPaste} disabled={UserStore.submitting} />
            <input type="number" value={code[2]} maxLength="1" className={styles.codeInput} ref={input3} onChange={e => onChange(e.target.value, 2)} onKeyUp={e => onKeyUp(e, 2)} onPaste={onPaste} disabled={UserStore.submitting} />
            <input type="number" value={code[3]} maxLength="1" className={styles.codeInput} ref={input4} onChange={e => onChange(e.target.value, 3)} onKeyUp={e => onKeyUp(e, 3)} onPaste={onPaste} disabled={UserStore.submitting} />
          </div>

          <div className={styles.tip}>
            Having trouble? Try to&nbsp;log in&nbsp;with Google or&nbsp;email&nbsp;us at&nbsp;<a href="mailto:main@refero.design">main@refero.design</a>.
          </div>
        </div>
      }

    </>
  );
}));

export default EmailSignup;
