import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import mixpanel from 'api/mixpanel';
import classnames from 'classnames';
import Layout from 'components/Layout/Layout';
import SearchResult from 'components/SearchResult/SearchResult';
import Paywall3 from 'components/Paywall3/Paywall3';
import NothingFound from 'components/NothingFound/NothingFound';
// import Suggestions from 'components/Suggestions/Suggestions';
import Sort from 'components/Sort/Sort';
import SitesStore from 'stores/Sites';
import ScreenshotsStore from 'stores/Screenshots';
import UserStore from 'stores/User';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import * as Icons from 'icons';
// import getABValue from 'helpers/get-ab-value';
import getParamsString from 'helpers/get-params-string';
import FetchError from 'components/FetchError/FetchError';
import SearchResultSite from 'components/SearchResultSite/SearchResultSite';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import styles from './Search.module.scss';
import GroupingPopup from 'components/GroupingPopup/GroupingPopup';
// import Switch from 'components/Switch/Switch';

const Search = observer(() => {
  const location = useLocation();
  // const navigate = useNavigate();
  const screenshots = ScreenshotsStore.list(getParamsString());
  const pagination = ScreenshotsStore.pagination(getParamsString());
  const isGrouped = getParamsString()?.includes('grouped=true');
  const isSignedIn = UserStore.isSignedIn();
  // const screenGrouping = useMemo(() => getABValue('_screen_grouping', ['enabled', 'disabled']), []);
  // const groupControl = useMemo(() => getABValue('_group_control', ['tabs', 'switch']), []);

  useEffect(() => {
    UIStore.setSearchType('sites');
    window.addEventListener('scroll', onScroll);
    // window.addEventListener('keydown', onPressingSpace);

    return () => {
      window.removeEventListener('scroll', onScroll);
      // window.removeEventListener('keydown', onPressingSpace);
    }
  }, []);

  useEffect(() => {
    SearchStore.findSuggestions();
  }, [ SearchStore.items ]);

  useEffect(() => {  
    SitesStore.search(getParamsString());
    const query = location.search?.replace('?', '')?.split('&')?.map(param => param.split('='));
    const params = {};
    query?.forEach(item => {
      const key = item[0]?.replace('[id][]', '');
      if (key) {
        if (key === 'query') {
          params[key] = item[1];
        } else {
          params[key] ??= [];
          params[key]?.push(item[1]);
        }
      }
    });
    mixpanel.track('Search', {...params});
  }, [location.search]);

  useEffect(() => {
    if (UserStore.loginDate) {
      SitesStore.search(getParamsString(), true);
    }
  }, [ UserStore.loginDate ]);

  function onScroll(event) {    
    if (!SitesStore.fetching && !SitesStore.fetchError && document.body.scrollHeight - (window?.scrollY + window?.innerHeight) < window.innerHeight * 2) {
      SitesStore.search(getParamsString(), true, true);
    }
  }

  // function onPressingSpace(event) {
  //   if (event.keyCode === 32 && event.target === document.body) {
  //     event.preventDefault();
  //     window.scrollTo({
  //       top: window.scrollY + window.innerHeight - document.getElementById('header').offsetHeight,
  //       behavior: 'smooth'
  //     });
  //     mixpanel.track('SearchPressSpace');
  //   }
  // }

  function getSearchTitle() {
    let results = SearchStore.items?.map(item => item?.value?.name)?.join(' - ');
    results = results ? `| ${results}` : '';
    return results;
  }

  function refresh() {
    SitesStore.search(getParamsString(), true, screenshots?.length > 0);
  }

  function getLabelColor(type, color) {
    switch(type) {
      case 'page_types':
      case 'design_patterns':
        return 'rgba(232, 141, 141, 1)';
      case 'page_elements':
        return 'rgba(231, 148, 121, 1)';
      case 'site_id':
        return `#${color}`;
      default:
        return 'rgba(36, 188, 188, 1)';
    }
  }

  function getLabelIcon(type, favicon, domain) {
    switch(type) {
      case 'page_types':
        return <Icons.PageTypesColor width="48" height="48" />
      case 'design_patterns':
        return <Icons.PatternsColor width="48" height="48" />
      case 'page_elements':
        return <Icons.ElementsColor width="48" height="48" />
      case 'fonts':
        return <Icons.Fonts width="48" height="48" />
      case 'site_id':
        return (
          <img
            src={favicon ? `https://images.refero.design/${favicon}` : `https://www.google.com/s2/favicons?domain=${domain}&sz=256`}
            alt=""
          />
        )
      default:
        return <Icons.CategoryColor width="48" height="48" />
    }
  }
  
  function getPaywallLabel() {
    // console.log('SearchStore.items[0].value', SearchStore.items[0].value);

    if (SearchStore.items?.length === 1) {
      const { type, value } = SearchStore.items?.[0];
      const hasPageInName = value?.name?.toLowerCase()?.endsWith('page');
      return (
        <>
          <Paywall3.Icon>
            {getLabelIcon(type, value?.icon_url, value?.domain)}  
          </Paywall3.Icon>
          <span style={{ color: getLabelColor(type, value.background_color) }}>
            {value.name}{hasPageInName ? 's' : ''}
          </span> {hasPageInName ? '' : 'Pages'}
        </> 
      )
    }

    return 'Search Results';
  }

  function buildUrl(site, screenshot) {
    if (!site && !screenshot) {
      return '#';
    }

    return `/${site?.id}-${site?.domain}/${screenshot?.id}${location.search ? location.search + '&' : '?'}source=search`;
  }

  function renderContent() {
    if (isGrouped) {
      return (
        screenshots.map(id => {
          const site = ScreenshotsStore.show(id);

          return (
            <div 
              className={styles.imageContainer}
              key={id} 
            >
              <SearchResultSite 
                site={site}
                key={id}
              />
            </div>
          )
        })
      )
    }

    return (
      screenshots?.map(id => {
        const screenshot = ScreenshotsStore.show(id);
        const site = SitesStore.find(screenshot?.siteId);
        const url = buildUrl(site, screenshot);

        return (
          <div 
            className={styles.imageContainer}
            key={id} 
          >
            <SearchResult 
              url={url}
              site={site} 
              screenshot={screenshot}
              selected={screenshot?.selected}
              showSignUpOnClick={!isSignedIn}
            />
          </div>
        )
      })
    );
  }

  function buildGroupParams() {
    let params = SearchStore.params(false, true);
    params += params?.length ? '&' : '';
    params += 'grouped=true';
    return params;
  }

  function grouppingAvailable() {
    return !SearchStore.items?.find(item => item.type === 'site_id');
  }

  // function onChangeGrouped(newValue) {
  //   if (!UserStore.isSignedIn()) {
  //     return UIStore.showSignupPopup();
  //   }

  //   if (newValue) {
  //     navigate(`/search?${buildGroupParams()}`);
  //   } else {
  //     navigate(`/search?${SearchStore.params(false, true)}`);
  //   }
  // }

  function onClickGroupBySite() {
    if (UserStore.isSignedIn()) {
      return;
    }

    UIStore.showSignupPopup();
  }

  function renderLeftControls() {
    if (grouppingAvailable()) {
      // if (groupControl === 'tabs') {
        return(
          <TabsSlider
            noBg
            compact
            className={styles.groupTabs}
            activeIndex={SearchStore.grouped ? 1 : 0}
          >
            <TabsSlider.Item 
              to={`/search?${SearchStore.params(false, true)}`}
              
            >
              Screens
            </TabsSlider.Item>
            <TabsSlider.Item 
              onClick={onClickGroupBySite}
              to={UserStore.isSignedIn() ? `/search?${buildGroupParams()}` : null}
            >
              Websites 
              <div className={styles.newLabel}>
                New
              </div>
            </TabsSlider.Item>
          </TabsSlider>
        )
      // } else {
      //   return (
      //     <Switch 
      //       label="Group by Websites" 
      //       value={SearchStore.grouped}
      //       onChange={onChangeGrouped}
      //     />
      //   )
      // }
    }

    // return <Suggestions urlPrefix={window.location.search} />
    return null;
  }

  const isNothingFound = !screenshots?.length && !SitesStore.fetching && pagination?.count === 0;

  return (
    <>
      <Helmet>
        <title>Refero — Search {getSearchTitle()}</title>
      </Helmet>

      <Layout>
        <div className={styles.body}>
          <div className={styles.pagination}>

            <div className={styles.suggestionsWrap}>
              {renderLeftControls()}
            </div>

            {pagination?.count ?
              <span className={styles.paginationValue}>
                {(pagination.count)?.toLocaleString()} Pages Found
              </span>
            : <div />}

            {!isNothingFound ? 
              <div className={styles.sortWrap}>
                <Sort />
              </div>
            : null}
          </div>

          <div className={classnames(styles.images, {
            [styles.noSubscription]: !UserStore.isSignedIn() || (!UserStore.onTrial && !UserStore.hasSubscription())
          })}>
            {renderContent()}
          </div>

          {SitesStore.fetchError ?
            <FetchError onClick={refresh} />
          : null}

          {isNothingFound ?
            <NothingFound />
          : null}
        </div>
      </Layout>

      {!!screenshots?.length && pagination.count > 12 && 
        <Paywall3 
          screenshotsCount={pagination?.count - 12}
        >
          Log In or Sign Up to&nbsp;See&nbsp;All<br />
          {getPaywallLabel()}
        </Paywall3>
      }

      <GroupingPopup />
    </>
  );
});

export default Search;
