export default {
  "Black":"#000000",
  "Night":"#0C090A",
  "Charcoal":"#34282C",
  "Oil":"#3B3131",
  "Dark Gray":"#A9A9A9",
  "Light Black":"#454545",
  "Black Cat":"#413839",
  "Iridium":"#3D3C3A",
  "Black Eel":"#463E3F",
  "Black Cow":"#4C4646",
  "Gray Wolf":"#504A4B",
  "Vampire Gray":"#565051",
  "Iron Gray":"#52595D",
  "Gray Dolphin":"#5C5858",
  "Carbon Gray":"#625D5D",
  "Ash Gray":"#666362",
  "Dim Gray":"#696969",
  "Nardo Gray":"#686A6C",
  "Cloudy Gray":"#6D6968",
  "Smokey Gray":"#726E6D",
  "Alien Gray":"#736F6E",
  "Sonic Silver":"#757575",
  "Platinum Gray":"#797979",
  "Granite":"#837E7C",
  "Gray":"#808080",
  "Battleship Gray":"#848482",
  "Gunmetal Gray":"#8D918D",
  "Gray Cloud":"#B6B6B4",
  "Silver":"#C0C0C0",
  "Pale Silver":"#C9C0BB",
  "Gray Goose":"#D1D0CE",
  "Platinum Silver":"#CECECE",
  "Light Gray":"#D3D3D3",
  "Gainsboro":"#DCDCDC",
  "Platinum":"#E5E4E2",
  "Metallic Silver":"#BCC6CC",
  "Blue Gray":"#98AFC7",
  "Roman Silver":"#838996",
  "Light Slate Gray":"#778899",
  "Slate Gray":"#708090",
  "Rat Gray":"#6D7B8D",
  "Slate Granite Gray":"#657383",
  "Jet Gray":"#616D7E",
  "Mist Blue":"#646D7E",
  "Marble Blue":"#566D7E",
  "Slate Blue Grey":"#737CA1",
  "Light Purple Blue":"#728FCE",
  "Azure Blue":"#4863A0",
  "Blue Jay":"#2B547E",
  "Charcoal Blue":"#36454F",
  "Dark Blue Grey":"#29465B",
  "Dark Slate":"#2B3856",
  "Deep-Sea Blue":"#123456",
  "Night Blue":"#151B54",
  "MidnightBlue":"#191970",
  "Navy":"#000080",
  "Denim Dark Blue":"#151B8D",
  "DarkBlue":"#00008B",
  "Lapis Blue":"#15317E",
  "New Midnight Blue":"#0000A0",
  "Earth Blue":"#0000A5",
  "Cobalt Blue":"#0020C2",
  "MediumBlue":"#0000CD",
  "Blueberry Blue":"#0041C2",
  "Canary Blue":"#2916F5",
  "Blue":"#0000FF",
  "Bright Blue":"#0909FF",
  "Blue Orchid":"#1F45FC",
  "Sapphire Blue":"#2554C7",
  "Blue Eyes":"#1569C7",
  "Bright Navy Blue":"#1974D2",
  "Balloon Blue":"#2B60DE",
  "RoyalBlue":"#4169E1",
  "Ocean Blue":"#2B65EC",
  "Blue Ribbon":"#306EFF",
  "Blue Dress":"#157DEC",
  "Neon Blue":"#1589FF",
  "DodgerBlue":"#1E90FF",
  "Glacial Blue Ice":"#368BC1",
  "SteelBlue":"#4682B4",
  "Silk Blue":"#488AC7",
  "Windows Blue":"#357EC7",
  "Blue Ivy":"#3090C7",
  "Blue Koi":"#659EC7",
  "Columbia Blue":"#87AFC7",
  "Baby Blue":"#95B9C7",
  "Cornflower Blue":"#6495ED",
  "Sky Blue Dress":"#6698FF",
  "Iceberg":"#56A5EC",
  "Butterfly Blue":"#38ACEC",
  "DeepSky Blue":"#00BFFF",
  "Midday Blue":"#3BB9FF",
  "Crystal Blue":"#5CB3FF",
  "Denim Blue":"#79BAEC",
  "Day Sky Blue":"#82CAFF",
  "Light Sky Blue":"#87CEFA",
  "Sky Blue":"#87CEEB",
  "Jeans Blue":"#A0CFEC",
  "Blue Angel":"#B7CEEC",
  "Pastel Blue":"#B4CFEC",
  "Light Day Blue":"#ADDFFF",
  "Sea Blue":"#C2DFFF",
  "Heavenly Blue":"#C6DEFF",
  "Robin Egg Blue":"#BDEDFF",
  "Powder Blue":"#B0E0E6",
  "Coral Blue":"#AFDCEC",
  "Light Blue":"#ADD8E6",
  "Light Steel Blue":"#B0CFDE",
  "Gulf Blue":"#C9DFEC",
  "Pastel Light Blue":"#D5D6EA",
  "Lavender Blue":"#E3E4FA",
  "Lavender":"#E6E6FA",
  "Water":"#EBF4FA",
  "Alice Blue":"#F0F8FF",
  "Ghost White":"#F8F8FF",
  "Azure":"#F0FFFF",
  "Light Cyan":"#E0FFFF",
  "Light Slate":"#CCFFFF",
  "Electric Blue":"#9AFEFF",
  "Tron Blue":"#7DFDFE",
  "Blue Zircon":"#57FEFF",
  "Aqua":"#00FFFF",
  "Bright Cyan":"#0AFFFF",
  "Celeste":"#50EBEC",
  "Blue Diamond":"#4EE2EC",
  "Bright Turquoise":"#16E2F5",
  "Blue Lagoon":"#8EEBEC",
  "Pale Turquoise":"#AFEEEE",
  "Pale Blue Lily":"#CFECEC",
  "Tiffany Blue":"#81D8D0",
  "Blue Hosta":"#77BFC7",
  "Cyan Opaque":"#92C7C7",
  "Northern Lights Blue":"#78C7C7",
  "Blue Green":"#7BCCB5",
  "Medium Aqua Marine":"#66CDAA",
  "Magic Mint":"#AAF0D1",
  "Aquamarine":"#7FFFD4",
  "Light Aquamarine":"#93FFE8",
  "Turquoise":"#40E0D0",
  "Medium Turquoise":"#48D1CC",
  "Deep Turquoise":"#48CCCD",
  "Jellyfish":"#46C7C7",
  "Blue Turquoise":"#43C6DB",
  "Dark Turquoise":"#00CED1",
  "Macaw Blue Green":"#43BFC7",
  "Light Sea Green":"#20B2AA",
  "Seafoam Green":"#3EA99F",
  "Cadet Blue":"#5F9EA0",
  "Deep-Sea":"#3B9C9C",
  "Dark Cyan":"#008B8B",
  "Teal":"#008080",
  "Medium Teal":"#045F5F",
  "Deep Teal":"#033E3E",
  "Dark Slate Gray":"#25383C",
  "Gunmetal":"#2C3539",
  "Blue Moss Green":"#3C565B",
  "Beetle Green":"#4C787E",
  "Grayish Turquoise":"#5E7D7E",
  "Greenish Blue":"#307D7E",
  "Aquamarine Stone":"#348781",
  "Sea Turtle Green":"#438D80",
  "Dull-Sea Green":"#4E8975",
  "Deep-Sea Green":"#306754",
  "Sea Green":"#2E8B57",
  "Dark Mint":"#31906E",
  "Jade":"#00A36C",
  "Earth Green":"#34A56F",
  "Emerald":"#50C878",
  "Mint":"#3EB489",
  "Medium Sea Green":"#3CB371",
  "Camouflage Green":"#78866B",
  "Sage Green":"#848B79",
  "Hazel Green":"#617C58",
  "Venom Green":"#728C00",
  "Olive Drab":"#6B8E23",
  "Olive":"#808000",
  "Dark Olive Green":"#556B2F",
  "Army Green":"#4B5320",
  "Fern Green":"#667C26",
  "Fall Forest Green":"#4E9258",
  "Pine Green":"#387C44",
  "Medium Forest Green":"#347235",
  "Jungle Green":"#347C2C",
  "Forest Green":"#228B22",
  "Green":"#008000",
  "Dark Green":"#006400",
  "Deep Emerald Green":"#046307",
  "Dark Forest Green":"#254117",
  "Seaweed Green":"#437C17",
  "Shamrock Green":"#347C17",
  "Green Onion":"#6AA121",
  "Green Pepper":"#4AA02C",
  "Dark Lime Green":"#41A317",
  "Parrot Green":"#12AD2B",
  "Clover Green":"#3EA055",
  "Dinosaur Green":"#73A16C",
  "Green Snake":"#6CBB3C",
  "Alien Green":"#6CC417",
  "Green Apple":"#4CC417",
  "LimeGreen":"#32CD32",
  "Pea Green":"#52D017",
  "Kelly Green":"#4CC552",
  "Zombie Green":"#54C571",
  "Frog Green":"#99C68E",
  "Dark Sea Green":"#8FBC8F",
  "Green Peas":"#89C35C",
  "Dollar Bill Green":"#85BB65",
  "Iguana Green":"#9CB071",
  "Acid Green":"#B0BF1A",
  "Avocado Green":"#B2C248",
  "Pistachio Green":"#9DC209",
  "Salad Green":"#A1C935",
  "Yellow Green":"#9ACD32",
  "Pastel Green":"#77DD77",
  "Hummingbird Green":"#7FE817",
  "Nebula Green":"#59E817",
  "Stoplight Go Green":"#57E964",
  "Neon Green":"#16F529",
  "Jade Green":"#5EFB6E",
  "Lime Mint Green":"#36F57F",
  "Spring Green":"#00FF7F",
  "Medium Spring Green":"#00FA9A",
  "Emerald Green":"#5FFB17",
  "Lime":"#00FF00",
  "Lawn Green":"#7CFC00",
  "Bright Green":"#66FF00",
  "Chartreuse":"#7FFF00",
  "Yellow Lawn Green":"#87F717",
  "Aloe Vera Green":"#98F516",
  "Dull Green Yellow":"#B1FB17",
  "Green Yellow":"#ADFF2F",
  "Chameleon Green":"#BDF516",
  "Neon Yellow Green":"#DAEE01",
  "Yellow Green Grosbeak":"#E2F516",
  "Tea Green":"#CCFB5D",
  "Slime Green":"#BCE954",
  "Algae Green":"#64E986",
  "Light Green":"#90EE90",
  "Dragon Green":"#6AFB92",
  "Pale Green":"#98FB98",
  "Mint Green":"#98FF98",
  "Green Thumb":"#B5EAAA",
  "Organic Brown":"#E3F9A6",
  "Light Jade":"#C3FDB8",
  "Light Rose Green":"#DBF9DB",
  "Honey Dew":"#F0FFF0",
  "Mint Cream":"#F5FFFA",
  "Lemon Chiffon":"#FFFACD",
  "Parchment":"#FFFFC2",
  "Cream":"#FFFFCC",
  "Cream White":"#FFFDD0",
  "Light Golden Rod Yellow":"#FAFAD2",
  "Light Yellow":"#FFFFE0",
  "Beige":"#F5F5DC",
  "Cornsilk":"#FFF8DC",
  "Blonde":"#FBF6D9",
  "Champagne":"#F7E7CE",
  "Antique White":"#FAEBD7",
  "Papaya Whip":"#FFEFD5",
  "Blanched Almond":"#FFEBCD",
  "Bisque":"#FFE4C4",
  "Wheat":"#F5DEB3",
  "Moccasin":"#FFE4B5",
  "Peach":"#FFE5B4",
  "Light Orange":"#FED8B1",
  "Peach Puff":"#FFDAB9",
  "Navajo White":"#FFDEAD",
  "Golden Blonde":"#FBE7A1",
  "Golden Silk":"#F3E3C3",
  "Dark Blonde":"#F0E2B6",
  "Light Gold":"#F1E5AC",
  "Vanilla":"#F3E5AB",
  "Tan Brown":"#ECE5B6",
  "Dirty White":"#E8E4C9",
  "Pale Golden Rod":"#EEE8AA",
  "Khaki":"#F0E68C",
  "Cardboard Brown":"#EDDA74",
  "Harvest Gold":"#EDE275",
  "Sun Yellow":"#FFE87C",
  "Corn Yellow":"#FFF380",
  "Pastel Yellow":"#FAF884",
  "Neon Yellow":"#FFFF33",
  "Yellow":"#FFFF00",
  "Canary Yellow":"#FFEF00",
  "Banana Yellow":"#F5E216",
  "Mustard Yellow":"#FFDB58",
  "Golden Yellow":"#FFDF00",
  "Bold Yellow":"#F9DB24",
  "Rubber Ducky Yellow":"#FFD801",
  "Gold":"#FFD700",
  "Bright Gold":"#FDD017",
  "Chrome Gold":"#FFCE44",
  "Golden Brown":"#EAC117",
  "Deep Yellow":"#F6BE00",
  "Macaroni and Cheese":"#F2BB66",
  "Saffron":"#FBB917",
  "Beer":"#FBB117",
  "Yellow Orange":"#FFAE42",
  "Cantaloupe":"#FFA62F",
  "Orange":"#FFA500",
  "Brown Sand":"#EE9A4D",
  "SandyBrown":"#F4A460",
  "Brown Sugar":"#E2A76F",
  "Camel Brown":"#C19A6B",
  "Deer Brown":"#E6BF83",
  "BurlyWood":"#DEB887",
  "Tan":"#D2B48C",
  "Light French Beige":"#C8AD7F",
  "Sand":"#C2B280",
  "Sage":"#BCB88A",
  "Fall Leaf Brown":"#C8B560",
  "Ginger Brown":"#C9BE62",
  "Dark Khaki":"#BDB76B",
  "Olive Green":"#BAB86C",
  "Brass":"#B5A642",
  "Cookie Brown":"#C7A317",
  "Metallic Gold":"#D4AF37",
  "Bee Yellow":"#E9AB17",
  "School Bus Yellow":"#E8A317",
  "Golden Rod":"#DAA520",
  "Orange Gold":"#D4A017",
  "Caramel":"#C68E17",
  "Dark Golden Rod":"#B8860B",
  "Cinnamon":"#C58917",
  "Peru":"#CD853F",
  "Bronze":"#CD7F32",
  "Tiger Orange":"#C88141",
  "Copper":"#B87333",
  "Dark Gold":"#AA6C39",
  "Dark Almond":"#AB784E",
  "Wood":"#966F33",
  "Oak Brown":"#806517",
  "Antique Bronze":"#665D1E",
  "Hazel":"#8E7618",
  "Dark Yellow":"#8B8000",
  "Dark Moccasin":"#827839",
  "Khaki Green":"#8A865D",
  "Bullet Shell":"#AF9B60",
  "Army Brown":"#827B60",
  "Sandstone":"#786D5F",
  "Taupe":"#483C32",
  "Mocha":"#493D26",
  "Milk Chocolate":"#513B1C",
  "Gray Brown":"#3D3635",
  "Dark Coffee":"#3B2F2F",
  "Old Burgundy":"#43302E",
  "Western Charcoal":"#49413F",
  "Bakers Brown":"#5C3317",
  "Dark Brown":"#654321",
  "Sepia Brown":"#704214",
  "Dark Bronze":"#804A00",
  "Coffee":"#6F4E37",
  "Brown Bear":"#835C3B",
  "Red Dirt":"#7F5217",
  "Sepia":"#7F462C",
  "Sienna":"#A0522D",
  "Saddle Brown":"#8B4513",
  "Dark Sienna":"#8A4117",
  "Sangria":"#7E3817",
  "Blood Red":"#7E3517",
  "Chestnut":"#954535",
  "Chestnut Red":"#C34A2C",
  "Mahogany":"#C04000",
  "Red Fox":"#C35817",
  "Dark Bisque":"#B86500",
  "Light Brown":"#B5651D",
  "Petra Gold":"#B76734",
  "Rust":"#C36241",
  "Copper Red":"#CB6D51",
  "Orange Salmon":"#C47451",
  "Chocolate":"#D2691E",
  "Sedona":"#CC6600",
  "Papaya Orange":"#E56717",
  "Halloween Orange":"#E66C2C",
  "Neon Orange":"#FF6700",
  "Bright Orange":"#FF5F1F",
  "Pumpkin Orange":"#F87217",
  "Carrot Orange":"#F88017",
  "DarkOrange":"#FF8C00",
  "Construction Cone Orange":"#F87431",
  "Indian Saffron":"#FF7722",
  "Sunrise Orange":"#E67451",
  "Mango Orange":"#FF8040",
  "Coral":"#FF7F50",
  "Basket Ball Orange":"#F88158",
  "Light Salmon Rose":"#F9966B",
  "Light Salmon":"#FFA07A",
  "Dark Salmon":"#E9967A",
  "Tangerine":"#E78A61",
  "Light Copper":"#DA8A67",
  "Salmon":"#FA8072",
  "Light Coral":"#F08080",
  "Pastel Red":"#F67280",
  "Pink Coral":"#E77471",
  "Bean Red":"#F75D59",
  "Valentine Red":"#E55451",
  "Indian Red":"#CD5C5C",
  "Tomato":"#FF6347",
  "Shocking Orange":"#E55B3C",
  "Orange Red":"#FF4500",
  "Red":"#FF0000",
  "Neon Red":"#FD1C03",
  "Scarlet":"#FF2400",
  "Ruby Red":"#F62217",
  "Ferrari Red":"#F70D1A",
  "Fire Engine Red":"#F62817",
  "Lava Red":"#E42217",
  "Love Red":"#E41B17",
  "Grapefruit":"#DC381F",
  "Cherry Red":"#C24641",
  "Chilli Pepper":"#C11B17",
  "Fire Brick":"#B22222",
  "Tomato Sauce Red":"#B21807",
  "Brown":"#A52A2A",
  "Carbon Red":"#A70D2A",
  "Cranberry":"#9F000F",
  "Saffron Red":"#931314",
  "Crimson Red":"#990000",
  "Wine Red":"#990012",
  "Dark Red":"#8B0000",
  "Maroon":"#800000",
  "Burgundy":"#8C001A",
  "Vermilion":"#7E191B",
  "Deep Red":"#800517",
  "Red Blood":"#660000",
  "Blood Night":"#551606",
  "Dark Scarlet":"#560319",
  "Black Bean":"#3D0C02",
  "Chocolate Brown":"#3F000F",
  "Midnight":"#2B1B17",
  "Purple Lily":"#550A35",
  "Purple Maroon":"#810541",
  "Plum Pie":"#7D0541",
  "Plum Velvet":"#7D0552",
  "Dark Raspberry":"#872657",
  "Velvet Maroon":"#7E354D",
  "Rosy-Finch":"#7F4E52",
  "Dull Purple":"#7F525D",
  "Puce":"#7F5A58",
  "Rose Dust":"#997070",
  "Rosy Pink":"#B38481",
  "Rosy Brown":"#BC8F8F",
  "Khaki Rose":"#C5908E",
  "Lipstick Pink":"#C48793",
  "Pink Brown":"#C48189",
  "Pink Daisy":"#E799A3",
  "Dusty Pink":"#D58A94",
  "Rose":"#E8ADAA",
  "Silver Pink":"#C4AEAD",
  "Rose Gold":"#ECC5C0",
  "Deep Peach":"#FFCBA4",
  "Pastel Orange":"#F8B88B",
  "Desert Sand":"#EDC9AF",
  "Unbleached Silk":"#FFDDCA",
  "Pig Pink":"#FDD7E4",
  "Blush":"#FFE6E8",
  "Misty Rose":"#FFE4E1",
  "Pink Bubble Gum":"#FFDFDD",
  "Light Red":"#FFCCCB",
  "Light Rose":"#FBCFCD",
  "Deep Rose":"#FBBBB9",
  "Pink":"#FFC0CB",
  "Light Pink":"#FFB6C1",
  "Donut Pink":"#FAAFBE",
  "Baby Pink":"#FAAFBA",
  "Flamingo Pink":"#F9A7B0",
  "Pastel Pink":"#FEA3AA",
  "Rose Pink":"#E7A1B0",
  "Cadillac Pink":"#E38AAE",
  "Carnation Pink":"#F778A1",
  "Blush Red":"#E56E94",
  "Pale Violet Red":"#DB7093",
  "Purple Pink":"#D16587",
  "Tulip Pink":"#C25A7C",
  "Bashful Pink":"#C25283",
  "Dark Pink":"#E75480",
  "Dark Hot Pink":"#F660AB",
  "Hot Pink":"#FF69B4",
  "Watermelon Pink":"#FC6C85",
  "Violet Red":"#F6358A",
  "Hot Deep Pink":"#F52887",
  "DeepPink":"#FF1493",
  "Neon Pink":"#F535AA",
  "Neon Hot Pink":"#FD349C",
  "Pink Cupcake":"#E45E9D",
  "Dimorphotheca Magenta":"#E3319D",
  "Pink Lemonade":"#E4287C",
  "Raspberry":"#E30B5D",
  "Crimson":"#DC143C",
  "Bright Maroon":"#C32148",
  "Rose Red":"#C21E56",
  "Rogue Pink":"#C12869",
  "Burnt Pink":"#C12267",
  "Pink Violet":"#CA226B",
  "Medium Violet Red":"#C71585",
  "Dark Carnation Pink":"#C12283",
  "Raspberry Purple":"#B3446C",
  "Pink Plum":"#B93B8F",
  "Orchid":"#DA70D6",
  "Deep Mauve":"#DF73D4",
  "Violet":"#EE82EE",
  "Bright Neon Pink":"#F433FF",
  "Magenta":"#FF00FF",
  "Crimson Purple":"#E238EC",
  "Heliotrope Purple":"#D462FF",
  "Tyrian Purple":"#C45AEC",
  "Medium Orchid":"#BA55D3",
  "Purple Flower":"#A74AC7",
  "Orchid Purple":"#B048B5",
  "Pastel Violet":"#D291BC",
  "Mauve Taupe":"#915F6D",
  "Viola Purple":"#7E587E",
  "Eggplant":"#614051",
  "Plum Purple":"#583759",
  "Grape":"#5E5A80",
  "Purple Navy":"#4E5180",
  "Slate Blue":"#6A5ACD",
  "Blue Lotus":"#6960EC",
  "Light Slate Blue":"#736AFF",
  "Medium Slate Blue":"#7B68EE",
  "Periwinkle Purple":"#7575CF",
  "Very Peri":"#6667AB",
  "Bright Grape":"#6F2DA8",
  "Purple Amethyst":"#6C2DC7",
  "Bright Purple":"#6A0DAD",
  "Deep Periwinkle":"#5453A6",
  "Dark Slate Blue":"#483D8B",
  "Purple Haze":"#4E387E",
  "Purple Iris":"#571B7E",
  "Dark Purple":"#4B0150",
  "Deep Purple":"#36013F",
  "Purple Monster":"#461B7E",
  "Indigo":"#4B0082",
  "Blue Whale":"#342D7E",
  "Rebecca Purple":"#663399",
  "Purple Jam":"#6A287E",
  "Dark Magenta":"#8B008B",
  "Purple":"#800080",
  "French Lilac":"#86608E",
  "Dark Orchid":"#9932CC",
  "Dark Violet":"#9400D3",
  "Purple Violet":"#8D38C9",
  "Jasmine Purple":"#A23BEC",
  "Purple Daffodil":"#B041FF",
  "Clematis Violet":"#842DCE",
  "Blue Violet":"#8A2BE2",
  "Purple Sage Bush":"#7A5DC7",
  "Lovely Purple":"#7F38EC",
  "Neon Purple":"#9D00FF",
  "Purple Plum":"#8E35EF",
  "Aztech Purple":"#893BFF",
  "Lavender Purple":"#967BB6",
  "Medium Purple":"#9370DB",
  "Light Purple":"#8467D7",
  "Crocus Purple":"#9172EC",
  "Purple Mimosa":"#9E7BFF",
  "Periwinkle":"#CCCCFF",
  "Pale Lilac":"#DCD0FF",
  "Mauve":"#E0B0FF",
  "Bright Lilac":"#D891EF",
  "Rich Lilac":"#B666D2",
  "Purple Dragon":"#C38EC7",
  "Lilac":"#C8A2C8",
  "Plum":"#DDA0DD",
  "Blush Pink":"#E6A9EC",
  "Pastel Purple":"#F2A2E8",
  "Blossom Pink":"#F9B7FF",
  "Wisteria Purple":"#C6AEC7",
  "Purple Thistle":"#D2B9D3",
  "Thistle":"#D8BFD8",
  "Periwinkle Pink":"#E9CFEC",
  "Cotton Candy":"#FCDFFF",
  "Lavender Pinocchio":"#EBDDE2",
  "Dark White":"#E1D9D1",
  "Ash White":"#E9E4D4",
  "White Chocolate":"#EDE6D6",
  "Soft Ivory":"#FAF0DD",
  "Off White":"#F8F0E3",
  "Pearl White":"#F8F6F0",
  "Lavender Blush":"#FFF0F5",
  "Pearl":"#FDEEF4",
  "Egg Shell":"#FFF9E3",
  "Old Lace":"#FDF5E6",
  "Linen":"#FAF0E6",
  "Sea Shell":"#FFF5EE",
  "Rice":"#FAF5EF",
  "FloralWhite":"#FFFAF0",
  "Ivory":"#FFFFF0",
  "Light White":"#FFFFF7",
  "White Smoke":"#F5F5F5",
  "Cotton":"#FBFBF9",
  "Snow":"#FFFAFA",
  "Milk White":"#FEFCFF",
  "White":"#FFFFFF"
}