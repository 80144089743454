import UserStore from 'stores/User';

function push(event, params = {}) {
  window.dataLayer?.push({
    event,
    ...params,
    user_id: UserStore.id ?? null
  });
}

export default {
  push
}