import classnames from 'classnames';
import * as Icons from 'icons';
import leo from 'images/testimonials/leo.jpg';
import warren from 'images/testimonials/warren.jpg';
import pavel from 'images/testimonials/pavel.jpg';
// import fara from 'images/testimonials/fara.jpeg';
import olivier from 'images/testimonials/olivier.jpeg';
import aaron from 'images/testimonials/aaron.jpg';
// import dmytro from 'images/testimonials/dmytro.jpeg';
import julien from 'images/testimonials/julien.jpeg';
import dann from 'images/testimonials/dann.jpeg';
// import natasha from 'images/testimonials/natasha.jpeg';
import abhishek from 'images/testimonials/abhishek.jpeg';
import josie from 'images/testimonials/josie.jpeg';
import anton from 'images/testimonials/anton.jpeg';
import styles from './Testimonials.module.scss';

const ITEMS = [
  {
    title: "Exploring its curated recommendations is like being a kid in a candy store, but for design ideas. Smart, intuitive, and playful – it feels like the platform 'gets' my creative whims.",
    text: "",
    name: "Josie Perez",
    role: "Mobile UI Designer",
    photo: josie,
    link: "https://www.producthunt.com/products/refero/reviews?review=708183"
  },
  {
    title: "Been using refero.design for my last web Project and LOVE its ability to filter components and website categories!",
    text: "",
    name: "Dann Leal",
    role: "Designing stuff for the web",
    photo: dann,
    link: "https://twitter.com/dannlealweb/status/1723311865110188374"
  },
  {
    title: "Really cool idea! As a designer, I constantly want to run benchmarks and it takes ages to create accounts, get some data in etc. This is going to be super useful.",
    text: "",
    name: "Julien Zmiro",
    role: "Design at AskMore.ai",
    photo: julien,
    link: "https://www.producthunt.com/products/refero/reviews?review=660547"
  },
  // {
  //   title: "Excelente, encuentro todo lo que necesito 💅💖✨",
  //   text: "",
  //   name: "Natasha Melgarejo",
  //   role: "Product Designer at @costavisions 🫖✨",
  //   photo: natasha,
  //   link: "https://www.producthunt.com/products/refero/reviews"
  // },
  {
    title: "Love the filter functionality the most — very helpful and easy to navigate and be inspired! Appreciate the tool; some great UI references from crazy websites!",
    text: "",
    name: "Abhishek Gharat",
    role: "Founder & Leadership at KraftPixel",
    photo: abhishek,
    link: "https://www.producthunt.com/products/refero/reviews?review=660820"
  },
  {
    title: "This looks amazing! Congrats with a launch! I clearly remember how many times I was thinking \"hm, what UI-pattern for this feature does Google/Figma/Miro use\". This could save a lot of time for that kind of research 🔥",
    text: "",
    name: "Anton Sutarmin",
    role: "Co-founder & CTO at Manymap",
    photo: anton,
    link: "https://www.producthunt.com/products/refero/reviews?review=660660"
  },
  {
    title: "💙 Reco du mardi: Pour tous les passionnés de design UX/UI, je vous partage un site pour vous inspirer!",
    text: "🚀 S’inspirer sur Dribble c’est bien pour la créativité, mais s’inspirer sur “Refero” c’est encore mieux pour la réalité d’une application. 😉",
    name: "Léo Guillon",
    role: "UX/UI Designer",
    photo: leo,
    link: "https://twitter.com/leogln/status/1640606149308186625"
  },
  {
    title: "What a fantastic idea and easy to use reference.",
    text: "I love anything to do with design and it's all captured so well on this site.",
    name: "Warren Cheetham",
    role: "Founder at Headlesshost",
    photo: warren,
    link: "https://www.producthunt.com/products/refero/reviews?review=589468"
  },
  {
    title: "It was kind of a treasure when I found the refero.design!",
    text: "There are a lot of useful cases and interfaces which I use as reference on my design challenges :)",
    name: "Pavel Asman",
    role: "UX/UI designer at Humbleteam",
    photo: pavel,
    link: null
  },
  {
    title: "Just spent 1h on @referodesign and I love everything about it. Whoever you are 🤜🤛",
    text: "",
    name: "Olivier.txt",
    role: "",
    photo: olivier,
    link: "https://twitter.com/olimermet/status/1638865858746363905"
  },
  {
    title: "My inspiration is mainly from different examples from refero design, it's an excellent platform to find cool UI designs",
    text: "",
    name: "Aaron-Pascal Sayar",
    role: "Designing cool Webflow websites",
    photo: aaron,
    link: "https://twitter.com/apsayar/status/1647627431359004673"
  },
  // {
  //   title: "Cool filters, love it",
  //   text: "",
  //   name: "Dmytro Shvydkyi",
  //   role: "Product Designer",
  //   photo: dmytro,
  //   link: "https://www.producthunt.com/products/refero/reviews"
  // },
  // {
  //   title: "Design Tool of the Day - @referodesign Curated design references to find inspiration for your next project",
  //   text: "",
  //   name: "Farah Hariri",
  //   role: "Designer",
  //   photo: fara,
  //   link: "https://twitter.com/farrahhariri/status/1636394032736923648"
  // }
].sort(shuffle);

function shuffle() {
  return (Math.random() > .5) ? 1 : -1;
}

export default function Testimonials() {
  return (
    <div className={styles.container}>
      <div className={styles.scroll}>
        <div className={styles.body}>
          {[...ITEMS, ...ITEMS].map((item, index)=> {
            const Tag = item.link ? 'a' : `div`;

            return (
              <Tag 
                href={item.link} 
                className={classnames(styles.item, {
                  [styles.mobileHidden]: index < ITEMS.length
                })}
                target="_blank" 
                rel="noreferrer"
                key={index}
              >
                {item.link ? 
                  <div className={styles.arr}>
                    <Icons.ArrowNE />
                  </div>
                : null}
                <div className={styles.header}>
                  <div className={styles.lquote} />
                  <div className={styles.title}>
                    {item.title}
                  </div>
                  <div className={styles.text}>
                    {item.text}
                  </div>
                  <div className={styles.rquote} />
                </div>
                <div>
                  <img 
                    className={styles.photo} 
                    src={item.photo} 
                    alt={item.name} 
                    loading="lazy" 
                  />
                  <div className={styles.name}>
                    {item.name}
                  </div>
                  <div className={styles.role}>
                    {item.role}
                  </div>
                </div>
              </Tag>
            )
          })}
        </div>
      </div>
    </div>
  );
}
