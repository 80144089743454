import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import mixpanel from 'api/mixpanel';
import * as Icons from 'icons';
import Button from 'components/Button/Button';
import Layout from 'components/Layout/Layout';
import styles from './HowItWorks.module.scss';
import { Link } from 'react-router-dom';

const FEATURES = [
  {
    title: 'Searching by tag',
    text: 'Click on the search box at the top of any Refero page to see finely curated tagging system with categories like Page Types, UX Patterns, and UI Elements, you can drill down to the tiniest detail in your design quest.',
    videoUrl: 'https://images.refero.design/videos/onboarding/step-1040-264-1.mp4',
    posterUrl: 'https://images.refero.design/videos/onboarding/step1.jpg'
  },
  {
    title: 'Combine Tags',
    text: 'You can combine several tags together, for example, to find screenshots of product pages that include illustrations, you can add both tags to the "Product Page & Landing" and "Illustration" search box.',
    videoUrl: 'https://images.refero.design/videos/onboarding/step-1040-264-2.mp4',
    posterUrl: 'https://images.refero.design/videos/onboarding/step2.jpg'
  },
  {
    title: 'Searching by Text Content',
    text: 'If you\'re looking for something that isn\'t tagged, you can also search for text content on a page. To do this, simply type the text you\'re looking for into the search box. For example, if you\'re looking for screenshots of the Airbnb log In page, you could search for "Airbnb&nbsp;login".',
    videoUrl: 'https://images.refero.design/videos/onboarding/step-1040-264-3.mp4',
    posterUrl: 'https://images.refero.design/videos/onboarding/step3.jpg'
  },
  {
    title: 'Searching by Site',
    text: 'You can also search for screenshots from a specific site. To do this, simply enter the site\'s address or name into the search box. For example, if you want to find screenshots from the Notion website, you could search for "notion".',
    videoUrl: 'https://images.refero.design/videos/onboarding/step-1040-264-4.mp4',
    posterUrl: 'https://images.refero.design/videos/onboarding/step4.jpg'
  },
  {
    title: 'Switch between Web and iOS',
    text: 'With a simple click on the toggle, you can switch between platforms seamlessly. Most of our tags overlap across platforms, so just toggle your search results to see design marvels from both platforms.',
    videoUrl: 'https://images.refero.design/videos/onboarding/step-1040-264-5.mp4',
    posterUrl: 'https://images.refero.design/videos/onboarding/step5.jpg'
  },
  {
    title: 'Start Exploring',
    text: 'Now that you\'re well-equipped with Refero\'s fundamental features, you\'re ready to dive deep into our comprehensive design library.',
    videoUrl: null,
    posterUrl: null,
    start: true
  }
]

export default function HowItWorks() {

  useEffect(() => {
    mixpanel.track('HowItWorksPageVisit');
  }, []);

  return (
    <>
      <Helmet>
        <title>Refero — How it works</title>
        <link rel="canonical" href={`https://refero.design/how-it-works`} />
        <meta property="og:url" content={`https://refero.design/how-it-works`} />
        <meta property="og:title" content={`Refero — How it works`} />
        <meta name="twitter:title" content={`Refero — How it works`} />
        <meta property="og:site_name" content={`Refero — How it works`} />
      </Helmet>

      <Layout>
        <div className={styles.container}>
          <h1 className={styles.title}>
            How it works
          </h1>

          <div className={styles.text}>
            With Refero, design inspiration is&nbsp;no&nbsp;more than a&nbsp;click away. Our extensive collection of&nbsp;top-notch web and iOS app designs is&nbsp;as&nbsp;easy to&nbsp;navigate as&nbsp;it&nbsp;is&nbsp;inspiring.
          </div>

          <div className={styles.features}>
            {FEATURES.map(feature =>
              <Feature 
                title={feature.title}
                text={feature.text}
                videoUrl={feature.videoUrl}
                posterUrl={feature.posterUrl}
                start={feature.start}
                key={feature.videoUrl}
              />
            )}
          </div>

        </div>
      </Layout>
    </>
  );
}

function Feature({ title, text, videoUrl, posterUrl, start = false }) {

  const player = useRef(null);
  const [ playing, setPlaying ] = useState(false);

  async function onMouseEnter() {
    try {
      await player.current?.play();
    } catch (err) {
      console.log('play err', err);
    }
  }

  async function onMouseLeave() {
    try {
      await player.current?.pause();
    } catch (err) {
      console.log('pause err', err);
    }
  }

  async function onClick() {
    try {
      await player.current?.play();
    } catch (err) {
      console.log('play err', err);
    } 
  }

  return (
    <div className={styles.feature}>
      {!start ?
        <div 
          className={classnames(styles.player, {
            [styles.paused]: !playing
          })} 
          onMouseEnter={onMouseEnter} 
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        >
          <video 
            className={styles.video}
            playsInline 
            muted 
            loop
            preload="metadata"
            poster={posterUrl}
            ref={player}
            onPlay={event => setPlaying(true)}
            // onStop={event => setPlaying(false)}
            onPause={event => setPlaying(false)}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          <div className={styles.play}>
            <Icons.Play />
          </div>
        </div>
      : 
        <div className={styles.collage}>

        </div>
      }

      <h3 className={styles.featureTitle}>
        {title}
      </h3>
      <div 
        className={styles.desc} 
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {start ?
        <Link to="/search">
          <Button square blockMobile>
            Explore Refero
          </Button>
        </Link>
      : null}
    </div>
  )
}