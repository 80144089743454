export default function PlanTeam(props) {
  return (
    <svg
      width={62}
      height={17}
      fill="none"
      viewBox="0 0 62 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3.2 17 5.566 3.61H.756l.52-2.99h12.74l-.52 2.99h-4.81L6.32 17H3.2Zm9.998 0L16.084.62h11.31l-.52 2.99h-8.19l-.624 3.51h7.54l-.52 2.99h-7.54l-.702 3.9h8.45l-.52 2.99h-11.57Zm12.504 0L34.698.62h2.86L40.782 17h-3.12l-.572-3.38h-6.24L29.082 17h-3.38Zm6.682-6.24h4.16L35.53 4.78l-3.146 5.98Zm9.81 6.24L45.08.62h2.6l3.458 12.09L58.86.62h2.86L58.834 17h-3.12l1.612-9.1-5.772 9.1h-2.08l-2.548-9.1-1.612 9.1h-3.12Z"
      />
    </svg>
  );
}
