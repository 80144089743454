const TIME_ZONES = [
  'asia/calcutta',
  'asia/kolkata',
  'asia/colombo'
];

const timeZome = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone?.toLowerCase();

export default function isIndianUser() {
  // return false;
  // console.log('timeZome', timeZome);
  return TIME_ZONES?.includes(timeZome);
}
