import { makeAutoObservable, runInAction } from 'mobx';
import ReferoApi from 'api/refero_api';
import App from './App';
import AppScreenshotsStore from './AppScreenshots';
import AlertStore from './Alert';

class Apps {

  store = {};
  lists = {};
  fetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  async show(id) {
    try {
      if (this.store[id] && this.store[id]?.hasAllFields()) {
        return;
      }

      const app = await ReferoApi.app(id);
      runInAction(() => {
        // this.store[id] = new App(app);
        this.add(app);
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  add(app) {
    const old = this.store[app.id];

    if (!old) {
      this.store[app.id] = new App(app);
    } else {
      this.store[app.id] = new App({
        ...this.store[app.id], 
        ...app,
        featured_screenshots: old.featuredScreenshots ?? app.featured_screenshots
      });
    }
  }

  find(id) {
    return this.store[id] ?? null;
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  populate(id) {
    this.find(id)?.populate();
  }

  async search(params, ignoreCache = false, withPagination = false) {
    try {
      if (params === this.searchKey && !ignoreCache) {
        return;
      }

      if (this.fetching) {
        return;
      }
      
      this.setFetching(true);
      this.searchKey = params ?? 'root';

      if (withPagination) {
        const { next = null, pages, per_page, count, current } = AppScreenshotsStore.pagination(params);
        const screenLeft = count - (current * per_page);
        const screenNumber = Math.min(screenLeft, per_page) ?? 24;

        if (!next || pages === 0) {
          this.setFetching(false);
          return;
        }
        params += `&page=${next}`;

        if (screenNumber > 0) {
          AppScreenshotsStore.addPlaceholders(this.searchKey, screenNumber);
        }
      } else {
        AppScreenshotsStore.addPlaceholders(this.searchKey);
      }

      const { records, pagination } = await ReferoApi.searchApps(params);
      AppScreenshotsStore.add(records, this.searchKey, null, pagination);
      // UserStore.setLeftQueries(left_queries);

      runInAction(() => {
        records?.forEach(({ app }) => {
          this.add(app);
        });
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    } 
  }

  trySearchNext(params) {
    console.warn('run Apps trySearchNext');
    if (this.fetching) {
      return;
    }
    const { next = null } = AppScreenshotsStore.pagination(params);

    if (!next) {
      return;
    }

    this.search(params, true, true);
  }

}

const AppsStore = new Apps();
export default AppsStore;
