export default function Google(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.001 16.311c0-1.15-.095-1.99-.301-2.862H16.287v5.196h7.873c-.159 1.29-1.016 3.235-2.92 4.542l-.027.174 4.24 3.22.294.028c2.699-2.442 4.254-6.035 4.254-10.298Z"
        fill="#4285F4"
      />
      <path
        d="M16.285 30c3.857 0 7.095-1.244 9.46-3.39l-4.507-3.423c-1.207.825-2.826 1.4-4.953 1.4a8.584 8.584 0 0 1-8.127-5.817l-.167.014-4.41 3.344-.058.157c2.35 4.573 7.175 7.716 12.762 7.716Z"
        fill="#34A853"
      />
      <path
        d="M8.16 18.77A8.463 8.463 0 0 1 7.685 16c0-.965.175-1.898.46-2.769l-.007-.185-4.465-3.399-.146.069A13.785 13.785 0 0 0 2.002 16c0 2.256.556 4.387 1.524 6.284L8.16 18.77Z"
        fill="#FBBC05"
      />
      <path
        d="M16.285 7.413c2.683 0 4.492 1.136 5.524 2.085l4.032-3.858C23.365 3.384 20.142 2 16.285 2 10.698 2 5.873 5.142 3.523 9.715l4.62 3.516c1.158-3.375 4.365-5.818 8.142-5.818Z"
        fill="#EB4335"
      />
    </svg>
  )
}