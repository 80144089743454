import { useRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import GmailConnect from 'components/GmailConnect/GmailConnect';
import EmailSignup from 'components/EmailSignup/EmailSignup';
import * as Icons from 'icons';
import styles from './SignupForm.module.scss';

export default function SignupForm({ noClooseButton = false, className, onClose }) {

  const [ email, setEmail ] = useState('');
  const emailForm = useRef(null);

  function emailChangeCallback(email) {
    setEmail(email);
  }

  return (
    <div className={classnames(styles.body, className)}>
      <div className={styles.header}>
      {!email ?
        <div className={styles.iconBlast} />
      :
        <div 
          className={styles.close} 
          onClick={event => emailForm.current.clearEmail()}
        >
          <Icons.ArrowLeft2 width="14" />
        </div>
      }

        <div className={styles.title}>
          {!email ?
            'Log In or Sign Up'
          :
            'Check Your Inbox'
          }
        </div>

        {!noClooseButton ? 
          <div 
            className={styles.close} 
            // onClick={event => UIStore.hideSignupPopup()}
            onClick={onClose}
          >
            <Icons.Close />
          </div>
        : <div className={styles.iconBlast} />}
      </div>

      <div>
        {!email ?
          <>
            <GmailConnect>
              Continue with Google  
            </GmailConnect>

            <div className={styles.hr}>
              <div className={styles.hrLabel}>or</div>
            </div>
          </>
        : null}

        <EmailSignup 
          ref={emailForm}
          emailChangeCallback={emailChangeCallback}
        />
        
        {!email ?
          <div className={styles.agreements}>
            By loging in you are agree with our <Link to="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</Link>&nbsp;and&nbsp;<Link to="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</Link>
          </div>
        : null}
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          Full access
        </li>

        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          No card required
        </li>

        <li className={styles.listItem}>
          <div className={styles.listIconWrap}>
            <Icons.Check width="18" className={styles.listIcon} />
          </div>
          3 days free trial
        </li>
      </ul>
    </div>
  )
}
