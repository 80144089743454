export default function Search(props) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.71 19.291 17 15.611a9 9 0 1 0-1.39 1.39l3.68 3.68a1.002 1.002 0 0 0 1.42 0 1 1 0 0 0 0-1.39ZM10 17.001a7 7 0 1 1 0-14 7 7 0 0 1 0 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
