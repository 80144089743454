export default function PlanFree(props) {
  return (
    <svg
      width={57}
      height={17}
      fill="none"
      viewBox="0 0 57 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.69 17 3.576.62h10.92l-.52 2.99h-7.8l-.728 4.03h6.37l-.52 2.99h-6.37L3.81 17H.69Zm13.254 0L16.83.62h6.526c2.756 0 4.602 2.314 4.602 4.68 0 2.184-1.118 3.562-2.21 4.316-.65.442-1.378.78-2.184 1.014l2.73 6.37h-3.51l-2.314-5.98h-2.34L17.064 17h-3.12Zm4.706-8.97h3.614c1.352 0 2.548-1.066 2.548-2.34 0-1.04-.806-2.08-1.976-2.08H19.43l-.78 4.42ZM28.493 17 31.379.62h11.31l-.52 2.99h-8.19l-.624 3.51h7.54l-.52 2.99h-7.54l-.702 3.9h8.45l-.52 2.99h-11.57Zm14.168 0L45.547.62h11.31l-.52 2.99h-8.19l-.624 3.51h7.54l-.52 2.99h-7.54l-.702 3.9h8.45L54.23 17H42.66Z"
      />
    </svg>
  );
}
