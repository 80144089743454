export default function Lock4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.6}
        d="M9 11.5V7a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v0"
      />
      <rect
        width={12.4}
        height={8.4}
        x={5.8}
        y={11.8}
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.6}
        rx={1.2}
      />
    </svg>
  )
}

