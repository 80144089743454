import card1 from 'images/cards/screen-11.jpeg';
import card12 from 'images/cards/screen-12.jpeg';
import card13 from 'images/cards/screen-13.jpeg';
import card21 from 'images/cards/screen-21.jpeg';
import card22 from 'images/cards/screen-22.jpeg';
import card23 from 'images/cards/screen-23.jpeg';
import card31 from 'images/cards/screen-31.jpeg';
import card32 from 'images/cards/screen-32.jpeg';
import card33 from 'images/cards/screen-33.jpeg';
import card41 from 'images/cards/screen-41.jpeg';
import card42 from 'images/cards/screen-42.jpeg';
import card43 from 'images/cards/screen-43.jpeg';
import card51 from 'images/cards/screen-51.jpeg';
import card52 from 'images/cards/screen-52.jpeg';
import card53 from 'images/cards/screen-53.jpeg';
import card61 from 'images/cards/screen-61.jpeg';
import card62 from 'images/cards/screen-62.jpeg';
import card63 from 'images/cards/screen-63.jpeg';

const APP_PAGE_TYPES_LIST = [
  {
    name: 'Welcome Screens',
    id: 4,
    images: [card1, card12, card13],
    enlarged: false,
    apps: true
  },
  {
    name: 'Log In Screens',
    id: 18,
    images: [card21, card22, card23],
    enlarged: false,
    apps: true
  },
  {
    name: 'Paywal & Subscription',
    id: 13,
    images: [card31, card32, card33],
    enlarged: false,
    apps: true
  },
  
  {
    name: 'Walkthrough Screens',
    id: 22,
    images: [card41, card42, card43],
    enlarged: false,
    apps: true
  },
  {
    name: 'Launch Screens',
    id: 3,
    images: [card51, card52, card53],
    enlarged: false,
    apps: true
  },
  {
    name: 'Widgets',
    id: 17,
    images: [card61, card62, card63],
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Quiz & Poll',
    id: 31,
    enlarged: false,
    apps: true
  },
  {
    name: 'Account Setup',
    id: 24,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Signup Page',
    id: 19,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Carts & Bags',
    id: 10,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'User Profile & Account',
    id: 15,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Guided Tour',
    id: 21,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Settings',
    id: 16,
    enlarged: false,
    compact: true,
    apps: true
  },
  {
    name: 'Product Details',
    id: 47,
    enlarged: false,
    compact: true,
    apps: true
  }
];

export default APP_PAGE_TYPES_LIST;