import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'refero'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5ZcENtaEJBQUFDVUFWaHJG.77-9b--_vTwM77-977-977-9Jz9NCu-_ve-_vRTvv71aFQHvv71PHzpF77-9Q38_77-9Zu-_vVg',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    // {
    //   type: 'privacy',
    //   path: '/privacy-policy',
    // },
    // {
    //   type: 'terms',
    //   path: '/terms-of-use',
    // }
  ]
});
