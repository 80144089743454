import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserStore from 'stores/User';
import referoApi from 'api/refero_api';
import mixpanel from 'api/mixpanel';
import styles from './PastDueBanner.module.scss';
import AlertStore from 'stores/Alert';

const PastDueBanner = observer(() => {
  if (UserStore?.subscription?.status !== 'past_due') {
    return null;
  }

  return <BannerBody />
});

export default PastDueBanner;

function BannerBody() {

  useEffect(() => {
    mixpanel.track('PastDueBannerRender');
  }, []);

  const [ fetching, setFetching ] = useState(false);

  async function onClick() {
    let url = 'https://referodesign.lemonsqueezy.com/billing';
    setFetching(true);
    mixpanel.track('PastDueBannerClick');

    try { 
      const { customer_portal_url } = await referoApi.customerPortal();
      if (customer_portal_url) {
        url = customer_portal_url;
      }
    } catch (err) {
      AlertStore.add(err.message);
    }

    window.location.href = url;  
    // setFetching(false);
  }

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.body}>
        {!fetching ?
          // 'Oops! We couldn\'t process your Pro charge. Click here to update your payment info'
          'Oops! We were unable to process the payment for your Pro subscription. Click here to update your payment method'
        : 
          'You will be redirected shortly'
        }
      </div>
    </div>
  );
}

