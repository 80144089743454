import { makeAutoObservable, runInAction } from 'mobx';
import ReferoApi from 'api/refero_api';
// import feedJsonPage1 from 'json/app-feed.json';
import AlertStore from './Alert';
import AppsStore from './Apps';

class AppFeed {

  list = [];
  listRandom = [];
  fetching = false;
  pagination = null;
  feedLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch() {
    try {
      if (this.fetching) {
        return;
      }

      const page = this.getNextPage();

      if (page === null) {
        this.feedLoaded = true;
        return;
      }

      if (page > 1) {
        this.list = [...this.list, ...this.generatePlaceholders() ];
      }

      this.setFetching(true);
      const { records, pagination } = page === 1 ? await this.firstPage() : await ReferoApi.appsAvailable(page);

      runInAction(() => {
        this.pagination = pagination;

        let lastIndex = this.list?.length - 1;
        while(lastIndex >= 0) {
          if (this.list[lastIndex].startsWith?.('placeholder_')) {
            this.list.splice(lastIndex,1);
          }
          lastIndex--;
        }
        records?.forEach(app => {
          const index = this.list.indexOf(app.id);
          if (index === -1) {
            this.list.push(app.id);
            AppsStore.add(app);
          }
        });
      });

      this.setFetching(false);
      return page;
    } catch (err) {
      this.setFetching(false);
      AlertStore.add(err.message);
    }
  }

  async firstPage() {
    try {
      return (await fetch('/json/feed/apps.json')).json();
    } catch (err) {
      return null;
    }
  }

  getNextPage() {
    if (this.pagination?.next === null) {
      return null;
    }

    return this.pagination?.next ?? 1;
  }

  // async random() {
  //   try {
  //     if (this.fetching) {
  //       return;
  //     }

  //     this.setFetching(true);
  //    // this.listRandom = [...this.generatePlaceholders()];
  //     this.listRandom = this.generatePlaceholders();
  //     const { sites } = await ReferoApi.sitesRandom();

  //     runInAction(() => {
  //       this.listRandom = [];

  //       sites?.forEach(site => {
  //         this.listRandom.push(site.id);
  //         AppsStore.add(site);
  //       });
  //     });

  //     this.setFetching(false);

  //   } catch (err) {
  //     this.setFetching(false);
  //     AlertStore.add(err.message);
  //   }
  // }

  setFetching(state = false) {
    this.fetching = state;
  }

  populate(id) {
    this.find(id)?.populate();
  }

  generatePlaceholders() {
    const screenLeft = this.pagination.count - (this.pagination.current * this.pagination.per_page);
    const screenNumber = Math.min(screenLeft, this.pagination.per_page) ?? 24;
    return Array.from(Array(screenNumber)).map(() => `placeholder_${Math.random().toString(36)}`);
    // return Array.from(Array(12)).map(() => `placeholder_${Math.random().toString(36)}`);
  }

}

const AppFeedStore = new AppFeed();
export default AppFeedStore;
