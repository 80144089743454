import classnames from 'classnames';
import styles from './TabsSlider.module.scss';
import { Link } from 'react-router-dom';

function TabsSlider({ children, className, activeIndex, wide, noBg, noBgMobile, compact, inline, pricing, defaultCursor, withHover, fixedWidthMobile }) {

  function getPosition(index) {
    return 100 * Math.max(index, 0);
  }

  return (
    <div className={classnames(styles.tabs, className, {
      [styles.wide]: wide,
      [styles.noBg]: noBg,
      [styles.noBgMobile]: noBgMobile,
      [styles.compact]: compact,
      [styles.inline]: inline,
      [styles.pricing]: pricing,
      [styles.defaultCursor]: defaultCursor,
      [styles.withHover]: withHover,
      [styles.fixedWidthMobile]: fixedWidthMobile,
    })}>
      <div className={styles.body}>
        <div 
          className={classnames(styles.tabsBg)} 
          style={{ 
            width: `${100 / (children?.length ?? 1)}%`,
            transform: `translateX(${getPosition(activeIndex)}%)`
            // left: `${100 / (children?.length ?? 1) * activeIndex}%`
          }}
        />
        {children}
      </div>
    </div>
  );
};

function Item({ children, onClick, active, to, state, className, note }) {
  if (to || to == "") {
    return (
      <Link 
        to={to}
        onClick={onClick}
        className={classnames(styles.tab, className, {
          [styles.active]: active
        })} 
        state={state}
      >
        {children}
        {note ? <div className={styles.note}>
          {note}
        </div> : null}
      </Link>
    )
  }

  return (
    <div 
      className={classnames(styles.tab, {
        [styles.active]: active
      })} 
      onClick={onClick}
    >
      <div className={styles.label}>
        {children}
      </div>
      {note ? <div className={styles.note}>
        {note}
      </div> : null}
    </div>
  )
}

function Icon({ children, className }) {
  return (
    <div className={classnames(styles.icon, className)}>
      {children}
    </div>
  )
}

function Postfix({ children }) {
  return (
    <div className={classnames(styles.postfix)}>
      {children}
    </div>
  )
}

TabsSlider.Item = Item;
TabsSlider.Icon = Icon;
TabsSlider.Postfix = Postfix;

export default TabsSlider;