import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';
import styles from './NotFound.module.scss';

export default function NotFound() {
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.title}>
          404 Error
        </div>
        <Title priority="extra" tag="h1">
          Looks like you’re lost
        </Title>
      </div>
    </Layout>
  );
}
