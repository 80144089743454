import classnames from 'classnames';
import styles from './Placeholder.module.scss';

function Placeholder({ name, disableAnimation }) {
  return (
    <div
      className={classnames(styles.container, {
        [styles.preview]: name === 'preview',
        [styles.sitePic]: name === 'sitePic',
        [styles.siteTitle]: name === 'siteTitle',
        [styles.siteDesc]: name === 'siteDesc',
        [styles.screenPreviewBody]: name === 'screenPreviewBody',
        [styles.sitePreview3]: name === 'sitePreview3',
        [styles.sitePreview3Compact]: name === 'sitePreview3Compact',
        [styles.desc]: name === 'desc',
        [styles.priceTitle]: name === 'priceTitle',
        [styles.pricePeriod]: name === 'pricePeriod',
        [styles.sitePicLarge]: name === 'sitePicLarge',
        [styles.previewName]: name === 'previewName',
        [styles.previewDesc]: name === 'previewDesc',
        [styles.disableAnimation]: disableAnimation,
        [styles.folder]: name === 'folder',
        [styles.folderPreview]: name === 'folderPreview',
        [styles.newPrice]: name === 'newPrice',
        [styles.newPricePeriod]: name === 'newPricePeriod',
      })}
    />
  )
}

function SitePreview({ children }) {
  return (
    <div className={styles.sitePreview}>
      <div className={styles.sitePreviewHead}>
        <Placeholder name="sitePic" />
        <div className={styles.sitePreviewTitles}>
          <Placeholder name="siteTitle" />
          <Placeholder name="siteDesc" />
        </div>
      </div>
      
      <div className={styles.sitePreviewBody}>
        {children}
      </div>
    </div>
  )
}

function Preview({ withSiteName, search }) {
  return (
    <div className={classnames(styles.screenPreview, {
      [styles.withSiteName]: withSiteName,
      [styles.search]: search
    })}>
      <Placeholder name="screenPreviewBody" />
    </div>  
  )
}

function PreviewApp({ withSiteName, search }) {
  return (
    <div className={classnames(styles.screenPreviewApp, {
      [styles.withSiteName]: withSiteName,
      [styles.search]: search
    })}>
      <Placeholder name="screenPreviewBody" />
    </div>  
  )
}

Placeholder.SitePreview = SitePreview;
Placeholder.Preview = Preview;
Placeholder.PreviewApp = PreviewApp;

export default Placeholder;