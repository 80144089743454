import classnames from 'classnames';
import { Link } from 'react-router-dom';
import mixpanel from 'api/mixpanel';
import Placeholder from 'components/Placeholder/Placeholder';
import styles from './SiteHead.module.scss';

export default function SiteHead({
  large = false, 
  noMargin, 
  site, 
  app, 
  tag = 'div', 
  inline, 
  bubble, 
  className, 
  mobileCentered, 
  noDesc, 
  card, 
  source, 
  doNotTrack
}) {
  const TitleTag = tag;

  function onClick() {
    if (doNotTrack) {
      return;
    }
    mixpanel.track('SiteHeadClick', {domain: site?.domain, source, bubble});
  }

  function getUrl() {
    if (app) {
      return `/apps/${app.id}`;
    }

    if (!site?.id || !site?.domain) {
      return '';
    }
    return `/${site?.id}-${site?.domain}`;
  }

  function getName() {
    if (!app) {
      return site?.name ? site?.name : site?.domain;
    }
    return app?.name ?? ' ';
  }

  function getDesc() {
    return site?.description ?? app?.description ?? <Placeholder name="desc" />
  }

  return (
    <Link 
      to={getUrl()}
      className={classnames(styles.container, className, {
        [styles.large]: large,
        [styles.noMargin]: noMargin,
        [styles.inline]: inline,
        [styles.bubble]: bubble,
        [styles.mobileCentered]: mobileCentered,
        [styles.card]: card,
        [styles.placeholder]: !app && !site
      })}
      title={`${site?.name ? site.name : site?.domain} ${site?.description ? '- ' + site?.description : ''}`}
      onClick={onClick}
      tabIndex="-1"
    >
      <div className={classnames(styles.iconWrapper, {
        [styles.iconWrapperApp]: app
      })}>
        {(site?.domain || site?.faviconUrl) && 
          <img 
            className={styles.icon}
            src={site?.faviconUrl ?? `https://www.google.com/s2/favicons?domain=${site?.domain}&sz=256`} 
            async
            loading="lazy"
            alt={site?.name ? site.name : site?.domain}
          />
        }
        
      </div>
      <div className={styles.titleWrapper}>
        <TitleTag className={styles.title}>
          {getName()}
        </TitleTag>
        {!noDesc && 
          <div className={styles.desc}>
            {getDesc()}
          </div>
        }
      </div>
    </Link>
  );
}
