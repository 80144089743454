import { observer } from 'mobx-react-lite';
import * as Icons from 'icons';
// import TabsSlider from 'components/TabsSlider/TabsSlider';
// import PricingStore from 'stores/Pricing';
import styles from './CompareTable.module.scss';
// import PricePerPeriod from 'components/PricePerPeriod/PricePerPeriod';
// import PricePerMonth from 'components/PricePerMonth/PricePerMonth';

const CompareTable = observer(() => {

  // function getPeriodName() {
  //   const tab = PricingStore.getActiveTab();

  //   if (tab?.interval === 'year') {
  //     return 'annually';
  //   }

  //   if (tab?.interval === 'month' && tab?.interval_count === 3) {
  //     return 'quarterly';
  //     // return 'every 3 months';
  //   }

  //   return 'monthly';
  // }

  function renderRow(cell1, cell2, cell3, cell4) {
    return (
      <div className={styles.row}>
        <div className={styles.cell}>
          {cell1}
        </div>
        <div className={styles.cell}>
          <div className={styles.mobileLabel}>
            <div className={styles.type}>
              {/* <div className={styles.iconWrap}>
                <Icons.Planet width="24" className={styles.typeIcon} />
              </div> */}
              Free Plan
            </div>
          </div>
         {cell2}
        </div>
        <div className={styles.cell}>
          <div className={styles.mobileLabel}>
            <div className={styles.type}>
              {/* <div className={styles.iconWrap}>
                <Icons.Pro width="24" className={styles.typeIcon} />
              </div> */}
              Pro
            </div>
          </div>
          {cell3}
        </div>

        <div className={styles.cell}>
          <div className={styles.mobileLabel}>
            <div className={styles.type}>
              {/* <div className={styles.iconWrap}>
                <Icons.Pro width="24" className={styles.typeIcon} />
              </div> */}
              Team
            </div>
          </div>
          {cell4}
        </div>
        
      </div>
    )
  }


  return (
    <div className={styles.container}>
      
      <div className={styles.table}>
        <div className={styles.head}>
          <div className={styles.row}>
            <div className={styles.cell}>
              <h3 className={styles.title}>
                Compare Plans
              </h3>

              {/* <TabsSlider 
                wide
                activeIndex={PricingStore.activeTab}
                className={styles.tabs}
              >
                {PricingStore.tabs.map((tab, index) => 
                  <TabsSlider.Item 
                    onClick={event => PricingStore.setTab(index)}
                    active={index === PricingStore.activeTab}
                    key={tab.name}
                  >
                    {tab.name}
                  </TabsSlider.Item>
                )}
              </TabsSlider>    */}

            </div>
            <div className={styles.cell}>
              <div className={styles.type}>
                <div className={styles.iconWrap}>
                  <Icons.PlanFree className={styles.typeIcon} />
                </div>
                {/* Free Plan */}
              </div>

              {/* <div className={styles.price}>
                <PricePerMonth price={webPlan?.price} plan={webPlan} withoutPrefix />/month
              </div> */}

              {/* <div className={styles.period}>
                <PricePerPeriod price={webPlan?.price} /> billed {getPeriodName()} 
              </div> */}
            </div>
            
            <div className={styles.cell}>
              <div className={styles.type}>
                <div className={styles.iconWrap}>
                  <Icons.PlanPro className={styles.typeIcon} />
                </div>
                {/* <div className={styles.iconWrap}>
                  <Icons.Planet width="24" className={styles.typeIcon} prefix="compareTable" /> 
                  <Icons.Plus2 width="16" />
                  <Icons.AppStore width="24" className={styles.typeIcon} prefix="compareTable" />
                </div> */}
                {/* Refero Pro */}
              </div>
            </div>

            <div className={styles.cell}>
              <div className={styles.type}>
                <div className={styles.iconWrap}>
                  <Icons.PlanTeam className={styles.typeIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.body}>

          {renderRow('Full size web screenshots', <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />)}

          {renderRow('Websites and app updates', <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />)}

          {renderRow('Advanced search and filters', <span className={styles.disabled}>Limited</span>, 'Unlimited', 'Unlimited')}
          {renderRow('Web search results', <span className={styles.disabled}>8 Screens</span>, 'Unlimited', 'Unlimited')}
          {renderRow('Screens per website', <span className={styles.disabled}>8 Screens</span>, 'Unlimited', 'Unlimited')}
          {renderRow('iOS search results', <span className={styles.disabled}>10 Screens</span>, 'Unlimited', 'Unlimited')}
          {renderRow('Screens per iOS app', <span className={styles.disabled}>10 Screens</span>, 'Unlimited', 'Unlimited')}
          
          {renderRow('Screen downloads', <span className={styles.disabled}>Limited</span>, 'Unlimited', 'Unlimited')}
          {renderRow('Batch downloads', <span className={styles.disabled}>Not Included</span>, 'Unlimited', 'Unlimited')}
          {renderRow('Bookmarks', 'Unlimited', 'Unlimited', 'Unlimited')}
          {renderRow('Bookmark folders', 'Unlimited', 'Unlimited', 'Unlimited')}
          {renderRow('Email support', <span className={styles.disabled}>Basic</span>, 'Advanced', 'Advanced')}
          
          {renderRow('New content every week', <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />)}
          {renderRow('Similar screens recommendation', <span className={styles.disabled}>Limited</span>, <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />)}
          {renderRow('Search by font', <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />,  <Icons.Check width="26" height="26" />)}
          {renderRow('Search by сolor (coming soon)', <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />, <Icons.Check width="26" height="26" />)}
          {/* {renderRow('Price', 'Free', 'From $8/month')} */}
          
        </div>
      </div>

    </div>
  )
});

export default CompareTable;
