import { useEffect } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as Icons from 'icons';
import mixpanel from 'api/mixpanel';
import UIStore from 'stores/UI';
import UserStore from 'stores/User';
import proImg from 'images/pro@x2.png';
import Layout from 'components/Layout/Layout';
import styles from './ExtendTrial.module.scss';

const ExtendTrial = observer(() => {

  useEffect(() => {
    mixpanel.track('ExtendTrialScreen');
  }, []);

  function buildUrl() {
    const id = UserStore.id;
    const email = UserStore.email;
    return `https://referodesign.lemonsqueezy.com/checkout/buy/111728f4-59c4-404a-8e95-10f0f87037e1?checkout[custom][user_id]=${id}&checkout[email]=${email}`
  }

  function subscribeClick() {
    mixpanel.track('ExtendTrialSubscribeClick');
  }

  return (
    <Layout withTopPadding>
      
      <div className={styles.body}>

        <div className={styles.logoWrap}>
          <img className={styles.pro} src={proImg} alt="Pro" />

          <h1 className={styles.title}>
            Get Additional Free Trial
          </h1>
        </div>

        <ul className={styles.list}>
          <li className={classnames(styles.listItem, styles.active)}>
            <div>
              <div className={styles.iconWrap}>
                <Icons.Check width="26" height="26" />
              </div>
            </div>
            Start using Refero
          </li>
          <li className={styles.listItem}>
            <div>
              <div className={styles.iconWrap}>
                <Icons.Lock4 />
              </div>
            </div>
            Get free access for 3 days 
          </li>
          <li className={styles.listItem}>
            <div>
              <div className={styles.iconWrap}>
                <Icons.Stars />
              </div>
            </div>
            In 3 days you will be charged or cancel anytime earlier
          </li>
        </ul>

        <div className={styles.footer}>
          <div className={styles.price}>
            3-day free trial, <span>then $96/year ($8/month)</span>
          </div>

          {UserStore.isSignedIn() ?
            <a  
              href={buildUrl()}
              className={classnames(styles.button, 'lemonsqueezy-button')}
              onClick={subscribeClick}
            >
              Start 3-Day Free Trial
            </a>
          : 
            <div 
              onClick={event => UIStore.showSignupPopup()}
              className={classnames(styles.button)}
            >
              Sign Up to Start Trial
            </div>
          }

        </div>

      </div> 

    </Layout>
  )
});

export default ExtendTrial;
