export default function Screen(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={18} height={12} x={3} y={6} fill="#C3C9EC" rx={3} />
      <rect width={18} height={12} x={3} y={6} fill="url(#a)" rx={3} />
      <rect width={18} height={12} x={3} y={6} fill="url(#b)" rx={3} />
      <path
        fill="#fff"
        d="m10.71 9.175 4.036 2.484a.4.4 0 0 1 0 .681l-4.036 2.485a.4.4 0 0 1-.61-.341V9.516a.4.4 0 0 1 .61-.341Z"
      />
      <defs>
        <radialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(-47.888 29.706 5.958) scale(21.231 14.2391)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4848" />
          <stop offset={0.432} stopColor="#FF4A96" />
          <stop offset={1} stopColor="#FF4DA2" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(11.25001 24.90002 -29.60262 13.37468 .75 -6.9)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9B454" />
          <stop offset={0.479} stopColor="#FAB454" />
          <stop offset={1} stopColor="#FEB750" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  )
}

