import classnames from 'classnames';
import styles from './Fonts.module.scss';

export default function Fonts({ className, width, height }) {
  return (
    <div 
      className={classnames(styles.container, className)} 
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  )
}

// export default function Fonts(props) {
//   return (
//     <svg
//       width={20}
//       height={18}
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//       <path
//         d="M2 12.99c0 3.096 3.613 4.537 5.677-1.14l.447-1.231c1.996-5.501 3.341-9.057 5.747-8.576"
//         stroke="url(#afonts)"
//         strokeWidth={3}
//         strokeLinecap="round"
//       />
//       <path
//         d="M2 12.99c0 3.096 3.613 4.537 5.677-1.14l.447-1.231c1.996-5.501 3.341-9.057 5.747-8.576"
//         stroke="url(#bfonts)"
//         strokeWidth={3}
//         strokeLinecap="round"
//       />
//       <path
//         d="M13.87 2.043c1.418.284.643 4.646-.483 8.8a81.51 81.51 0 0 1-1.582 5.136"
//         stroke="url(#cfonts)"
//         strokeWidth={3}
//         strokeLinecap="round"
//       />
//       <path
//         d="M18 9.3s-1.284 1.285-4.611 1.574c-.892.078-1.93.084-3.13-.025-.758-.069-1.47-.14-2.135-.199C4.255 10.305 2 10.241 2 13.021"
//         stroke="url(#dfonts)"
//         strokeWidth={3}
//         strokeLinecap="round"
//       />
//       <defs>
//         <linearGradient
//           id="afonts"
//           x1={13}
//           y1={2}
//           x2={1.5}
//           y2={14}
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#3AC7FB" />
//           <stop offset={1} stopColor="#63E1F5" />
//         </linearGradient>
//         <linearGradient
//           id="bfonts"
//           x1={13}
//           y1={2}
//           x2={1.5}
//           y2={14}
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#F75B7C" />
//           <stop offset={1} stopColor="#FF9A52" />
//         </linearGradient>
//         <linearGradient
//           id="cfonts"
//           x1={13.498}
//           y1={2.5}
//           x2={11.498}
//           y2={17}
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#F75B7C" />
//           <stop offset={1} stopColor="#7F2378" />
//         </linearGradient>
//         <linearGradient
//           id="dfonts"
//           x1={3.5}
//           y1={13.301}
//           x2={19}
//           y2={9.301}
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#FF9A52" />
//           <stop offset={1} stopColor="#FFB83D" />
//         </linearGradient>
//       </defs>
//     </svg>
//   )
// };