export default function Bookmarks2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={1.6}
        d="M7 5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v14.103a.4.4 0 0 1-.667.298l-3.665-3.292a1 1 0 0 0-1.336 0l-3.665 3.292A.4.4 0 0 1 7 19.103V5Z"
      />
    </svg>
  )
};