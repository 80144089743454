import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import styles from './SearchSuggestions2.module.scss';

import PAGE_TYPES from 'json/page-types-count.json';
import APP_PAGE_TYPES from 'json/app-page-types-count.json';
import renderTagName from 'helpers/render-tag-name';

const PageTypes = observer(({ onSelect }) => {

  function getCount(id) {
    return (UIStore.searchType === 'sites' ? PAGE_TYPES : APP_PAGE_TYPES)?.[id] ?? 0;
  }

  return (
    <>
      {SearchStore.pageTypeKinds?.map(kind =>
        <div className={styles.group} key={kind}>
          <div className={styles.kind}>
            {kind}
          </div>
          <div className={styles.items}>
            {SearchStore.pageTypeFilter(kind)?.map(item =>
              <div 
                className={classNames(styles.item, {
                  [styles.selected]: item.id === SearchStore.selected || item.active
                })} 
                key={item.id}
                onClick={event => onSelect('page_types', item.id)}
                id={`page_types-${item.id}`}
              >
                {renderTagName(item.name, item.app_name)}
                {/* {item.name}  */}
                <div className={styles.count}>
                  {getCount(item.id)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>   
  )
});

export default PageTypes;
