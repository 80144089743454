import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'components/Button/Button';
import styles from './GroupingPopup.module.scss';
import SearchStore from 'stores/Search';

const GroupingPopup = observer(() => {

  const [ isShownBefore, setShown ] = useState(localStorage.getItem('grouping_popup_shown') ?? false);

  function close() {
    localStorage.setItem('grouping_popup_shown', '1');
    setShown(true);
  }

  if (isShownBefore) {
    return null;
  }

  if (!SearchStore.grouped) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <div className={styles.body}>

        <div className={styles.videoWrapper}>
          <video 
            className={styles.video}
            playsInline 
            muted 
            loop
            autoPlay
            preload="metadata"
            poster="https://images.refero.design/videos/onboarding/grouping-preview.jpg"
            // ref={player}
          >
            <source src="https://images.refero.design/videos/onboarding/grouping-preview2.mp4" type="video/mp4" />
          </video>
        </div>

        <div className={styles.title}>
          New way to explore!
        </div>
        <div className={styles.text}>
          Navigate design screens categorized by&nbsp;website, streamlining your comparison and comprehension of&nbsp;distinct design flows.
        </div>
        <Button
          block
          square
          onClick={close}
        >
          Explore Now
        </Button>
      </div>
    </div>
  )
});

export default GroupingPopup;
