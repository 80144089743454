import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import mixpanel from 'api/mixpanel';
import { observer } from 'mobx-react-lite';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import styles from './Onboarding.module.scss';

const STEPS = [
  {
    title: 'Searching by Tag',
    text:  `Click on&nbsp;the search box at&nbsp;the top of&nbsp;any Refero page to&nbsp;see finely curated tagging system with categories like Page Types, UX&nbsp;Patterns, and UI&nbsp;Elements, you can drill down to&nbsp;the tiniest detail in&nbsp;your design quest.`,
    actions: [
      {
        label: 'See it in Action',
        url: '/search?page_types[id][]=44'
      },
      {
        label: 'More Examples',
        url: '/search?design_patterns[id][]=141'
      },
      {
        label: 'And One More Time',
        url: '/search?page_elements[id][]=98'
      }
    ]
  },
  {
    title: 'Combine Tags',
    text:  `You can combine several tags together, for example, to&nbsp;find screenshots of&nbsp;product pages that include illustrations, you can add both tags to&nbsp;the search box.`,
    actions: [
      {
        label: 'Show an Example',
        url: '/search?page_types[id][]=44&page_elements[id][]=95'
      },
      {
        label: 'More Examples',
        url: '/search?page_types[id][]=33&design_patterns[id][]=164'
      },
      {
        label: 'Let\'s go deeper',
        url: '/search?page_types[id][]=16&page_elements[id][]=61'
      }
    ]
  },
  {
    title: 'Searching by Text Content',
    text:  `If&nbsp;you&rsquo;re looking for something that isn&rsquo;t tagged, you can also search for text content on&nbsp;a&nbsp;page. To&nbsp;do&nbsp;this, simply type the text you&rsquo;re looking for into the search box.<br /> For example, if&nbsp;you&rsquo;re looking for screenshots of&nbsp;the Airbnb login page, you could search for "Airbnb login".`,
    actions: [
      {
        label: 'Show How it Works',
        url: '/search?query=airbnb%20login'
      },
      {
        label: 'Try Again',
        url: '/search?query=nft'
      },
      {
        label: 'Try Another Example',
        url: '/search?query=reset%20password'
      }
    ]
  },
  {
    title: 'Searching by Site',
    text:  `You can also search for screenshots from a&nbsp;specific site. To&nbsp;do&nbsp;this, simply enter the site&rsquo;s address or&nbsp;name into the search box. For example, if&nbsp;you want to&nbsp;find screenshots from the Notion website, you could search for "notion".`,
    actions: [
      {
        label: 'Show How it Works',
        url: '/search?site_id[id][]=14'
      },
      {
        label: 'See it in Action',
        url: '/search?site_id[id][]=72'
      },
      {
        label: 'One More Time',
        url: '/search?site_id[id][]=249'
      }
    ]
  },
  {
    title: 'Switch between Web and iOS',
    text:  `With a&nbsp;simple click on&nbsp;the toggle, you can switch between platforms seamlessly. Most of&nbsp;our tags overlap across platforms, so&nbsp;just toggle your search results to&nbsp;see design marvels from both platforms.`,
    actions: [
      {
        label: 'Switch to iOS',
        url: '/apps/search'
      },
      {
        label: 'Switch to Web',
        url: '/search'
      }
    ]
  }
];

const Onboarding = observer(() => {

  if (!UIStore.onboardingVisible) {
    return null;
  }

  return (
    <Popup />
  );
});

function Popup() {
  const [ step, setStep ] = useState(0);
  const [ actionIndex, setActionindex ] = useState(0);
  const videos = useRef([]);

  useEffect(() => {
    mixpanel.track('HowItWorksPopup');
  }, []);

  useEffect(() => {
    if (videos.current?.[step]) {
      videos.current[step].currentTime = 0;
    }
    setActionindex(0);
  }, [ step ]);

  function next() {
    const nextStep = step + 1;
    if (nextStep >= STEPS.length) {
      return close();
    }
    setStep(nextStep);
  }

  function nextAction() {
    let nextAction = actionIndex + 1;
    if (!STEPS[step]['actions']?.[nextAction]) {
      nextAction = 0;
    }
    setActionindex(nextAction);
    mixpanel.track('HowItWorksPopupNextClick', { nextAction });
  }

  function close() {
    mixpanel.track('HowItWorksPopupClose');
    return UIStore.hideOnboarding();
  }

  function getVideoSource(index) {
    return `https://images.refero.design/videos/onboarding/step-264-${index + 1}.mp4`;
  }

  function getPoster(index) {
    return `https://images.refero.design/videos/onboarding/step${index + 1}.jpg`;
  } 

  return (
    <div className={styles.container}>
      {/* <div className={styles.bg} /> */}
      <div className={styles.body}>

        <div className={styles.head}>
          {STEPS.map((item, index) =>
            <video 
              className={classnames(styles.video, {
                [styles.visible]: step === index
              })} 
              autoPlay
              playsInline 
              muted 
              loop
              preload="metadata"
              poster={getPoster(index)}
              ref={element => videos.current[index] = element}
              key={index}
            >
              <source src={getVideoSource(index)} type="video/mp4" />
            </video>
          )}

          <div className={styles.close} onClick={close}>
            <Icons.Close />
          </div>
        </div>

        <div className={styles.content}>
          {/* <div className={styles.how}>
            How it works
          </div> */}

          <div className={styles.scroll}>
            <div className={styles.scrollContent} style={{ transform: `translateX(${-20 * step}%)` }}>

              {STEPS.map((item, index) =>
                <div 
                  className={classnames(styles.item, {
                    [styles.current]: index === step
                  })}
                  key={index}
                >
                  <div className={styles.title}>
                    {item.title}
                  </div>
                  <div 
                    className={styles.text} 
                    dangerouslySetInnerHTML={{ __html: item.text }} 
                  />
                </div>
              )}
            
            </div>
          </div>
        </div>

        <div className={styles.steps}>
          {STEPS?.map((_, index) =>
            <div 
              className={classnames(styles.step, {
                [styles.active]: index === step
              })} 
              onClick={event => setStep(index)}
              key={index}
            />
          )}
        </div> 

        <div className={styles.buttons}>
          {/* <div className={styles.steps}>
            {STEPS?.map((_, index) =>
              <div 
                className={classnames(styles.step, {
                  [styles.active]: index === step
                })} 
                onClick={event => setStep(index)}
                key={index}
              />
            )}
          </div> */}
          {/* <div 
            className={classnames(styles.button, {
              // [styles.final]: step === STEPS.length - 1
            })} 
            onClick={next}
          >
            {step === STEPS.length - 1 ? 'Close' : 'Next Tip'}
          </div> */}

          <CTAButton onClick={next}>
            {step === STEPS.length - 1 ? 'Close' : 'Next Tip'}
          </CTAButton>

          <Link 
            className={styles.link}            
            to={STEPS[step]['actions'][actionIndex]?.['url'] ?? '/'}
          >
            <CTAButton onClick={nextAction} accent>
              {STEPS[step]['actions'][actionIndex]?.['label']}
            </CTAButton>
          </Link>
        </div>

      </div>
    </div>
  );
}

function CTAButton({ children, onClick, accent }) {

  const [ clicked, setClicked ] = useState(false);

  function click() {
    setClicked(true);
    onClick();
  }

  return (
    <div 
      className={classnames(styles.button, {
        [styles.final]: accent,
        [styles.clicked]: clicked,
      })} 
      onClick={click}
      onAnimationEnd={event => setClicked(false)}
    >
      {children}
    </div>
  )
}

export default Onboarding;
