import classnames from 'classnames';
import { format } from 'date-fns';
import styles from './Dialog.module.scss';

export default function Dialog({ subscription, close, submit }) {
  return (
    <div className={classnames(styles.container, {
      [styles.visible]: subscription
    })}>
      <div className={styles.bg} />
      <div className={styles.body}>
        <div className={styles.title}>
          Are you sure you want to cancel?
        </div>
        <div>
          We're sad to see you go, but remember, you'll still have access to all of Refero's features until {format(new Date(subscription?.renews_at ?? null), 'd MMM yyyy')}. If you change your mind, we'll be here to welcome you back with open arms!
        </div>

        <div className={styles.buttons}>
          <div className={classnames(styles.button, styles.grey)} onClick={submit}>
            Confirm
          </div>
          <div className={classnames(styles.button)} onClick={close}>
            Stay Subscribed
          </div>
          
        </div>
      </div>
    </div>
  );
}
