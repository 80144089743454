import { useRef, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
// import mixpanel from 'api/mixpanel';
import * as Icons from 'icons';
import UIStore from 'stores/UI';
import SearchStore from 'stores/Search';
import searchTips from 'json/search-tips.json';
import styles from './SearchField.module.scss';
import renderTagName from 'helpers/render-tag-name';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const SearchField = observer(() => {
  const navigate = useNavigate();
  const input = useRef();
  const placeholder = useMemo(() => searchTips[randomInt(0, searchTips.length - 1)], []);

  useEffect(() => {
    const handleCmdK = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.keyCode === 75) {
        input.current.focus();
      }

      if (SearchStore.visible) {
        onKeyDown(event);
      }
    };
    
    window?.addEventListener('keydown', handleCmdK);

    return () => {
      window?.removeEventListener('keydown', handleCmdK);
    };
  }, []);

  useEffect(() => {
    if (SearchStore.visible) {
      document.body.classList.add(styles.bodyFix);
    } else {  
      document.body.classList.remove(styles.bodyFix);
    }
  }, [ SearchStore.visible ]);

  function onKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        return onPressArrowDown();
      case 'ArrowUp':
        return onPressArrowUp();
      case 'ArrowLeft':
        return onPressArrowLeft();  
      case 'ArrowRight':
        return onPressArrowRight();  
      case 'Tab':
        event.preventDefault();
        if (event.shiftKey) {
          return onPressTabShift();  
        }
        return onPressTab();  
      case 'Enter':
        return onPressEnter();
      case 'Backspace':
        return onPressBackspace();
      case 'Escape':
        return onPressEscape();
      default:
        return null;
    }
  }

  function onPressBackspace() {
    if (input.current.selectionStart !== 0 || input.current.selectionEnd !== 0 || !SearchStore.items?.length) {
      return;
    }
    const deleted = SearchStore.pop();
    const params = window.location.search
      ?.replace('?', '')
      ?.split('&')
      ?.filter(item => !!item)
      ?.filter(item => item !== `${deleted.type}[id][]=${deleted.value.id}`);
      
    navigate(`${UIStore.searchUrl}?${SearchStore.normalizeSearchParams(params)?.join('&')}`);
  }

  function onPressArrowDown() {
    const id = SearchStore.next(SearchStore.numberOfColumns);
    scrollToElement(id);
  }

  function onPressArrowUp() {
    const id = SearchStore.prev(SearchStore.numberOfColumns);
    scrollToElement(id);
  }

  function onPressArrowLeft() {
    const id = SearchStore.prev();
    scrollToElement(id);
  }

  function onPressArrowRight() {
    const id = SearchStore.next();
    scrollToElement(id);
  }

  function onPressTab() {
    if (SearchStore.query) {
      return;
    }
    SearchStore.nextTab();
  }

  function onPressTabShift() {
    if (SearchStore.query) {
      return;
    }
    SearchStore.prevTab();
  }

  function scrollToElement(id) {
    const elementId = SearchStore.filtered?.length ? `suggestion-${id}` : `${SearchStore.tab}-${id}`;
    document.getElementById(elementId)?.scrollIntoView({behavior: isSafari ? 'auto' : 'smooth', block: 'center'});
    // document.getElementById(elementId)?.scrollIntoView({behavior: 'auto', block: 'nearest'});
    // document.getElementById(elementId)?.scrollIntoView({block: 'center'});
  }

  function onPressEnter() {
    const params = window.location.search?.replace('?', '')?.split('&')?.filter(item => !!item);

    if (!SearchStore.filtered?.length && SearchStore.selected && !SearchStore.isSelected(SearchStore.tab, SearchStore.selected)) {
      params.push(`${SearchStore.tab}[id][]=${SearchStore.selected}`);  
    }

    const item = SearchStore.getFilteredItem();

    if (SearchStore.isSelected(item?.type, item?.value?.id)) {
      SearchStore.setQuery('');
      SearchStore.hide();
      return input.current.blur();
    }

    if (item && item?.type !== 'query') {
      const key = `${item.type}[id][]`;
      // params.push(`${key}=${SearchStore.selected}`);  
      params.push(`${key}=${item.value.id}`);  
      SearchStore.setQuery('');
    }

    if (item?.type === 'query') {
      SearchStore.selected = null;
    }

    navigate(`${UIStore.searchUrl}?${SearchStore.normalizeSearchParams(params)?.join('&')}`);
    SearchStore.hide();
    input.current.blur();
  }

  function onPressEscape() {
    SearchStore.hide();
    input.current.blur();
  }

  function onFocus() {
    SearchStore.show();
  }

  function renderIcon(type, favicon, domain) {
    switch (type) {
      case 'page_types':
        return <Icons.PageTypesColor className={styles.itemIcon} />
      case 'design_patterns':
        return <Icons.PatternsColor className={styles.itemIcon} />
      case 'page_elements':
        return <Icons.ElementsColor className={styles.itemIcon} />
      case 'categories':
        return <Icons.CategoryColor className={styles.itemIcon} />
      case 'fonts':
        return <Icons.Fonts className={styles.itemIcon} />
      case 'site_id':
      case 'app_id':
        return (
          <img 
            className={styles.itemIcon}
            src={favicon ? `https://images.refero.design/${favicon}` : `https://www.google.com/s2/favicons?domain=${domain}&sz=256`} 
            alt="" 
          />
        )
      default:
        return null;
    }
  }

  function remove(item) {
    const params = window.location.search?.replace('?', '')?.split('&')?.filter(item => !!item);
    const index = params?.findIndex(param => param === `${item.type}[id][]=${item.value.id}`);
    params.splice(index, 1);
    navigate(`${UIStore.searchUrl}?${SearchStore.normalizeSearchParams(params)?.join('&')}`);
  }

  function getPlaceholder() {
    return `Try searching "${placeholder}"`;
  }

  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function hintLabel() {
    if (navigator.userAgent.toLocaleLowerCase().indexOf('mac') > -1) {
      return '⌘+K';
    }

    return 'Ctrl+K';
  }

  return (
    <div className={classNames(styles.container, {
      [styles.visibleMobile]: SearchStore.visible
    })}>
      <div 
        className={classNames(styles.hint, {
          [styles.hintHidden]: SearchStore.items?.length || SearchStore.visible
        })}
      >
        {/* ⌘+K */}
        {hintLabel()}
      </div>

      <Link 
        to={UIStore.searchUrl}
        className={classNames(styles.clear, {
          [styles.hintHidden]: !SearchStore.items?.length
        })}
      >
        <Icons.Close />
      </Link>

      <div className={styles.close} onClick={onPressEscape}>
        <Icons.Close />
      </div>

      {/* <div className={styles.bg} /> */}
      
      <div className={styles.content}>
        {SearchStore.items?.length ?
          // <div className={styles.items}>
            SearchStore.items?.map(item =>
              <div 
                className={styles.item} 
                onClick={event => remove(item)}
                key={item?.value?.id ?? item}
              >
                <div className={styles.iconWrapper}>
                  {renderIcon(item.type, item?.value?.icon_url, item?.value?.domain)}
                  <div className={styles.itemRemove} >
                    <Icons.Close />
                  </div>
                </div>
                {renderTagName(item.value.name, item.value.app_name)}
              </div>  
            )
          // </div>
        :
          <Icons.Search className={styles.icon} />
        }  

        <input 
          id="search-field"
          ref={input}
          type="text" 
          className={styles.field} 
          placeholder={SearchStore?.items?.length ? '' : getPlaceholder()} 
          value={SearchStore.query}
          onChange={({target: {value}}) => SearchStore.setQuery(value)}
          onFocus={onFocus}
          autoComplete="off"
        />
      </div>

    </div>
  )
});

export default SearchField;
