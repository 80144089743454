export default function Download3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="M8 12.03 12 16m0 0 4-3.97M12 16V4M7.5 20H16"
      />
    </svg>
  )
}
