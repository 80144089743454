export default function Logo(props) {
  return (
    <svg
      width={78}
      height={24}
      viewBox="0 0 170 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68 40.32H0L7.104 0h16.064c6.784 0 11.328 5.696 11.328 11.52 0 5.376-2.752 8.768-5.44 10.624-1.6 1.088-3.392 1.92-5.376 2.496l6.72 15.68h-8.64L16.064 25.6h-5.76L7.68 40.32Zm5.824-32.96-1.92 10.88h8.896c3.328 0 6.272-2.624 6.272-5.76 0-2.56-1.984-5.12-4.864-5.12h-8.384ZM53.733 32.512c.512-.32.96-.704 1.28-1.152h7.68c-1.536 4.416-6.465 9.28-13.12 9.28-7.937 0-14.72-6.72-14.72-15.04s6.783-15.04 14.72-15.04c7.68 0 14.08 6.08 14.08 15.04 0 .502-.066.971-.126 1.408-.025.176-.048.347-.066.512l-.128.96h-20.48c.703 2.88 2.88 5.12 6.72 5.12 1.983 0 3.2-.512 4.16-1.088ZM49.573 17.6c-3.84 0-6.337 2.496-7.04 5.76h14.08c-.705-3.264-3.2-5.76-7.04-5.76ZM107.137 31.36c-.32.448-.768.832-1.28 1.152-.96.576-2.175 1.088-4.159 1.088-3.84 0-6.016-2.24-6.72-5.12h20.479l.129-.96c.018-.165.041-.336.066-.511.06-.437.125-.907.125-1.409 0-8.96-6.399-15.04-14.079-15.04-7.936 0-14.72 6.72-14.72 15.04s6.784 15.04 14.72 15.04c6.656 0 11.583-4.864 13.119-9.28h-7.68Zm-12.48-8c.705-3.264 3.2-5.76 7.041-5.76 3.84 0 6.335 2.496 7.039 5.76h-14.08Z"
        fill="currentColor"
      />
      <path
        d="M128.562 40.32h-7.36V10.88h5.441l1.28 3.2c.511-.64 1.152-1.216 1.856-1.728a8.244 8.244 0 0 1 5.183-1.792c.515 0 .894.069 1.235.13.125.023.245.045.365.062l.64.128v7.68l-.703-.128a18.292 18.292 0 0 1-.27-.041c-.421-.066-.961-.151-1.586-.151-3.584 0-6.081 2.56-6.081 6.4v15.68Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.135 10.56c-7.936 0-14.72 6.72-14.72 15.04s6.784 15.04 14.72 15.04c7.936 0 14.72-6.72 14.72-15.04s-6.784-15.04-14.72-15.04Zm0 7.04c4.16 0 7.36 3.136 7.36 8s-3.2 8-7.36 8-7.36-3.136-7.36-8 3.2-8 7.36-8Z"
        fill="currentColor"
      />
      <path
        d="M79.22 16h5.28L83 23h-4.945l-3.319 19.938c-1.137 6.832-8.012 11.116-14.647 9.127l-1.302-.39 2.01-6.706 1.303.39a4.5 4.5 0 0 0 5.731-3.57L70.959 23H66l1.5-7h4.624l.987-5.928C74.222 3.396 80.83-.882 87.376.837l1.018.268-1.778 6.77-1.018-.267a4.5 4.5 0 0 0-5.582 3.613L79.22 16Z"
        fill="currentColor"
      />
    </svg> 
  )
}
