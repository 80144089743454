import { useState } from 'react';
import classnames from 'classnames';
import * as Icons from 'icons';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import styles from './UniversalHeader.module.scss';

export default function UniversalHeader({ data }) {

  const [ iconLoaded, setIconLoaded ] = useState(false);

  function iconUrl() {
    if (!data?.faviconUrl && data?.domain) {
      return `https://www.google.com/s2/favicons?domain=${data?.domain}&sz=256`
    }
    return data?.faviconUrl;
  }
 
  return (
    <div className={styles.container}>
      <div 
        className={classnames(styles.body, {
          [styles.bodyWithSwitch]: data?.app || data?.site
        })}
      >
        <div 
          className={styles.bg}
          style={{ 
            backgroundColor: `#${data?.backgroundColor ? data.backgroundColor : 'AEB8D6'}1a`
          }}
        />

        {data?.app || data?.site ?
          <TabsSlider
            noBg
            compact
            className={styles.tabs}
            activeIndex={data?.app ? 0 : 1}
          >
            <TabsSlider.Item active={data?.app} to={data.site ? `/${data.site.id}-${data.site.domain}` : ''}>
              <TabsSlider.Icon>
                <Icons.Planet width="24" height="24" prefix="projectHeader" />
              </TabsSlider.Icon>
              Website
            </TabsSlider.Item>
            <TabsSlider.Item active={data?.site} to={data.app ? `/apps/${data.app?.id}` : ''}>
              <TabsSlider.Icon>
                <Icons.AppStore width="24" height="24" prefix="projectHeader" />
              </TabsSlider.Icon>
              iOS App
            </TabsSlider.Item>
          </TabsSlider>
        : null}

        <div className={styles.texts}>
          <h1 className={styles.title}>
            {data?.name ?? data?.domain}
          </h1>
          <div className={styles.desc}>
            {data?.description}
          </div>
        </div>
      </div>

      <div 
        className={styles.iconWrap}
        style={{ 
          backgroundColor: `#${data?.backgroundColor ? data.backgroundColor : 'AEB8D6'}1a`
        }}
      >
        <img 
          onLoad={event => setIconLoaded(true)}
          className={classnames(styles.icon, {
            [styles.loaded]: iconLoaded
          })}
          src={iconUrl()} 
          async
          loading="lazy"
          alt={data?.name ? data.name : data?.domain}
        />

        <div className={styles.textsMobile}>
          <h1 className={styles.title}>
            {data?.name ?? data?.domain}
          </h1>
          <div className={styles.desc}>
            {data?.description}
          </div>
        </div>
      </div>
    </div>
  )
}
