import { makeAutoObservable, runInAction } from 'mobx';
import { isAfter, formatDistance, isToday } from 'date-fns';
import AlertStore from './Alert';
import ReferoApi from 'api/refero_api';
import ga from 'api/ga';
import mixpanel from 'api/mixpanel';
import UIStore from './UI';
import BookmarksStore from './Bookmarks';

class User {

  id = null;
  email = null;
  name = null;
  createdAt = null;
  picture = null;
  fetching = false;
  roles = null;
  onTrial = false;
  trailEndsAt = null;
  loginDate = null;
  submitting = false;
  otpLoginAttempts = 0;
  subscription = null;
  // earlyAdopter = false;
  leftQueries = 5;
  teams = null;
  isReady = false;

  constructor() {
    makeAutoObservable(this);
    this.auth();
    this.subscribeEvents();
  }

  subscribeEvents() {
    window?.addEventListener('storage', this.onLocalStorageChange.bind(this));
  }

  onLocalStorageChange({key, newValue}) {
    if (key === 'id_token' && newValue) {
      localStorage?.removeItem('id_token');
      UIStore.hideSignupPopup();
      this.googleAuth(newValue);
    }
  }

  isSignedIn() {
    const token = localStorage?.getItem('refero_token');
    return !!(this.id || token);
  }

  logout() {
    localStorage.removeItem('refero_token');
    this.id = null;
    this.email = null;
    this.name = null;
    this.createdAt = null;
    this.picture = null;
    this.roles = null;
    this.onTrial = false;
    this.subscription = null;
    this.trailEndsAt = null;
    this.teams = null;

    UIStore.hideProfile();
    BookmarksStore.clear();
    window.location.replace('/')

    // AlertStore.add('You have been succesfully logged out', 'success');
  }

  async auth() {
    try {
      const token = localStorage?.getItem('refero_token');
      if (token) {
        this.fetching = true;
        const user = await ReferoApi.users();
        this.applyData(user);
      }
    } catch (err) {
      AlertStore.add(err.message);
    } finally {
      runInAction(() => {
        this.fetching = false;
        this.isReady = true;
      });
      
      if (this.id) {
        BookmarksStore.fetch();
      }
    }
  }

  async googleAuth(code) {
    try {
      if (this.fetching) {
        return;
      }
      this.fetching = true;
      const { user, token } = await ReferoApi.login(code);
      localStorage.setItem('refero_token', token);
      this.applyData(user);
      runInAction(() => {
        this.loginDate = new Date();
        // if (user.on_trial) {
        //   UIStore.setShowPaywall(true);
        // }
      });
    } catch (err) {
      AlertStore.add(err.message);
    } finally {
      AlertStore.add('You have been succesfully logged in', 'success');
      runInAction(() => {
        this.fetching = false;
      });
      
      if (this.id) {
        BookmarksStore.fetch();
      }

      this.onSignupCallback();
    }
  }

  async otpEmail(email) {
    return new Promise(async (resolve, reject) => {
      try {
        this.submitting = true;
        await ReferoApi.otpEmail(email);
        resolve();
      } catch (err) {
        reject(err);
      } finally {
        runInAction(() => {
          this.submitting = false;
        });
      }
    })

    // try {
    //   this.submitting = true;
    //   await ReferoApi.otpEmail(email);
    //   // this.email = email;
    //   runInAction(() => {
    //     this.submitting = false;
    //   });
    // } catch (err) {
    //   runInAction(() => {
    //     this.submitting = false;
    //   });
    //   AlertStore.add(err.message);
    // }
  }

  async otpCode(email, code) {
    return new Promise(async (resolve, reject) => {
      try {
        this.submitting = true;
        const { user, token } = await ReferoApi.otpCode(email, code);
        if (!user || !token) {
          throw new Error('Invalid Code');
        }
        localStorage.setItem('refero_token', token);
        this.applyData(user);

        AlertStore.add('You have been succesfully logged in', 'success');
        runInAction(() => {
          this.submitting = false;
          this.loginDate = new Date();
        });
        
        if (this.id) {
          BookmarksStore.fetch();
        }

        UIStore.hideSignupPopup();
        resolve();
      } catch (err) {
        reject(err);
      } finally {
        runInAction(() => {
          this.submitting = false;
        });
      }
    });
  }

  // async otpCode(email, code) {
  //   try {
  //     this.submitting = true;
  //     const { user, token } = await ReferoApi.otpCode(email, code);
  //     if (!user || !token) {
  //       throw new Error('Invalid Code');
  //     }
  //     localStorage.setItem('refero_token', token);
  //     this.applyData(user);

  //     AlertStore.add('You have been succesfully logged in', 'success');
  //     runInAction(() => {
  //       this.submitting = false;
  //       this.loginDate = new Date();
  //     });
      
  //     if (this.id) {
  //       BookmarksStore.fetch();
  //     }
  //     UIStore.hideSignupPopup();
  //   } catch (err) {
  //     AlertStore.add(err.message ?? 'Invalid Code');
  //     runInAction(() => {
  //       this.submitting = false;
  //       this.otpLoginAttempts += 1;
  //       if (this.otpLoginAttempts >= 3) {
  //         this.email = null;
  //       }
  //     });
  //   } 
  // }

  applyData(user) {
    runInAction(() => {
      this.id = user.id;
      this.name = user.name;
      this.createdAt = user.created_at;
      this.email = user.email;
      this.picture = user.picture;
      this.roles = user.roles;
      this.onTrial = user.on_trial;
      this.trailEndsAt = user.trail_ends_at;
      this.subscription = user.subscription;
      // this.earlyAdopter = user.early_adopter;
      this.teams = user.teams;
    });

    ga.push('auth');
    mixpanel.identify(user);
  }

  isAdmin() {
    return this.roles?.includes('admin');
  }

  isTrialExpired() {
    return this.onTrial === false && !this.hasSubscription();
  }

  hasSubscription() {
    return this.subscription?.status === 'active' || 
      (this.subscription?.status === 'cancelled' && isAfter(new Date(this.subscription?.ends_at), new Date()) );
      // (this.subscription?.status === 'expired' && isAfter(new Date(this.subscription?.renews_at), new Date()) );
  }

  // isFreeTrialEnabled() {
  //   return false;
  //   // return this.isSignedIn() && this.isTrialExpired();
  // }

  onSignupCallback() {
    if (this.createdAt && isToday(new Date(this.createdAt))) {
    // if (this.createdAt && !isToday(new Date(this.createdAt))) {
      UIStore.showOnboarding();
    }
  }

  tillTrialEnds() {
    const now = new Date();
    const to = new Date(this.trailEndsAt ?? null);
    return formatDistance(now, to);
  }

  async addToAbondonedCartGroup() {
    try {
      if (!this.isSignedIn() || this.hasSubscription() || this.onTrial) {
        return;
      }
      await ReferoApi.addAbandonedCart();
    } catch (err) {
      console.log(err);
    }
  }

  setLeftQueries(value) {    
    if (value === null || value === undefined) {
      return;
    }
    this.leftQueries = parseInt(value);
  }

  clearEmail() {
    if (!this.id) {
      this.email = null;
    }
  }
    
}

const UserStore = new User();
export default UserStore;
