export default function Pro(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={20}
      viewBox="0 0 68 32"
      fill="none"
      {...props}
    >
      <path
        fill="#C3C9EC"
        d="M5.453 2.658A3.19 3.19 0 0 1 8.6 0h55.125a3.19 3.19 0 0 1 3.146 3.723l-4.323 25.524a3.19 3.19 0 0 1-3.146 2.658H4.276a3.19 3.19 0 0 1-3.145-3.723L5.453 2.658Z"
      />
      <path
        fill="url(#apro)"
        d="M5.453 2.658A3.19 3.19 0 0 1 8.6 0h55.125a3.19 3.19 0 0 1 3.146 3.723l-4.323 25.524a3.19 3.19 0 0 1-3.146 2.658H4.276a3.19 3.19 0 0 1-3.145-3.723L5.453 2.658Z"
      />
      <path
        fill="url(#bpro)"
        d="M5.453 2.658A3.19 3.19 0 0 1 8.6 0h55.125a3.19 3.19 0 0 1 3.146 3.723l-4.323 25.524a3.19 3.19 0 0 1-3.146 2.658H4.276a3.19 3.19 0 0 1-3.145-3.723L5.453 2.658Z"
      />
      <path
        fill="#fff"
        d="M13.327 23.927h3.063l.944-5.36h3.471c3.14 0 5.922-2.578 5.922-5.794 0-2.552-1.838-4.926-4.569-4.926H16.16l-2.833 16.08Zm4.518-8.295.868-4.85h2.935c1.148 0 2.016 1.15 2.016 2.298 0 1.352-1.353 2.552-2.654 2.552h-3.165ZM27.235 23.927h3.063l1.047-5.87h2.297l2.272 5.87h3.445l-2.68-6.253a7.503 7.503 0 0 0 2.144-.995c1.072-.74 2.17-2.093 2.17-4.237 0-2.323-1.812-4.595-4.518-4.595H30.07l-2.834 16.08Zm4.62-8.805.766-4.34h3.344c1.148 0 1.94 1.021 1.94 2.043 0 1.25-1.175 2.297-2.502 2.297h-3.548ZM48.945 24.055c4.34 0 8.627-3.905 8.627-9.01 0-4.11-3.037-7.325-7.044-7.325-4.34 0-8.627 3.905-8.627 9.01 0 4.109 3.037 7.325 7.044 7.325Zm.307-2.935c-2.425 0-4.288-1.889-4.288-4.569 0-3.471 2.68-5.896 5.283-5.896 2.45 0 4.263 1.889 4.263 4.569 0 3.471-2.68 5.896-5.258 5.896Z"
      />
      <defs>
        <radialGradient
          id="apro"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(-55.49992 16.00034 -14.7054 -51.00816 65 16)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4848" />
          <stop offset={0.432} stopColor="#FF4A96" />
          <stop offset={1} stopColor="#FF4DA2" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="bpro"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(134.409 33.75 14.184) scale(34.2965 72.0226)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9B454" />
          <stop offset={0.479} stopColor="#FAB454" />
          <stop offset={1} stopColor="#FEB750" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}
