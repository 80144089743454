import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import Layout from 'components/Layout/Layout';
import TabsSlider from 'components/TabsSlider/TabsSlider';
import Hero3 from 'components/Hero3/Hero3';
import * as Icons from 'icons';
// import PatternCards from 'components/PatternCards/PatternCards';
import AppFeed from 'components/AppFeed/AppFeed';
import Cards from 'components/Cards/Cards';
// import styles from './HomeApps.module.scss';
import styles from 'pages/Home/Home.module.scss';

import lang from 'json/lang.json'; 
import screenshots from 'json/screenshots-count.json';
import appScreenshots from 'json/app-screenshots-count.json';
import UserStore from 'stores/User';
import UIStore from 'stores/UI';

import PATTERNS_LIST from 'json/app-patterns-index';
import PATTERNS_COUNTS from 'json/app-patterns-count.json';

import PAGE_TYPES_LIST from 'json/app-page-types-index';
import PAGE_TYPES_COUNTS from 'json/app-page-types-count';

import ELEMENTS_LIST from 'json/app-elements-index';
import ELEMENTS_COUNTS from 'json/app-elements-count';
import SignupForm from 'components/SingupForm/SignupForm';

const TABS = [
  {
    id: 'page_types',
    title: 'Page Types'
  },
  {
    id: 'design_patterns',
    title: 'UX Patterns'
  },
  {
    id: 'page_elements',
    title: 'UI Elements'
  },
];

const CELLS = Array(12).fill(0).map((_, index) => index);

const HomeApps = observer(() => {

  const [ tagsTab, setTagsTab ] = useState(localStorage.getItem('app_tags_tab') ?? 'page_types');

  useEffect(() => {
    UIStore.setSearchType('apps');
  }, []);

  useEffect(() => {
    if (tagsTab) {
      localStorage.setItem('app_tags_tab', tagsTab);
    }
  }, [ tagsTab ]);

  function renderIcon(id) {
    switch(id) {
      case 'page_types':
        return <Icons.PageTypesColor />
      case 'design_patterns':
        return <Icons.PatternsColor />
      case 'page_elements':
        return <Icons.ElementsColor />
      default:
        return null;
    }
  }

  function getItem(index) {
    switch(tagsTab) {
      case 'page_types':
        return PAGE_TYPES_LIST[index];
      case 'design_patterns':
        return PATTERNS_LIST[index];
      case 'page_elements':
        return ELEMENTS_LIST[index];
      default:
        return null;
    }
  }

  function getCount(id) {
    switch(tagsTab) {
      case 'page_types':
        return PAGE_TYPES_COUNTS[id];
      case 'design_patterns':
        return PATTERNS_COUNTS[id];
      case 'page_elements':
        return ELEMENTS_COUNTS[id];
      default:
        return null;
    }
  }

  function getExploreUrl() {
    switch(tagsTab) {
      case 'page_types':
        return '/apps/pagetypes'
      case 'design_patterns':
        return '/apps/patterns';
      case 'page_elements':
        return '/apps/elements';
      default:
        return null;
    }
  }

  function randomKey() {
    return (Math.random() + 1).toString(36).substring(7);
  }
 
  return (
    <>
      <Helmet>
        <title>{lang.meta.title}</title>
        <link rel="canonical" href="https://refero.design/apps" />
        <meta property="og:url" content="https://refero.design/apps" />
        <meta property="og:title" content={lang.meta.title} />
        <meta name="twitter:title" content={lang.meta.title} />
        <meta property="og:site_name" content={lang.meta.title} />
      </Helmet>

      <Hero3 
        apps
        title="Explore real-world designs from the <span>best products</span>"
        // subTitle={`We make design research easier and faster than ever before. Explore over then ${(Math.floor(screenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;pages and&nbsp;over than ${(Math.floor(appScreenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;iOS&nbsp;screens.`}
        subTitle={`We make design research easier and faster than ever before. Explore over ${(Math.floor(screenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;web&nbsp;pages and more than ${(Math.floor(appScreenshots?.all/1000)*1000)?.toLocaleString()}&nbsp;iOS&nbsp;screens`}
      /> 

      <div className={classnames(styles.section)}>

        <div className={styles.tabs}>
          <TabsSlider 
            noBg
            inline
            activeIndex={TABS?.findIndex(p => p.id === tagsTab)}
          >
            {TABS?.map(tab =>
              <TabsSlider.Item 
                onClick={event => setTagsTab(tab.id)}
                active={tagsTab === tab.id}
                key={tab.id}
              >
                <TabsSlider.Icon>
                  {renderIcon(tab.id)}
                </TabsSlider.Icon>
                {tab.title}
              </TabsSlider.Item>
            )}
          </TabsSlider>
        </div>

        <Cards>
          {CELLS.map(index => {
            const item = getItem(index);

            return (
              <Cards.Item 
                to={`/apps/search?${tagsTab}[id][]=${item.id}&order=popular`}
                title={item.name}
                subTitle={`${getCount(item.id)} Pages`}
                hideCollage={index > 5}
                key={randomKey()}
              > 
                {item.images?.length ? 
                  <Cards.AppScreens images={item.images} app />
                : 
                  <Cards.Background type={tagsTab} id={item.id} app />
                }
              </Cards.Item>
            );
          })}
        </Cards>
        
        <div className={styles.exploreWrap}>
          <Link className={styles.explore} to={getExploreUrl()}>
            Explore More
          </Link>
        </div>

        {/* <PatternCards type={tagsTab} apps /> */}
      </div>

      <div className={styles.tabs}>
        <div className={styles.tabLabel}>
          <Icons.AppStore width="32" height="32" className={styles.tabIconSites} prefix="home" />
          iOS Apps
        </div>
      </div>

      <AppFeed />

      <Hero3 
        apps
        title="We make design research fast and&nbsp;easy"
        subTitle="Our massive collection of design references from real-life products will inspire your creativity and make your design research a breeze."
      />

      <Layout.WithPadding>
        {!UserStore.isSignedIn() ?
          <SignupForm noClooseButton />
        :null}
      </Layout.WithPadding>
    </>
  );
});

export default HomeApps;
