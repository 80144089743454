import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import styles from './SearchSuggestions2.module.scss';

import CATEGORIES from 'json/categories.json';
import CATEGORIES_COUNT from 'json/categories-count.json';
import APP_CATEGORIES_COUNT from 'json/app-categories-count.json';

const Categories = observer(({ onSelect }) => {

  function getCount(id) {
    return (UIStore.searchType === 'sites' ? CATEGORIES_COUNT : APP_CATEGORIES_COUNT)?.[id] ?? 0;
  }

  return (
    <>
      <div className={styles.group}>
        <div className={styles.items}>
          {CATEGORIES?.map(item =>
            <div 
              className={classNames(styles.item, {
                [styles.selected]: item.id === SearchStore.selected
              })} 
              key={item.id}
              onClick={event => onSelect('categories', item.id)}
              id={`categories-${item.id}`}
            >
              {item.name}
              <div className={styles.count}>
                {getCount(item.id)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>   
  )
});

export default Categories;
