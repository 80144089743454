import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Layout/Layout';
import TeamsStore from 'stores/Teams';
import * as Icons from 'icons';
import UserStore from 'stores/User';
import styles from './Team.module.scss';
import AlertStore from 'stores/Alert';
import Button from 'components/Button/Button';
import TeamEdit from 'components/TeamEdit/TeamEdit';
import { Link } from 'react-router-dom';

export const Team = observer(() => {

  const field = useRef(null);
  const [ editing, setEditing ] = useState(false);

  useEffect(() => {
    TeamsStore.list();
  }, []); 

  const team = TeamsStore.firstTeam();

  async function copy() {
    if (!team) {
      return;
    }
    
    await navigator.clipboard.writeText(`${window.location.origin}/teams/join/${team?.token ?? ''}`);
    AlertStore.add('Invite link copied!', 'success');
  }

  function onFocus() {
    field.current.select();
  }

  function deleteUser(userId) {
    let confirmAction = window.confirm("Are you really want to remove member from the team?");
    if (confirmAction == true) {
      team.deleteUser(userId);
    }
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <div className={styles.info}>

            {team?.isAdmin(UserStore.id) ?
              <div className={styles.edit} onClick={event => setEditing(true)}>
                <Icons.Edit />
              </div>
            :null}
            
            <div className={styles.teamPicWrapper}>
              <img src={team?.logoUrl} alt="" />
            </div>

            <div>
              <div className={styles.name}>
                {team?.name}
              </div>

              <div className={styles.url}>
                {team?.domains?.[0]}
              </div>
            </div>

          </div>
        </div>

        <div className={styles.content}>
          
          {team?.token && team?.id !== 3 && team?.id !== 5 && team?.id !== 6 ?
            <div className={styles.header}>
              
              {team.isAdmin(UserStore.id) && !team.hasSubscription ?
                <div className={styles.alert}>
                  Your team is almost ready. The last step is to <Link to={`/teams/checkout/${team?.id}`}>set up a team subscription</Link>
                </div>
              : null}

              <h2 className={styles.title}>
                Invite new members
              </h2>

              <div className={styles.invite}>
                <input 
                  ref={field}
                  value={`${window.location.origin}/teams/join/${team?.token ?? ''}`} 
                  onFocus={onFocus}
                  readOnly
                  className={styles.field}
                  disabled={!team}
                />

                <Button 
                  square
                  block
                  onClick={copy}
                  className={styles.copy}
                >
                  Copy Link 
                </Button>
              </div>

              <div className={styles.note}>
                Add as many users as you need, price will adjust automatically.
              </div>

            </div>
          :null}


          <h2 className={styles.title}>
            Members {team?.members?.length}
          </h2>

          
          <div className={styles.members}>
            {team?.members?.map(member =>
              <div 
                className={styles.member}
                key={member?.id}
              > 
                <div className={styles.memberInfo}>
                  <img className={styles.memberPic} src={member.picture} alt={member?.email} />
                  {member?.email}
                </div>

                <div className={styles.role}>
                  {member.id === UserStore.id ? 'you, ' : ''}
                  {member.role}
                </div>
                
                {team?.isAdmin(UserStore.id) && member.id !== UserStore.id ?
                  <div className={styles.delete} onClick={event => deleteUser(member.id)}>
                    <Icons.Trash width="16" />
                  </div>
                : 
                  <div className={styles.blast} />
                }
              </div>
            )}
          </div>
        </div>
      </div>
      
      {team ?
        <TeamEdit 
          team={team}
          visible={editing} 
          changeVisibility={setEditing} 
        />
      :null}
    </Layout>
  )
});
