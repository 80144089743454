import { makeAutoObservable, runInAction } from 'mobx';
import ReferoApi from 'api/refero_api';
import AlertStore from './Alert';
import ScreenshotsStore from './Screenshots';
import SitesStore from './Sites';
import AppsStore from './Apps';
import Folder from './Folder';
import AppScreenshotsStore from './AppScreenshots';

class Bookmarks {

  list = [];
  storage = {};
  screenshots = [];
  fetching = false;
  folders = null;
  folderStorage = {};
  createFolderPopupVisible = false;
  deletePopupVisible = false;
  creatingFolder = false;
  screenToAdd = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch() {
    try {
      const { bookmarks } = await ReferoApi.bookmarks();
      runInAction(() => {
        bookmarks?.forEach(bookmark => this._storage(bookmark));
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async fetchScreenshots() {
    try {
      this.setFetching(true);
      const { screenshots } = await ReferoApi.bookmarksScreenshots();
      const webScreens = screenshots.filter(screen => !screen.app_id);
      const iosScreens = screenshots.filter(screen => screen.app_id);

      ScreenshotsStore.add(webScreens, 'bookmarks');
      AppScreenshotsStore.add(iosScreens, 'bookmarks');

      screenshots?.forEach(({site, app}) => {
        if (app) {
          AppsStore.add(app);
        } else {
          SitesStore.add(site);
        }
      });
      // ScreenshotsStore.add(screenshots, 'bookmarks');
      // screenshots.forEach(({site}) => SitesStore.add(site));
      
      this.setFetching(false);
    } catch (err) {
      console.log('got err', err);
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  async create(id) {
    try {
      const { bookmark } = await ReferoApi.bookmarksCreate(id);
      this._storage(bookmark);
      AlertStore.add('Added to Bookmarks', 'bookmark');
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  async delete(screenId) {
    try {
      const { id } = this.storage[screenId];
      await ReferoApi.bookmarksDelete(id);
      const index = this.screenshots?.indexOf(parseInt(screenId));
      if (index > -1) {
        runInAction(() => {
          this.screenshots.splice(index, 1);
          ScreenshotsStore.deleteScreenshot('bookmarks', parseInt(screenId))
        });
      }
      AlertStore.add('Deleted from Bookmarks', 'success');
    } catch (err) {
      console.log(err);
      AlertStore.add(err.message);
    }
  }

  isBookmarked(screenId) {
    return this.screenshots.indexOf(parseInt(screenId)) > -1;
  }

  clear() {
    this.list = [];
    this.storage = {};
    this.screenshots = [];
  }

  setFetching(state = false) {
    this.fetching = state;
  }

  _storage(bookmark) {
    this.screenshots.push(bookmark.screenshot_id);
    this.list.push(bookmark.id);
    this.storage[bookmark.screenshot_id] = bookmark;
  }

  async fetchFolders() {
    try {
      if (this.fetching) {
        return;
      }
      this.setFetching(true);
      const { records } = await ReferoApi.bookmarksFolders();
      runInAction(() => {
        this.folders = records?.sort((a, b) => b.updated_at?.localeCompare(a.updated_at))?.map(folder => folder.id);

        records?.forEach(record => {
          this.folderStorage[record.id] ??= new Folder(record);
          this.addScreenshots(record.id, record?.screenshots);
          // this.folderStorage[record.id].addPreviewScreenshots(record?.screenshots?.slice(0, 3));
        });
      });
      this.setFetching(false);
    } catch (err) {
      AlertStore.add(err.message);
      this.setFetching(false);
    }
  }

  async createFolder(name) {
    try {
      if (this.creatingFolder) {
        return;
      }
      this.creatingFolder = true;
      const folder = await ReferoApi.bookmarksFoldersCreate(name);
      runInAction(() => {
        this.folderStorage[folder.id] = new Folder(folder);
        this.folders.unshift(folder.id);
      });
    } catch (err) {
      AlertStore.add(err.message);
    } finally {
      runInAction(() => {
        this.creatingFolder = false;
      });
    }
  }

  async updateFolder(id, name) {
    try {
      if (this.creatingFolder) {
        return;
      }
      this.creatingFolder = true;
      const folder = await ReferoApi.bookmarksFoldersUpdate(id, name);
      runInAction(() => {
        // this.folderStorage[folder.id] = new Folder(folder);
        this.folderStorage[folder.id]?.update(folder);
      });
    } catch (err) {
      AlertStore.add(err.message);
    } finally {
      runInAction(() => {
        this.creatingFolder = false;
      });
    }
  }

  async deleteFolder(id) {
    try {
      if (this.creatingFolder) {
        return;
      }
      this.creatingFolder = true;
      await ReferoApi.bookmarksFoldersDelete(id);
      this._deleteFolder(id);
    } catch (err) {
      AlertStore.add(err.message);
    } finally {
      runInAction(() => {
        this.creatingFolder = false;
      });
    }
  }

  _deleteFolder(id) {
    const index = this.folders?.findIndex(folder => folder.id === id);
    if (index > 1) {
      this.folders.splice(index, 1);
    }
  }

  async showFolder(id) {
    try {
      const folder = await ReferoApi.bookmarksFolder(id);
      runInAction(() => {
        this.folderStorage[id] ??= new Folder(folder);
        this.addScreenshots(id, folder.screenshots);
      });
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  addScreenshots(folderId, screenshots) {
    const webScreens = screenshots.filter(screen => !screen.app_id);
    const iosScreens = screenshots.filter(screen => screen.app_id);

    ScreenshotsStore.add(webScreens, `bookmarks_${folderId}`);
    AppScreenshotsStore.add(iosScreens, `bookmarks_${folderId}`);

    screenshots?.forEach(({site, app}) => {
      if (app) {
        AppsStore.add(app);
      } else {
        SitesStore.add(site);
      }
    });
  }

  getFolder(id) {
    return this.folderStorage[id] ?? null;
  }

  setFolderPopupVisible(state) {
    this.createFolderPopupVisible = state;
  }

  setDeletePopupVisible(state) {
    this.deletePopupVisible = state;
  }

  hideAllFolderPopups() {
    this.setFolderPopupVisible(false);
    this.setDeletePopupVisible(false);
  }

  setScreenToAdd(id) {
    this.screenToAdd = id;
  }
   
}

const BookmarksStore = new Bookmarks();
export default BookmarksStore;
