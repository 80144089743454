export default function Copy(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width={9}
        height={9}
        x={10}
        y={10}
        stroke="currentColor"
        strokeWidth={1.6}
        rx={1}
      />
      <path
        stroke="currentColor"
        strokeWidth={1.6}
        d="M14 9.5V6a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h3.5"
      />
    </svg>
  )
};