import classnames from 'classnames';
import styles from './CategoryColor.module.scss';

export default function CategoryColor({ className, width, height }) {
  return (
    <div 
      className={classnames(styles.container, className)} 
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  )
}
