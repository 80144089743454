import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import useEscape from 'hooks/useEscape';
import styles from './TeamEdit.module.scss';
import TeamPic from 'components/TeamPic/TeamPic';
import Button from 'components/Button/Button';
import AlertStore from 'stores/Alert';

const TeamEdit = observer(({ team, visible, changeVisibility }) => {

  const [ hiding, setHiding ] = useState(false);
  const [ logoUrl, setLogoUrl ] = useState(team.logoUrl);
  const [ name, setName ] = useState(team.name);
  const [ domain, setDomain ] = useState(team?.domains?.[0] ?? '');

  useEffect(() => {
    return () => {
      document.body.classList.remove(styles.bodyFix);
    }
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.classList.add(styles.bodyFix);
      changeVisibility(true);
      setHiding(false);
    } else {
      document.body.classList.remove(styles.bodyFix);
      changeVisibility(false);
    }
  }, [ visible ]);

  useEscape(() => {
    close();
  });

  function close() {
    setHiding(true);
  }

  function onAnimationEnd() {
    if (hiding) {
      changeVisibility(false);
      document.body.classList.remove(styles.bodyFix);
    } 
  }

  async function submit(event) {
    try {
      event.preventDefault();
      team.update(name, logoUrl, [ domain ]);
      AlertStore.add('Team succesfully updated', 'success');
      close();
    } catch (err) {
      AlertStore.add(err.message);
    }
  }

  if (!visible) {
    return null;
  }

  return (
    <div 
      className={classnames(styles.container, {
        [styles.hiding]: hiding 
      })}
      onAnimationEnd={onAnimationEnd}
    >
      <div 
        className={styles.background} 
        onClick={close} 
      />

      <div className={styles.popup}>
        <div className={styles.body}>
          <form onSubmit={submit}>
            <div className={styles.title}>
              Edit Team
            </div>
            <TeamPic 
              className={styles.teamPic} 
              url={logoUrl}
              onReady={setLogoUrl}
            />

            <div className={styles.row}>
              <input 
                value={name}
                onChange={event => setName(event.target.value)}
                className={styles.field} 
                type="text" 
                placeholder="Team Name" 
                autoFocus 
                required
              />
            </div>
            <div className={styles.row}>
              <input 
                value={domain}
                onChange={event => setDomain(event.target.value)}
                className={styles.field} 
                type="text" 
                placeholder="Website Url" 
                required
              />
            </div>

            <div className={styles.buttons}>
              <Button light square className={styles.button} onClick={close}>
                Cancel
              </Button>
              <Button square className={styles.button}  element="button">
                Save
              </Button>
            </div>
          </form>

        </div>
      </div>

    </div>
  )
});

export default TeamEdit;
