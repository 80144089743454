import classnames from 'classnames';
import styles from './Title.module.scss';

 function Title({ children, priority = '1', tag, noMargin, lineHeightReset, capitalize, flex, paywall, className, uppercased }) {
  const TitleTag = tag ? tag : `h${priority}`;

  return (
    <TitleTag 
      className={classnames(styles.title, className, {
        [styles.hero]: priority === 'hero',
        [styles.primary]: priority === '1',
        [styles.secondary]: priority === '2',
        [styles.tertiary]: priority === '3',
        [styles.quaternary]: priority === '4',
        [styles.extra]: priority === 'extra',
        [styles.section]: priority === 'section',
        [styles.paywall]: paywall,
        [styles.noMargin]: noMargin,
        [styles.lineHeightReset]: lineHeightReset,
        [styles.capitalize]: capitalize,
        [styles.flex]: flex,
        [styles.uppercased]: uppercased,
      })}
    >
      {children}
    </TitleTag>
  );
}

function Icon({ children }) {
  return (
    <div className={styles.icon}>
      {children}
    </div>
  )
}

Title.Icon = Icon;

export default Title;