import { memo } from 'react';
import SiteHead from 'components/SiteHead/SiteHead';
import ScreenLink from 'components/ScreenLink/ScreenLink';
// import BookmarksStore from 'stores/Bookmarks';
import ThumbControls from 'components/ThumbControls/ThumbControls';
import styles from './SearchResult.module.scss';

function SearchResult({
  site, 
  screenshot, 
  url, 
  replaceOnClick = false, 
  mixpanelEvent,
  app, 
  isApp = false, 
  showSignUpOnClick, 
  redirectToPrice,
  selected = false
}) {

  // function onMouseEnter() {
  //   screenshot?.setBookmarked(BookmarksStore.isBookmarked(screenshot.id));
  // }

  return (
    <div 
      className={styles.container} 
      // onMouseEnter={onMouseEnter}
    >
      <ScreenLink 
        replaceOnClick={replaceOnClick}
        data={screenshot} 
        site={site}  
        url={url}
        app={app}
        appScreen={isApp}
        mixpanelEvent={mixpanelEvent}
        disableUrl={showSignUpOnClick || redirectToPrice}
        showSignUpOnClick={showSignUpOnClick}
        redirectToPrice={redirectToPrice}
        className={styles.img}
        selected={selected}
      />

      <div className={styles.info}>
        <SiteHead 
          site={site ?? app}
          app={app} 
          inline
          noDesc
          source="search"
        />

        {screenshot?.isNew() ?
          <div className={styles.new}>
            New
          </div>
        : null}
      </div>
      
      {!redirectToPrice ?
        <ThumbControls 
          screenshot={screenshot}
          app={app}
          site={site}
          className={styles.thumbs}
        />
      : null}
    </div>
  );
}

function areEqual(prev, next) {
  // console.log('next', next);
  return prev.selected === next.selected 
    && prev.url === next.url 
    && prev.showSignUpOnClick === next.showSignUpOnClick;
}

export default memo(SearchResult, areEqual);

