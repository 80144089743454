import isIndianUser from './is-indian-user';
import isBrazilianUser from './is-brazilian-user';
// import getABValue from 'helpers/get-ab-value';

// const pricingTest2 = getABValue('_annual_pricing', ['old', 'next']);
// const testWeekly = getABValue('_weekly_pricing', ['default', 'weekly']);

export default function priceKey(alwaysDefault = false) {
  if (isIndianUser()) {
    return 'sD3c4Gth';
  }

  if (isBrazilianUser()) {
    return 'BcR4G8';
  }

  if (alwaysDefault) {
    return 'default';
  }

  // if (testWeekly === 'weekly') {
  //   return 'weekly';
  // }

  // if (pricingTest2 === 'next') {
  //   return 'next2';
  // }

  // if (pricingTest === '1') {
  //   return 'default';
  // }

  // return 'next';
  return 'default';
}