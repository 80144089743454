import { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Placeholder from 'components/Placeholder/Placeholder';
import AppScreenshotsStore from 'stores/AppScreenshots';
import styles from './AppPreview.module.scss';

function AppPreview({ 
  app,
  screenshots,
  source,
  compact,
  plain
}) {
  const [ logoLoaded, setLogoLoaded ] = useState(false);
  const [ photo1Loaded, setPhoto1Loaded ] = useState(false);
  const [ photo2Loaded, setPhoto2Loaded ] = useState(false);

  return (
    <Link
      className={classnames(styles.container, {
        [styles.compact]: compact
      })}
      to={`/apps/${app?.id}`}
    >
      <div 
        className={styles.bg} 
        style={{ 
          backgroundColor: `#${app?.backgroundColor ? app.backgroundColor : 'AEB8D6'}`,
        }}
      />

      <div className={styles.head}>
      {app ?
          <div className={classnames(styles.faviconWrap, {
            [styles.faviconLoaded]: logoLoaded
          })}>
            <img 
              className={styles.favicon}
              src={app?.faviconUrl} 
              onLoad={event => setLogoLoaded(true)}
              alt="" 
            />
          </div>
        : 
          <Placeholder name="sitePicLarge" disableAnimation />
        }

      </div>
      
      <div>
        <div className={styles.title}>
          {app?.name}
        </div>

        {app?.description ? 
          <div className={styles.desc}>
            {app.description}
          </div>
        : null}
      </div>
      

      {!compact && (screenshots?.slice(0,2))?.map((item, index) => {
        const screenshot = plain ? item : AppScreenshotsStore.show(item);

        return (
          <div 
            className={classnames(styles.imageContainer, {
              [styles.imageContainerMain]: index === 0
            })}
            style={{ color: `#${app?.backgroundColor ? app.backgroundColor : 'AEB8D6'}66` }}
            key={index}
          >
            <img 
              className={classnames(styles.image, styles[`image-${index}`], {
                [styles.loaded]: index === 0 ? photo1Loaded : photo2Loaded
              })} 
              src={screenshot.thumbnail} 
              onLoad={event => index === 0 ? setPhoto1Loaded(true) : setPhoto2Loaded(true)}
              loading="lazy"
              alt="" 
            />
          </div>
        )
      })}        

    </Link>
  );
}

function areEqual(prev, next) {
  // console.log('next', next);
  // return false;
  return prev.app?.id === next.app?.id;
}

export default memo(AppPreview, areEqual);