export default function ArrowNE2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="m12.077 9.58.166-5.823m0 0-5.823.167m5.823-.167-8.485 8.486"
      />
    </svg>
  )
}
