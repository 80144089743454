const TIME_ZONES = [
  'america/eirunepe',
  'america/rio_Branco',
  'america/boa_vista',
  'america/campo_grande',
  'america/cuiaba',
  'america/manaus',
  'america/porto_velho',
  'america/araguaina',
  'america/bahia',
  'america/belem',
  'america/fortaleza',
  'america/maceio',
  'america/recife',
  'america/santarem',
  'america/sao_paulo',
  'america/noronha'
];

const timeZome = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone?.toLowerCase();

export default function isBrazilianUser() {
  return TIME_ZONES?.includes(timeZome);
}
