import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './ButtonPro.module.scss';

function ButtonPro({ className, block, onClick }) {
  return (
    <Link 
      to="/upgrade"
      className={classnames(styles.container, className, {
        [styles.block]: block
      })}
      onClick={onClick}
    >
      <div className={styles.badge}>
        Pro
      </div>
      <div className={styles.label}>
        Get Refero Pro
      </div>
    </Link>
  );
}

export default ButtonPro;
