import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import SearchStore from 'stores/Search';
import UIStore from 'stores/UI';
import styles from './SearchSuggestions2.module.scss';

import ELEMENTS from 'json/elements-count.json';
import APP_ELEMENTS from 'json/app-elements-count.json';
import renderTagName from 'helpers/render-tag-name';

const Elements = observer(({ onSelect }) => {

  function getCount(id) {
    return (UIStore.searchType === 'sites' ? ELEMENTS : APP_ELEMENTS)?.[id] ?? 0;
  }

  return (
    <>
      {SearchStore.elementKinds?.map(kind =>
        <div className={styles.group} key={kind}>
          <div className={styles.kind} >
            {kind}
          </div>
          <div className={styles.items}>
            {SearchStore.elementFilter(kind)?.map(item =>
              <div 
                className={classNames(styles.item, {
                  [styles.selected]: item.id === SearchStore.selected || item.active
                })} 
                key={item.id}
                onClick={event => onSelect('page_elements', item.id)}
                id={`page_elements-${item.id}`}
              >
                {renderTagName(item.name, item.app_name)}
                {/* {item.name} */}
                <div className={styles.count}>
                  {getCount(item.id)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>   
  )
});

export default Elements;
