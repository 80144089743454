export default function Search5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.252 5.004a7 7 0 1 0 3.82 12.69L17.377 20a1.5 1.5 0 0 0 2.121-2.121l-2.414-2.415a6.968 6.968 0 0 0 .912-3.718c-.834.767-2.175.764-3.005-.01a4 4 0 1 1-3.727-3.727c-.775-.83-.78-2.17-.012-3.005Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M16.208 2.783c.099-.27.48-.27.579 0l.876 2.369a.309.309 0 0 0 .183.182l2.369.877c.268.099.268.48 0 .579l-2.37.876a.309.309 0 0 0-.182.183l-.876 2.368a.309.309 0 0 1-.58 0l-.876-2.368a.309.309 0 0 0-.182-.183l-2.37-.876a.309.309 0 0 1 0-.58l2.37-.876a.309.309 0 0 0 .182-.182l.877-2.37Z"
      />
    </svg>
  )
}

