import { useEffect } from 'react';
import Button from 'components/Button/Button';
import styles from './FetchError.module.scss';
import mixpanel from 'api/mixpanel';

export default function FetchError({ onClick }) {

  useEffect(() => {
    mixpanel.track('FetchErrorRender', { desktop: true });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.chip}>
        <div className={styles.illustration} />
        Failed to Fetch
      </div>

      <div className={styles.text}>
        This could be due to things like slow internet or server hiccups.<br /> Just hit refresh and try again! 
      </div>

      <Button onClick={onClick}>
        Refresh
      </Button>
    </div>
  )
}