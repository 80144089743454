export default function AppStore(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill={`url(#${props.prefix ?? ''}AppStoreGradient)`}
        fillRule="evenodd"
        d="M5.558 17.024c.64 0 1.23.243 1.708.652a.157.157 0 0 1 .031.197l-1.2 2.069a1.279 1.279 0 0 1-1.872.384c-.529-.392-.649-1.138-.318-1.708l.797-1.374a.251.251 0 0 1 .144-.116 2.49 2.49 0 0 1 .71-.104ZM13.56 7.99a.148.148 0 0 1 .256-.002l3.59 6.115h2.983c.668 0 1.264.489 1.328 1.154a1.28 1.28 0 0 1-1.273 1.405H18.89l1.134 1.955c.33.57.21 1.315-.318 1.707a1.277 1.277 0 0 1-1.872-.383l-2.77-4.774-2.167-3.756a.247.247 0 0 1-.033-.104 5.438 5.438 0 0 1-.018-.433c0-1.042.222-2.046.714-2.884Zm-1.252-3.854a1.28 1.28 0 0 1 1.854-.396c.544.389.667 1.151.331 1.73l-5.01 8.633h3.929c.076 0 .153.019.218.059a2.264 2.264 0 0 1 1.065 1.936 2.3 2.3 0 0 1-.056.497.086.086 0 0 1-.084.067H3.556a1.28 1.28 0 0 1-1.273-1.405c.064-.665.66-1.154 1.328-1.154h2.915l3.96-6.826L9.41 5.422a1.28 1.28 0 0 1 .577-1.807c.607-.279 1.329-.008 1.664.57l.315.544.344-.592Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id={`${props.prefix ?? ''}AppStoreGradient`}
          x1={12}
          x2={12}
          y1={3.5}
          y2={32.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6BE0FB" />
          <stop offset={0.51} stopColor="#478EEE" />
          <stop offset={1} stopColor="#3565E8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
