import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as Icons from 'icons';
import SearchStore from 'stores/Search';
import styles from './Sort.module.scss';

const Sort = observer(() => {
  const navigate = useNavigate();

  function onChange({target: {value}}) {
    SearchStore.setOrder(value);    
    navigate(SearchStore.searchUrl());
  }

  return (
    <div className={styles.container}>
      <Icons.ArrowBottom className={styles.icon} />
      <select 
        className={styles.select}
        onChange={onChange}
        value={SearchStore.order}
      >
        <option value="default">
          Sort by Relevance
        </option>
        <option value="popular">
          Sort by Popularity
        </option>
      </select>
    </div>
  )
});

export default Sort;
