import { useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PrismicProvider } from '@prismicio/react';
import { HelmetProvider } from 'react-helmet-async';
import { HotkeysProvider } from 'react-hotkeys-hook';
// import Home from 'pages/Home/Home';
import Home2 from 'pages/Home2/Home2';
import HomeApps from 'pages/HomeApps/HomeApps';
import Site from 'pages/Site/Site';
import App from 'pages/App/App';
import Screen from 'pages/Screen/Screen';
import AppScreen from 'pages/AppScreen/AppScreen';
import Search from 'pages/Search/Search';
import SearchApps from 'pages/SearchApps/SearchApps';
import Terms from 'pages/Terms/Terms';
import MediaKit from 'pages/MediaKit/MediaKit';
import NotFound from 'pages/NotFound/NotFound';
import Bookmarks from 'pages/Bookmarks/Bookmarks';
import Folder from 'pages/Folder/Folder';
import Price from 'pages/Price/Price';
import Tags from 'pages/Tags/Tags';
import Redirect from 'pages/Redirect/Redirect';
import PaymentLinkRedirect from 'pages/PaymentLinkRedirect/PaymentLinkRedirect';
import Checkout from 'pages/Checkout/Checkout';
import Billing from 'pages/Billing/Billing';
import Affiliate from 'pages/Affiliate/Affiliate';
// import Feed from 'pages/Feed/Feed';
import HowItWorks from 'pages/HowItWorks/HowItWorks';
import Feedback from 'pages/Feedback/Feedback';
import ExtendTrial from 'pages/ExtendTrial/ExtendTrial';
import Header from 'components/Header/Header';
import Alerts from 'components/Alerts/Alerts';
import Footer from 'components/Footer/Footer';
// import SignupForm from 'components/SingupForm/SignupForm';
import Downloads from 'components/Downloads/Downloads';
// import Survey from 'components/Survey/Survey';
// import NewPricing from 'components/NewPricing/NewPricing';
import WelcomePro from 'components/WelcomePro/WelcomePro';
import Onboarding from 'components/Onboarding/Onboarding';
import Selection from 'components/Selection/Selection';
import BookmarksPopup from 'components/BookmarksPopup/BookmarksPopup';
import { TeamCreate, TeamInvite, TeamCheckout } from 'pages/TeamCreate/TeamCreate';
// import ProductHuntBanner from 'components/ProductHuntBanner/ProductHuntBanner';
import { client } from 'api/prismic';
import styles from './Root.module.scss';
import SearchStore from 'stores/Search';
import SignupPopup from 'components/SignupPopup/SignupPopup';
// import Survey2 from 'components/Survey2/Survey2';
import Flow from 'pages/Flow/Flow';
import PastDueBanner from 'components/PastDueBanner/PastDueBanner';
import { Team } from 'pages/Team/Team';
import { TeamJoin } from 'pages/TeamJoin/TeamJoin';
import TeamSuccess from 'pages/TeamSuccess/TeamSuccess';
// import SurveyGoogleForm from 'components/SurveyGoogleForm/SurveyGoogleForm';
// import Home3 from 'pages/Home3/Home3';
// import getABValue from 'helpers/get-ab-value';
// import UIStore from 'stores/UI';
// import ChristmasOffer from 'components/ChristmasOffer/ChristmasOffer';
// import TrialPopup from 'components/TrialPopup/TrialPopup';
// import TrialPopupExtend from 'components/TrialPopupExtend/TrialPopupExtend';

export default function Root() {
  const location = useLocation();
  const isInitialLocation = useRef(true);
  // const homeScreenAB = useMemo(() => getABValue('_home_screen_ab', ['compact', 'unfolded']), []);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    const metaTags = document.querySelectorAll('meta[name="theme-color"]');
    if (theme === 'dark') {
      document.body.setAttribute('id', 'dark-theme');
      metaTags?.forEach(tag => tag.setAttribute('content', '#13151A'));
    }
    if (theme === 'light') {
      document.body.setAttribute('id', 'light-theme');
      metaTags?.forEach(tag => tag.setAttribute('content', '#ffffff'));
    }
  }, []);

  useEffect(() => {
    isInitialLocation.current = false;
    if (location?.state?.preventScrollTop) {
      return;
    }
    window?.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    SearchStore.addParams(location.search);
  }, [location.search]);

  const isPopupView = !!location?.state?.prevPath && !isInitialLocation.current;

  return (
    <PrismicProvider client={client}>
      <HelmetProvider>
        <HotkeysProvider initiallyActiveScopes={[ 'screen', 'login', 'bookmarks' ]}>
          <div className={styles.container}>
            <Header hidden={isPopupView} />
            <PastDueBanner />
            <Routes>
              <Route path="/" element={<ViewWrapper><Home2 /></ViewWrapper>} />
              {/* <Route path="/" element={<ViewWrapper>{homeScreenAB === 'compact' ? <Home2 /> : <Home3 />}</ViewWrapper>} /> */}

              {/* <Route path="/" element={<ViewWrapper><Home2 /></ViewWrapper>} /> */}
              {/* <Route path="/" element={<ViewWrapper><Home3 /></ViewWrapper>} /> */}
              {/* <Route path="/feed" element={<ViewWrapper><Feed /></ViewWrapper>} /> */}
              <Route path="/apps" element={<ViewWrapper><HomeApps /></ViewWrapper>} />
              <Route path="/success" element={<ViewWrapper><Home2 /><WelcomePro /></ViewWrapper>} />
              <Route path="/search" element={<ViewWrapper><Search /></ViewWrapper>} />
              
              <Route path="/how-it-works" element={<ViewWrapper><HowItWorks /></ViewWrapper>} />
              <Route path="/apps/search" element={<ViewWrapper><SearchApps /></ViewWrapper>} />
              <Route path="/bookmarks" element={<ViewWrapper><Bookmarks /></ViewWrapper>} />
              <Route path="/bookmarks/:id" element={<ViewWrapper><Folder /></ViewWrapper>} />
              <Route path="/bookmarks/:id/:type" element={<ViewWrapper><Folder /></ViewWrapper>} />
              <Route path="/privacy-policy" element={<ViewWrapper><Terms uid="privacy-policy" /></ViewWrapper>} />
              <Route path="/extended-trial" element={<ViewWrapper><ExtendTrial /></ViewWrapper>} />
              <Route path="/terms-of-use" element={<ViewWrapper><Terms uid="terms-of-use" /></ViewWrapper>} />
              <Route path=":siteId-:domain" element={<ViewWrapper><Site /></ViewWrapper>} />
              <Route path=":siteId-:domain/:id" element={<ViewWrapper><Screen isPopup={isPopupView} /></ViewWrapper>} />
              <Route path="/apps/:id" element={<ViewWrapper><App /></ViewWrapper>} />
              <Route path="/apps/:id/flows" element={<ViewWrapper noFooter><Flow /></ViewWrapper>} />
              <Route path="/apps/:appId/:id" element={<ViewWrapper><AppScreen isPopup={isPopupView} /></ViewWrapper>} />
              <Route path="/price" element={<ViewWrapper><Price /></ViewWrapper>} />
              <Route path="/pricing" element={<ViewWrapper><Price /></ViewWrapper>} />
              <Route path="/upgrade" element={<ViewWrapper><Price onePrice title="Get Unlimited Access to Everything on Refero" /></ViewWrapper>} />
              <Route path="/trial" element={<ViewWrapper><Price onePrice title="Your Trial Has Expired" subTitle="Upgrade your account to Pro now to&nbsp;access the entire collection of&nbsp;references and elevate your design process to&nbsp;new heights." /></ViewWrapper>} />
              <Route path="/billing" element={<ViewWrapper><Billing /></ViewWrapper>} />
              <Route path="/checkout" element={<ViewWrapper><Checkout /></ViewWrapper>} />
              <Route path="/mediakit" element={<ViewWrapper><MediaKit /></ViewWrapper>} />
              <Route path="/feedback" element={<ViewWrapper><Feedback /></ViewWrapper>} />
              <Route path="/affiliate" element={<ViewWrapper><Affiliate /></ViewWrapper>} />
              
              <Route path="/pagetypes" element={<ViewWrapper><Tags source="pagetypes" /></ViewWrapper>} />
              <Route path="/patterns" element={<ViewWrapper><Tags source="patterns" /></ViewWrapper>} />
              <Route path="/elements" element={<ViewWrapper><Tags source="elements" /></ViewWrapper>} />

              <Route path="/teams" element={<ViewWrapper><Team /></ViewWrapper>} />
              <Route path="/teams/create" element={<ViewWrapper><TeamCreate /></ViewWrapper>} />
              {/* <Route path="/teams/invite" element={<ViewWrapper><TeamInvite /></ViewWrapper>} /> */}
              <Route path="/teams/invite/:id" element={<ViewWrapper><TeamInvite /></ViewWrapper>} />
              <Route path="/teams/checkout/:id" element={<ViewWrapper><TeamCheckout /></ViewWrapper>} />
              <Route path="/teams/join/:token" element={<ViewWrapper><TeamJoin /></ViewWrapper>} />
              <Route path="/teams/success" element={<ViewWrapper><TeamSuccess /></ViewWrapper>} />

              <Route path="/apps/pagetypes" element={<ViewWrapper><Tags source="pagetypes" type="apps" /></ViewWrapper>} />
              <Route path="/apps/patterns" element={<ViewWrapper><Tags source="patterns" type="apps" /></ViewWrapper>} />
              <Route path="/apps/elements" element={<ViewWrapper><Tags source="elements" type="apps" /></ViewWrapper>} />

              <Route path="/patterns/:id" element={<Redirect />} />
              <Route path="/pagetypes/:id" element={<Redirect param="page_types" />} />
              <Route path="/elements/:id" element={<Redirect param="page_elements" />} />
              <Route path="/example/:id" element={<Redirect search />} />

              <Route path="/payment/:url" element={<PaymentLinkRedirect />} />

              <Route path="*" element={<ViewWrapper><NotFound /></ViewWrapper>} />
            </Routes>
            <Alerts />
            <Downloads />
            {/* <Survey /> */}
            {/* <Survey2 /> */}
            {/* <SurveyGoogleForm /> */}
            {/* <NewPricing /> */}
            {/* <ChristmasOffer /> */}
            
            {/* <SignupForm /> */}
            <SignupPopup />
            <Onboarding />
            <BookmarksPopup />

            <Selection />
            
            {/* <ProductHuntBanner /> */}

            {/* <TrialPopup /> */}
            {/* <TrialPopupExtend /> */}
          </div>
        </HotkeysProvider>
      </HelmetProvider>
    </PrismicProvider>
  );
}

function ViewWrapper({ children, noFooter = false }) {
  return (
    <>
      {children}
      {!noFooter && <Footer />}
    </>
  )
}